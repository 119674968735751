import React from 'react';
import { hp } from '../../utils/responsive';
import TextLato from '../../components/TextLato';
import add from '../../assets/images/add-icon.png';
import sales from '../../assets/images/sales-add-product.png';
import orders from '../../assets/images/sales-see-order.png';
class MainSales extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  render() {
    const textStyle = {
      fontSize: hp(2.3),
      fontWeight: '500',
    };
    const { navigate } = this.props;
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ height: hp(8) }} />
        <div
          onClick={() => {
            navigate(null, 1);
          }}
          style={{
            cursor: 'pointer',
            boxShadow: '3px 5px 30px rgba(0,0,0,0.2)',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: hp(1.3),
            width: '40%',
            flex: 1,
            display: 'flex',
          }}
        >
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <img src={sales} style={{ height: hp(16) }} />
          </div>

          <TextLato style={textStyle}>Agregar Productos</TextLato>
          <img src={add} style={{ height: hp(4.2), marginTop: hp(1), marginBottom: hp(3.5) }} />
        </div>
        <div style={{ height: hp(4) }} />
        <div
          onClick={() => {
            navigate(null, 2);
          }}
          style={{
            cursor: 'pointer',
            boxShadow: '3px 10px 30px rgba(0,0,0,0.2)',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: hp(1.3),
            width: '40%',
            flex: 1,
            display: 'flex',
          }}
        >
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <img src={orders} style={{ height: hp(16) }} />
          </div>
          <TextLato style={textStyle}>Ver Pedidos</TextLato>
          <img src={add} style={{ height: hp(4.2), marginTop: hp(1), marginBottom: hp(3.5) }} />
        </div>
        <div style={{ height: hp(8) }} />
      </div>
    );
  }
}

export default MainSales;
