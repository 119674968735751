import React from 'react';
import './index.css';
import { hp, wp } from '../../utils/responsive';
import searchIcon from '../../assets/images/search-icon.png';
import iconHidePass from '../../assets/images/contraseña1.svg';
import iconShowPass from '../../assets/images/contrseña2.svg';
import Spacer from '../Spacer';
import TextLato from '../TextLato';
class InputBox extends React.Component {
  state = {
    passInput: false,
  };
  componentDidMount = () => {
    const { pass } = this.props;
    this.setState({ passInput: pass });
  };
  render() {
    const {
      onChangeText,
      pass,
      value,
      placeholder,
      search,
      levelShadow = 30,
      style = {},
      disabled,
      name,
      prefix,
      id
    } = this.props;
    var { fieldStyle } = this.props
    if (fieldStyle == null) fieldStyle = {};
    return (
      <div
        className="field"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          paddingTop: hp(2.5),
          paddingBottom: hp(2.5),
          borderRadius: hp(1.2),
          boxShadow: `2px 0px ${levelShadow}px rgba(0,0,0,0.2)`,
          ...style,
        }}
      >
        {search && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Spacer width={hp(2.4)} />
            <img src={searchIcon} style={{ height: (wp(0.3) + hp(1.5)) * 1.1 }} />
          </div>
        )}
        <div style={{ display: 'flex', flex: 1, marginRight: '0.7rem' }}>
          {prefix && (
            <TextLato
              style={{
                color: disabled ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,1)',
                ...fieldStyle,
              }}
            >
              {prefix}
            </TextLato>
          )}
          <input
            disabled={disabled}
            placeholder={placeholder || ''}
            value={value}
            style={{
              fontSize: wp(0.3) + hp(1.5) < 13 ? 13 : wp(0.3) + hp(1.5),
              paddingLeft: search ? hp(2.4) : hp(3),
              paddingRight: hp(3),
              ...(disabled ? { color: '#C4C4C4' } : {}),
            }}
            name={name ? name : ''}
            id={id ? id : ''}
            type={this.state.passInput ? 'password' : 'text'}
            onChange={(e) => {
              if (onChangeText != null) {
                onChangeText(e.target.value, name);
              }
            }}
          />
          {pass && (
            <button
              style={{ border: 'none', cursor: 'pointer', background: 'transparent' }}
              value="Ver"
              onClick={() => this.setState({ passInput: !this.state.passInput })}
            >
              {' '}
              <img
                src={this.state.passInput ? iconHidePass : iconShowPass}
                style={{ height: '25px' }}
                alt="CKEditor 5 logo"
              />
            </button>
          )}
        </div>
      </div>
    );
  }
}
export default InputBox;
