import React from 'react';
import './index.css';
import { hp } from '../../utils/responsive';
import Spacer from '../Spacer';
import TextLato from '../TextLato';
import DatePicker from 'react-datepicker';
import moment from "moment"
class InputDate extends React.Component {
    render() {
        var { onChangeText, placeholder, value, style, bottomSpc, name, disabled, fieldStyle, onBlur, prefix, maxDate } = this.props
        if (fieldStyle == null) fieldStyle = {}
        return <div
            className="input"
            style={{
                width: '100%',
                display: 'flex',
                position: 'relative',
                flexDirection: "column",
                alignItems: 'stretch', justifyContent: 'center',
                borderRadius: hp(1.2), ...style
            }}>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: "row",
                alignItems: 'center', justifyContent: 'flex-start',
            }}>
                {prefix && <TextLato style={{
                    color: disabled ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,1)",
                    ...fieldStyle,
                }}>{prefix}</TextLato>}
                <input onBlur={onBlur != null ? onBlur : () => { }} disabled={disabled} value={value} style={{
                    fontSize: hp(2.2), display: 'flex', flex: 1,
                    color: disabled ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,1)", ...fieldStyle
                }} placeholder={placeholder || "Ingrese texto..."} onChange={(e) => {
                    if (onChangeText != null) {
                        onChangeText(e.target.value, name)
                    }
                }} />
            </div>
            <Spacer height={bottomSpc} />
            <div style={{ height: 1, backgroundColor: disabled ? 'rgba(100,100,100,0.2)' : 'rgba(100,100,100,0.4)' }} />
            <div
                onClick={() => {
                    this.datepicker.setOpen(true);
                }}
                style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 20,
                    backgroundColor: 'transparent',
                }}
            >
                <DatePicker
                    onClickOutside={() => { }}
                    customInput={<div></div>}
                    maxDate={maxDate ? maxDate : moment('01/01/1920', 'DD/MM/YYYY').toDate()}
                    ref={(x) => (this.datepicker = x)}
                    selected={
                        value != null && value != '' ? moment(value, 'DD/MM/YYYY').toDate() : new Date()
                    }
                    onChange={(x) => onChangeText(moment(x).format('DD/MM/YYYY'), name)}
                />
            </div>
        </div>
    }
}
export default InputDate;