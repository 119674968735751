import React from 'react';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import Input from '../Input';
import Spacer from '../Spacer';
import Checkbox from '../Checkbox';
import Button from '../Button';
import BoxImage from '../BoxImage';
class FormProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.initialData != null ? props.initialData.name : '',
      email: props.initialData != null ? props.initialData.email : '',
      phone: props.initialData != null ? props.initialData.phone : '',
      address: props.initialData != null ? props.initialData.address : '',
      initial: props.initialData != null ? props.initialData : null,
      pesoProm:
        props.initialData != null
          ? props.initialData.pesoProm
            ? props.initialData.pesoProm
            : 0
          : 0,
    };
  }
  onChangeText = (value, key) => {
    const { onChange } = this.props;
    this.setState(
      {
        [key]: value,
      },
      () => {
        if (onChange) {
          onChange({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            pesoProm: this.state.pesoProm,
          });
        }
      },
    );
  };
  render() {
    const {
      style,
      admin,
      reduced,
      placeholders = {
        name: ' ',
        email: ' ',
        phone: ' ',
        address: ' ',
        pesoProm: ' ',
      },
    } = this.props;
    const { name, email, phone, address, pesoProm } = this.state;
    const labelStyle = {
      fontSize: hp(1.8),
      opacity: 0.7,
    };
    const inputStyle = {
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    const fieldStyle = {
      fontSize: hp(2.2),
      fontFamily: 'Lato',
      fontWeight: '400',
    };
    return (
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          // boxShadow: "2px 0px 20px rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,

            position: 'absolute',
            top: hp(0.6),
            left: hp(0.6),
            right: hp(0.6),
            bottom: hp(0.6),
            zIndex: 2,
            alignItems: 'center',
            borderRadius: hp(1.2),
            boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            zIndex: 3,
            width: '100%',
            flex: 1,
            borderRadius: hp(1.2),
            paddingTop: hp(3),
            paddingBottom: hp(3),
            ...style,
          }}
        >
          <Spacer width={hp(3)} />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {reduced ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <TextLato style={labelStyle}>Nombre del establecimiento</TextLato>
                  <Spacer height={hp(0.8)} />
                  <Input
                    disabled={!admin}
                    value={name}
                    fieldStyle={fieldStyle}
                    onChangeText={this.onChangeText}
                    name="name"
                    bottomSpc={hp(0.6)}
                    style={inputStyle}
                    placeholder={placeholders.name}
                  />
                </div>
                <Spacer width={hp(2)} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <TextLato style={labelStyle}>E-mail de contacto</TextLato>
                  <Spacer height={hp(0.8)} />
                  <Input
                    disabled={!admin}
                    value={email}
                    fieldStyle={fieldStyle}
                    onChangeText={this.onChangeText}
                    name="email"
                    bottomSpc={hp(0.6)}
                    style={inputStyle}
                    placeholder={placeholders.email}
                  />
                </div>
                <Spacer width={hp(2)} />
                {admin && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <TextLato style={labelStyle}>Peso promedio kg</TextLato>
                    <Spacer height={hp(0.8)} />
                    <Input
                      disabled={!admin}
                      value={pesoProm}
                      fieldStyle={fieldStyle}
                      onChangeText={this.onChangeText}
                      name="pesoProm"
                      bottomSpc={hp(0.6)}
                      style={inputStyle}
                      placeholder={placeholders.pesoProm}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TextLato style={labelStyle}>Nombre del establecimiento</TextLato>
                <Spacer height={hp(0.8)} />
                <Input
                  disabled={!admin}
                  value={name}
                  fieldStyle={fieldStyle}
                  onChangeText={this.onChangeText}
                  name="name"
                  bottomSpc={hp(0.6)}
                  style={inputStyle}
                  placeholder={placeholders.name}
                />
                <Spacer height={hp(0.8)} />
                <TextLato style={labelStyle}>E-mail de contacto</TextLato>
                <Spacer height={hp(0.8)} />
                <Input
                  disabled={!admin}
                  value={email}
                  fieldStyle={fieldStyle}
                  onChangeText={this.onChangeText}
                  name="email"
                  bottomSpc={hp(0.6)}
                  style={inputStyle}
                  placeholder={placeholders.email}
                />
              </div>
            )}
            <Spacer height={hp(0.8)} />
            {reduced ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 3,
                  }}
                >
                  <TextLato style={labelStyle}>Teléfono</TextLato>
                  <Spacer height={hp(0.8)} />
                  <Input
                    disabled={!admin}
                    value={phone}
                    fieldStyle={fieldStyle}
                    onChangeText={this.onChangeText}
                    name="phone"
                    bottomSpc={hp(0.6)}
                    style={inputStyle}
                    placeholder={placeholders.phone}
                  />
                </div>
                <Spacer width={hp(2)} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 9,
                  }}
                >
                  <TextLato style={labelStyle}>Dirección</TextLato>
                  <Spacer height={hp(0.8)} />
                  <Input
                    disabled={true}
                    value={address}
                    fieldStyle={fieldStyle}
                    onChangeText={this.onChangeText}
                    name="address"
                    bottomSpc={hp(0.6)}
                    style={inputStyle}
                    placeholder={placeholders.address}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TextLato style={labelStyle}>Teléfono</TextLato>
                <Spacer height={hp(0.8)} />
                <Input
                  disabled={!admin}
                  value={phone}
                  fieldStyle={fieldStyle}
                  onChangeText={this.onChangeText}
                  name="phone"
                  bottomSpc={hp(0.6)}
                  style={inputStyle}
                  placeholder={placeholders.phone}
                />
                <Spacer height={hp(0.8)} />
                <TextLato style={labelStyle}>Dirección</TextLato>
                <Spacer height={hp(0.8)} />
                <Input
                  disabled={!admin}
                  value={address}
                  fieldStyle={fieldStyle}
                  onChangeText={this.onChangeText}
                  name="address"
                  bottomSpc={hp(0.6)}
                  style={inputStyle}
                  placeholder={placeholders.address}
                />
              </div>
            )}
          </div>

          <Spacer width={hp(3)} />
        </div>
      </div>
    );
  }
}
export default FormProfile;
