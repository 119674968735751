const {
  REACT_APP_ADMIN_USERNAME: ADMIN_USERNAME,
  REACT_APP_ADMIN_PASSWORD: ADMIN_PASSWORD,
  REACT_APP_MARKETING_USERNAME: MARKETING_USERNAME,
  REACT_APP_MARKETING_PASSWORD: MARKETING_PASSWORD,
  REACT_APP_COMERCIAL_USERNAME: COMERCIAL_USERNAME,
  REACT_APP_COMERCIAL_PASSWORD: COMERCIAL_PASSWORD,
  REACT_APP_GOOGLEMAPS_KEY: GOOGLEMAPS_KEY,
  REACT_APP_FIREBASE_KEY: FIREBASE_KEY,
} = process.env;

const DEBUG = false;

export {
  DEBUG,
  ADMIN_USERNAME,
  ADMIN_PASSWORD,
  MARKETING_USERNAME,
  MARKETING_PASSWORD,
  COMERCIAL_USERNAME,
  COMERCIAL_PASSWORD,
  GOOGLEMAPS_KEY,
  FIREBASE_KEY,
};
