import React from 'react';
import { hp, wp } from '../../../utils/responsive';
import TextLato from '../../../components/TextLato';
import { connect } from 'react-redux';
import { firebase } from '../../../utils/firebase';
import Spacer from '../../../components/Spacer';
import { setCurrentCompanyID } from '../../../store/actions';
import FormUser from '../../../components/FormUser';
import Button from '../../../components/Button';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { updateUserProfile, suspendUser } from '../../../utils/services';
import ModalQuestion from '../../ModalQuestion';
class ProfileUser extends React.Component {
  state = {
    formUser: {
      fullName: '',
      phone: '',
      gender: '',
      birthdate: '',
    },
    suspendVisible: false,
    restVisible: false,
    mailSent: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    const { currentUser } = this.props;
    this.setState({
      formUser: {
        fullName: currentUser.fullName,
        phone: currentUser.phone,
        gender:
          currentUser.gender != null
            ? currentUser.gender == 'f'
              ? 'Femenino'
              : currentUser.gender == 'm'
              ? 'Masculino'
              : 'Otro'
            : 'Otro',
        birthdate:
          currentUser.birthdate != null
            ? moment(new Date(currentUser.birthdate)).isBefore(moment('01/01/1920', 'DD/MM/YYYY'))
              ? '01/01/1920'
              : moment(new Date(currentUser.birthdate)).format('DD/MM/YYYY')
            : '01/01/2000',
      },
    });
    // this.groupRestaurants(storeList)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    const { suspendVisible, mailSent, restVisible } = this.state;
    const { currentUser } = this.props;
    const titleStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
      marginLeft: hp(6.5),
    };
    const subtitleStyle = {
      fontSize: wp(0.6) + hp(0.7) < 15 ? 15 : wp(0.6) + hp(0.7),
      fontWeight: '400',
      textAlign: 'center',
    };
    return (
      <div
        style={{
          width: '100%',
          minWidth: 600,
          justifyContent: 'stretch',
          backgroundColor: 'transparent',
          display: 'flex',
        }}
      >
        <div
          className="scrollnone"
          style={{
            overflowY: 'auto',
            width: '100%',
            flex: 5,
            flexDirection: 'column',
            backgroundColor: 'transparent',
            height: hp(83),
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Spacer height={hp(5)} />
          <TextLato style={titleStyle}>Datos</TextLato>
          <Spacer height={hp(2)} />
          <div style={{ width: '100%', flex: 1 }}>
            <FormUser
              onChange={(data) => {
                this.setState({
                  formUser: {
                    fullName: data.name,
                    phone: data.phone,
                    gender: data.genre,
                    birthdate: data.date,
                  },
                });
              }}
              admin={true}
              initialData={{
                name: currentUser.fullName || 'N/A',
                email: currentUser.email || 'N/A',
                phone: currentUser.phone || 'N/A',
                date:
                  currentUser.birthdate != null
                    ? moment(new Date(currentUser.birthdate)).isBefore(
                        moment('01/01/1920', 'DD/MM/YYYY'),
                      )
                      ? '01/01/1920'
                      : moment(new Date(currentUser.birthdate)).format('DD/MM/YYYY')
                    : '01/01/2000',
                genre:
                  currentUser.gender != null
                    ? currentUser.gender == 'f'
                      ? 'Femenino'
                      : currentUser.gender == 'm'
                      ? 'Masculino'
                      : 'Otro'
                    : 'Otro',
              }}
              containerStyle={{ marginLeft: hp(6), marginRight: hp(6) }}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spacer width={hp(6)} />
            <Button
              onClick={async () => {
                const { formUser } = this.state;
                const {
                  currentUser,
                  loading,
                  loadUsers,
                  updateUserinList,
                  updateCurrentUser,
                  usersList,
                } = this.props;
                var request = {
                  id: currentUser.id,
                  fullName: formUser.fullName,
                  phone: formUser.phone,
                  gender:
                    formUser.gender == 'Femenino'
                      ? 'f'
                      : formUser.gender == 'Masculino'
                      ? 'm'
                      : 'o',
                  birthdate: moment(formUser.birthdate, 'DD/MM/YYYY').toISOString(),
                };
                loading();
                await updateUserProfile(request);
                loading(false);
                var newCurrentUser = { ...currentUser, ...request };
                updateCurrentUser(newCurrentUser);
                var newUserList = usersList;
                newUserList[currentUser.index] = newCurrentUser;
                //alert(JSON.stringify(newUserList[currentUser.index]));
                updateUserinList(newUserList);
                //loadUsers(false);
              }}
              backgroundColor={'#D87F7A'}
              second={true}
              textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
              style={{
                display: 'flex',
                padding: hp(1.4),
                paddingLeft: hp(6.5),
                paddingRight: hp(6.5),
                borderRadius: hp(0.8),
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Guardar
            </Button>
            <Spacer width={hp(6)} />
          </div>
          <Spacer height={hp(5)} />
        </div>
        <div
          className="scrollnone"
          style={{
            overflowY: 'auto',
            flex: 4,
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            height: hp(83),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spacer height={hp(5)} />
          <div
            style={{
              width: '55%',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextLato style={subtitleStyle}>Reestablecer contraseña</TextLato>
            <Spacer height={hp(2)} />
            <div style={{ width: '100%' }}>
              <Button
                onClick={() => {
                  this.setState({ restVisible: true });
                }}
                backgroundColor={'#D87F7A'}
                second={true}
                textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                style={{
                  flex: 1,
                  display: 'flex',
                  padding: hp(1.4),
                  paddingLeft: hp(4.5),
                  paddingRight: hp(4.5),
                  borderRadius: hp(0.8),
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Reestablecer
              </Button>
            </div>
            {mailSent && (
              <TextLato
                style={{
                  ...subtitleStyle,
                  color: '#D87F7A',
                  fontWeight: '400',
                  marginTop: hp(2),
                  marginBottom: hp(1),
                }}
              >
                ¡Correo enviado!
              </TextLato>
            )}
            <Spacer height={hp(6)} />
            <TextLato style={subtitleStyle}>
              {currentUser.suspended ? 'Reactivar' : 'Suspender'}&nbsp;usuario
            </TextLato>
            <Spacer height={hp(2)} />
            <div style={{ width: '100%' }}>
              <Button
                backgroundColor={'#D87F7A'}
                second={true}
                onClick={() => {
                  this.setState({ suspendVisible: true });
                }}
                textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                style={{
                  flex: 1,
                  display: 'flex',
                  padding: hp(1.4),
                  paddingLeft: hp(4.5),
                  paddingRight: hp(4.5),
                  borderRadius: hp(0.8),
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {currentUser.suspended ? 'Reactivar' : 'Suspender'}
              </Button>
            </div>
          </div>
        </div>
        <ModalQuestion
          visible={suspendVisible}
          message={`¿Estas seguro que desea ${
            currentUser.suspended ? 'reactivar' : 'suspender'
          } al usuario?`}
          okAction={async () => {
            const {
              usersList,
              currentUser,
              loading,
              updateUserinList,
              updateCurrentUser,
            } = this.props;
            loading();
            await suspendUser(currentUser.id);
            var newCurrentUser = { ...currentUser, suspended: !currentUser.suspended };
            updateCurrentUser(newCurrentUser);
            var newUserList = usersList;
            newUserList[currentUser.index] = newCurrentUser;
            //alert(JSON.stringify(newUserList[currentUser.index]));
            updateUserinList(newUserList);
            setTimeout(() => {
              loading(false);
              this.setState({ suspendVisible: false });
            }, 0);
          }}
          cancelAction={() => {
            this.setState({ suspendVisible: false });
          }}
          okText={currentUser.suspended ? 'Reactivar' : 'Suspender'}
        />
        <ModalQuestion
          visible={restVisible}
          message={`¿Estas seguro que desea reestablecer contraseña`}
          okAction={() => {
            this.setState({ mailSent: true, restVisible: false }, () => {
              setTimeout(() => {
                this.setState({ mailSent: false });
              }, 4000);
            });
          }}
          cancelAction={() => {
            this.setState({ restVisible: false });
          }}
          okText={'Reestablecer'}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyID: (value) => {
    dispatch(setCurrentCompanyID(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUser);
