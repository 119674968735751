const actionNames = {
  SET_FIREBASEID: 'SET_FIREBASEID',
  SET_COMPANYID: 'SET_COMPANYID',
  SET_CURRENTRECEIPT: 'SET_CURRENTRECEIPT',
  SET_COMPANYINFO: 'SET_COMPANYINFO',
  SET_CURRENTCOMPANYID: 'SET_CURRENTCOMPANYID',
  SET_OWNER: 'SET_OWNER',
  SET_LOCALS: 'SET_LOCALS',
  SET_USERS: 'SET_USERS',
  SET_STORES: 'SET_STORES',
  SET_NODEV_STORES: 'SET_NODEV_STORES',
};

export default actionNames;
