import React from 'react';
import './index.css';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import Spacer from '../Spacer';
import moment from 'moment';
class Toggler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.initial,
    };
  }
  toggle = (val) => {
    this.setState({ active: val });
  };
  render() {
    const { size, handle, style } = this.props;
    const { active } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',

          width: size ? size * 1.5 - 4 : 40,
          height: size ? size / 2 + 3 : 12,
          backgroundColor: active ? 'rgba(154,214,205,0.5)' : 'rgba(172,172,182,0.5)',
          alignItems: 'center',
          position: 'relative',
          borderRadius: size ? size / 2 + 1 : 12,
          boxShadow: '0px 0px -3px rgba(0,0,0,0.2)',
          ...style,
        }}
      >
        <div
          onClick={() => {
            var newvalue = !this.state.active;
            this.setState({
              active: newvalue,
            });
            handle(newvalue);
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            left: active ? (size ? size * 0.75 : 17) : -5,
            transition: 'left 0.5s',
            width: size || 22,
            height: size || 22,
            borderRadius: size ? size / 2 : 11,
            backgroundColor: active ? '#9AD6CD' : '#8A8A8F',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
          }}
        ></div>
      </div>
    );
  }
}
export default Toggler;
