import React from 'react'
import { FadeLoader } from 'react-spinners'
import { css } from '@emotion/core';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const LoadingSection = ({ show = false }) => {
  return (
    show ?
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          zIndex: 5,
          left: 0,
          backgroundColor: 'rgba(0,0,0,0.4)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FadeLoader css={override} size={130} color={'#40E0D0'} loading={true} />
      </div>
      :
      <div></div>
  )
}
