import React from 'react';
import './index.css';
import { hp } from '../../utils/responsive';
import Spacer from '../Spacer';
import TextLato from '../TextLato';
import check from '../../assets/images/check-icon.png';
import checked from '../../assets/images/checked-icon.png';
import fillCheckIcon from '../../assets/images/fill-check.png';
class CheckFillButton extends React.Component {
  render() {
    const { handle, label, size, labelStyle, val } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          onClick={() => {
            handle(!val);
          }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <img
            src={fillCheckIcon}
            style={{
              opacity: val ? 1 : 0,
              transition: 'opacity 0.2s',
              width: size,
              height: size,
            }}
          />
          <div
            style={{
              position: 'absolute',
              opacity: val ? 0 : 1,
              transition: 'opacity 0.2s',
              display: 'flex',
              borderRadius: size * 0.24,
              alignItems: 'center',
              justifyContent: 'center',
              width: size,
              height: size,
              backgroundColor: 'rgb(166,212,205)',
            }}
          >
            <div
              style={{
                width: size * 0.9,
                height: size * 0.9,
                borderRadius: size * 0.17,
                backgroundColor: 'white',
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
export default CheckFillButton;
