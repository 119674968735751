import React from 'react';
import './index.css';
import { wp } from '../../utils/responsive';
const Layout = React.memo(function Layout({
  children,
  header,
  footer,
  style,
  containerStyle = {},
}) {
  return (
    <div
      className="content"
      style={{ ...containerStyle, width: '100%', position: 'fixed', overflowY: 'hidden', top: 0 }}
    >
      {header != null ? header() : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          alignItems: 'stretch',
          width: '100%',
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
});

export default Layout;
