import React from 'react';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import BoxButton from '../../components/BoxButton';
import BoxImage from '../../components/BoxImage';
import BoxSearch from '../../components/BoxSearch';
import FormSales from '../../components/FormSales';
import List from '../../components/List';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import Toggler from '../../components/Toggler';
import { firebase, storage } from '../../utils/firebase';
import { number_format, timeConverter } from '../../utils/functions';
import { hp, wp } from '../../utils/responsive';
import { notifyUsers, updateStoreStock } from '../../utils/services';
import './index.css';

const tempImage =
  'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';

const params = [
  {
    title: 'Img',
    key: 'image',
    width: '15%',
    render: (row, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '15%',
            justifyContent: 'flex-start',
          }}
        >
          <BoxImage url={row} size={wp(4)} />
        </div>
      );
    },
  },
  {
    title: 'Plato',
    key: 'name',
    width: '45%',
    alignment: 'flex-start',
  },
  {
    title: 'Precio',
    key: 'discounted',
    width: '20%',
    alignment: 'center',
    prefix: 'S/ ',
  },
  {
    title: 'Venta',
    width: '20%',
    alignment: 'center',
    render: (row, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '20%',
            justifyContent: 'center',
          }}
        >
          <TextLato style={textStyle}>
            {row['count']} | {row['total']}
          </TextLato>
        </div>
      );
    },
  },
];

class Products extends React.Component {
  state = {
    products: [],
    sales: [],
    formVisible: false,
    selectVisible: false,
    currentProduct: null,
    notif: false,
    file: null,
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.getProducts();
    this.getSales();
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  transformOrder = (data) => {
    const hours = timeConverter(data.date.seconds, 'hours');
    const date = timeConverter(data.date.seconds, 'date');
    // store the original date
    data.originalDate = data.date;
    const fulldate = `${date} ${hours}`;
    data.date = hours;
    data.fulldate = fulldate;
    data.delivered = null;

    if (data.deliveredAt != null) {
      const hoursDelivered = timeConverter(data.deliveredAt.seconds, 'hours');
      const dateDelivered = timeConverter(data.deliveredAt.seconds, 'date');
      const fulldateDelivered = `${dateDelivered} ${hoursDelivered}`;
      data.date = hoursDelivered;
      data.delivered = fulldateDelivered;
    }

    return data;
  };
  getSales = () => {
    const $this = this;
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    console.log(companyID);
    if (companyID != null) {
      firebase
        .firestore()
        .collection(companyInfo.status == 'dev' ? 'sales' : 'sales')
        .where('companyId', '==', companyID.trim())
        .onSnapshot((querySnapshot) => {
          const data = [];

          querySnapshot.docs.forEach((doc) => {
            let temp = doc.data();
            if (!(temp.image != null)) {
              temp.image = tempImage;
            } else {
              if (temp.image.length === 0 || temp.image === null || temp.image === '') {
                temp.image = tempImage;
              }
            }

            if (!(temp.visible != null)) {
              if (!(temp.active != null)) {
                data.push({ ...temp, id: doc.id, key: doc.id });
              } else {
                if (temp.active !== false) {
                  data.push({ ...temp, id: doc.id, key: doc.id });
                }
              }
            } else {
              if (temp.visible !== false) {
                if (!(temp.active != null)) {
                  data.push({ ...temp, id: doc.id, key: doc.id });
                } else {
                  if (temp.active !== false) {
                    data.push({ ...temp, id: doc.id, key: doc.id });
                  }
                }
              }
            }
          });

          $this.setState({ sales: data });
        });
    }
  };
  getProducts = () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    var $this = this;
    if (companyID != null) {
      firebase
        .firestore()
        .collection('products')
        .where('companyId', '==', companyID.trim())
        .onSnapshot((querySnapshot) => {
          const products = [];

          querySnapshot.docs.forEach((doc) => {
            let temp = doc.data();
            if (!(temp.image != null)) {
              temp.image = tempImage;
            } else {
              if (temp.image.length === 0 || temp.image === null || temp.image === '') {
                temp.image = tempImage;
              }
            }

            if (!(temp.active != null)) {
              products.push({ ...temp, id: doc.id, key: doc.id });
            } else {
              if (temp.active !== false) {
                products.push({ ...temp, id: doc.id, key: doc.id });
              }
            }
          });

          $this.setState({ products });
        });
    }
  };
  onSave = async (data) => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const configProduct = firebase
      .firestore()
      .collection(companyInfo.status == 'dev' ? 'sales' : 'sales');
    const $this = this;
    if (data != null) {
      var sendData = {};
      var currentPrice = parseFloat(data.original);
      var currentDiscount = parseFloat(data.discount);
      var currentCount = 0;
      var currentTotal = parseInt(data.count);
      if (parseFloat(currentDiscount.toFixed(2)) > parseFloat((currentPrice * 0.6).toFixed(2))) {
        this.setState({
          visible: false,
          error: '¡El precio descontado debe tener 40% de dscto o más!',
        });
        console.log(
          'error 1' +
            parseFloat(currentDiscount.toFixed(2)) +
            parseFloat((currentPrice * 0.6).toFixed(2)),
        );
        return;
      }
      if (currentTotal <= 0 || currentTotal == null || currentTotal == '') {
        this.setState({
          visible: false,
          error: '¡El valor ingresado debe ser un número superior a 0!',
        });
        console.log('error 2');
        return;
      }

      var duplicate = this.state.sales.find((x) => x.name == data.name);
      if (duplicate != null) {
        alert('Este producto ya ha sido publicado');
        return;
      }
      // delete sendData['image'];
      sendData.date = new Date();
      sendData.uid = companyID;
      sendData.userId = companyID;
      sendData.companyId = companyID;
      sendData.count = 0;
      sendData.name = data.name;
      sendData.description = data.description;
      sendData.keepPublished = false;
      sendData.price = data.original == null ? '0.00' : number_format(data.original.toString());
      sendData.discounted =
        data.discount == null ? '0.00' : number_format(data.discount.toString());
      sendData.total = data.count.toString();
      sendData.image =
        data.image != null
          ? data.image
          : 'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';
      sendData.radio = data.radio;
      sendData.peso = data.peso == null ? '0.00' : number_format(data.peso.toString());
      sendData.expireDate = data.expireDate;
      sendData.publish = data.publish;
      sendData.publishedTil = data.publishedTil;
      if (this.state.file != null) {
        var type = this.state.file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        var filename = uuidv1() + '.' + type;
        Resizer.imageFileResizer(
          this.state.file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            const uploadTask = storage.ref(`stores/${companyID}/published/${filename}`).put(uri, {
              contentType: 'image/jpeg',
            });
            uploadTask.on(
              'state_changed',
              () => {},
              () => {},
              () => {
                sendData.image = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fpublished%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
                configProduct
                  .add(sendData)
                  .then((docSales) => {
                    this.setState({
                      formVisible: false,
                      selectVisible: false,
                      file: null,
                    });
                    updateStoreStock(companyID);
                    if (this.state.notif) notifyUsers(companyID);
                  })
                  .catch((error) => {
                    alert('Error adding document: ' + error);
                  });
              },
            );
          },
          'blob',
        );
      } else {
        configProduct
          .add(sendData)
          .then((docSales) => {
            this.setState({
              formVisible: false,
              selectVisible: false,
            });
            updateStoreStock(companyID);
            if (this.state.notif) notifyUsers(companyID);
          })
          .catch((error) => {
            alert('Error adding document: ' + error);
          });
      }
    }
  };
  onUpdate = async (data) => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const configProduct = firebase
      .firestore()
      .collection(companyInfo.status == 'dev' ? 'sales' : 'sales');

    if (data != null) {
      var current = parseInt(this.state.currentProduct.total);
      var currentTotal = parseInt(data.count);
      var sendData = this.state.currentProduct;
      var totalModified = sendData.total !== data.count.toString();
      sendData.name = data.name;
      sendData.description = data.description;
      sendData.price = data.original == null ? '0.00' : number_format(data.original.toString());
      sendData.discounted =
        data.discount == null ? '0.00' : number_format(data.discount.toString());
      sendData.total = data.count.toString();
      sendData.expireDate = data.expireDate;
      sendData.radio = data.radio;
      sendData.publish = data.publish;
      sendData.publishedTil = data.publishedTil;
      if (currentTotal <= 0 || currentTotal == null || currentTotal == '') {
        this.setState({
          visible: false,
          error: '¡El valor ingresado debe ser un número superior a 0!',
        });
        console.log('error 2');
        return;
      }

      sendData.total = currentTotal.toString();
      if (this.state.file != null) {
        var type = this.state.file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        var filename = uuidv1() + '.' + type;
        Resizer.imageFileResizer(
          this.state.file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            const uploadTask = storage.ref(`stores/${companyID}/published/${filename}`).put(uri, {
              contentType: 'image/jpeg',
            });
            uploadTask.on(
              'state_changed',
              () => {},
              () => {},
              () => {
                sendData.image = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fpublished%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
                configProduct
                  .doc(sendData.id)
                  .update(sendData)
                  .then((docSales) => {
                    this.setState({
                      formVisible: false,
                      selectVisible: false,
                      formDetails: false,
                    });
                    if (totalModified) {
                      updateStoreStock(companyID);
                    }
                    //notifyUsers(companyID);
                  })
                  .catch((error) => {
                    alert('Error adding document: ' + error);
                  });
              },
            );
          },
          'blob',
        );
      } else {
        configProduct
          .doc(sendData.id)
          .update(sendData)
          .then((docSales) => {
            this.setState({
              formVisible: false,
              selectVisible: false,
              formDetails: false,
            });
            //notifyUsers(companyID);
          })
          .catch((error) => {
            alert('Error adding document: ' + error);
          });
      }
    }
  };

  onDelete = async () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const configProduct = firebase
      .firestore()
      .collection(companyInfo.status == 'dev' ? 'sales' : 'sales');

    var sendData = this.state.currentProduct;
    configProduct
      .doc(sendData.id)
      .delete()
      .then(() => {
        this.setState({
          formVisible: false,
          selectVisible: false,
          formDetails: false,
        });
        updateStoreStock(companyID);
        //notifyUsers(companyID);
      })
      .catch((error) => {
        alert('Error delete document: ' + error);
      });
  };
  onChangeImage = (file) => {
    this.setState({ file });
  };
  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1),
      fontWeight: '500',
    };
    const { navigate } = this.props;
    const { products, sales, formVisible, selectVisible, formDetails } = this.state;
    return (
      <div
        className="scrollnone"
        style={{
          overflowY: 'auto',
          display: 'flex',
          width: '100%',
          height: hp(92.5),
          flexDirection: 'row',
          justifyContent: 'stretch',
        }}
      >
        <div
          style={{
            flexDirection: 'column',
            width: wp(40),
            paddingLeft: hp(5),
            paddingTop: hp(3),
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Toggler
              initial={false}
              handle={(val) =>
                this.setState({
                  notif: val,
                })
              }
              size={wp(1) + hp(1)}
            />
            <TextLato
              style={{
                fontSize: wp(1) + hp(1),
                fontWeight: '500',
                marginLeft: 5,
              }}
            >
              Enviar notificacion
            </TextLato>
          </div>
          <Spacer height={hp(2)} />
          <TextLato style={textStyle}>Platos Actuales</TextLato>
          <Spacer height={hp(3)} />
          <List
            handleRow={(data) => {
              this.setState(
                {
                  formDetails: false,
                  formVisible: false,
                  selectVisible: false,
                },
                () =>
                  this.setState({
                    currentProduct: data,
                    formDetails: true,
                    formVisible: false,
                    selectVisible: false,
                  }),
              );
            }}
            header={true}
            params={params}
            data={sales}
          />
          <Spacer height={hp(3)} />
          <TextLato style={textStyle}>Publicar</TextLato>
          <Spacer height={hp(3)} />
          <div style={{ width: '80%' }}>
            {!formVisible && !selectVisible && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <BoxButton
                  onClick={() => {
                    this.setState({
                      selectVisible: true,
                      formDetails: false,
                    });
                  }}
                  icon={true}
                >
                  Agregar Existente
                </BoxButton>
                <Spacer height={hp(3)} />
                <BoxButton
                  onClick={() => {
                    this.setState({
                      currentProduct: null,
                      formVisible: true,
                      formDetails: false,
                    });
                  }}
                  icon={true}
                >
                  Agregar Nuevo
                </BoxButton>
                <Spacer height={hp(2)} />
              </div>
            )}
            {selectVisible && !formVisible && (
              <BoxSearch
                handleRow={(row) => {
                  this.setState({ formVisible: true, currentProduct: row });
                }}
                products={products}
              />
            )}

            {selectVisible && formVisible && (
              <BoxButton
                onClick={() => {
                  this.setState({ formVisible: false });
                }}
              >
                {this.state.currentProduct.name}
              </BoxButton>
            )}
            {selectVisible && formVisible && <Spacer height={hp(3)} />}
            {formVisible && (
              <FormSales
                noStock={true}
                initialData={this.state.currentProduct}
                onSave={this.onSave}
                onSuccessImage={this.onChangeImage}
                onRelease={() => {
                  this.setState({
                    formVisible: false,
                    currentProduct: null,
                    file: null,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            flexDirection: 'column',
            width: wp(40),
            paddingLeft: hp(5),
            paddingTop: hp(3),
          }}
        >
          <div style={{ width: '80%' }}>
            {formDetails && (
              <FormSales
                admin={true}
                initialData={this.state.currentProduct}
                onSave={this.onUpdate}
                onDelete={this.onDelete}
                onSuccessImage={this.onChangeImage}
                onRelease={() => {
                  this.setState({
                    formDetails: false,
                    file: null,
                    currentProduct: null,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.currentCompantID,
    companyInfo: state.companyInfo,
  };
};

export default connect(mapStateToProps, null)(Products);
