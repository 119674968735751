import React from 'react';
import { connect } from 'react-redux';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { setCompanyID, setCompanyInfo, setFirebaseID } from '../../store/actions';
import { hp, wp } from '../../utils/responsive';
import Products from '../Products';
import Settings from '../Settings';
import Stored from '../Stored';
const headerNames = ['Ventas', 'Productos', 'Configuraciones'];

class Admin extends React.Component {
  state = {
    headerIndex: 0,
  };
  async componentDidMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  handleNavigate = (index) => {
    const { headerIndex } = this.state;

    this.setState({
      headerIndex: index,
    });
  };
  renderScreen() {
    const { headerIndex } = this.state;
    var screen = headerNames[headerIndex];
    switch (screen) {
      case 'Ventas':
        return <Products navigate={this.handleNavigate} />;
      case 'Productos':
        return <Stored admin={true} navigate={this.handleNavigate} />;
      case 'Configuraciones':
        return <Settings admin={true} navigate={this.handleNavigate} />;
      default:
        return null;
    }
  }

  render() {
    const { headerIndex } = this.state;
    var screen = headerNames[headerIndex];

    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: wp(100) }}>
        <div style={{ width: '15%', backgroundColor: '#135656' }}>
          <Spacer height={hp(2)} />
          {headerNames.map((item, index) => {
            return (
              <div
                onClick={() => {
                  this.setState({
                    headerIndex: index,
                  });
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Spacer height={hp(2)} />
                <TextLato style={{ fontSize: wp(1.7) }}>{item}</TextLato>
                <Spacer height={hp(2)} />
              </div>
            );
          })}
        </div>
        <div style={{ width: '85%' }}>{this.renderScreen()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseID: state.firebaseID,
    companyID: state.companyID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFirebaseID: (id) => {
      dispatch(setFirebaseID(id));
    },
    setCompanyID: (id) => {
      dispatch(setCompanyID(id));
    },
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
