import React from 'react';
import { hp } from '../../utils/responsive';
import addIcon from '../../assets/images/add_light.png';
import './style.css';

class BoxImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileUrl: '',
      dim: { w: 0, h: 0 },
      horizontal: false,
    };
    var image = new Image();
    const storeDimesions = (w, h) => {
      if (this.props.onDisplay) this.props.onDisplay({ w, h });
      this.setState({
        dim: { w, h },
        horizontal: w / h > (props.banner ? 3.65 : props.large ? 1.6 : 1),
      });
    };
    image.onload = function () {
      storeDimesions(this.width, this.height);
    };
    image.src = props.url;
  }
  componentWillReceiveProps(props) {
    const { large, banner } = this.props;
    if (props.url != null) {
      if (props.dimensions != null) {
        this.setState({
          file: null,
          fileUrl: '',
          dim: props.dimensions,
          horizontal: props.dimensions.w / props.dimensions.h > (banner ? 3.65 : large ? 1.6 : 1),
        });
      } else this.setState({ file: null, fileUrl: '' });
    }
  }
  handleChange = (file) => {
    const { onSuccess } = this.props;

    if (onSuccess != null) {
      onSuccess(file);
    }
    console.log(URL.createObjectURL(file));
    this.setState({ file, fileUrl: URL.createObjectURL(file) });
  };
  render() {
    const { size, url, upload, large, banner } = this.props;
    const { dim, fileUrl, horizontal } = this.state;
    return (
      <div>
        {upload && (
          <input
            type="file"
            name={banner ? 'bannerfile' : 'file'}
            id={banner ? 'bannerfile' : 'file'}
            class="inputfile"
            accept="image/png, image/jpeg"
            onChange={(e) => this.handleChange(e.target.files.item(0))}
          />
        )}
        {upload ? (
          <label for={banner ? 'bannerfile' : 'file'} style={{ cursor: 'pointer' }}>
            <div
              style={{
                backgroundColor: '#DADADA',
                display: 'flex',
                flexDirection: 'row',
                borderRadius: size * (large ? 0.05 : 0.15),
                boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                overflow: 'hidden',

                height: size,
                width: banner ? size * 3.65 : large ? size * 1.6 : size,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {fileUrl != '' ? (
                <img
                  src={fileUrl}
                  style={{
                    objectFit: 'cover',
                    ...(horizontal ? { height: '100%' } : { width: '100%' }),
                    borderRadius: size * (large ? 0.05 : 0.15),
                  }}
                />
              ) : url != null ? (
                <img
                  src={typeof url != 'string' ? URL.createObjectURL(url) : url}
                  style={{
                    objectFit: 'cover',
                    ...(horizontal ? { height: '100%' } : { width: '100%' }),
                    borderRadius: size * (large ? 0.05 : 0.15),
                  }}
                />
              ) : (
                <img src={addIcon} style={{ height: size * 0.5, width: size * 0.5 }} />
              )}
            </div>
          </label>
        ) : (
          <div
            style={{
              backgroundColor: '#DADADA',
              display: 'flex',
              flexDirection: 'row',
              borderRadius: size * (large ? 0.05 : 0.15),
              boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
              height: size,
              overflow: 'hidden',
              width: banner ? size * 3.65 : large ? size * 1.6 : size,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {fileUrl != '' ? (
              <img
                src={fileUrl}
                style={{
                  objectFit: 'cover',
                  ...(horizontal ? { height: '100%' } : { width: '100%' }),
                  borderRadius: size * (large ? 0.05 : 0.15),
                }}
              />
            ) : url != null ? (
              <img
                src={url}
                style={{
                  objectFit: 'cover',
                  ...(horizontal ? { height: '100%' } : { width: '100%' }),
                  borderRadius: size * (large ? 0.05 : 0.15),
                }}
              />
            ) : (
              <img src={addIcon} style={{ height: size * 0.5, width: size * 0.5 }} />
            )}
          </div>
        )}
      </div>
    );
  }
}
export default BoxImage;
