import React, { useEffect, useState } from 'react'
import './faq-section.css'
import { useFAQFirestore } from '../../hooks/useFAQFirestore'
import { LoadingSection } from './LoadingSection'

const FAQSection = () => {

  const { faq, updateQuestions } = useFAQFirestore()
  const [isLoading, setIsLoading] = useState(false)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    setQuestions(faq)
    // setIsLoading(false)
  }, [faq])

  const addQuestion = () => {
    const newQuestion = { question: '', answer: '', id: new Date().getTime() }
    setQuestions([...questions, newQuestion])
  }

  const updateQuestion = (type, value, index) => {
    const nextQustions = questions.map((item, i) => {
      if (i == index) {
        if (type === 'question') {
          return { ...item, question: value };
        } else {
          return { ...item, answer: value };
        }
      }
      return item
    });
    setQuestions(nextQustions)
  }

  const deleteQuestion = (id) => {
    setQuestions(
      questions.filter(item =>
        item.id !== id
      )
    );
  }

  const saveChanges = async () => {
    setIsLoading(true)
    const isQustionsSaved = await updateQuestions(questions)
    setIsLoading(false)
  }

  const undoChanges = () => {
    setQuestions(faq)
  }

  return (
    <>
      <div style={{ padding: '3rem', width: '100%' }}>
        <div className='flex-row justify-between mb-20'>
          <h3 style={{ fontSize: '3.25rem' }}>Pregunta Frecuentes</h3>
          <div className='grid-center'>
            <div className='flex-row gap-10'>
              <button onClick={undoChanges} type="button" class="btn btn-default btn-lg">Deshacer</button>
              <button onClick={addQuestion} type="button" className={`${questions.length > 0 ? 'hidden' : ''} btn btn-success btn-lg`}>+ Agregar</button>
              <button onClick={saveChanges} type="button" className="btn btn-primary btn-lg">Guardar</button>
            </div>
          </div>
        </div>
        {questions.map((item, index) =>
          <div className='card' key={item.id}>
            <div className='card-content'>
              <div className='flex-row justify-between mb-15'>
                <div className='flex-col justify-center'>
                  <p className='title-card'>Pregunta {index + 1}:</p>
                </div>
                <button onClick={() => { deleteQuestion(item.id) }} type="button" className="btn btn-danger btn-lg">Eliminar</button>
              </div>
              <div className="form-group">
                <label
                  className='label-form'
                  htmlFor={`question-${index}`}>Pregunta:</label>
                <input
                  className="form-control"
                  id={`question-${index}`}
                  placeholder=""
                  value={item.question}
                  onChange={(input) => updateQuestion('question', input.target.value, index)} />
              </div>
              <div className="form-group">
                <label
                  className='label-form'
                  htmlFor={`answer-${index}`}>Respuesta:</label>
                <textarea
                  value={item.answer}
                  id={`answer-${index}`}
                  className="form-control" rows="4"
                  onChange={(input) => updateQuestion('answer', input.target.value, index)} />
              </div>
            </div>
          </div>
        )}
        <div className='flex-row justify-end'>
          <button onClick={addQuestion} type="button" className={`${questions.length > 0 ? '' : 'hidden'} btn btn-success btn-lg`}>+ Agregar</button>
        </div>
      </div>
      <LoadingSection show={isLoading} />
    </>
  )
}

export default FAQSection
