import React from 'react';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import './index.css';
class InputBoxDefault extends React.Component {
  constructor(props) {
    super(props);
    this.listBox = React.createRef();
    this.state = {
      open: false,
    };
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.state.open)
      if (!this.listBox.current.contains(event.target)) {
        this.setState({ open: false });
      }
  };
  render() {
    const { open } = this.state;
    const { list, value, levelShadow = 30, style = {}, onHandle } = this.props;
    return (
      <div
        ref={this.listBox}
        className="field"
        style={{
          width: '100%',
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          paddingTop: hp(2.5),
          paddingBottom: hp(2.5),
          borderRadius: hp(1.2),
          boxShadow: `2px 0px ${levelShadow}px rgba(0,0,0,0.2)`,
          ...style,
          zIndex: '1',
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          <div
            style={{
              fontSize: wp(0.3) + hp(1.5) < 13 ? 13 : wp(0.3) + hp(1.5),
              paddingLeft: hp(3),
              paddingRight: hp(3),
            }}
          >
            {value.name || 'General'}
          </div>
        </div>
        <div
          onClick={() => this.setState({ open: !this.state.open })}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,

            height: '100%',
            width: '100%',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            bottom: open ? -hp(19.3) : 0,
            transition: 'height 0.2s,box-shadow 0.2s, bottom 0.2s',
            left: 0,
            height: open ? hp(20) : 0,
            width: '100%',
            backgroundColor: 'white',
            borderBottomLeftRadius: hp(1.2),
            borderBottomRightRadius: hp(1.2),
            boxShadow: open ? `2px 14px ${levelShadow}px rgba(0,0,0,0.2)` : 'none',
          }}
        >
          <div
            className="scrollbar"
            style={{
              transition: 'height 0.2s,box-shadow 0.2s, bottom 0.2s',
              marginRight: hp(2.2),
              height: open ? hp(19) : 0,
              overflowY: 'auto',
            }}
          >
            {list.map((x, index) => {
              return (
                <div
                  key={x.id}
                  onClick={() => {
                    onHandle(x);
                    this.setState({ open: !this.state.open });
                  }}
                  style={{
                    display: 'flex',
                    padding: hp(1),
                    alignItems: 'center',
                    paddingRight: hp(2.2),
                  }}
                >
                  <TextLato
                    style={{
                      paddingLeft: hp(1.8),
                      fontSize: wp(0.3) + hp(1.5) < 12 ? 12 : wp(0.3) + hp(1.4),
                    }}
                  >
                    {x.name}
                  </TextLato>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default InputBoxDefault;
