import moment from 'moment';
import React from 'react';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import CLOSE from '../../assets/images/closeIcon.png';
import cropImage from '../../assets/images/crop.png';
import editIcon from '../../assets/images/edit-icon.png';
import BoxImage from '../../components/BoxImage';
import Button from '../../components/Button';
import CheckButton from '../../components/CheckButton';
import FormBusiness from '../../components/FormBusiness';
import Input from '../../components/Input';
import InputBox from '../../components/InputBox';
import List from '../../components/List';
import Modal from '../../components/Modal';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import Toggler from '../../components/Toggler';
import Triangle from '../../components/Triangle';
import { setCompanyInfo, setCurrentCompanyID } from '../../store/actions';
import { firebase, storage } from '../../utils/firebase';
import { getPositionByAddress } from '../../utils/maps';
import { hp, isMobile, wp } from '../../utils/responsive';
import {
  createComerciales,
  createEmployee,
  createGroupedStores,
  createStores,
  deleteComercial,
  deleteGroup,
  deleteStore,
  getAllCentrosComerciales,
  getAllGroupedStores,
  getAllGroupedUsers,
  getCompany,
  getStoreProductionCount,
  updateComercialInfo,
  updateStoreInfo,
} from '../../utils/services';
import ModalQuestion from '../ModalQuestion';
import './index.css';

const paramsComercials = [
  {
    title: isMobile() ? 'Nomb. C.' : 'Nombre de Comercial',
    key: 'nameComercial',
    width: '45%',
    alignment: 'flex-start',
  },
  {
    title: 'Dirección',
    key: 'direccionComercial',
    width: '45%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onEdit(data);
          }}
          style={{
            width: '5%',
            zIndex: 2,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <img src={editIcon} style={{ height: hp(3), width: hp(3) }} />
        </div>
      );
    },
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onDelete(data);
          }}
          style={{
            width: '5%',
            zIndex: 2,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <img src={CLOSE} style={{ height: hp(2.2), width: hp(2.2) }} />
        </div>
      );
    },
  },
];

const params = [
  {
    title: isMobile() ? 'Rest.' : 'Restaurante',
    key: 'name',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: isMobile() ? 'Establ.' : 'Establecimientos',
    width: '25%',
    alignment: 'center',
    render: (res, textStyle) => {
      const { locales } = res;

      return (
        <div
          style={{
            display: 'flex',
            width: '25%',
            justifyContent: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, textAlign: 'center' }}>
            {locales} {isMobile() ? 'establ.' : 'establecimientos'}
          </TextLato>
        </div>
      );
    },
  },
  {
    title: 'Última sesión',
    key: 'lastsession',
    width: '22.5%',
    alignment: 'flex-start',
  },
  {
    title: 'Última publicación',
    key: 'lastpublish',
    width: '22.5%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onClick(data);
          }}
          style={{
            width: '5%',

            zIndex: 2,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <img src={editIcon} style={{ height: hp(3), width: hp(3) }} />
        </div>
      );
    },
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onDelete(data);
          }}
          style={{
            width: '5%',

            zIndex: 2,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <img src={CLOSE} style={{ height: hp(2.2), width: hp(2.2) }} />
        </div>
      );
    },
  },
];
const paramsListed = [
  {
    title: isMobile() ? 'Rest.' : 'Restaurante',
    key: 'name',
    width: isMobile() ? '25%' : '30%',
    alignment: 'flex-start',
  },
  {
    title: 'Última sesión',
    key: 'lastsession',
    width: '25%',
    alignment: 'flex-start',
  },
  {
    title: 'Última publicación',
    key: 'lastpublish',
    width: '25%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onRedirect(data);
          }}
          style={{
            width: isMobile() ? '20%' : '15%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#00A7A9', fontSize: textStyle.fontSize * 0.9 }}>
            {'Ingresar >'}
          </TextLato>
        </div>
      );
    },
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          style={{
            width: '5%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',

            alignItems: 'flex-start',
          }}
        >
          <img src={editIcon} style={{ height: hp(3), width: hp(3) }} />
          <div
            className="hoverDelete"
            style={{
              overflow: 'visible',
              position: 'absolute',
              top: -hp(0.3),
              height: '100%',
              flexDirection: 'column',
              width: hp(6),
              left: -hp(1),
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                position: 'relative',
                top: -hp(2),
                display: 'flex',
                backgroundColor: '#757575',
              }}
            >
              <TextLato
                onClick={() => {
                  actions.onEdit(data);
                }}
                style={{
                  position: 'relative',
                  width: hp(6),
                  paddingTop: hp(0.3),
                  paddingBottom: hp(0.3),
                  textAlign: 'center',
                  fontSize: hp(1.1),
                  fontWeight: '400',
                  color: 'white',
                }}
              >
                Editar
              </TextLato>
              <TextLato
                style={{
                  position: 'relative',
                  backgroundColor: 'white',
                  width: 1,
                  height: '80%',
                  textAlign: 'center',
                  fontSize: hp(1.1),
                  fontWeight: '400',
                  color: 'transparent',
                }}
              >
                E
              </TextLato>
              <TextLato
                onClick={() => {
                  actions.onDelete(data);
                }}
                style={{
                  position: 'relative',
                  width: hp(6),
                  paddingTop: hp(0.3),
                  paddingBottom: hp(0.3),
                  textAlign: 'center',
                  fontSize: hp(1.1),
                  fontWeight: '400',
                  color: 'white',
                }}
              >
                Eliminar
              </TextLato>
            </div>

            <Triangle
              style={{ position: 'relative', top: -hp(2.1) }}
              color={'#757575'}
              w={hp(1.5)}
              h={hp(0.6)}
              direction="bottom"
            />
          </div>
        </div>
      );
    },
  },
];
const paramsUsers = [
  {
    title: 'Usuarios',
    key: 'email',
    width: '30%',
    alignment: 'flex-start',
  },
  {
    title: 'Última sesión',
    key: 'lastsession',
    width: '55%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onEdit(data);
          }}
          style={{ width: '15%', cursor: 'pointer' }}
        >
          <TextLato style={{ ...textStyle, color: '#00A7A9', fontSize: textStyle.fontSize * 0.9 }}>
            {'Editar >'}
          </TextLato>
        </div>
      );
    },
  },
];
class Restaurants extends React.Component {
  state = {
    restaurants: {},
    grouped: [],
    mode: 'grouped',
    search: '',
    currentRestaurant: '',
    toggleUsers: false,
    currentRestaurants: [],
    currentGroupId: null,
    currentUsers: [],
    createModalVisible: false,
    businessValue: '',
    deleteId: null,
    userForm: {
      username: '',
      password: '',
      owner: false,
      checks: [],
    },
    storeForm: null,
    editId: null,
    showComerciales: false,
    scc: '',
    showModalComercials: false,
    idComercial: 0,
    nameComercial: '',
    direccionComercial: '',
    imageFile: null,
    crop: null,
    size: null,
    imageEdit: false,
    comercials: [],
  };

  componentDidMount() {
    const { loadedRestaurants, selectedRestaurants } = this.props;
    window.addEventListener('resize', this.resize);
    this.loadProductionCount();
    this.loadBusiness(loadedRestaurants, selectedRestaurants);
    this.loadCenterComercials();
    // this.groupRestaurants(storeList)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };
  loadProductionCount = async () => {
    try {
      const productionCountResponse = await getStoreProductionCount();
      console.log(productionCountResponse);
      if (productionCountResponse.errorCode === '200') {
        this.setState({ productionCount: productionCountResponse.errorMessage });
      }
    } catch (error) {
      alert(error);
    }
  };
  loadCenterComercials = async () => {
    const { loading } = this.props;
    const com = [];
    const comerciales = await getAllCentrosComerciales();
    for (let i = 0; i < comerciales.items.length; i++) {
      const x = comerciales.items[i];
      com.push({
        id: x.id,
        nameComercial: x.nameComercial,
        direccionComercial: x.direccionComercial,
        imageComercial: x.imageComercial,
      });
    }
    this.setState({ comercials: com });
  };

  loadBusiness = async (initial, initialSelection) => {
    const { loading, persistRestaurants } = this.props;
    if (initial != null) {
      this.setState({
        grouped: initial,
      });
    } else {
      loading(true);
    }
    if (initialSelection != null) {
      loading(true);
      this.loadUsers(initialSelection.currentGroupId);
      this.setState({
        mode: 'listed',
        currentRestaurant: initialSelection.currentRestaurant,
        currentGroupId: initialSelection.currentGroupId,
      });
    }
    const stores = await getAllGroupedStores();
    console.log(stores);
    loading(false);
    const storeList = [];
    let crests = null;
    for (let i = 0; i < stores.items.length; i++) {
      const x = stores.items[i];

      var lastLogin =
        x.stores.length > 0
          ? x.stores.sort(function (a, b) {
              a = a.lastLogin != null ? new Date(a.lastLogin) : new Date('01-01-2020');
              b = b.lastLogin != null ? new Date(b.lastLogin) : new Date('01-01-2020');
              return a > b ? -1 : a < b ? 1 : 0;
            })[0].lastLogin
          : 'N/A';
      var lastPublish =
        x.stores.length > 0
          ? x.stores.sort(function (a, b) {
              a = a.lastPublish != null ? new Date(a.lastPublish) : new Date('01-01-2020');
              b = b.lastPublish != null ? new Date(b.lastPublish) : new Date('01-01-2020');
              return a > b ? -1 : a < b ? 1 : 0;
            })[0].lastPublish
          : 'N/A';
      if (this.state.currentRestaurant == x.name) {
        crests = x.stores;
      }
      storeList.push({
        id: x.id,
        name: x.name,
        locales: x.stores.length,
        stores: x.stores,
        lastsession:
          lastLogin != null
            ? lastLogin == 'N/A'
              ? lastLogin
              : moment(new Date(lastLogin)).add(-5, 'hours').format('DD/MM/YYYY HH:mm')
            : 'N/A',
        lastpublish:
          lastPublish != null
            ? lastPublish == 'N/A'
              ? lastPublish
              : moment(new Date(lastPublish)).add(-5, 'hours').format('DD/MM/YYYY HH:mm')
            : 'N/A',
      });
    }
    if (crests != null) {
      this.setState({
        currentRestaurants: crests.map((x) => ({
          ...x,
          lastsession:
            x.lastLogin != null ? moment(new Date(x.lastLogin)).format('DD/MM/YYYY HH:mm') : 'N/A',
          lastpublish:
            x.lastPublish != null
              ? moment(new Date(x.lastPublish)).format('DD/MM/YYYY HH:mm')
              : 'N/A',
        })),
      });
    }
    console.log('list stores', storeList);
    this.setState(
      {
        grouped: storeList,
      },
      () => {
        persistRestaurants(storeList);
      },
    );
  };

  loadUsers = async (groupId) => {
    const list = await getAllGroupedUsers(groupId);
    this.setState({
      currentUsers: list.items.map((x) => ({
        ...x,
        lastsession:
          x.lastLogin != null ? moment(new Date(x.lastLogin)).format('DD/MM/YYYY HH:mm') : 'N/A',
      })),
    });
  };

  uploadImage = async (fileImage, name, companyID, callback) => {
    console.log('fileImage=> ', fileImage);
    console.log('name=> ', name);
    const filename = name;
    var file = null;
    if (typeof fileImage == 'string') {
      file = this.dataURItoBlob(fileImage);
    } else {
      file = fileImage;
    }
    Resizer.imageFileResizer(
      file,
      400,
      400,
      'JPEG',
      100,
      0,
      (uri) => {
        const uploadTask = storage.ref(`stores/${companyID}/profile/${filename}`).put(uri, {
          contentType: 'image/jpeg',
        });
        uploadTask.on(
          'state_changed',
          () => {},
          () => {},
          () => {
            // var imageUrl = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fprofile%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
            if (callback) callback();
          },
        );
      },
      'blob',
    );
  };

  uploadImageComercial = async (fileImage, name, comercialId, callback) => {
    console.log('fileImage comercial=> ', fileImage);
    console.log('name comercial=> ', name);
    const filename = name;
    var file = null;
    if (typeof fileImage == 'string') {
      file = this.dataURItoBlob(fileImage);
    } else {
      file = fileImage;
    }
    Resizer.imageFileResizer(
      file,
      400,
      400,
      'JPEG',
      100,
      0,
      (uri) => {
        const uploadTask = storage.ref(`comerciales/${comercialId}/profile/${filename}`).put(uri, {
          contentType: 'image/jpeg',
        });
        uploadTask.on(
          'state_changed',
          () => {},
          () => {},
          () => {
            // var imageUrl = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fprofile%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
            if (callback) callback();
          },
        );
      },
      'blob',
    );
  };

  uploadBanner = async (fileImage, name, companyID, callback) => {
    const filename = name;
    var file = null;
    if (typeof fileImage == 'string') {
      file = this.dataURItoBlob(fileImage);
    } else {
      file = fileImage;
    }
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      100,
      0,
      (uri) => {
        const uploadTask = storage.ref(`stores/${companyID}/banner/${filename}`).put(uri, {
          contentType: 'image/jpeg',
        });
        uploadTask.on(
          'state_changed',
          () => {},
          () => {},
          () => {
            // var imageUrl = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fprofile%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
            if (callback) callback();
          },
        );
      },
      'blob',
    );
  };

  createClick = () => {
    const { mode } = this.state;
    this.setState({ createModalVisible: true });
  };

  updateStores = async () => {
    firebase
      .firestore()
      .collection('products')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.update({
            radio: 1,
          });
          //console.log(doc.id);
        });
      });
  };

  createLocal = async () => {
    const { storeForm } = this.state;

    this.props.loading();
    if (storeForm.pinpoint == null) {
      var response = await getPositionByAddress(storeForm.address);
      if (response != null) {
        storeForm.pinpoint = response;
      } else {
        alert(
          'No se encontro un pinpoint para esa dirección, has click en el icono para agregarlo manualmente',
        );
        this.props.loading(false);
        return;
      }
    }
    var file = storeForm.image;
    let filename = null;
    if (typeof storeForm.image == 'string') {
      if (storeForm.image.substr(0, 10) == 'data:image') {
        file = storeForm.image;
        let type = null;
        if (file != null) {
          type = 'jpg';
          filename = `${uuidv1()}.${type}`;
        }
      }
    } else if (typeof storeForm.image == 'object') {
      file = storeForm.image;
      let type = null;
      if (file != null) {
        type = file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        filename = `${uuidv1()}.${type}`;
      }
    }
    var bannerfile = storeForm.banner;
    let bannerfilename = null;
    if (typeof storeForm.banner == 'string') {
      if (storeForm.banner.substr(0, 10) == 'data:image') {
        bannerfile = storeForm.banner;
        let type = null;
        if (bannerfile != null) {
          type = 'jpg';
          bannerfilename = `${uuidv1()}.${type}`;
        }
      }
    } else if (typeof storeForm.banner == 'object') {
      bannerfile = storeForm.banner;
      let type = null;
      if (bannerfile != null) {
        type = bannerfile.name.indexOf('png') != -1 ? 'png' : 'jpg';
        bannerfilename = `${uuidv1()}.${type}`;
      }
    }
    /*  if (file != null) {
       type = file.name.indexOf('png') != -1 ? 'png' : 'jpg';
       filename = `${uuidv1()}.${type}`;
     }
  */
    const createResponse = await createStores({
      name: storeForm.name,
      email: storeForm.email,
      phone: storeForm.phone,
      address: storeForm.address,
      prefix: storeForm.prefix,
      hasRestaurant: storeForm.hasRestaurant,
      comercialId: !storeForm.hasRestaurant ? storeForm.comercialId : 0,
      groupId: this.state.currentGroupId,
      groupName: this.state.currentRestaurant,
      imageFileName: filename,
      bannerFileName: bannerfilename,
      ...(storeForm.pinpoint != null ? storeForm.pinpoint : {}),
    });

    if (createResponse.id != null) {
      if (file != null) {
        this.uploadImage(file, filename, createResponse.id, () => {
          if (bannerfile != null) {
            this.uploadBanner(bannerfile, bannerfilename, createResponse.id, () => {
              this.setState(
                {
                  storeForm: {
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    prefix: '',
                    image: '',
                    banner: '',
                  },
                  editId: null,
                  createModalVisible: false,
                },
                this.loadBusiness,
              );
            });
          } else {
            this.setState(
              {
                storeForm: {
                  name: '',
                  email: '',
                  phone: '',
                  address: '',
                  prefix: '',
                  image: '',
                  banner: '',
                },
                editId: null,
                createModalVisible: false,
              },
              this.loadBusiness,
            );
          }
        });
      } else if (bannerfile != null) {
        this.uploadBanner(bannerfile, bannerfilename, createResponse.id, () => {
          this.setState(
            {
              storeForm: {
                name: '',
                email: '',
                phone: '',
                address: '',
                prefix: '',
                image: '',
                banner: '',
              },
              editId: null,
              createModalVisible: false,
            },
            this.loadBusiness,
          );
        });
      } else {
        this.setState(
          {
            storeForm: {
              name: '',
              email: '',
              phone: '',
              address: '',
              prefix: '',
              image: '',
              banner: '',
            },
            editId: null,
            createModalVisible: false,
          },
          this.loadBusiness,
        );
      }
    } else {
      this.props.loading(false);
    }
  };

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  };

  editComercial = async () => {
    this.props.loading();
    const { imageFile, idComercial, nameComercial, direccionComercial } = this.state;
    console.log('idComercial => ', idComercial);
    let filename = null;
    let filePath = null;
    var file = null;

    if (typeof imageFile == 'string') {
      if (imageFile.substr(0, 10) == 'data:image') {
        file = imageFile;
        let type = null;
        if (file != null) {
          type = 'jpg';
          filename = `${uuidv1()}.${type}`;
          filePath = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/comerciales%2F${idComercial}%2Fprofile%2F${filename}?alt=media&token=5ad7f2b8-3238-4112-bd6b-c8b71d349363`;
        }
      } else if (imageFile.indexOf('firebasestorage') != -1) {
        filePath = imageFile;
      }
    } else if (typeof imageFile == 'object') {
      file = imageFile;
      let type = null;
      if (file != null) {
        type = file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        filename = `${uuidv1()}.${type}`;
        filePath = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/comerciales%2F${idComercial}%2Fprofile%2F${filename}?alt=media&token=5ad7f2b8-3238-4112-bd6b-c8b71d349363`;
      }
    }
    const editResponse = await updateComercialInfo({
      Id: idComercial,
      nameComercial: nameComercial,
      direccionComercial: direccionComercial,
      imageComercial: filePath,
    });
    this.setState({
      idComercial: 0,
      showModalComercials: false,
      nameComercial: '',
      direccionComercial: '',
    });
    await this.uploadImageComercial(imageFile, filename, idComercial);
    if (editResponse.errorCode == '200') {
      this.props.loading(false);
      this.loadCenterComercials();
      this.setState({
        imageUrl: null,
        imageFile: null,
        idComercial: 0,
        showModalComercials: false,
        nameComercial: '',
        direccionComercial: '',
      });
    }
  };

  editLocal = async () => {
    const { storeForm, editId } = this.state;
    console.log('storeForm => ', storeForm);
    this.props.loading();
    if (storeForm.pinpoint == null) {
      var response = await getPositionByAddress(storeForm.address);
      console.log('response => ', response);
      if (response != null) {
        storeForm.pinpoint = response;
      } else {
        alert(
          'No se encontro un pinpoint para esa dirección, has click en el icono para agregarlo manualmente',
        );
        this.props.loading(false);
        return;
      }
    }
    let filename = null;
    let filePath = null;
    var file = null;
    let bannerfilename = null;
    let bannerfilePath = null;
    var bannerfile = null;
    if (typeof storeForm.image == 'string') {
      if (storeForm.image.substr(0, 10) == 'data:image') {
        file = storeForm.image;
        let type = null;
        if (file != null) {
          type = 'jpg';
          filename = `${uuidv1()}.${type}`;
          filePath = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${editId}%2Fprofile%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
        }
      } else if (storeForm.image.indexOf('firebasestorage') != -1) {
        filePath = storeForm.image;
      }
    } else if (typeof storeForm.image == 'object') {
      file = storeForm.image;
      let type = null;
      if (file != null) {
        type = file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        filename = `${uuidv1()}.${type}`;
        filePath = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${editId}%2Fprofile%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
      }
    }
    if (typeof storeForm.banner == 'string') {
      if (storeForm.banner.substr(0, 10) == 'data:image') {
        bannerfile = storeForm.banner;
        let type = null;
        if (bannerfile != null) {
          type = 'jpg';
          bannerfilename = `${uuidv1()}.${type}`;
          bannerfilePath = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${editId}%2Fbanner%2F${bannerfilename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
        }
      } else if (storeForm.banner.indexOf('firebasestorage') != -1) {
        bannerfilePath = storeForm.banner;
      }
    } else if (typeof storeForm.banner == 'object') {
      bannerfile = storeForm.banner;
      let type = null;
      if (bannerfile != null) {
        type = bannerfile.name.indexOf('png') != -1 ? 'png' : 'jpg';
        bannerfilename = `${uuidv1()}.${type}`;
        bannerfilePath = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${editId}%2Fbanner%2F${bannerfilename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
      }
    }
    const editResponse = await updateStoreInfo({
      storeId: editId,
      storeName: storeForm.name,
      email: storeForm.email,
      phoneNumber: storeForm.phone,
      address: storeForm.address,
      prefix: storeForm.prefix,
      emailNotificationEnabled: storeForm.emailNotificationEnabled,
      smsNotificationEnabled: storeForm.smsNotificationEnabled,
      emailNotification: storeForm.emailNotification,
      smsNotification: storeForm.smsNotification,
      comercialId: storeForm.comercialId,
      hasRestaurant: storeForm.hasRestaurant,
      image: filePath,
      banner: bannerfilePath,
      ...(storeForm.pinpoint != null ? storeForm.pinpoint : {}),
    });
    this.setState({
      storeForm: null,
      editId: null,
      createModalVisible: false,
    });
    if (editResponse.errorCode == '200') {
      if (file != null) {
        this.uploadImage(file, filename, editId, async () => {
          if (bannerfile != null) {
            this.uploadBanner(bannerfile, bannerfilename, editId, async () => {
              this.setState({
                storeForm: null,
                editId: null,
                createModalVisible: false,
              });
              await this.loadBusiness();
            });
          } else {
            this.setState({
              storeForm: null,
              editId: null,
              createModalVisible: false,
            });
            await this.loadBusiness();
          }
        });
      } else if (bannerfile != null) {
        this.uploadBanner(bannerfile, bannerfilename, editId, async () => {
          this.setState({
            storeForm: null,
            editId: null,
            createModalVisible: false,
          });
          await this.loadBusiness();
        });
      } else {
        this.setState({
          storeForm: null,
          editId: null,
          createModalVisible: false,
        });
        await this.loadBusiness();
      }
    } else {
      this.props.loading(false);
    }
  };

  createUser = async () => {
    this.props.loading();

    await createEmployee({
      username: this.state.userForm.username,
      password: this.state.userForm.password,
      owner: this.state.userForm.owner,
      stores: this.state.userForm.checks,
      groupId: this.state.currentGroupId,
      id: this.state.userForm.id,
    });
    await this.loadUsers(this.state.currentGroupId);
    this.setState({
      createModalVisible: false,
      userForm: {
        username: '',
        password: '',
        owner: false,
        checks: [],
      },
    });
    this.props.loading(false);
  };

  redirectLocal = async (data) => {
    const { setCurrentCompanyID, setCompanyInfo, changeAdminMode, persistAdmin } = this.props;

    setCurrentCompanyID(`${data.id}`);
    this.props.loading();

    const response = await getCompany(data.id);
    persistAdmin({ response, id: data.id });
    this.props.loading(false);
    setCompanyInfo(response);
    changeAdminMode(1);
  };

  redirectBusiness = async (data) => {
    const { persistSelection } = this.props;
    this.loadUsers(data.id);
    this.setState(
      {
        mode: 'listed',
        currentRestaurant: data.name,
        currentGroupId: data.id,
        currentRestaurants: data.stores.map((x) => ({
          ...x,
          lastsession:
            x.lastLogin != null ? moment(new Date(x.lastLogin)).format('DD/MM/YYYY HH:mm') : 'N/A',
          lastpublish:
            x.lastPublish != null
              ? moment(new Date(x.lastPublish)).format('DD/MM/YYYY HH:mm')
              : 'N/A',
        })),
      },
      () => {
        persistSelection({
          currentRestaurant: data.name,
          currentGroupId: data.id,
        });
      },
    );
  };

  setUserOpen = (data) => {
    this.setState({
      userForm: {
        username: data.email,
        password: data.pass,
        owner: data.owner,
        checks: data.stores,
        id: data.id,
      },
      createModalVisible: true,
    });
  };

  onDeleteLocal = async (deleteId) => {
    const { mode } = this.state;
    this.props.loading();
    if (mode == 'listed') {
      await deleteStore(deleteId);
    } else {
      await deleteGroup(deleteId);
    }
    this.setState({ deleteId: null }, this.loadBusiness);
  };

  onDeleteComercial = async (comercialId) => {
    this.props.loading();
    await deleteComercial(comercialId);
    this.props.loading(false);
    this.loadCenterComercials();
  };

  renderList = () => {
    const { search, currentUsers, grouped, mode, toggleUsers, currentRestaurants } = this.state;
    const dataList =
      mode == 'listed'
        ? toggleUsers
          ? currentUsers
          : currentRestaurants
        : grouped.sort(function (a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
    console.log(dataList);
    const dataParams = mode == 'listed' ? (toggleUsers ? paramsUsers : paramsListed) : params;

    const paramFilter = mode == 'listed' ? (toggleUsers ? 'email' : 'name') : 'name';
    const labelEmpty =
      mode == 'listed'
        ? toggleUsers
          ? 'No hay usuarios creados'
          : 'No hay locales creados'
        : 'No hay negocios creados';
    const actions =
      mode == 'listed'
        ? toggleUsers
          ? { onEdit: (pdata) => this.setUserOpen(pdata) }
          : {
              onRedirect: (pdata) => this.redirectLocal(pdata),
              onDelete: (pdata) => this.setState({ deleteId: pdata.id }),
              onEdit: (pdata) =>
                this.setState({
                  createModalVisible: true,
                  editId: pdata.id,
                  storeForm: {
                    name: pdata.name,
                    email: pdata.email,
                    phone: pdata.number,
                    address: pdata.address,
                    prefix: pdata.prefix,
                    image: pdata.image,
                    banner: pdata.banner,
                    emailNotificationEnabled: pdata.emailNotificationEnabled,
                    emailNotification: pdata.emailNotification,
                    smsNotificationEnabled: pdata.smsNotificationEnabled,
                    smsNotification: pdata.smsNotification,
                    hasRestaurant: pdata.hasRestaurant,
                    comercialId: pdata.comercialId,
                  },
                }),
            }
        : {
            onDelete: (pdata) => this.setState({ deleteId: pdata.id }),
            onClick: this.redirectBusiness,
          };
    return (
      <List
        actions={actions}
        labelEmpty={labelEmpty}
        topHeader
        header
        params={dataParams}
        data={dataList.filter(
          (x) => x[paramFilter].toUpperCase().indexOf(search.toUpperCase()) != -1,
        )}
      />
    );
  };

  renderCreateModal = () => {
    const {
      createModalVisible,
      currentRestaurants,
      toggleUsers,
      mode,
      businessValue,
      storeForm,
      userForm,
      editId,
    } = this.state;
    const { loading } = this.props;
    if (createModalVisible) {
      const maxWidth = mode == 'listed' ? (toggleUsers ? 500 : 600) : 370;
      const padding = mode == 'listed' ? (toggleUsers ? hp(4) : hp(4)) : hp(6);
      const title =
        mode == 'listed'
          ? toggleUsers
            ? 'Crear Usuario'
            : (editId != null ? 'Editar' : 'Crear') + ' Local'
          : 'Crear Negocio';

      const valid =
        storeForm != null
          ? storeForm.name != '' &&
            storeForm.phone != '' &&
            storeForm.address != '' &&
            storeForm.email != ''
          : false;
      return (
        <Modal>
          <div
            style={{
              backgroundColor: 'white',
              width: wp(90),
              maxWidth,
              maxHeight: '90%',
              overflow: 'auto',
              paddingRight: padding,
              paddingLeft: padding,
              borderRadius: hp(1),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextLato
              style={{
                marginTop: hp(3),
                marginBottom: hp(2),
                fontWeight: '400',
                fontSize: hp(2.5),
                color: '#392E2E',
              }}
            >
              {title}
            </TextLato>
            {mode == 'listed' ? (
              toggleUsers ? (
                <div
                  style={{
                    width: '75%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <InputBox
                    style={{
                      paddingTop: hp(1.5),
                      paddingBottom: hp(1.5),
                    }}
                    placeholder="Usuario"
                    value={userForm.username}
                    levelShadow={10}
                    onChangeText={(str) => {
                      this.setState({
                        userForm: { ...userForm, username: str },
                      });
                    }}
                  />
                  <Spacer height={hp(5)} />
                  <InputBox
                    style={{
                      paddingTop: hp(1.5),
                      paddingBottom: hp(1.5),
                    }}
                    placeholder="Password"
                    value={userForm.password}
                    levelShadow={10}
                    onChangeText={(str) => {
                      this.setState({
                        userForm: { ...userForm, password: str },
                      });
                    }}
                  />
                  <Spacer height={hp(5)} />
                  <TextLato
                    style={{
                      fontWeight: '400',
                      fontSize: hp(2),
                      color: '#000000',
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Funcionalidades
                  </TextLato>
                  <Spacer height={hp(1.5)} />
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <TextLato
                      style={{
                        fontWeight: '300',
                        fontSize: hp(2),
                        color: '#000000',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      Reportes
                    </TextLato>
                    <Toggler
                      initial={userForm.owner}
                      handle={(val) =>
                        this.setState({
                          userForm: { ...userForm, owner: val },
                        })
                      }
                      size={wp(1) + hp(1)}
                    />
                  </div>
                  <Spacer height={hp(4)} />
                  <TextLato
                    style={{
                      fontWeight: '400',
                      fontSize: hp(2),
                      color: '#000000',
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Locales
                  </TextLato>
                  <Spacer height={hp(2)} />
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {currentRestaurants.map((x) => (
                      <div
                        style={{
                          alignItems: 'center',
                          marginBottom: hp(2),
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <CheckButton
                          initial={userForm.checks.includes(x.id)}
                          handle={(val) => {
                            const prevChecks = userForm.checks;
                            const newChecks = val
                              ? [...prevChecks, x.id]
                              : prevChecks.filter((y) => y != x.id);
                            this.setState({
                              userForm: { ...userForm, checks: newChecks },
                            });
                          }}
                          size={hp(3)}
                        />
                        <Spacer width={hp(2)} />
                        <TextLato
                          style={{
                            fontWeight: '300',
                            fontSize: hp(2),
                            color: '#000000',
                            width: '100%',
                            textAlign: 'left',
                          }}
                        >
                          {x.name}
                        </TextLato>
                      </div>
                    ))}
                  </div>
                  <Spacer height={hp(7)} />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '45%' }}>
                      <Button
                        backgroundColor="#ffffff"
                        onClick={() => {
                          this.setState({
                            userForm: {
                              username: '',
                              password: '',
                              owner: false,
                              checks: [],
                            },
                            createModalVisible: false,
                          });
                        }}
                        textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: hp(1),
                          paddingTop: hp(1.5),
                          paddingBottom: hp(1.5),
                        }}
                      >
                        Volver
                      </Button>
                    </div>
                    <div style={{ width: '45%' }}>
                      <Button
                        backgroundColor={
                          userForm.username != '' &&
                          userForm.password != '' &&
                          userForm.checks.length > 0
                            ? '#D87F7A'
                            : '#BDBDBD'
                        }
                        onClick={() => {
                          if (
                            userForm.username != '' &&
                            userForm.password != '' &&
                            userForm.checks.length > 0
                          ) {
                            this.createUser();
                          }
                        }}
                        textStyle={{ fontSize: hp(2.3) }}
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: hp(1),
                          paddingTop: hp(1.5),
                          paddingBottom: hp(1.5),
                        }}
                      >
                        Guardar
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <FormBusiness
                      edit={editId != null}
                      admin
                      onChange={(x) => {
                        const { storeForm } = this.state;
                        this.setState({ storeForm: { ...storeForm, ...x } });
                      }}
                      onPinpointChange={(data) => {
                        const { storeForm } = this.state;
                        this.setState({ storeForm: { ...storeForm, pinpoint: data } });
                      }}
                      loading={loading}
                      initialData={storeForm != null ? storeForm : null}
                      placeholders={{
                        name: 'Nombre',
                        email: 'Correo soporte',
                        phone: 'Número soporte',
                        address: 'Ubicación del local',
                        prefix: 'Prefijo (2-3 letras mayúsculas)',
                      }}
                    />
                  </div>
                  <Spacer height={hp(5)} />
                  <div
                    style={{
                      width: '80%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: hp(3),
                    }}
                  >
                    <div style={{ width: '45%' }}>
                      <Button
                        backgroundColor="#ffffff"
                        onClick={() => {
                          this.setState({
                            storeForm: null,
                            editId: null,
                            createModalVisible: false,
                          });
                        }}
                        textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: hp(1),
                          paddingTop: hp(1.5),
                          paddingBottom: hp(1.5),
                        }}
                      >
                        Volver
                      </Button>
                    </div>
                    <div style={{ width: '45%' }}>
                      <Button
                        backgroundColor={!valid ? '#BDBDBD' : '#D87F7A'}
                        onClick={editId != null ? this.editLocal : this.createLocal}
                        textStyle={{ fontSize: hp(2.3) }}
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: hp(1),
                          paddingTop: hp(1.5),
                          paddingBottom: hp(1.5),
                        }}
                      >
                        {editId != null ? 'Editar' : 'Crear'}
                      </Button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <InputBox
                  style={{
                    width: '80%',
                    paddingTop: hp(1.5),
                    paddingBottom: hp(1.5),
                  }}
                  placeholder="Nombre"
                  value={businessValue}
                  levelShadow={10}
                  onChangeText={(str) => {
                    this.setState({
                      businessValue: str,
                    });
                  }}
                />
                <Spacer height={hp(5)} />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '45%' }}>
                    <Button
                      backgroundColor="#ffffff"
                      onClick={() => {
                        this.setState({
                          businessValue: '',
                          createModalVisible: false,
                        });
                      }}
                      textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: hp(1),
                        paddingTop: hp(1.5),
                        paddingBottom: hp(1.5),
                      }}
                    >
                      Volver
                    </Button>
                  </div>
                  <div style={{ width: '45%' }}>
                    <Button
                      backgroundColor={businessValue == '' ? '#BDBDBD' : '#D87F7A'}
                      onClick={async () => {
                        this.props.loading();
                        var res = await createGroupedStores({ name: businessValue });
                        //alert(JSON.stringify(res));
                        this.setState(
                          {
                            businessValue: '',
                            createModalVisible: false,
                          },
                          this.loadBusiness,
                        );
                      }}
                      textStyle={{ fontSize: hp(2.3) }}
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: hp(1),
                        paddingTop: hp(1.5),
                        paddingBottom: hp(1.5),
                      }}
                    >
                      Crear
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <Spacer height={hp(5)} />
          </div>
        </Modal>
      );
    }
    return null;
  };

  saveComercial = async () => {
    this.props.loading();
    var file = this.state.imageFile;
    let filename = null;
    if (typeof this.state.imageFile == 'string') {
      if (this.state.imageFile.substr(0, 10) == 'data:image') {
        file = this.state.imageFile;
        let type = null;
        if (file != null) {
          type = 'jpg';
          filename = `${uuidv1()}.${type}`;
        }
      }
    } else if (typeof this.state.imageFile == 'object') {
      file = this.state.imageFile;
      let type = null;
      if (file != null) {
        type = file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        filename = `${uuidv1()}.${type}`;
      }
    }

    const createResponse = await createComerciales({
      nameComercial: this.state.nameComercial,
      direccionComercial: this.state.direccionComercial,
      imageComercial: filename,
    });
    this.uploadImageComercial(file, filename, createResponse.errorMessage);
    if (createResponse.errorCode == '200') {
      this.loadCenterComercials();
      this.setState({
        imageUrl: null,
        imageFile: null,
        idComercial: 0,
        showModalComercials: false,
        nameComercial: '',
        direccionComercial: '',
      });
    }
  };

  onSuccessImage = (file) => {
    const { onChange } = this.props;
    this.setState({ image: file, imageUrl: file, imageFile: file }, () => {
      console.log('hecho');
      /*if (onChange) {
        onChange({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          address: this.state.address,
          prefix: this.state.prefix,
          image: this.state.image,
        });
      }*/
    });
  };

  renderImageBox = () => {
    const { imageFile, crop } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextLato
          style={{
            fontSize: hp(1.8),
            opacity: 0.7,
          }}
        >
          Imagen
        </TextLato>
        <Spacer height={hp(1.2)} />
        <div style={{ display: 'flex' }}>
          <BoxImage
            onDisplay={(x) => this.setState({ size: x })}
            large
            dimensions={crop != null ? { w: crop.width, h: crop.height } : null}
            onSuccess={this.onSuccessImage}
            upload={true}
            url={imageFile}
            size={wp(1) + hp(7)}
          />
          <div
            onClick={() => {
              this.setState({ imageEdit: true });
            }}
            style={{ marginLeft: hp(2), cursor: 'pointer' }}
          >
            <img src={cropImage} style={{ width: hp(3), height: hp(3) }} />
          </div>
        </div>
      </div>
    );
  };

  onChangeText = (value, key) => {
    this.setState({
      [key]: value,
    });
  };

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      )
      .catch((e) => {});

  renderModalCreateComercials = () => {
    const padding = hp(6);
    const labelStyle = {
      fontSize: hp(1.7),
      opacity: 0.7,
    };
    const fieldStyle = {
      fontSize: hp(1.8),
      fontFamily: 'Lato',
      fontWeight: '400',
    };
    const inputStyle = {
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    const { nameComercial, direccionComercial, idComercial } = this.state;
    const { loading } = this.props;
    return (
      <Modal>
        <div
          style={{
            backgroundColor: 'white',
            width: wp(90),
            maxWidth: '370px',
            paddingRight: padding,
            paddingLeft: padding,
            borderRadius: hp(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Spacer height={hp(5)} />
          <TextLato style={{ fontWeight: '400', fontSize: hp(2.5), color: '#392E2E' }}>
            Crear Centro Comercial
          </TextLato>
          <Spacer height={hp(5)} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ width: '45%', margin: '5px' }}>
                <TextLato style={labelStyle}>Nombre de centro comercial</TextLato>
                <Spacer height={hp(0.8)} />
                <Input
                  value={nameComercial}
                  fieldStyle={fieldStyle}
                  onChangeText={this.onChangeText}
                  name={'nameComercial'}
                  bottomSpc={hp(0.6)}
                  style={inputStyle}
                  placeholder={'Ingresa un nombre'}
                />
                <Spacer height={hp(5)} />
              </div>
              <div style={{ width: '45%', margin: '5px' }}>
                <TextLato style={labelStyle}>Dirección</TextLato>
                <Spacer height={hp(0.8)} />
                <Input
                  value={direccionComercial}
                  fieldStyle={fieldStyle}
                  onChangeText={this.onChangeText}
                  name={'direccionComercial'}
                  bottomSpc={hp(0.6)}
                  style={inputStyle}
                  placeholder={'Ingresa una direccion'}
                />
                <Spacer height={hp(5)} />
              </div>
            </div>
            <div>{this.renderImageBox()}</div>
            <Spacer height={hp(5)} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '45%' }}>
                <Button
                  backgroundColor="#ffffff"
                  onClick={() => {
                    this.setState({
                      imageUrl: null,
                      imageFile: null,
                      idComercial: 0,
                      showModalComercials: false,
                      nameComercial: '',
                      direccionComercial: '',
                    });
                  }}
                  textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: hp(1),
                    paddingTop: hp(1.5),
                    paddingBottom: hp(1.5),
                  }}
                >
                  Volver
                </Button>
              </div>
              <div style={{ width: '45%' }}>
                <Button
                  backgroundColor={'#D87F7A'}
                  onClick={async () => {
                    loading(true);
                    var res =
                      (await idComercial) != 0 ? this.editComercial() : this.saveComercial();
                    console.log(JSON.stringify(res));
                    loading(false);
                    return false;
                    this.setState(
                      {
                        businessValue: '',
                        createModalVisible: false,
                      },
                      this.loadBusiness,
                    );
                  }}
                  textStyle={{ fontSize: hp(2.3) }}
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: hp(1),
                    paddingTop: hp(1.5),
                    paddingBottom: hp(1.5),
                  }}
                >
                  {idComercial != 0 ? 'Editar' : 'Crear'}
                </Button>
              </div>
            </div>
          </div>
          <Spacer height={hp(5)} />
        </div>
      </Modal>
    );
  };

  renderHeader = () => {
    const { grouped, productionCount = 0, mode } = this.state;
    return mode == 'grouped' ? (
      <div
        style={{
          height: hp(7.5),
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div style={{ width: '3.5%' }} />
        {/* <button onClick={addAhorroTotal}>ACTION</button> */}
        <TextLato
          style={{
            fontWeight: '500',
            fontSize: hp(2.4),
            color: '#555155',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          NEGOCIOS&nbsp;
          <TextLato style={{ fontSize: hp(2.4), color: '#9C9C9C' }}>
            ({productionCount}/{grouped != null ? grouped.length : 0})
          </TextLato>
        </TextLato>
      </div>
    ) : (
      <div
        style={{
          height: hp(7.5),
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Spacer width={hp(7)} />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.setState({
              mode: 'grouped',
              currentRestaurant: '',
              toggleUsers: false,
              currentRestaurants: [],
              currentGroupId: null,
              currentUsers: [],
            });
          }}
        >
          <TextLato
            style={{
              fontWeight: '500',
              fontSize: hp(2.4),
              color: '#00A7A9',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {'< Negocios'}
          </TextLato>
        </div>
      </div>
    );
  };

  renderNegociosFromComercials = () => {
    return (
      <div
        style={{
          height: hp(7.5),
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Spacer width={hp(7)} />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.setState({
              showComerciales: false,
            });
          }}
        >
          <TextLato
            style={{
              fontWeight: '500',
              fontSize: hp(2.4),
              color: '#00A7A9',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {'< Negocios'}
          </TextLato>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
    );
  };

  renderQuestion = () => {
    const { deleteId, mode } = this.state;
    return (
      <ModalQuestion
        visible={deleteId != null}
        message={`¿Estas seguro que deseas eliminar el ${mode == 'listed' ? 'local' : 'negocio'}?`}
        okAction={() => {
          this.onDeleteLocal(deleteId);
        }}
        cancelAction={() => {
          this.setState({ deleteId: null });
        }}
        okText="Eliminar"
      />
    );
  };

  renderContent = () => {
    const { mode, currentRestaurant, search, toggleUsers } = this.state;
    return (
      <div style={{ width: '93%' }}>
        {mode == 'listed' && (
          <TextLato
            style={{
              marginTop: hp(2),
              marginBottom: hp(2),
              fontWeight: '500',
              fontSize: hp(2.6),
              color: '#392E2E',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {currentRestaurant}
          </TextLato>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile() ? 'column' : 'row',
            alignItems: isMobile() ? 'stretch' : 'center',
            marginTop: hp(3),
            marginBottom: hp(4),
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: isMobile() ? 'column' : 'row',
            }}
          >
            <div style={{ display: 'flex', width: isMobile() ? '100%' : 300 }}>
              <InputBox
                search
                placeholder={
                  mode == 'listed' ? (toggleUsers ? 'Buscar' : 'Buscar local') : 'Buscar negocio'
                }
                value={search}
                onChangeText={(str) => {
                  this.setState({
                    search: str,
                  });
                }}
              />
            </div>
            {isMobile() ? <Spacer height={hp(2)} /> : <Spacer width={hp(4)} />}
            {mode == 'listed' && (
              <div
                style={{
                  paddingTop: hp(2.5),
                  paddingBottom: hp(2.5),
                  backgroundColor: 'white',

                  position: 'relative',
                  width: isMobile() ? '100%' : 200,
                  boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
                  borderRadius: hp(1.2),
                }}
              >
                <TextLato
                  style={{ opacity: 0, fontSize: isMobile() ? wp(1) + hp(1.3) : wp(0.3) + hp(1) }}
                >
                  hol
                </TextLato>
                <div
                  style={{
                    top: 0,
                    borderRadius: hp(1.2),
                    position: 'absolute',
                    height: '100%',
                    width: '50%',
                    left: toggleUsers ? '50%' : 0,
                    transition: 'left 0.5s',
                    backgroundColor: '#00A7A9',
                  }}
                />
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TextLato
                      style={{
                        fontSize: isMobile() ? wp(1) + hp(1.3) : wp(0.3) + hp(1),
                        transition: 'color 0.5s',
                        color: toggleUsers ? '#1F2041' : 'white',
                      }}
                    >
                      Locales
                    </TextLato>
                  </div>
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TextLato
                      style={{
                        fontSize: isMobile() ? wp(1) + hp(1.3) : wp(0.3) + hp(1),
                        transition: 'color 0.5s',
                        color: !toggleUsers ? '#1F2041' : 'white',
                      }}
                    >
                      Usuarios
                    </TextLato>
                  </div>
                </div>
                <div
                  onClick={() => this.setState({ toggleUsers: !toggleUsers })}
                  style={{
                    top: 0,
                    borderRadius: hp(1.2),
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                  }}
                />
              </div>
            )}
          </div>
          {isMobile() ? <Spacer height={hp(2)} /> : <Spacer width={hp(2)} />}
          <div style={{ display: 'flex' }}>
            {
              <Button
                backgroundColor="#D87F7A"
                onClick={() => this.setState({ showComerciales: true })}
                textStyle={{ fontSize: isMobile() ? hp(2) : hp(2.3), textAlign: 'center' }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: hp(1),
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                  paddingLeft: isMobile() ? hp(1) : hp(1.5),
                  paddingRight: isMobile() ? hp(1) : hp(1.5),
                  whiteSpace: 'nowrap',
                }}
              >
                Ir a centros comerciales
              </Button>
            }
            <Button
              backgroundColor="#D87F7A"
              onClick={this.createClick}
              textStyle={{ fontSize: isMobile() ? hp(2) : hp(2.3), textAlign: 'center' }}
              style={{
                margin: '0px 0px 0px 10px',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: hp(1),
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
                paddingLeft: isMobile() ? hp(1) : hp(1.5),
                paddingRight: isMobile() ? hp(0) : hp(1.5),
                whiteSpace: 'nowrap',
              }}
            >
              Crear {mode == 'listed' ? (toggleUsers ? 'usuario' : 'local') : 'negocio'}
            </Button>
          </div>
          {isMobile() && <Spacer width={wp(1) + hp(2)} />}
        </div>
        {this.renderList()}
        <Spacer height={hp(5)} />
      </div>
    );
  };

  renderContentComercials = () => {
    return (
      <div style={{ width: '93%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: hp(3),
            marginBottom: hp(4),
          }}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ display: 'flex', width: isMobile() ? '100%' : 300 }}>
              <InputBox
                //search
                placeholder={'Buscar Centro Comercial'}
                value={''}
                onChangeText={(scc) => {
                  this.setState({
                    search: scc,
                  });
                }}
              />
            </div>
            <Spacer width={hp(4)} />
          </div>
          {isMobile() && <Spacer width={hp(2)} />}
          <div style={{ display: 'flex' }}>
            <Button
              backgroundColor="#D87F7A"
              onClick={() => this.setState({ showModalComercials: true })}
              textStyle={{ fontSize: isMobile() ? hp(2) : hp(2.3), textAlign: 'center' }}
              style={{
                margin: '0 10px',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: hp(1),
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
                paddingLeft: isMobile() ? hp(1) : hp(1.5),
                paddingRight: isMobile() ? hp(1) : hp(1.5),
                whiteSpace: 'nowrap',
              }}
            >
              Crear Centro Comercial
            </Button>
          </div>
          <Spacer width={wp(1) + hp(2)} />
        </div>
        <div style={{ marginBottom: '1rem' }}>{this.renderListComercials()}</div>
      </div>
    );
  };

  renderListComercials = () => {
    const actions = {
      onDelete: (pdata) => this.onDeleteComercial(pdata.id),
      onEdit: (pdata) => {
        if (typeof pdata.imageComercial == 'string') {
          this.toDataURL(pdata.imageComercial).then((dataUrl) => {
            this.setState({
              showModalComercials: true,
              idComercial: pdata.id,
              nameComercial: pdata.nameComercial,
              direccionComercial: pdata.direccionComercial,
              imageUrl: dataUrl,
              imageFile: dataUrl,
            });
          });
        }
      },
    };
    const labelEmpty = 'No hay centros comerciales';
    return (
      <List
        actions={actions}
        labelEmpty={labelEmpty}
        topHeader
        header
        params={paramsComercials}
        data={this.state.comercials}
      />
    );
  };

  render() {
    return (
      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {this.state.showComerciales && this.renderNegociosFromComercials()}
        {this.state.showComerciales && (
          <div
            className="scrollnone"
            style={{
              overflowY: 'auto',
              flexDirection: 'column',
              backgroundColor: '#F9F9F9',
              paddingBottom: hp(5),
              flex: 1,
              width: '100%',
              display: 'flex',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                top: 0,
                height: '100%',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              {this.renderContentComercials()}{' '}
            </div>
          </div>
        )}
        {this.state.showModalComercials && this.renderModalCreateComercials()}

        {!this.state.showComerciales && this.renderHeader()}
        {!this.state.showComerciales && (
          <div
            className="scrollnone"
            style={{
              overflowY: 'auto',
              flexDirection: 'column',
              backgroundColor: '#F9F9F9',
              paddingBottom: hp(5),
              flex: 1,
              width: '100%',
              display: 'flex',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                top: 0,
                height: '100%',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              {this.renderContent()}
            </div>
            {this.renderCreateModal()}
          </div>
        )}
        {!this.state.showComerciales && this.renderQuestion()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  companyID: state.companyID,
  companyInfo: state.companyInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyID: (value) => {
    dispatch(setCurrentCompanyID(value));
  },
  setCompanyInfo: (value) => {
    dispatch(setCompanyInfo(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
