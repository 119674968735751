import Geocode from 'react-geocode';
import { GOOGLEMAPS_KEY } from './constants';

Geocode.setApiKey(GOOGLEMAPS_KEY);
Geocode.enableDebug();

export async function getPositionByAddress(val) {
  if (val != '') {
    try {
      var res = await Geocode.fromAddress(val);

      console.log(res);
      if (res.status == 'OK') {
        var place = res.results[0].geometry.location;
        return place;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
export const loadGoogleMapScript = (callback) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    callback();
  } else {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAPS_KEY}&libraries=places&language=es`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  }
};
const loadScript = (url, callback) => {
  let script = document.createElement('script'); // create script tag
  script.type = 'text/javascript';

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; // load by url
  document.getElementsByTagName('head')[0].appendChild(script); // append to head
};

export function autoComplete(val) {
  var autoComplete = new window.google.maps.places.Autocomplete(val, {
    types: ['(cities)'],
    componentRestrictions: { country: 'us' },
  });
  const addressObject = autoComplete.getPlace();
  console.log(addressObject);
  /* const query = addressObject.formatted_address; */
  return 'query';
}
