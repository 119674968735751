import actionNames from './action_names';

const options = {
  '12': 4,
  '11': 12,
  '10': 1,
  '9': 2,
  '8': 9,
  '1': 3,
  '2': 5,
  '3': 6,
  '4': 7,
  '5': 8,
  '6': 10,
  '7': 11,
};

function setFirebaseID(value) {
  return {
    type: actionNames.SET_FIREBASEID,
    payload: value,
  };
}
function setCompanyID(value) {
  return {
    type: actionNames.SET_COMPANYID,
    payload: value,
  };
}
function setCurrentReceipt(value) {
  return {
    type: actionNames.SET_CURRENTRECEIPT,
    payload: value,
  };
}
function setCompanyInfo(value) {
  const { storeSchedules = [] } = value || {};
  storeSchedules.sort((a, b) => options[a.type] - options[b.type]);
  return {
    type: actionNames.SET_COMPANYINFO,
    payload: value,
  };
}
function setCurrentCompanyID(value) {
  return {
    type: actionNames.SET_CURRENTCOMPANYID,
    payload: value,
  };
}
function setOwner(value) {
  return {
    type: actionNames.SET_OWNER,
    payload: value,
  };
}
function setLocals(value) {
  return {
    type: actionNames.SET_LOCALS,
    payload: value,
  };
}
function setUsers(value) {
  return {
    type: actionNames.SET_USERS,
    payload: value,
  };
}
function setStores(value) {
  return {
    type: actionNames.SET_STORES,
    payload: value,
  };
}
function setNoDevStores(value) {
  return {
    type: actionNames.SET_NODEV_STORES,
    payload: value,
  };
}
export {
  setFirebaseID,
  setCompanyID,
  setCurrentReceipt,
  setCompanyInfo,
  setCurrentCompanyID,
  setOwner,
  setLocals,
  setUsers,
  setStores,
  setNoDevStores,
};
