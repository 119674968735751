import moment from 'moment';
import React from 'react';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import emptyIcon from '../../assets/images/empty-products.png';
import notifications from '../../assets/images/notifications.png';
import viewed from '../../assets/images/viewed.png';
import BoxButton from '../../components/BoxButton';
import BoxImage from '../../components/BoxImage';
import BoxSearch from '../../components/BoxSearch';
import Button from '../../components/Button';
import FormSales from '../../components/FormSales';
import List from '../../components/List';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import Toggler from '../../components/Toggler';
import _emitter from '../../utils/events';
import { firebase, storage } from '../../utils/firebase';
import { number_format } from '../../utils/functions';
import { hp, wp } from '../../utils/responsive';
import { notifyUsers, updateStoreStock } from '../../utils/services';
import ModalQuestion from '../ModalQuestion';
import './index.css';
const tempImage =
  'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';

const params = [
  {
    title: 'Img',
    key: 'image',
    width: '15%',
    render: (row, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '15%',
            justifyContent: 'flex-start',
          }}
        >
          <BoxImage url={row} size={wp(4)} />
        </div>
      );
    },
  },
  {
    title: 'Producto',
    key: 'name',
    width: '45%',
    alignment: 'flex-start',
  },
  {
    title: 'Precio',
    key: 'discounted',
    width: '20%',
    alignment: 'center',
    prefix: 'S/ ',
  },
  {
    title: 'Venta',
    width: '20%',
    alignment: 'center',
    render: (row, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '20%',
            justifyContent: 'center',
          }}
        >
          <TextLato style={textStyle}>
            {row['count']} | {row['total']}
          </TextLato>
        </div>
      );
    },
  },
];

class Sales extends React.Component {
  state = {
    products: [],
    sales: [],
    formVisible: false,
    selectVisible: false,
    currentProduct: null,
    file: null,
    notif: true,
    updateVisible: false,
    deleteVisible: false,
    tempData: false,
    publishVisible: false,
    cantNotif: 0,
    pendingdelete: [],
    showMessageBox: false,
    listNotifications: [],
    countViewed: 0,
    imageProfile: '',
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.getProducts();
    this.getSales();
    this.configureNotif();
    this.getNotifications();
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    this.notifListener();
    window.removeEventListener('resize', this.resize);
  }
  getNotifications = async () => {
    const { companyInfo } = this.props;
    console.log(this.props);
    //let info = await getInfoCompany(companyInfo.id);
    //console.log(info);
    firebase
      .firestore()
      .collection('notifications')
      .doc(companyInfo.id.toString().trim())
      .collection('list')
      .orderBy('date', 'desc')
      .onSnapshot((querySnapshot) => {
        console.log(querySnapshot.docs);
        let notifications = [];
        let count = 0;
        querySnapshot.docs.forEach((doc) => {
          let data = doc.data();
          if (!data.viewed) {
            count++;
          }
          console.log(data);
          notifications.push({ ...data, id: doc.id });
        });
        this.setState({
          listNotifications: notifications,
          countViewed: count,
          imageProfile: companyInfo.image,
        });
      });
  };
  updateStateNotification = (item) => {
    const { companyInfo } = this.props;
    firebase
      .firestore()
      .collection('notifications')
      .doc(companyInfo.id.toString().trim())
      .collection('list')
      .doc(item.id)
      .update({ viewed: true })
      .then((docSales) => {
        //notifyUsers(companyID);
      })
      .catch((error) => {});
  };
  configureNotif = () => {
    const { companyInfo } = this.props;
    console.log(companyInfo.id.toString());
    this.notifListener = firebase
      .firestore()
      .collection('webnotif')
      .where('storeId', '==', companyInfo.id.toString())
      .onSnapshot(async (snapshot) => {
        console.log('sales => ', snapshot.docs);
        var pendingdeleteArray = [];
        snapshot.docs.forEach((doc) => {
          pendingdeleteArray.push(doc.id);
        });

        console.log(pendingdeleteArray);
        _emitter.emit('pedidos', snapshot.docs.length);
        this.setState({ cantNotif: snapshot.docs.length, pendingdelete: pendingdeleteArray });
      });
  };

  deletePendingNotif = async () => {
    console.log(this.state.pendingdelete);
    this.notifListener();
    for (var i = 0; i < this.state.pendingdelete.length; i++) {
      var elem = firebase.firestore().collection('webnotif').doc(this.state.pendingdelete[i]);
      await elem.delete();
    }
    this.configureNotif();
  };

  getSales = () => {
    const $this = this;
    const { companyInfo } = this.props;

    var companyID = companyInfo.id.toString();
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';
    if (companyID != null) {
      firebase
        .firestore()
        .collection(dev ? 'sales' : 'sales')
        .where('companyId', '==', companyID.trim())
        .onSnapshot((querySnapshot) => {
          const data = [];

          querySnapshot.docs.forEach((doc) => {
            let temp = doc.data();
            if (!(temp.image != null)) {
              temp.image = tempImage;
            } else {
              if (temp.image.length === 0 || temp.image === null || temp.image === '') {
                temp.image = tempImage;
              }
            }

            if (!(temp.visible != null)) {
              if (!(temp.active != null)) {
                data.push({ ...temp, id: doc.id, key: doc.id });
              } else {
                if (temp.active !== false) {
                  data.push({ ...temp, id: doc.id, key: doc.id });
                }
              }
            } else {
              if (temp.visible !== false) {
                if (!(temp.active != null)) {
                  data.push({ ...temp, id: doc.id, key: doc.id });
                } else {
                  if (temp.active !== false) {
                    data.push({ ...temp, id: doc.id, key: doc.id });
                  }
                }
              }
            }
          });

          $this.setState({
            sales: data.sort((a, b) => {
              if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
                return 1;
              }
              if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
                return -1;
              }
              // a must be equal to b
              return 0;
            }),
          });
        });
    }
  };
  getProducts = () => {
    const { companyInfo } = this.props;
    console.log(companyInfo);
    var companyID = companyInfo.id.toString();
    var $this = this;
    if (companyID != null) {
      firebase
        .firestore()
        .collection('products')
        .where('companyId', '==', companyID.trim())
        .onSnapshot((querySnapshot) => {
          const products = [];

          querySnapshot.docs.forEach((doc) => {
            let temp = doc.data();
            if (!(temp.image != null)) {
              temp.image = tempImage;
            } else {
              if (temp.image.length === 0 || temp.image === null || temp.image === '') {
                temp.image = tempImage;
              }
            }

            if (!(temp.active != null)) {
              products.push({ ...temp, productId: doc.id, id: doc.id, key: doc.id });
            } else {
              if (temp.active !== false) {
                products.push({ ...temp, productId: doc.id, id: doc.id, key: doc.id });
              }
            }
          });

          $this.setState({ products });
        });
    }
  };
  onSave = async (data) => {
    const { companyInfo } = this.props;
    const { notif } = this.state;
    var companyID = companyInfo.id.toString();

    const { status } = companyInfo;
    var dev = status == 'dev';
    const configProduct = firebase.firestore().collection(dev ? 'sales' : 'sales');
    const $this = this;
    if (data != null) {
      var sendData = {};
      var currentPrice = parseFloat(data.original);
      var currentDiscount = parseFloat(data.discount);
      var currentTotal = parseInt(data.count);
      if (parseFloat(currentDiscount.toFixed(2)) > parseFloat((currentPrice * 0.6).toFixed(2))) {
        this.setState({
          visible: false,
          error: '¡El precio descontado debe tener 40% de dscto o más!',
        });
        console.log('error 1');
        return;
      }
      if (currentTotal <= 0 || currentTotal == null || currentTotal == '') {
        this.setState({
          visible: false,
          error: '¡El valor ingresado debe ser un número superior a 0!',
        });
        console.log('error 2');
        return;
      }

      var duplicate = this.state.sales.find((x) => x.name == data.name);
      if (duplicate != null) {
        this.setState({ errorModal: '' });
        return;
      }
      // delete sendData['image'];
      sendData.date = new Date();
      sendData.uid = companyID;
      sendData.userId = companyID;
      sendData.companyId = companyID;
      sendData.count = 0;
      sendData.name = data.name;
      sendData.description = data.description;
      sendData.keepPublished = false;
      sendData.price = data.original == null ? '0.00' : number_format(data.original.toString());
      var finalDiscounted =
        data.discount == null ? '0.00' : number_format(data.discount.toString());
      sendData.dicountedValue = parseFloat(finalDiscounted);
      sendData.discounted = finalDiscounted;
      sendData.total = parseInt(data.count).toString();
      sendData.image =
        data.image != null
          ? data.image
          : 'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';
      sendData.radio = data.radio;
      sendData.peso = data.peso == null ? '0.00' : number_format(data.peso.toString());
      sendData.expireDate = data.expireDate;
      sendData.publish = data.publish;
      sendData.publishedTil = data.publishedTil;
      sendData.productId = data.productId;
      sendData.brandId = data.brandId || '';
      sendData.brandQuestionId = data.brandQuestionId || '';
      sendData.brandAnswerId = data.brandAnswerId || '';
      this.props.loading(true);
      if (this.state.file != null) {
        var type = this.state.file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        var filename = uuidv1() + '.' + type;
        Resizer.imageFileResizer(
          this.state.file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            const uploadTask = storage.ref(`stores/${companyID}/published/${filename}`).put(uri, {
              contentType: 'image/jpeg',
            });
            uploadTask.on(
              'state_changed',
              () => {},
              () => {},
              () => {
                sendData.image = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fpublished%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
                configProduct
                  .add(sendData)
                  .then(async (docSales) => {
                    $this.setState({
                      formVisible: false,
                      selectVisible: false,
                    });
                    this.props.loading(false);
                    if (data.toStore && !sendData.productId) {
                      const newProductId = await this.onSaveToStored(sendData);
                      if (newProductId) {
                        docSales.update({ productId: newProductId });
                      }
                    }
                    updateStoreStock(companyID);
                    if (notif) notifyUsers(companyID);
                  })
                  .catch((error) => {
                    alert('Error adding document: ' + error);
                  });
              },
            );
          },
          'blob',
        );
      } else {
        configProduct
          .add(sendData)
          .then(async (docSales) => {
            this.setState({
              formVisible: false,
              selectVisible: false,
            });
            this.props.loading(false);
            if (data.toStore && !sendData.productId) {
              const newProductId = await this.onSaveToStored(sendData);
              if (newProductId) {
                docSales.update({ productId: newProductId });
              }
            }
            updateStoreStock(companyID);
            if (notif) notifyUsers(companyID);
          })
          .catch((error) => {
            alert('Error adding document: ' + error);
          });
      }
    }
  };
  onUpdate = async (data) => {
    console.log('data =>', data);
    console.log('currentProduct =>', this.state.currentProduct);
    const { companyInfo, admin } = this.props;
    var companyID = companyInfo.id.toString();
    const { status } = companyInfo;
    var dev = status == 'dev';
    const configProduct = firebase.firestore().collection(dev ? 'sales' : 'sales');

    if (data != null) {
      var current = parseInt(this.state.currentProduct.total);
      var currentTotal = parseInt(data.count);
      var sendData = this.state.currentProduct;
      var totalModified = sendData.total !== data.count.toString();
      if (!admin && currentTotal < data.stock) {
        alert('El stock no puede ser menor a la cantidad vendida.');
        return;
      } else {
        sendData.total = currentTotal.toString();
      }
      if (currentTotal <= 0 || currentTotal == null || currentTotal == '') {
        this.setState({
          visible: false,
          error: '¡El valor ingresado debe ser un número superior a 0!',
        });
        console.log('error 2');
        return;
      }
      /**
       * currentTotal => lo que estoy editando
       * current => lo que viene desde la BD
       */

      if (admin) {
        if (parseInt(data.stock) <= parseInt(currentTotal)) sendData.count = parseInt(data.stock);
      }
      sendData.publish = data.publish;
      sendData.publishedTil = data.publishedTil;
      sendData.name = data.name;
      sendData.description = data.description;
      sendData.radio = data.radio;
      sendData.expireDate = data.expireDate;
      sendData.price = data.original == null ? '0.00' : number_format(data.original.toString());
      sendData.peso = data.peso == null ? '0.00' : number_format(data.peso.toString());
      var finalDiscounted =
        data.discount == null ? '0.00' : number_format(data.discount.toString());
      sendData.dicountedValue = parseFloat(finalDiscounted);
      sendData.discounted = finalDiscounted;
      this.props.loading(true);
      if (this.state.file != null) {
        var type = this.state.file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        var filename = uuidv1() + '.' + type;
        Resizer.imageFileResizer(
          this.state.file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            const uploadTask = storage.ref(`stores/${companyID}/published/${filename}`).put(uri, {
              contentType: 'image/jpeg',
            });
            uploadTask.on(
              'state_changed',
              () => {},
              () => {},
              () => {
                sendData.image = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fpublished%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
                configProduct
                  .doc(sendData.id)
                  .update(sendData)
                  .then((docSales) => {
                    this.setState({
                      formVisible: false,
                      selectVisible: false,
                      formDetails: false,
                      file: null,
                    });
                    console.log('totalModified', totalModified);
                    if (totalModified) {
                      updateStoreStock(companyID);
                    }
                    this.props.loading(false);
                  })
                  .catch((error) => {
                    alert('Error adding document: ' + error);
                  });
              },
            );
          },
          'blob',
        );
      } else {
        configProduct
          .doc(sendData.id)
          .update(sendData)
          .then((docSales) => {
            this.setState({
              formVisible: false,
              selectVisible: false,
              formDetails: false,
              file: null,
            });
            console.log('totalModified', totalModified);
            if (totalModified) updateStoreStock(companyID);
            this.props.loading(false);
          })
          .catch((error) => {
            alert('Error adding document: ' + error);
          });
      }
    }
  };
  onDelete = async () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const configProduct = firebase
      .firestore()
      .collection(companyInfo.status == 'dev' ? 'sales' : 'sales');

    var sendData = this.state.currentProduct;
    configProduct
      .doc(sendData.id)
      .delete()
      .then(() => {
        this.setState({
          formVisible: false,
          selectVisible: false,
          formDetails: false,
        });
        updateStoreStock(companyID);
        //notifyUsers(companyID);
      })
      .catch((error) => {
        alert('Error delete document: ' + error);
      });
  };
  onChangeImage = (file) => {
    this.setState({ file });
  };
  onSaveToStored = async (data) => {
    const configProduct = firebase.firestore().collection('products');

    if (data != null) {
      var sendData = data;
      delete sendData.publish;
      delete sendData.publishedTil;
      sendData.total = '0';
      var duplicate = this.state.products.find((x) => x.name == data.name);
      if (duplicate != null) {
        alert('Este producto ya ha sido publicado');
        return;
      }
      try {
        const newProduct = await configProduct.add(sendData);
        return newProduct.id;
      } catch (error) {
        console.log(error);
      }
    }
    return null;
  };

  showUpdateNotifications = () => {
    const { countViewed, listNotifications, showMessageBox } = this.state;
    if (countViewed > 0) {
      listNotifications.forEach((not) => {
        if (!not.viewed) {
          this.updateStateNotification(not);
        }
      });
    }

    this.setState({ showMessageBox: !showMessageBox });
  };
  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const { navigate, admin } = this.props;
    const {
      products,
      sales,
      formVisible,
      selectVisible,
      formDetails,
      updateVisible,
      deleteVisible,
      publishVisible,
      showMessageBox,
      countViewed,
    } = this.state;
    const subtitleText = {
      fontSize: wp(0.8) + hp(0.7) < 14 ? 14 : wp(0.8) + hp(0.7),
      fontWeight: '400',
      color: '#392E2E',
    };
    return (
      <div
        onClick={() => showMessageBox && this.setState({ showMessageBox: false })}
        style={{
          width: '100%',
          minWidth: 900,
        }}
      >
        <div
          className="scrollbar"
          style={{
            overflowY: 'auto',
            display: 'flex',
            width: '100%',
            minWidth: 800,
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'stretch',
          }}
        >
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                top: 0,
                height: '100%',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <div
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  paddingLeft: hp(10),
                  paddingTop: hp(3),
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div
                      onClick={() => {
                        navigate(0, 1);
                        setTimeout(() => navigate(0, 0), 50);
                      }}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        flexDirection: 'column',
                        justifyContent: 'center',

                        borderBottom: '1px solid black',
                      }}
                    >
                      <Spacer height={hp(1)} />
                      <TextLato style={{ ...subtitleText }}>Agregar Productos</TextLato>
                      <Spacer height={hp(1)} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div
                      onClick={async () => {
                        //await this.deletePendingNotif();
                        navigate(0, 0);
                        setTimeout(() => navigate(0, 1), 50);
                        //this.configureNotif();
                      }}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Spacer height={hp(1)} />
                      <TextLato style={subtitleText}>Ver Pedidos </TextLato>
                      <Spacer height={hp(1)} />
                    </div>
                    {''}
                    {this.state.cantNotif > 0 && (
                      <div
                        style={{
                          marginLeft: '5px',
                          color: 'white',
                          borderRadius: '50%',
                          fontSize: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '2em',
                          height: '2em',
                          background: '#D87F7A',
                        }}
                      >
                        {this.state.cantNotif}
                      </div>
                    )}
                  </div>
                </div>
                <Spacer height={hp(5)} />
                <TextLato style={textStyle}>Productos Actuales</TextLato>
                <Spacer height={hp(3)} />
                {sales.length > 0 ? (
                  <List
                    handleRow={(data) => {
                      this.setState(
                        {
                          formDetails: false,
                          formVisible: false,
                          selectVisible: false,
                        },
                        () =>
                          this.setState({
                            currentProduct: data,
                            file: null,
                            formDetails: true,
                            formVisible: false,
                            selectVisible: false,
                          }),
                      );
                    }}
                    header={true}
                    params={params}
                    data={sales}
                  />
                ) : (
                  <div
                    style={{
                      width: hp(25),
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Spacer height={hp(2)} />
                    <img src={emptyIcon} height={hp(20)} />
                    <Spacer height={hp(2)} />
                    <TextLato
                      style={{
                        fontSize: textStyle.fontSize * 0.7,
                        fontWeight: '300',
                        textAlign: 'center',
                      }}
                    >
                      Todavía no hay productos a la venta...
                    </TextLato>
                    <Spacer height={hp(4)} />
                  </div>
                )}
                <Spacer height={hp(3)} />
                <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.85 }}>
                  Publicar
                </TextLato>
                <Spacer height={hp(1)} />
                <div style={{ backgroundColor: '#BCBBC1', height: 1 }} />
                <Spacer height={hp(3)} />
                <div
                  style={{ width: '100%', position: 'relative', zIndex: 2, paddingBottom: '20px' }}
                >
                  {!formVisible && !selectVisible && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <BoxButton
                        onClick={() => {
                          this.setState({
                            selectVisible: true,
                            formDetails: false,
                          });
                        }}
                        icon={true}
                      >
                        Agregar Existente
                      </BoxButton>
                      <Spacer height={hp(3)} />
                      <BoxButton
                        onClick={() => {
                          this.setState(
                            {
                              currentProduct: null,
                              file: null,
                              formVisible: true,
                              formDetails: false,
                            },
                            () => {
                              this.bottomDiv.scrollIntoView({ behavior: 'smooth' });
                            },
                          );
                        }}
                        icon={true}
                      >
                        Agregar Nuevo
                      </BoxButton>
                      <Spacer height={hp(2)} />
                    </div>
                  )}
                  {selectVisible && !formVisible && (
                    <div>
                      <BoxSearch
                        search={true}
                        maxHeight={hp(43)}
                        handleRow={(row) => {
                          console.log('row', row);
                          this.setState(
                            { formVisible: true, currentProduct: { ...row, radio: 1 }, file: null },
                            () => {
                              this.bottomDiv.scrollIntoView({ behavior: 'smooth' });
                            },
                          );
                        }}
                        products={products}
                      />
                      <div
                        style={{
                          flex: 1,
                          width: '50%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          backgroundColor={'#ffff'}
                          onClick={() => {
                            this.setState({
                              formVisible: false,
                              selectVisible: false,
                              currentProduct: null,
                              file: null,
                            });
                          }}
                          second={true}
                          textStyle={{ fontSize: wp(0.8) + hp(0.5), color: '#392E2E' }}
                          style={{
                            display: 'flex',
                            flex: 1,
                            paddingTop: hp(1.4),
                            paddingBottom: hp(1.4),
                            borderRadius: hp(0.8),
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Volver
                        </Button>
                      </div>
                    </div>
                  )}

                  {selectVisible && formVisible && (
                    <BoxButton
                      onClick={() => {
                        this.setState({ formVisible: false });
                      }}
                    >
                      {this.state.currentProduct.name}
                    </BoxButton>
                  )}
                  {selectVisible && formVisible && <Spacer height={hp(3)} />}
                  {formVisible && (
                    <div style={{ display: 'flex', position: 'relative', width: '190%' }}>
                      <FormSales
                        loading={this.props.loading}
                        admin={admin}
                        sales={true}
                        reduced={true}
                        noStock={true}
                        file={this.state.file}
                        initialData={this.state.currentProduct}
                        onSave={(data) => {
                          this.setState({
                            tempData: data,
                            publishVisible: true,
                          });
                        }}
                        onSuccessImage={this.onChangeImage}
                        onRelease={() => {
                          this.setState({
                            formVisible: false,
                            selectVisible: false,
                            currentProduct: null,
                            file: null,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                <div ref={(x) => (this.bottomDiv = x)}></div>
              </div>
              <div
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  paddingLeft: hp(5),
                  paddingTop: hp(3),
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      opacity: 0,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',

                        borderBottom: '1px solid black',
                      }}
                    >
                      <Spacer height={hp(1)} />
                      <TextLato style={{ ...subtitleText }}>Agregar Productos</TextLato>
                      <Spacer height={hp(1)} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      opacity: 0,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Spacer height={hp(1)} />
                      <TextLato style={subtitleText}>Ver Pedidos</TextLato>
                      <Spacer height={hp(1)} />
                    </div>
                  </div>
                  {!admin && (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          margin: '0 1rem',
                        }}
                      >
                        <Spacer height={hp(1)} />
                        <Button
                          backgroundColor="#ffffff"
                          style={{
                            display: 'flex',
                            height: hp(6),
                            width: wp(3.2),
                            borderRadius: '50%',
                            boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            zIndex: 101,
                          }}
                          //onClick={() => this.setState({ showMessageBox: !showMessageBox })}
                          onClick={() => this.showUpdateNotifications()}
                        >
                          <img src={notifications} style={{ height: wp(1.8) }} />
                          {countViewed > 0 && (
                            <div
                              style={{
                                width: wp(1),
                                height: wp(1),
                                backgroundColor: 'red',
                                position: 'absolute',
                                right: wp(0.2),
                                top: wp(0.1),
                                borderRadius: wp(0.5) + 3,
                                border: '3px solid white',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: wp(0.5),
                              }}
                            >
                              {countViewed}{' '}
                            </div>
                          )}
                        </Button>
                        <Spacer height={hp(1)} />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Spacer height={hp(1)} />
                        <Button
                          backgroundColor="#ffffff"
                          style={{
                            display: 'flex',
                            height: hp(6),
                            width: wp(3.2),
                            borderRadius: '50%',
                            boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            zIndex: 101,
                          }}
                        >
                          <img
                            src={this.state.imageProfile}
                            style={{
                              borderRadius: wp(1.5),
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          />
                        </Button>
                        <Spacer height={hp(1)} />
                      </div>
                    </div>
                  )}
                </div>
                <Spacer height={hp(2)} />
                <div style={{ position: 'relative', width: '80%' }}>
                  <TextLato style={{ ...textStyle, opacity: 0 }}>Productos Actuales</TextLato>
                  {admin && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                      }}
                    >
                      <Toggler
                        initial={true}
                        handle={(val) =>
                          this.setState({
                            notif: val,
                          })
                        }
                        size={wp(1) + hp(1)}
                      />
                      <Spacer width={hp(2)} />
                      <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.85 }}>
                        Enviar notificación
                      </TextLato>
                    </div>
                  )}
                </div>
                <Spacer height={hp(3)} />
                <div style={{ width: '80%' }}>
                  {formDetails && (
                    <FormSales
                      loading={this.props.loading}
                      admin={admin}
                      showPersistance={admin}
                      initialData={this.state.currentProduct}
                      onSave={(data) => {
                        if (data.stock == 0 && data.count == 0) {
                          this.setState({
                            tempData: data,
                            deleteVisible: true,
                          });
                        } else {
                          this.setState({
                            tempData: data,
                            updateVisible: true,
                          });
                        }
                      }}
                      file={this.state.file}
                      onDelete={this.onDelete}
                      onSuccessImage={this.onChangeImage}
                      onRelease={() => {
                        this.setState({
                          formDetails: false,
                          currentProduct: null,
                          file: null,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {showMessageBox && (
            <>
              <div
                style={{
                  backgroundColor: '#00000020',
                  width: wp(120),
                  height: hp(100),
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  zIndex: 100,
                }}
              ></div>
              <div
                style={{
                  borderRadius: hp(1.2),
                  MozBorderRadius: hp(1.2),
                  WebkitBorderRadius: hp(1.2),
                  paddingTop: hp(3),
                  paddingBottom: hp(3),
                  WebkitBoxShadow: '2px 0px 20px rgba(0,0,0,0.2)',
                  MozBoxShadow: '2px 0px 20px rgba(0,0,0,0.2)',
                  boxShadow: '2px 0px 20px rgba(0,0,0,0.2)',
                  position: 'fixed',
                  right: hp(5),
                  top: hp(13),
                  zIndex: 101,
                  backgroundColor: '#ffffff',
                  width: '25%',
                }}
              >
                <TextLato
                  style={{ margin: '0 1rem', fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1) }}
                >
                  Notificaciones
                </TextLato>
                <Spacer height={hp(1)} />
                <div style={{ margin: '0 1rem', backgroundColor: '#BCBBC1', height: 1 }} />
                <div
                  class="scrollbar"
                  style={{ height: '400px', overflowY: 'auto', margin: '1rem' }}
                >
                  {this.state.listNotifications.map(
                    (item) =>
                      item.send == 1 && (
                        <div class="scroll">
                          <div
                            style={{ margin: '1rem 0 ', cursor: 'pointer' }}
                            onClick={() => this.updateStateNotification(item)}
                          >
                            <TextLato
                              style={{
                                fontSize: wp(0.6) + hp(0.6) < 15 ? 15 : wp(0.6) + hp(0.6),
                                fontWeight: 500,
                              }}
                            >
                              {!item.viewed && (
                                <img
                                  src={viewed}
                                  style={{ height: hp(2.5), marginRight: '0.6rem ' }}
                                />
                              )}
                              {moment(item.date.toDate()).format('DD/MM/YY')} - {item.title}
                            </TextLato>
                            <Spacer height={hp(1)} />
                            <TextLato
                              style={{
                                color: 'rgba(57, 46, 46, 1)',
                                fontSize: wp(0.6) + hp(0.6) < 14 ? 14 : wp(0.6) + hp(0.6),
                                fontWeight: 300,
                              }}
                            >
                              {item.body}
                            </TextLato>
                            <Spacer height={hp(1)} />
                          </div>
                          <div
                            style={{ backgroundColor: '#BCBBC1', height: 1, marginRight: '0.6rem' }}
                          />
                          <Spacer height={hp(2)} />
                        </div>
                      ),
                  )}
                </div>
              </div>
            </>
          )}
          <ModalQuestion
            visible={updateVisible}
            message={'¿Estas seguro que deseas actualizar el producto?'}
            okAction={() => {
              var temporal = this.state.tempData;
              this.onUpdate(temporal);
              this.setState({ updateVisible: false, tempData: null });
            }}
            cancelAction={() => {
              this.setState({ updateVisible: false, tempData: null });
            }}
            okText={'Actualizar'}
          />
          <ModalQuestion
            visible={deleteVisible}
            message={'¿Estas seguro que deseas eliminar el producto?'}
            okAction={() => {
              this.onDelete();
              this.setState({ deleteVisible: false, tempData: null });
            }}
            cancelAction={() => {
              this.setState({ deleteVisible: false, tempData: null });
            }}
            okText={'Confirmar'}
          />
          <ModalQuestion
            visible={publishVisible}
            message={'¿Estas seguro que deseas publicar el producto?'}
            okAction={() => {
              var temporal = this.state.tempData;
              this.onSave(temporal);
              this.setState({ publishVisible: false, tempData: null });
            }}
            cancelAction={() => {
              this.setState({ publishVisible: false, tempData: null });
            }}
            okText={'Publicar'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.companyID,
    companyInfo: state.companyInfo,
  };
};

export default connect(mapStateToProps, null)(Sales);
