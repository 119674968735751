import React from 'react';
import { connect } from 'react-redux';
import deliveryIcon from '../../assets/images/delivery.png';
import emptyState from '../../assets/images/empty-state.png';
import Star from '../../assets/images/fill-star.png';
import specsIcon from '../../assets/images/specs.png';
import StarActive from '../../assets/images/star.png';
import BoxButton from '../../components/BoxButton';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import List from '../../components/List';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { setCurrentReceipt } from '../../store/actions';
import _emitter from '../../utils/events';
import { firebase } from '../../utils/firebase';
import { timeConverter } from '../../utils/functions';
import { hp, wp } from '../../utils/responsive';
import { cancelOrderPickupDate, getRatings, sendNotification } from '../../utils/services';
import Receipt from '../Receipt';
import './index.css';

class Orders extends React.Component {
  state = {
    dataDelivered: [],
    dataNext: [],
    receipt: false,
    search: '',
    ratings: {},
    users: {},
    cantNotif: 0,
    pendingdelete: [],
  };
  componentDidMount = async () => {
    _emitter.emit('showNotif', false);
    await this.configureNotif();
    window.addEventListener('resize', this.resize);
    this.getProximos();
    this.getEntregados();
    /* this.loadRating(); */
    //await this.deletePendingNotif();
  };

  configureNotif = async () => {
    const { companyInfo } = this.props;
    //this.notifListener = await firebase
    console.log('pre listener', false);
    _emitter.emit('listen_notif', false);
    //setTimeout(async () => {
    await firebase
      .firestore()
      .collection('webnotif')
      .where('storeId', '==', companyInfo.id.toString())
      .get()
      .then(async (snapshot) => {
        console.log('orders notif', snapshot.docs);
        var pendingdeleteArray = [];
        snapshot.docs.forEach((doc) => {
          pendingdeleteArray.push(doc.id);
        });

        for (var i = 0; i < pendingdeleteArray.length; i++) {
          var elem = firebase.firestore().collection('webnotif').doc(pendingdeleteArray[i]);
          await elem.delete();
        }
        document.title = 'Cirkula';
        _emitter.emit('listen_notif', true);
        _emitter.emit('pedidos', 0);
        //await this.setState({ cantNotif: snapshot.docs.length, pendingdelete: pendingdeleteArray });
      });
    //}, 2000);
  };

  deletePendingNotif = async () => {
    console.log('order delete => ', this.state.pendingdelete);
  };

  loadRating = async () => {
    try {
      var ratings = await getRatings();
      if (ratings.items != null) {
        var rate = ratings.items.reduce((prev, x) => ({ ...prev, [x.orderNumber]: x.rating }), {});
        this.setState({
          ratings: rate,
        });
      }
    } catch (error) {}
  };
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    _emitter.emit('showNotif', true);
    window.removeEventListener('resize', this.resize);
    //this.notifListener();
  }
  transformOrder = (data) => {
    const hours = timeConverter(data.date.seconds, 'hours');
    const date = timeConverter(data.date.seconds, 'date');
    // store the original date
    data.originalDate = data.date;
    const fulldate = `${date} ${hours}`;
    data.date = hours;
    data.fulldate = fulldate;
    data.delivered = null;

    if (data.deliveredAt != null) {
      const hoursDelivered = timeConverter(data.deliveredAt.seconds, 'hours');
      const dateDelivered = timeConverter(data.deliveredAt.seconds, 'date');
      const fulldateDelivered = `${dateDelivered} ${hoursDelivered}`;
      data.date = hoursDelivered;
      data.delivered = fulldateDelivered;
    }

    return data;
  };

  getEntregados = () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';
    var $this = this;

    if (companyID != null) {
      firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        //.collection('orders')
        .where('companyId', '==', companyID)
        //.orderBy('deliveredAt', 'desc')
        .onSnapshot(async (querySnapshot) => {
          const dataDelivered = [];

          querySnapshot.docs.forEach((doc) => {
            if (doc.data().state == 'FAILED' || doc.data().state == 'PENDING') return;
            let temp = $this.transformOrder(doc.data());
            //dataDelivered.push({ ...temp, id: doc.id, key: doc.id });
            // don't add anything if the item doesn't have a date or if it's date is from a past day
            if (!temp.originalDate) {
              return;
            }

            const unixDate = new Date(temp.originalDate.seconds * 1000);

            const now = new Date(),
              nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
              orderDateOnly = new Date(
                unixDate.getFullYear(),
                unixDate.getMonth(),
                unixDate.getDate(),
                0,
                0,
                0,
              );

            if (nowDateOnly.getTime() !== orderDateOnly.getTime()) {
              return;
            }

            if (companyID != null) {
              if (!(temp.visible != null)) {
                if (temp.deliveredAt != null) {
                  dataDelivered.push({ ...temp, id: doc.id, key: doc.id });
                }
              } else {
                if (temp.visible !== false) {
                  if (temp.deliveredAt != null) {
                    dataDelivered.push({ ...temp, id: doc.id, key: doc.id });
                  }
                }
              }
            }
          });

          var users = {};
          console.log(users);
          $this.setState({
            users: { ...this.state.users, ...users },
            dataDelivered: dataDelivered.sort((a, b) => {
              if (a.companyHref < b.companyHref) {
                return 1;
              }
              if (a.companyHref > b.companyHref) {
                return -1;
              }
              // a must be equal to b
              return 0;
            }),
          });
        });
    }
  };

  getProximos = () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';
    var $this = this;
    if (companyID != null) {
      firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        .where('companyId', '==', companyID)
        //.orderBy("deliveredAt", "desc")
        .onSnapshot(async (querySnapshot) => {
          const dataNext = [];

          querySnapshot.docs.forEach((doc) => {
            //console.log('order =>', doc.data());
            if (doc.data().state == 'FAILED' || doc.data().state == 'PENDING') return;
            let temp = $this.transformOrder(doc.data());
            //dataNext.push({ ...temp, id: doc.id, key: doc.id });
            // don't add anything if the item doesn't have a date or if it's date is from a past day
            if (!temp.originalDate) {
              return;
            }

            const unixDate = new Date(temp.originalDate.seconds * 1000);

            const now = new Date(),
              nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
              orderDateOnly = new Date(
                unixDate.getFullYear(),
                unixDate.getMonth(),
                unixDate.getDate(),
                0,
                0,
                0,
              );

            if (nowDateOnly.getTime() !== orderDateOnly.getTime()) {
              return;
            }
            if (companyID != null) {
              if (!(temp.visible != null)) {
                if (!(temp.deliveredAt != null)) {
                  dataNext.push({ ...temp, id: doc.id, key: doc.id });
                }
              } else {
                if (temp.visible !== false) {
                  if (!(temp.deliveredAt != null)) {
                    dataNext.push({ ...temp, id: doc.id, key: doc.id });
                  }
                }
              }
            }
          });
          var users = {};
          $this.setState({
            users: { ...this.state.users, ...users },
            dataNext: dataNext.sort((a, b) => {
              if (a.companyHref < b.companyHref) {
                return 1;
              }
              if (a.companyHref > b.companyHref) {
                return -1;
              }
              // a must be equal to b
              return 0;
            }),
          });
        });
    }
  };
  parseHref = (href) => {
    var index = href.indexOf('0');
    parseInt(href.substr(0));
  };
  handleRow = (row) => {
    const { navigate, setCurrentReceipt } = this.props;
    setCurrentReceipt(row);
    this.setState({ receipt: true });
  };
  uncanceled = async () => {
    try {
      const { currentReceipt } = this.props;
      const { companyInfo } = this.props;
      const { status } = companyInfo || { status: 'live' };
      var dev = status == 'dev';
      const id = currentReceipt.id;

      if (this.props.loading != null) {
        this.props.loading(true);
      }
      const orderRef = firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        .doc(id);
      await orderRef.update({
        canceled: false,
      });

      if (this.props.loading != null) {
        this.props.loading(false);
      }

      this.setState({
        receipt: false,
      });
    } catch (error) {
      alert(error);
    }
  };
  canceled = async () => {
    try {
      const { currentReceipt, navigate } = this.props;
      const { companyInfo } = this.props;
      const companyID = companyInfo.id;
      const { status } = companyInfo || { status: 'live' };
      var dev = status == 'dev';
      const userId = companyID;
      const id = currentReceipt.id;

      const now = new Date();

      if (this.props.loading != null) {
        this.props.loading(true);
      }
      const deliveredAt = firebase.firestore.Timestamp.fromDate(now);
      const orderRef = firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        .doc(id);
      const updateResult = await orderRef.update({
        canceled: true,
        deliveredAt,
        status: 'not delivered',
        employeId: userId,
      });

      const order = await orderRef.get();
      const backendOrderId = order.data().backendOrderId;

      if (backendOrderId != null) {
        if (backendOrderId > 0) {
          var updateBackendResult = await cancelOrderPickupDate(backendOrderId);
        }
      }
      if (this.props.loading != null) {
        this.props.loading(false);
      }

      this.setState({
        receipt: false,
      });
    } catch (error) {
      alert(error);
    }
  };
  renderStars = () => {
    const { currentReceipt } = this.props;
    const { ratings } = this.state;
    const val = ratings[currentReceipt.backendOrderId] || 0;
    var filled = [0, 1, 2, 3, 4];
    return (
      <>
        {ratings[currentReceipt.backendOrderId] != null && val > 0
          ? filled.map((x) => (
              <img src={x < val ? StarActive : Star} style={{ width: hp(3.7), height: hp(3.7) }} />
            ))
          : null}
      </>
    );
  };

  sendNotification = async () => {
    const { currentReceipt } = this.props;
    var ids = [];
    //ids.push(20290)
    ids.push(currentReceipt.userId);
    console.log('pedidos ids => ', ids);
    var send = await sendNotification(
      ids,
      'Pedido cancelado',
      'Lo sentimos tu pedido ha sido cancelado revisar tu correo para mayor detalle.',
    );
    if (send == 200) {
      alert('Notificación enviada.');
    } else {
      alert('Ocurrió un problema al enviar la notificación.');
    }
  };

  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const { navigate, admin, currentReceipt } = this.props;
    const { users } = this.state;
    const subtitleText = {
      fontSize: wp(0.8) + hp(0.7) < 14 ? 14 : wp(0.8) + hp(0.7),
      fontWeight: '400',
      color: '#392E2E',
    };
    const { search } = this.state;
    var dataDelivered =
      search != ''
        ? this.state.dataDelivered.filter(
            (x) => x.companyHref.toUpperCase().indexOf(search.toUpperCase()) != -1,
          )
        : this.state.dataDelivered;
    var dataNext =
      search != ''
        ? this.state.dataNext.filter(
            (x) => x.companyHref.toUpperCase().indexOf(search.toUpperCase()) != -1,
          )
        : this.state.dataNext;
    const params = [
      {
        key: 'companyHref',
        alignment: 'flex-start',
      },
      {
        key: 'products',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                paddingLeft: wp(1),
              }}
            >
              {data.map((item) => {
                return (
                  <TextLato style={textStyle}>
                    ({item.count}) {item.name}
                  </TextLato>
                );
              })}
            </div>
          );
        },
      },
      {
        width: '20%',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div onClick={() => this.handleRow(data)} style={{ width: '20%' }}>
              <TextLato style={textStyle}>{data.canceled ? 'CANCELADO' : data.justDate}</TextLato>
            </div>
          );
        },
      },
      {
        key: 'userName',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                paddingLeft: wp(1),
              }}
            >
              <TextLato style={textStyle}>{data}</TextLato>
            </div>
          );
        },
      },
      {
        key: 'date',
        alignment: 'center',
      },
      {
        key: 'delivery',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: wp(5),
              }}
            >
              {data ? <img src={deliveryIcon} width={wp(1.2)} height={wp(2.6)} /> : null}
            </div>
          );
        },
      },
      {
        key: 'specifications',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: wp(5),
              }}
            >
              {data !== '' ? <img src={specsIcon} width={wp(2.6)} height={wp(2.6)} /> : null}
            </div>
          );
        },
      },
    ];
    return (
      <div
        className="scrollnone"
        style={{
          overflowY: 'auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          minWidth: 700,
          flexDirection: 'row',
          justifyContent: 'stretch',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Spacer width={hp(10)} />
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                paddingTop: hp(3),
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div
                      onClick={() => {
                        navigate(0, 0);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Spacer height={hp(1)} />
                      <TextLato style={{ ...subtitleText }}>Agregar Productos</TextLato>
                      <Spacer height={hp(1)} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div
                      onClick={() => {
                        navigate(0, 1);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',

                        borderBottom: '1px solid black',
                      }}
                    >
                      <Spacer height={hp(1)} />
                      <TextLato style={subtitleText}>Ver Pedidos</TextLato>
                      <Spacer height={hp(1)} />
                    </div>
                    {/*
                    <div
                      style={{
                        marginLeft: '5px',
                        color: 'white',
                        borderRadius: '50%',
                        fontSize: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '2em',
                        height: '2em',
                        background: '#D87F7A',
                      }}
                    >
                      {this.state.cantNotif}
                    </div>*/}
                  </div>
                </div>
                <div style={{ display: 'flex', flex: 1 }}></div>
                <Spacer width={hp(15)} />
              </div>

              <Spacer height={hp(5)} />
              {this.state.receipt && (
                <div
                  style={{
                    cursor: 'pointer',
                    width: 350,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <TextLato
                    onClick={() => {
                      this.setState({
                        receipt: false,
                      });
                    }}
                    style={{
                      color: '#02A9AB',
                      fontSize: wp(0.6) + hp(1.2),
                      fontWeight: '400',
                      lineHeight: 1.6,
                      borderBottom: '1px solid #02A9AB',
                    }}
                  >
                    {'<Volver a pedidos'}{' '}
                  </TextLato>
                  <Spacer width={hp(8)} />
                  {admin && (
                    <div style={{ display: 'flex', flex: 1 }}>
                      <Button
                        backgroundColor={'#D87F7A'}
                        onClick={
                          this.props.currentReceipt.canceled ? this.uncanceled : this.canceled
                        }
                        textStyle={{ fontSize: hp(2.3) }}
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: hp(1),
                          paddingTop: hp(1.5),
                          paddingBottom: hp(1.5),
                        }}
                      >
                        {this.props.currentReceipt.canceled ? 'Descancelar' : 'Cancelar'} pedido
                      </Button>
                    </div>
                  )}
                  {/* {admin &&
                    currentReceipt.canceled == null &&
                    (currentReceipt.deliveredAt != null ? (
                      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        {this.renderStars()}
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flex: 1 }}>
                        <Button
                          backgroundColor={'#D87F7A'}
                          onClick={this.canceled}
                          textStyle={{ fontSize: hp(2.3) }}
                          style={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: hp(1),
                            paddingTop: hp(1.5),
                            paddingBottom: hp(1.5),
                          }}
                        >
                          Cancelar pedido
                        </Button>
                      </div>
                    ))} */}
                  <Spacer width={hp(8)} />
                  {admin && currentReceipt.canceled && (
                    <>
                      <Spacer height={hp(1.5)} />
                      <div style={{ display: 'flex', flex: 1 }}>
                        <Button
                          backgroundColor={'#D87F7A'}
                          onClick={() => this.sendNotification()}
                          textStyle={{ fontSize: hp(2.3) }}
                          style={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: hp(1),
                            paddingTop: hp(1.5),
                            paddingBottom: hp(1.5),
                          }}
                        >
                          Enviar Notificación
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}

              {this.state.receipt ? (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Spacer height={hp(2)} />
                  <Receipt
                    style={{ marginLeft: 0 }}
                    admin={this.props.admin}
                    onRelease={() =>
                      this.setState({
                        receipt: false,
                      })
                    }
                    loading={this.props.loading}
                  />
                </div>
              ) : dataNext.length > 0 || dataDelivered.length > 0 ? (
                <div style={{}}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', width: 300 }}>
                      <InputBox
                        search={true}
                        placeholder="Buscar pedido"
                        value={search}
                        onChangeText={(str) => {
                          this.setState({
                            search: str,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <Spacer height={hp(4)} />
                  <TextLato style={textStyle}>Pedidos Próximos</TextLato>
                  <Spacer height={hp(3)} />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                      {dataNext.length == 0 ? (
                        <BoxButton>No tienes pedidos próximos</BoxButton>
                      ) : (
                        <List handleRow={this.handleRow} params={params} data={dataNext} />
                      )}
                    </div>
                    <Spacer width={hp(10)} />
                  </div>
                  <Spacer height={hp(3)} />
                  <TextLato style={textStyle}>Pedidos Anteriores</TextLato>
                  <Spacer height={hp(3)} />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                      {dataDelivered.length == 0 ? (
                        <BoxButton>No tienes pedidos entregados</BoxButton>
                      ) : (
                        <List handleRow={this.handleRow} params={params} data={dataDelivered} />
                      )}
                    </div>
                    <Spacer width={hp(10)} />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    paddingLeft: hp(10),
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={emptyState} height={hp(30)} />
                  <Spacer height={hp(2)} />
                  <TextLato
                    style={{
                      fontSize: textStyle.fontSize,
                      fontWeight: '300',
                      textAlign: 'center',
                    }}
                  >
                    No cuentas con pedidos aún...
                  </TextLato>
                  <Spacer height={hp(12)} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    companyID: state.companyID,
    companyInfo: state.companyInfo,
    currentReceipt: state.currentReceipt,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentReceipt: (value) => {
      dispatch(setCurrentReceipt(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
