import React, { useEffect, useImperativeHandle, useState } from 'react';
import List from '../../components/List';
import Pagination from 'react-js-pagination';
import TextLato from '../../components/TextLato';
import { queryUsers } from 'src/utils/services';
import { StyleSheet } from 'src/utils/styles';

interface Props {
  initialSearch: string;
  handleLoading: (val?: boolean) => void;
  onRedirect: (data: any) => void;
}

export const SearchUsers = React.forwardRef(
  ({ initialSearch, onRedirect, handleLoading }: Props, ref) => {
    const [query, setQuery] = useState<string>(initialSearch);
    const [count, setCount] = useState(0);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    const fetchUsers = async (query: string, newPage: number) => {
      try {
        handleLoading(true);
        const res = await queryUsers(query, newPage);
        if (res.errorCode === '200') {
          const { items, count: newCount } = res;
          setCount(newCount);
          setList(items);
          setPage(newPage);
        }
      } catch (error) {
        console.log(error);
      } finally {
        handleLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      search: (str: string) => {
        setQuery(str);
        fetchUsers(str, 1);
      },
    }));

    useEffect(() => {
      fetchUsers(query, 1);
    }, []);

    const onChangePage = (newPage: number) => {
      fetchUsers(query, newPage);
    };

    const renderSpacer = () => <div style={styles.spacer} />;

    const renderAction = (content: any, textStyle: any) => {
      const { data, actions } = content;

      const onClick = () => {
        actions.onRedirect(data);
      };

      return (
        <div onClick={onClick} style={styles.hyperLinkContainer}>
          <TextLato
            style={{ ...textStyle, ...styles.hyperLink, fontSize: textStyle.fontSize * 0.9 }}
          >
            {'Ver más >'}
          </TextLato>
        </div>
      );
    };

    return (
      <div>
        <List
          actions={{
            onRedirect,
          }}
          labelEmpty="No hay usuarios creados"
          topHeader
          header
          params={[
            {
              title: 'Name',
              key: 'fullName',
              width: '20%',
              alignment: 'flex-start',
            },
            {
              title: '',
              width: '2%',
              render: renderSpacer,
            },
            {
              title: 'Correo',
              key: 'email',
              width: '24%',
              alignment: 'flex-start',
            },
            {
              title: 'Última sesión',
              key: 'lastLogin',
              width: '18%',
              alignment: 'flex-start',
            },
            {
              title: 'Creado',
              key: 'created',
              width: '18%',
              alignment: 'flex-start',
            },
            {
              type: 'actions',
              title: '',
              render: renderAction,
            },
          ]}
          data={list}
        />
        <Pagination
          activePage={page}
          itemsCountPerPage={10}
          totalItemsCount={count}
          pageRangeDisplayed={5}
          onChange={onChangePage}
        />
      </div>
    );
  },
);

const styles = StyleSheet.create({
  spacer: {
    width: '2%',
  },
  hyperLinkContainer: {
    width: '18%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hyperLink: {
    color: '#2697FF',
  },
});
