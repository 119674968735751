import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadGoogleMapScript } from '../../utils/maps';
import Login from '../Login';
import SplashScreen from '../SplashScreen';
import Welcome from '../Welcome';
class App extends React.Component {
  componentDidMount() {
    loadGoogleMapScript(() => {});
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={SplashScreen} />
          <Route path="/login" component={Login} />
          <Route path="/inicio" component={Welcome} />
        </Switch>
      </Router>
    );
  }
}

export default App;
