import React from 'react';
import './index.css';
import { hp } from '../../utils/responsive';
import TextLato from '../TextLato';
class Button extends React.Component {
  render() {
    const { children, style, textStyle, onClick, second, disabled, backgroundColor } = this.props;
    const labelStyle = {
      color: 'white',
      textAlign: 'center',
      fontWeight: '400',
      fontSize: hp(1.9),
      ...textStyle,
    };
    return (
      <div
        className={
          disabled
            ? 'disabled-button'
            : backgroundColor
            ? ''
            : second
            ? 'button-gradient-two'
            : 'button-gradient'
        }
        onClick={() => {
          if (onClick && !disabled) {
            onClick();
          }
        }}
        style={{
          cursor: 'pointer',
          display: 'flex',
          backgroundColor: disabled ? '#bcbbc1' : backgroundColor,
          boxShadow: '0px 2px 5px rgba(0,0,0,0.35)',
          ...style,
        }}
      >
        <TextLato style={labelStyle}>{children}</TextLato>
      </div>
    );
  }
}
export default Button;
