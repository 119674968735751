import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import XLSX from 'xlsx';
import { firebase } from './firebase';
import { getUsersFirebase } from './services';

export const ReportActions = {
  SALES: (users, callback, ratings, stores) => {
    const obj = {
      headers: [
        'PayMe',
        'Codigo',
        'Tienda',
        'Usuario',
        'Email',
        'Fecha',
        'Pedido',
        'Fecha Entregado',
        'Entregado',
        'Productos',
        'SubTotal',
        'Descuento',
        'Total',
        'Rating',
        'Delivery',
        'Tarifa de Servicio',
        'Ahorro Total',
        'CO2',
        'Peso',
        'Total Productos',
      ],
      keys: [
        'orderNumber',
        'companyHref',
        'companyName',
        'userId',
        'userEmail',
        'date',
        'created',
        'dateDelivered',
        'delivered',
        'products',
        'total',
        'ponderatedDiscount',
        'final',
        'rating',
        'delivery',
        'serviceFee',
        'ahorroTotal',
        'co2',
        'pesoKg',
        'quantity',
      ],
      data: [],
    };
    const orders = [];
    firebase
      .firestore()
      .collection('orders')
      .where('date', '>=', new Date('2024-01-01'))
      .where('state', '==', 'SUCCESS')
      .orderBy('date', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id);
          var item = doc.data();
          if (item.state === 'FAILED' || item.state === 'PENDING') return;
          var final = parseFloat(
            (parseFloat(item.total) - parseFloat(item.ponderatedDiscount)).toFixed(2),
          );
          orders.push({
            orderNumber: item.canceled
              ? 'CANCELADO'
              : item.orderNumber != null
                ? item.orderNumber.indexOf('some') != -1
                  ? 'No Pasarela'
                  : item.orderNumber
                : 'No Pasarela',
            companyHref: item.companyHref,
            companyName: item.companyName || stores[item.companyId]?.name || item.companyId,
            userId: parseInt(item.userId),
            userEmail:
              item.userEmail /* users[`${item.userId}`] != null ? users[`${item.userId}`].email :  */,
            date: item.date.toDate() /* moment(date, 'DD/MM/YYYY').toDate() */,
            created: moment(item.date.toDate()).format('HH:mm'),
            dateDelivered:
              item.deliveredAt != null
                ? item.deliveredAt.toDate() /* moment(timeConverter(item.deliveredAt.seconds, 'date'), 'DD/MM/YYYY').toDate() */
                : '-',
            delivered:
              item.deliveredAt != null ? moment(item.deliveredAt.toDate()).format('HH:mm') : '-',
            products: item.products.map((item) => item.name + '(' + item.count + ')').toString(),
            total: item.total,
            ponderatedDiscount: parseFloat(
              (parseFloat(final < 0 ? final : 0) + parseFloat(item.ponderatedDiscount)).toFixed(2),
            ),
            final: final < 0 ? 0 : final,
            timestamp: moment(item.date.toDate()).unix(),
            rating:
              ratings[item.backendOrderId] != null
                ? ratings[item.backendOrderId] != 0
                  ? ratings[item.backendOrderId]
                  : '-'
                : '-',
            delivery: item.delivery ? item.deliveryAmount : 0,
            serviceFee: item.hasServiceFee ? 0.75 : 0,
            ahorroTotal: item.ahorroTotal || 0,
            co2: item.co2 || 0,
            pesoKg: item.pesoKg || 0,
            quantity: item.products
              .reduce((prev, item) => prev + parseInt(item.count), 0)
              .toString(),
          });
        });
        console.log(orders);
        // setTable(ordenes)Obj
        obj.data = orders
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((x) => {
            delete x.timestamp;
            return Object.values(x);
          });

        callback();
        console.log(obj);
        generateXLSX(obj, 'ventas');
      }).catch(e => console.log('error', e));
  },
  GROUPS: (data, initFromDate, initToDate, callback) => {
    try {
      let grouparray = Object.entries(data);
      var zip = new JSZip();
      for (const element of grouparray) {
        let [group, orders] = element;
        const shouldHasComision = group.includes('Dunkin');
        const obj = {
          headers: shouldHasComision
            ? [
              'Codigo',
              'Local',
              'Fecha',
              'Hora',
              'Fecha Entregado',
              'Hora Entregado',
              'Total',
              'Comision_Cirkula',
              'IGV',
              'A_Transferir',
              'Delivery',
              'Productos',
              'Rating',
            ]
            : [
              'Codigo',
              'Local',
              'Fecha',
              'Hora',
              'Fecha Entregado',
              'Hora Entregado',
              'Total',
              'Delivery',
              'Productos',
              'Rating',
            ],
          keys: shouldHasComision
            ? [
              'companyHref',
              'companyName',
              'date',
              'time',
              'dateDelivered',
              'timeDelivered',
              'final',
              'comision',
              'igv',
              'transfer',
              'delivery',
              'products',
              'rating',
            ]
            : [
              'companyHref',
              'companyName',
              'date',
              'time',
              'dateDelivered',
              'timeDelivered',
              'final',
              'delivery',
              'products',
              'rating',
            ],
          data: [],
        };

        obj.data = orders
          .sort((a, b) => b.timestamp - a.timestamp)
          .map(
            ({
              companyHref,
              companyName,
              date,
              time,
              dateDelivered,
              timeDelivered,
              final,
              delivery,
              products,
              rating,
            }) => {
              const comision = final * 0.15;
              const igv = comision * 0.18;
              return shouldHasComision
                ? [
                  companyHref,
                  companyName,
                  date,
                  time,
                  dateDelivered,
                  timeDelivered,
                  final,
                  comision,
                  igv,
                  final - comision - igv,
                  delivery,
                  products,
                  rating,
                ]
                : [
                  companyHref,
                  companyName,
                  date,
                  time,
                  dateDelivered,
                  timeDelivered,
                  final,
                  delivery,
                  products,
                  rating,
                ];
            },
          );
        // callback();
        //var store = zip.folder(group);
        var excel = zipExcel(obj, group);
        let title = group.split(' ').join('_');
        let init = initFromDate.split('/').join('_');
        let to = initToDate.split('/').join('_');
        zip.file(`${title}-${init}-${to}.xlsx`, excel, { base64: true });
        //zip.file(group + "_" + initFromDate + "-" + initToDate + ".xlsx", excel, { base64: true });
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'reportes-de-ventas-' + initFromDate + '-' + initToDate + '.zip');
      });
    } catch (e) {
      console.log('error=> ', e);
    }
  },
  USERS: async (usersDataTemp, callback) => {
    const obj = {
      headers: [
        'Id',
        'Nombre',
        'Email',
        'Fecha Creacion',
        'Phone',
        'Gender',
        'Fecha de Nacimiento',
        'Instalado',
      ],
      keys: ['id', 'fullName', 'email', 'created', 'phone', 'gender', 'birthday', 'active'],
      data: [],
    };
    const response = await getUsersFirebase();
    const usersData = response.items;
    var users = [];
    console.log(usersData[0]);
    for (var i = 0; i < usersData.length; i++) {
      const item = usersData[i];
      /* if (item.fullName != 'N/A' && item.fullName.toString().trim() != '') */
      users.push({
        id: parseInt(item.id),
        fullName: item.fullName,
        email: item.email,
        created: item.createdAt ? moment(item.createdAt).toDate() : '-',
        phone: item.phone
          ? item.phone.length > 4
            ? item.phone.substr(4).split(' ').join('')
            : '-'
          : '-',
        gender: item.gender,
        birthday: item.birthdate ? moment(item.birthdate).toDate() : '-',
        active: item.active == 1 ? 'SI' : item.active == 0 ? 'NO' : '-',
      });
    }
    obj.data = users.map((x) => Object.values(x));
    console.log(obj.data);

    callback();
    generateXLSX(obj, 'users', 'DD/MM/YY');
  },
  USERSFIREBASE: async (usersDataTemp, callback) => {
    const obj = {
      headers: [
        'Id',
        'Nombre',
        'Correo',
        'Ultimo Login',
        'Fecha Creacion',
        'Genero',
        'Telefono',
        'Fecha de Nacimiento',
        'Instalado',
      ],
      keys: [
        'id',
        'fullName',
        'email',
        'lastLogin',
        'created',
        'gender',
        'phone',
        'birthday',
        'active',
      ],
      data: [],
    };
    const response = await getUsersFirebase();

    const usersData = response.items;
    console.log(usersData);
    var users = [];
    for (var i = 0; i < usersData.length; i++) {
      const item = usersData[i];
      /* if (item.fullName != 'N/A' && item.fullName.toString().trim() != '') */
      users.push({
        id: parseInt(item.id),
        fullName: item.fullName,
        email: item.email,
        lastLogin: item.lastLogin ? new Date(item.lastLogin) : '-',
        created: item.created ? new Date(item.created) : '-',
        gender: item.gender,
        phone: item.phone
          ? (item.phone.length > 4 ? item.phone.substr(4) : '-').split(' ').join('')
          : '-',
        birthday: item.birthdate ? new Date(item.birthdate) : '-',
        active: item.active == 1 ? 'SI' : item.active == 0 ? 'NO' : '-',
      });
    }
    console.log(users);
    obj.data = users.map((x) => Object.values(x));
    callback();
    generateXLSX(obj, 'users', 'DD/MM/YY');
  },
  CART: (users, stores, callback) => {
    const obj = {
      headers: ['Usuario', 'Email', 'Tienda', 'Producto', 'Fecha'],
      keys: ['user', 'email', 'store', 'productName', 'created'],
      data: [],
    };
    var carts = [];
    firebase
      .firestore()
      .collection('cart')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var item = doc.data();
          var date = item.createdAt;
          if (stores[item.companyId])
            carts.push({
              user: parseInt(item.userId),
              email: '-' /* users[item.userId].email */,
              store: stores[item.companyId].name || item.companyId,
              productName: item.productName,
              created: date.toDate(),
              timestamp: moment(date).unix(),
            });
        });
        obj.data = carts
          .map((x) => ({
            user: x.user,
            email: '-',
            store: x.store,
            productName: x.productName,
            created: x.created,
            timestamp: x.timestamp,
          }))
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((x) => {
            var newx = x;
            delete x.timestamp;
            return Object.values(x);
          });

        callback();
        console.log(obj);
        generateXLSX(obj, 'carrito', 'DD/MM/YY - HH:mm');
      });
  },
  PRODUCTSBYSTORE: (companyID, callback) => {
    const obj = {
      headers: [
        'Nombre del Producto',
        'Descripción del Producto',
        'Precio original',
        'Precio descontado',
        'Peso',
        'Image',
        'ID',
      ],
      keys: ['name', 'description', 'price', 'discount', 'peso', 'image', 'id'],
      data: [],
    };
    var products = [];
    firebase
      .firestore()
      .collection('products')
      .where('companyId', '==', companyID.trim())
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var item = doc.data();
          products.push({
            name: item.name,
            description: item.description,
            price: parseFloat(item.price),
            discount: parseFloat(item.discounted),
            peso: item.peso ? parseFloat(item.peso).toFixed(2) : '0.00',
            image: item.image,
            id: doc.id,
          });
        });
        obj.data = products.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'products');
      });
  },
  FAVORITES: (callback) => {
    const obj = {
      headers: ['Id', 'Name', 'Email', 'Store'],
      keys: ['id', 'name', 'email', 'store'],
      data: [],
    };
    fetch('https://api.maagalapp.com/stores/store-fav')
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var favoritesList = [];
        for (var i = 0; i < response.items.length; i++) {
          const item = response.items[i];

          favoritesList.push({
            id: item.userId ? parseInt(item.userId) : '-',
            name: item.name,
            email: item.email,
            store: item.store,
          });
        }
        obj.data = favoritesList.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'favoritos');
      });
  },
  PUBLICADOS: (callback) => {
    const obj = {
      headers: ['Fecha', 'Local', 'Publicado', 'Valor Publicado', 'Vendido', 'Valor Vendido'],
      keys: ['date', 'name', 'published', 'publishedValue', 'sold', 'soldValue'],
      data: [],
    };
    fetch('https://api.maagalapp.com/reports/sales-report')
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var reportList = [];
        for (var i = 0; i < response.items.length; i++) {
          const item = response.items[i];
          if (parseInt(item.quantityPublished) > 0)
            reportList.push({
              date: moment(item.date).toDate(),
              name: item.storeName,
              published: item.quantityPublished,
              publishedValue: item.valuePublished,
              sold: item.quantitySold,
              soldValue: item.valueSold,
            });
        }
        obj.data = reportList.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'publicados', 'DD/MM/YYYY');
      });
  },
  REFERIDOS: (callback) => {
    const obj = {
      headers: [
        'ID Usuario',
        'ID Referido',
        'Email Usuarios',
        'Email Referido',
        'Codigo Referido',
        'Usuario Compro?',
        'Referido reclamo codigo?',
      ],
      keys: [
        'userId',
        'redeemUserId',
        'userEmail',
        'redeemEmail',
        'redeemCode',
        'isUsed',
        'isReclaimed',
      ],
      data: [],
    };
    fetch('https://api.maagalapp.com/promos/report-redeem')
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var reportList = [];
        for (var i = 0; i < response.items.length; i++) {
          const item = response.items[i];
          reportList.push({
            userId: item.userId,
            redeemUserId: item.redeemUserId,
            userEmail: item.userEmail,
            redeemEmail: item.redeemEmail,
            redeemCode: item.redeemCode,
            isUsed: item.isUsed ? 'Si' : 'No',
            isReclaimed: item.isReclaimed ? 'Si' : 'No',
          });
        }
        obj.data = reportList.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'referidos');
      });
  },
  SUSPENDED: (callback) => {
    const obj = {
      headers: ['Id', 'Name', 'Email'],
      keys: ['id', 'fullName', 'email'],
      data: [],
    };
    fetch('https://api.maagalapp.com/reports/suspended-users')
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var suspendedUsers = [];
        for (var i = 0; i < response.items.length; i++) {
          const item = response.items[i];

          suspendedUsers.push({
            id: item.id ? parseInt(item.id) : '-',
            name: item.fullName || '-',
            email: item.email || '-',
          });
        }
        obj.data = suspendedUsers.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'suspendidos');
      });
  },
  DELETED: (callback) => {
    const obj = {
      headers: ['Id', 'Name', 'Email'],
      keys: ['id', 'fullName', 'email'],
      data: [],
    };
    fetch('https://api.maagalapp.com/reports/deleted-users')
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var deletedUsers = [];
        for (var i = 0; i < response.items.length; i++) {
          const item = response.items[i];

          deletedUsers.push({
            id: item.id ? parseInt(item.id) : '-',
            name: item.fullName || '-',
            email: item.email || '-',
          });
        }
        obj.data = deletedUsers.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'cuentas-borradas');
      });
  },
  STOREIDS: (callback) => {
    const obj = {
      headers: ['Id', 'Name'],
      keys: ['id', 'name'],
      data: [],
    };
    fetch('https://api.maagalapp.com/reports/store-ids')
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        obj.data = response.items.map((x) => Object.values(x));
        callback();
        generateXLSX(obj, 'store-ids');
      });
  },
  ONSALE: async (callback, stores) => {
    const obj = {
      headers: ['Local', 'Producto', 'Cantidad Publicada', 'Cantidad Vendida', 'Precio Dscto'],
      keys: ['companyName', 'name', 'total', 'count', 'discounted'],
      data: [],
    };
    const products = [];
    const querySnapshot = await firebase.firestore().collection('sales').get();
    const currentMinuteTime = getMinutesInUTC_5();
    querySnapshot.forEach((doc) => {
      const { companyId, name, count, total, discounted } = doc.data();
      if (stores[companyId]) {
        const { name: storeName, buySince, buyLimit } = stores[companyId];
        if (buySince < currentMinuteTime && buyLimit > currentMinuteTime) {
          const companyName = storeName || companyId;
          products.push([companyName, name, total, count, discounted]);
        }
      }
    });

    obj.data = products;

    callback();
    generateXLSX(obj, 'productos');
  },
};

function generateXLSX(obj, name, format = 'DD/MM/YYYY') {
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: 'SheetJS Tutorial',
    Subject: 'Test',
    Author: 'Red Stapler',
    CreatedDate: new Date(2017, 12, 19),
  };

  wb.SheetNames.push('Hoja 1');
  var ws_data = [obj.headers, ...obj.data];

  var ws = XLSX.utils.aoa_to_sheet(ws_data, { dateNF: format });
  wb.Sheets['Hoja 1'] = ws;
  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'binary',
  });
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  saveAs(
    new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    }),
    `${name}.xlsx`,
  );
}
function zipExcel(obj, name, format = 'DD/MM/YYYY') {
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: 'SheetJS Tutorial',
    Subject: 'Test',
    Author: 'Red Stapler',
    CreatedDate: new Date(2017, 12, 19),
  };

  wb.SheetNames.push('Hoja 1');
  var ws_data = [obj.headers, ...obj.data];

  var ws = XLSX.utils.aoa_to_sheet(ws_data, { dateNF: format });
  wb.Sheets['Hoja 1'] = ws;
  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'base64',
  });

  return wbout;
}
function generateXLSXMultiTabs(objMap, name) {
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: 'SheetJS Tutorial',
    Subject: 'Test',
    Author: 'Red Stapler',
    CreatedDate: new Date(2017, 12, 19),
  };
  var entries = Object.entries(objMap);
  console.log(entries);
  for (var i = 0; i < entries.length; i++) {
    var obj = entries[i][1];
    wb.SheetNames.push(entries[i][0]);
    var ws_data = [obj.headers, ...obj.data];

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[entries[i][0]] = ws;
  }

  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'binary',
  });
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  saveAs(
    new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    }),
    `${name}.xlsx`,
  );
}
export const eventNames = {
  Login: {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId'],
  },
  Registro: {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId'],
  },
  'Click en Plato': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurante', 'Producto'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'store', 'product'],
  },
  'Click en Agregar al Carrito': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurante', 'Producto'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'store', 'product'],
  },
  'Agregar tarjeta': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId'],
  },
  'Compra exitosa': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurantes', 'Productos'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'restaurantes', 'platos'],
  },
  'Agregar codigo': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Codigo'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'codigo'],
  },
  'Click en Agregar a Favoritos': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurante'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'store'],
  },
  'Click en Comprar': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurantes', 'Productos'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'restaurantes', 'platos'],
  },
  'Click en Comprar (Pasarela)': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurantes', 'Productos'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'restaurantes', 'platos'],
  },
  'Click en Restaurante': {
    headers: ['Mail', 'Fecha', 'Pais', 'Celular', 'Usuario ID', 'Restaurante'],
    keys: ['userEmail', 'fecha', 'mp_country_code', 'mp_lib', 'userId', 'store'],
  },
};

const getMinutesInUTC_5 = () => {
  const currentTime = moment().utcOffset('-05:00');
  const currentHour = currentTime.hours();
  const currentMinutes = currentTime.minutes();
  return currentMinutes + currentHour * 60;
};
