import React from 'react';
import { connect } from 'react-redux';
import infoIcon from '../../assets/images/error-icon.png';
import RedAdd from '../../assets/images/red-add.png';
import trashIcon from '../../assets/images/trash.png';
import BoxSelection from '../../components/BoxSelection';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CheckButton from '../../components/CheckButton';
import FormProfile from '../../components/FormProfile';
import InputBox from '../../components/InputBox';
import InputBoxSelector from '../../components/InputBoxSelector';
import Modal from '../../components/Modal';
import SliderMulti from '../../components/SliderMulti';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import Toggler from '../../components/Toggler';
import { setCompanyInfo } from '../../store/actions';
import { hp, wp } from '../../utils/responsive';
import {
  changeStoreDelivery,
  changeStoreStatus,
  changeStoreType,
  createCategories,
  deleteNewStoreSchedule,
  getCategories,
  getCompany,
  getHolidays,
  updateCategories,
  updateCategoriesv2,
  updateNewStoreSchedule,
  updateStoreSchedule,
} from '../../utils/services';
import HolidaysModal from './components/HolidaysModal';
import './index.css';
const options = [
  { key: 12, name: 'Martes a Domingo', position: 4 }, //4
  { key: 11, name: 'Todos los días', position: 12 }, //12
  { key: 10, name: 'Lunes a Viernes', position: 1 }, //1
  { key: 9, name: 'Lunes a Sábado', position: 2 }, //2
  { key: 8, name: 'Sábado y Domingo', position: 9 }, //9
  { key: 1, name: 'Lunes', position: 3 }, //3
  { key: 2, name: 'Martes', position: 5 }, //5
  { key: 3, name: 'Miércoles', position: 6 }, //6
  { key: 4, name: 'Jueves', position: 7 }, //7
  { key: 5, name: 'Viernes', position: 8 }, //8
  { key: 6, name: 'Sábado', position: 10 }, //10
  { key: 7, name: 'Domingo', position: 11 }, //11
];

const optionsKeys = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
  8: 'Sábado y Domingo',
  9: 'Lunes a Sábado',
  10: 'Lunes a Viernes',
  11: 'Todos los días',
  12: 'Martes a Domingo',
};

const distritos = [
  { name: 'Ate' },
  { name: 'Barranco' },
  { name: 'Breña' },
  { name: 'Callao' },
  { name: 'Callao (Bellavista)' },
  { name: 'Callao (Carmen de la Legua)' },
  { name: 'Callao (La Punta)' },
  { name: 'Callao (La Perla)' },
  { name: 'Callao (Ventanilla)' },
  { name: 'Cercado de Lima' },
  { name: 'Chorrillos' },
  { name: 'Comas' },
  { name: 'El Agustino' },
  { name: 'Independencia' },
  { name: 'Jesús María' },
  { name: 'La Molina' },
  { name: 'La Victoria' },
  { name: 'Lince' },
  { name: 'Los Olivos' },
  { name: 'Magdalena del Mar' },
  { name: 'Miraflores' },
  { name: 'Pueblo Libre' },
  { name: 'Rímac' },
  { name: 'San Borja' },
  { name: 'San Isidro' },
  { name: 'San Juan de Lurigancho' },
  { name: 'San Juan de Miraflores' },
  { name: 'San Luis' },
  { name: 'San Martín de Porres' },
  { name: 'San Miguel' },
  { name: 'Santa Anita' },
  { name: 'Santiago de Surco' },
  { name: 'Surquillo' },
  { name: 'Villa El Salvador' },
  { name: 'Villa María del Triunfo' },
];
class Settings extends React.Component {
  constructor(props) {
    super(props);
    const { companyInfo } = props;
    console.log('companyInfo => ', companyInfo);
    this.state = {
      categoryInputValue: null,
      pickup: companyInfo.type == 'pickup' || companyInfo.type == 'complete',
      delivery: companyInfo.type == 'delivery' || companyInfo.type == 'complete',
      developer: companyInfo.status == 'dev',
      categories: {
        italiana:
          companyInfo.categories == null ? false : companyInfo.categories.indexOf('italiana') != -1,
        polleria:
          companyInfo.categories == null ? false : companyInfo.categories.indexOf('polleria') != -1,
        pasteleria:
          companyInfo.categories == null
            ? false
            : companyInfo.categories.indexOf('pasteleria') != -1,
        marino:
          companyInfo.categories == null ? false : companyInfo.categories.indexOf('marino') != -1,
        productos:
          companyInfo.categories == null
            ? false
            : companyInfo.categories.indexOf('productos') != -1,
        vegano:
          companyInfo.categories == null ? false : companyInfo.categories.indexOf('vegano') != -1,
      },
      formDelivery: {
        coverage: companyInfo.coverage,
        minOrder: companyInfo.minOrder,
        deliveryAmount: companyInfo.deliveryAmount,
        deliveryConditions: companyInfo.deliveryConditions,
      },
      formStore: {
        name: companyInfo.fullName,
        email: companyInfo.email,
        phone: companyInfo.phone,
        address: companyInfo.address,
        pesoProm: companyInfo.pesoProm || 0,
      },
      errorText: '',
      schedules: [
        {
          type: 11,
          label: 'Todos los dias',
          buySince: '07:30',
          buyLimit: '19:00',
          buySince: '19:30',
        },
        {
          type: 11,
          label: 'Todos los dias',
          buySince: '07:30',
          buyLimit: '19:00',
          buySince: '19:30',
        },
      ],
      allCoveredError: false,
      cateList: [],
      cateArray: [],
      listDis: null,
      holidays: [],
      showModalHoliDay: false,
      startDate: new Date(),
    };
  }
  componentDidMount() {
    this.loadDist();
    window.addEventListener('resize', this.resize);
    this.loadCategories();
    this.loadHolidays();
  }

  loadHolidays = async () => {
    let holidays = await getHolidays();
    if (holidays.errorCode == '200') {
      this.setState({ holidays: holidays.items });
    }
  };

  loadDist = async () => {
    const {
      companyInfo: { coverage },
    } = this.props;
    //console.log('coverage: ', coverage);
    const distMap = coverage.split(', ').reduce((prev, value) => {
      return { ...prev, [value]: true };
    }, {});
    //console.log('distMap: ', distMap);
    this.setState({
      listDis: distritos.map((item) => {
        return { ...item, value: distMap[item.name] || false };
      }),
    });
  };

  loadCategories = async () => {
    const { companyInfo } = this.props;
    //console.log(companyInfo);
    var keyCurrentCategories = companyInfo.categories
      ? companyInfo.categories.reduce((prev, val) => ({ ...prev, [val]: true }), {})
      : {};
    var res = await getCategories();
    //console.log(JSON.stringify(res.items));
    this.setState({
      cateList: res.items.reduce((prev, val) => {
        return { ...prev, [val.name]: val };
      }, {}),
      cateArray: res.items
        .map((x) => ({
          id: x.id,
          name: x.name,
          value: keyCurrentCategories[x.name] != null ? keyCurrentCategories[x.name] : false,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    });
  };
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  renderOption = (title, icon, onClick) => {
    const textStyle = {
      fontSize: hp(2.4),
      fontWeight: '400',
    };
    return (
      <div
        onClick={onClick != null ? onClick : () => {}}
        style={{
          cursor: onClick != null ? 'pointer' : 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {icon && <img src={icon} style={{ height: hp(3) }} />}
        {icon && <Spacer width={hp(3)} />}
        <TextLato style={textStyle}>{title}</TextLato>
      </div>
    );
  };
  renderSeparator = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: hp(7) }}>
        <div
          style={{
            display: 'flex',
            height: 1.5,
            width: '100%',
            opacity: 0.7,
            backgroundColor: '#C8C7CC',
          }}
        />
      </div>
    );
  };
  callStoreMode = () => {
    const { delivery, pickup } = this.state;
    const { companyInfo } = this.props;
    var mode = '';

    if (delivery && pickup) {
      mode = 'complete';
    } else if (delivery) {
      mode = 'delivery';
    } else if (pickup) {
      mode = 'pickup';
    } else {
      mode = 'pickup';
      this.setState({ pickup: true });
      this.pickupToggle.toggle(true);
    }
    changeStoreType({
      storeId: companyInfo.id,
      type: mode,
    });
    //alert(mode)
  };
  callStoreStatus = () => {
    const { developer } = this.state;
    const { companyInfo, setCompanyInfo } = this.props;
    var mode = '';

    if (developer) {
      mode = 'dev';
    } else {
      mode = 'live';
    }
    changeStoreStatus({
      storeId: companyInfo.id,
      status: mode,
    });
    var newData = { ...companyInfo, status: mode };
    setCompanyInfo(newData);
    //alert(mode)
  };
  updateSchedule = async (type, time) => {
    const { companyInfo, setCompanyInfo } = this.props;

    console.log({
      storeId: companyInfo.id,
      newSchedule: time,
      scheduleType: type,
    });
    const updateResult = await updateStoreSchedule({
      storeId: companyInfo.id,
      newSchedule: time,
      scheduleType: type,
    });

    if (updateResult.errorCode === '200') {
      const params = ['startBuy', 'finishBuy', 'pickupLimit'];
      var newData = { ...companyInfo, [params[type - 1]]: time };
      setCompanyInfo(newData);
      this.setState({ errorText: '' });
      return '';
    }
    this.setState({ errorText: updateResult.errorMessage });
    return updateResult.errorMessage;
  };
  changeStore = (formStore) => {
    this.setState({ formStore });
  };
  updateCategoriesV2 = async (id) => {
    const { cateArray } = this.state;
    await updateCategoriesv2({
      storeId: id,
      categoryIds: cateArray.filter((x) => x.value).map((x) => x.id),
    });
  };
  update = async () => {
    const { companyInfo, loading, setCompanyInfo, admin } = this.props;
    const { formDelivery, formStore, cateArray } = this.state;
    let dist = '';
    if (formDelivery.listDis) {
      formDelivery.listDis
        .filter((x) => x.value && x.value)
        .map((x) => {
          dist += '' + x.name + ', ';
        });
    } else {
      this.state.listDis
        .filter((x) => x.value && x.value)
        .map((x) => {
          dist += '' + x.name + ', ';
        });
    }

    let distfinal = dist.substr(0, dist.length - 2);
    const pesoFinal = parseFloat(formStore.pesoProm);

    loading(true);
    await changeStoreDelivery({
      storeId: companyInfo.id,
      storeName: formStore.name,
      email: formStore.email,
      phoneNumber: formStore.phone,
      address: formStore.address,
      pesoProm: pesoFinal === 0 ? companyInfo.pesoProm : pesoFinal,
      //coverage: formDelivery.coverage,
      coverage: distfinal,
      deliveryConditions: formDelivery.deliveryConditions,
      minOrder: formDelivery.minOrder,
      deliveryAmount: formDelivery.deliveryAmount,
    });
    if (admin) await this.updateCategoriesV2(companyInfo.id);

    const response = await getCompany(companyInfo.id);
    setCompanyInfo(response);
    loading(false);
    alert('Los datos han sido actualizados');
  };
  createCategory = async (name) => {
    const { companyInfo, loading } = this.props;
    loading(true);
    var res = await createCategories({ name });
    loading(false);
    var keyCurrentCategories = companyInfo.categories.reduce(
      (prev, val) => ({ ...prev, [val]: true }),
      {},
    );
    this.setState({
      categoryInputValue: null,
      cateList: res.items.reduce((prev, val) => {
        return { ...prev, [val.name]: val };
      }, {}),
      cateArray: res.items.map((x) => ({
        id: x.id,
        name: x.name,
        value: keyCurrentCategories[x.name] != null ? keyCurrentCategories[x.name] : false,
      })),
    });
  };
  changeCategory = async (id) => {
    const { companyInfo } = this.props;
    var res = await updateCategories({
      storeId: companyInfo.id,
      categoryId: id,
    });
  };
  renderCategory = (data) => {
    const { name, index, title } = data;
    const textStyle = {
      fontSize: wp(1.2),
      fontWeight: '400',
    };
    const { categories } = this.state;
    return (
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          flex: 10,
          marginBottom: hp(1),
        }}
      >
        <CheckButton
          //ref={(x) => (this.pickupToggle = x)}
          initial={categories[`${name}`]}
          handle={(val) => {
            this.setState({
              categories: { ...categories, [`${name}`]: val },
            });
            this.changeCategory(index);
          }}
          size={hp(3)}
        />
        <Spacer width={hp(2)} />
        <TextLato style={textStyle}>{title}</TextLato>
      </div>
    );
  };
  handleShedule = async (val, x, index, key) => {
    console.log(val, x, index, key);
    if (this[`${key}${index}`] != null) clearTimeout(this[`${key}${index}`]);

    const { companyInfo, setCompanyInfo, admin } = this.props;
    const storeSchedules = companyInfo.storeSchedules;

    if (x.label == null || x.id == null) return;
    var prevSchedule = JSON.stringify(storeSchedules);
    var newSchedule = storeSchedules;
    newSchedule[index] = { ...storeSchedules[index], [key]: val };

    setCompanyInfo({ ...companyInfo, storeSchedules: newSchedule });

    this[`${key}${index}`] = setTimeout(async () => {
      var res = await updateNewStoreSchedule({
        id: x.id,
        [key]: val,
        admin,
      });
      if (res.errorCode == '400') {
        var correctSchedule = JSON.parse(prevSchedule);
        correctSchedule[index] = {
          ...correctSchedule[index],
          buySince: res.buySince,
          buyLimit: res.buyLimit,
          pickupLimit: res.pickupLimit,
        };
        setCompanyInfo({ ...companyInfo, storeSchedules: correctSchedule });
        this.setState({ errorText: res.errorMessage });
      } else {
        this.setState({ errorText: '' });
      }
    }, 500);
  };
  renderSche = (x, index, list) => {
    const { companyInfo, setCompanyInfo, admin } = this.props;

    const storeSchedules = companyInfo.storeSchedules;
    const finalWidth = '91%';
    const rowStyle = {
      fontSize: wp(0.5) + hp(0.6) < 13 ? 13 : wp(0.5) + hp(0.6),
      fontWeight: '400',
    };

    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {index == 0 && (
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ display: 'flex', flex: 2 }} />
            <Spacer width={hp(2)} />
            <div
              style={{
                paddingLeft: hp(3),
                paddingRight: hp(3),
                display: 'flex',
                flex: 6,
                justifyContent: 'space-around',
              }}
            >
              <TextLato style={rowStyle}>Comprar desde</TextLato>
              <TextLato style={rowStyle}>Comprar hasta</TextLato>
              <TextLato style={rowStyle}>Recoger hasta</TextLato>
            </div>
          </div>
        )}
        <Spacer height={hp(1.5)} />
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ display: 'flex', flex: 2 }}>
            <BoxSelection
              selected={x.label || 'Elegir dia(s)'}
              wrapper={{ width: '100%', paddingBottom: 0 }}
              style={{
                marginBottom: 0,
                paddingBottom: hp(1.7),
                paddingTop: hp(1.7),
                width: '100%',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              handleRow={async (a) => {
                console.log(a);
                if (!this.canAddCoverage(x.id, a.key)) {
                  this.setState({
                    allCoveredError: true,
                  });
                  return;
                }
                var newSchedule = storeSchedules;
                newSchedule[index] = {
                  ...storeSchedules[index],
                  type: a.key,
                  /*position: a.position,*/ label: a.name,
                };
                setCompanyInfo({ ...companyInfo, storeSchedules: newSchedule });
                if (x.id != null)
                  updateNewStoreSchedule({
                    id: x.id,
                    type: a.key,
                  });
                else {
                  this.props.loading();
                  await updateNewStoreSchedule({
                    storeId: companyInfo.id,
                    type: a.key,
                  });
                  const response = await getCompany(companyInfo.id);
                  setCompanyInfo(response);
                  this.props.loading(false);
                }
              }}
              items={options}
            />
          </div>
          <Spacer width={hp(2)} />
          <div style={{ display: 'flex', flex: 6 }}>
            <Card
              flex={1}
              style={{
                flex: 1,
                borderRadius: hp(1.2),
                paddingBottom: hp(0.2),
                paddingTop: hp(0.2),
              }}
              paddingHorizontal={hp(3)}
              wrapper={{
                flexDirection: 'row',
                display: 'flex',

                justifyContent: 'space-around',
              }}
            >
              <SliderMulti
                handle={(val) => this.handleShedule(val, x, index, 'buySince')}
                value={x.buySince}
              />
              <SliderMulti
                handle={(val) => this.handleShedule(val, x, index, 'buyLimit')}
                value={x.buyLimit}
              />
              <SliderMulti
                disabled={!this.state.pickup}
                handle={(val) => this.handleShedule(val, x, index, 'pickupLimit')}
                value={x.pickupLimit}
              />
            </Card>
          </div>
        </div>
        {list.filter((x) => x.id != null).length > 1 && (
          <div
            onClick={() => {
              var newSchedule = storeSchedules.filter((x, jndex) => jndex != index);
              if (x.id != null) deleteNewStoreSchedule(x.id);
              setCompanyInfo({ ...companyInfo, storeSchedules: newSchedule });
            }}
            style={{ cursor: 'pointer', position: 'absolute', right: -hp(4), top: hp(2.5) }}
          >
            {index == 0 && <Spacer height={hp(2.5)} />}
            <img src={trashIcon} alt={index} style={{ width: hp(2), height: hp(2) / 0.79 }} />
          </div>
        )}
      </div>
    );
  };
  getDaysCoveredByType = (type) => {
    switch (type) {
      case 1:
        return ['monday'];
      case 2:
        return ['tuesday'];
      case 3:
        return ['wednesday'];
      case 4:
        return ['thursday'];
      case 5:
        return ['friday'];
      case 6:
        return ['saturday'];
      case 7:
        return ['sunday'];
      case 8:
        return ['saturday', 'sunday'];
      case 9:
        return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      case 10:
        return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
      case 11:
        return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      case 12:
        return ['tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      default:
        return [];
    }
  };
  canAddCoverage = (id, type) => {
    const { companyInfo } = this.props;
    var covered = this.getDaysCovered(id);
    var toCover = this.getDaysCoveredByType(type);
    var canAdd = true;
    for (var i = 0; i < toCover.length; i++) {
      if (covered[toCover[i]]) {
        canAdd = false;
        break;
      }
    }
    return canAdd;
  };
  getDaysCovered = (id) => {
    const { companyInfo } = this.props;
    var schedules =
      id != null
        ? companyInfo.storeSchedules.filter((x) => x.id != id)
        : companyInfo.storeSchedules;
    var covered = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    for (var i = 0; i < schedules.length; i++) {
      const sch = schedules[i];
      var cov = this.getDaysCoveredByType(sch.type);
      for (var j = 0; j < cov.length; j++) {
        covered[cov[j]] = true;
      }
    }
    //alert(JSON.stringify(covered))
    return covered;
  };
  createNewSchedule = () => {
    const { companyInfo, setCompanyInfo } = this.props;
    if (Object.values(this.getDaysCovered()).includes(false)) {
      var prevStoreSchedules = companyInfo.storeSchedules;
      prevStoreSchedules.push({
        buySince: '07:30',
        buyLimit: '19:00',
        pickupLimit: '19:30',
      });
      setCompanyInfo({ ...companyInfo, storeSchedules: prevStoreSchedules });
    } else {
      this.setState({
        allCoveredError: true,
      });
    }
  };
  renderSchedules = () => {
    const finalWidth = '91%';
    const { companyInfo } = this.props;
    const { errorText } = this.state;
    const schedules = companyInfo.storeSchedules.map((x) => ({
      ...x,
      label: optionsKeys[x.type],
      // order: x.position
    }));
    return (
      <div style={{ width: finalWidth, display: 'flex', flexDirection: 'column' }}>
        {schedules.map(this.renderSche)}
        <Spacer height={hp(1)} />
        {errorText != '' ? (
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ display: 'flex', flex: 2 }} />
            <Spacer width={hp(2)} />
            <div
              style={{
                paddingLeft: hp(3),
                paddingRight: hp(3),
                display: 'flex',
                flex: 6,
                justifyContent: 'space-around',
              }}
            >
              <TextLato
                style={{
                  color: '#D87F7A',
                  fontWeight: '500',
                  fontSize: wp(0.5) + hp(0.6) < 12 ? 12 : wp(0.5) + hp(0.6),
                }}
              >
                {errorText}
              </TextLato>
            </div>
          </div>
        ) : (
          <Spacer height={hp(1)} />
        )}
        <Spacer height={hp(1)} />
        <div style={{ cursor: 'pointer' }} onClick={this.createNewSchedule}>
          <TextLato
            style={{
              color: '#02A9AB',
              fontWeight: '500',
              fontSize: wp(0.5) + hp(0.6) < 15 ? 15 : wp(0.5) + hp(0.6),
            }}
          >
            + Agregar otro horario
          </TextLato>
        </div>
        <Spacer height={hp(3)} />
      </div>
    );
  };
  renderAllCoveredError = () => {
    const { allCoveredError } = this.state;
    if (allCoveredError) {
      const textStyle = {
        fontSize: wp(0.9) + hp(1),
        fontWeight: '300',
        textAlign: 'center',
      };
      return (
        <Modal>
          <div
            style={{
              backgroundColor: 'white',
              width: wp(90),
              maxWidth: 360,
              borderRadius: hp(1),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '70%',
              }}
            >
              <Spacer height={hp(4)} />
              <img src={infoIcon} style={{ width: hp(9), height: hp(9) }} />
              <Spacer height={hp(2)} />
              <TextLato style={textStyle}>
                ¡Todos los días ya cuentan con un horario establecido o ya se ha establecido un
                horario para el día seleccionado!
              </TextLato>
              <Spacer height={hp(5)} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '42%',
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({ allCoveredError: false });
                  }}
                  backgroundColor={'#D87F7A'}
                  second={true}
                  textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                  style={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    paddingTop: hp(1.6),
                    paddingBottom: hp(1.6),
                    paddingLeft: wp(2),
                    paddingRight: wp(2),
                    borderRadius: hp(1.2),
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Volver
                </Button>
              </div>
            </div>
            <Spacer height={hp(5)} />
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  };
  openModalCategory = () => {
    this.setState({ categoryInputValue: '' });
  };
  renderModalCategory = () => {
    const { categoryInputValue } = this.state;
    return categoryInputValue == null ? null : (
      <Modal>
        <div
          style={{
            backgroundColor: 'white',
            width: wp(90),
            maxWidth: 370,
            paddingRight: hp(6),
            paddingLeft: hp(6),
            borderRadius: hp(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Spacer height={hp(5)} />
          <TextLato style={{ fontWeight: '400', fontSize: hp(2.5), color: '#392E2E' }}>
            Crear categoría
          </TextLato>
          <Spacer height={hp(5)} />

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <InputBox
              style={{
                width: '80%',
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
              }}
              placeholder="Nombre de la categoría"
              value={categoryInputValue}
              levelShadow={10}
              onChangeText={(str) => {
                this.setState({
                  categoryInputValue: str,
                });
              }}
            />
            <Spacer height={hp(5)} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '45%' }}>
                <Button
                  backgroundColor="#ffffff"
                  onClick={() => {
                    this.setState({
                      categoryInputValue: null,
                    });
                  }}
                  textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: hp(1),
                    paddingTop: hp(1.5),
                    paddingBottom: hp(1.5),
                  }}
                >
                  Volver
                </Button>
              </div>
              <div style={{ width: '45%' }}>
                <Button
                  backgroundColor={categoryInputValue == '' ? '#BDBDBD' : '#D87F7A'}
                  onClick={() => {
                    this.createCategory(categoryInputValue);
                  }}
                  textStyle={{ fontSize: hp(2.3) }}
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: hp(1),
                    paddingTop: hp(1.5),
                    paddingBottom: hp(1.5),
                  }}
                >
                  Crear
                </Button>
              </div>
            </div>
          </div>

          <Spacer height={hp(5)} />
        </div>
      </Modal>
    );
  };
  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const textSmall = {
      fontSize: wp(1) + hp(0.5) < 14 ? 14 : wp(1) + hp(0.5),
      fontWeight: '400',
    };
    const { navigate, companyInfo, admin, loading } = this.props;
    const {
      pickup,
      delivery,
      developer,
      categories,
      cateArray,
      cateList,
      showModalHoliDay,
    } = this.state;
    const finalWidth = '91%';
    const finalParcWidth = admin ? '80%' : '70%';

    var categoriesMap = [
      { name: 'italiana', index: 6, title: 'Italiana' },
      { name: 'polleria', index: 1, title: 'Polleria' },
      { name: 'pasteleria', index: 2, title: 'Pastelerias' },
      { name: 'marino', index: 3, title: 'Marino' },
      { name: 'productos', index: 4, title: 'Productos' },
      { name: 'vegano', index: 5, title: 'Vegano' },
    ];

    return (
      <div
        className="scrollnone"
        style={{
          overflowY: 'auto',
          width: '100%',
          minWidth: 800,
          height: '100%',
          justifyContent: 'stretch',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                paddingLeft: hp(10),
                paddingTop: hp(5),
                flex: 1,
              }}
            >
              <div
                style={{
                  width: finalWidth,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <TextLato style={textStyle}>Datos</TextLato>
                <Button
                  backgroundColor={'#D87F7A'}
                  onClick={this.update}
                  textStyle={{ fontSize: hp(2.3) }}
                  style={{
                    borderRadius: hp(1),
                    paddingTop: hp(1.5),
                    paddingBottom: hp(1.5),
                    paddingLeft: hp(5),
                    paddingRight: hp(5),
                  }}
                >
                  Guardar
                </Button>
              </div>
              <Spacer height={hp(2)} />

              <div style={{ width: finalWidth }}>
                <FormProfile
                  reduced={true}
                  admin={admin}
                  onChange={this.changeStore}
                  initialData={{
                    name: companyInfo.fullName,
                    email: companyInfo.email,
                    phone: companyInfo.phone,
                    address: companyInfo.address,
                    pesoProm: companyInfo.pesoProm,
                  }}
                />
              </div>
              <Spacer height={hp(3)} />
              <div style={{ width: finalWidth }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextLato style={textStyle}>Horarios</TextLato>
                  <Spacer width={hp(4.5)} />
                  {admin && <TextLato style={textStyle}>Recojo en tienda</TextLato>}
                  <Spacer width={hp(2)} />
                  {admin && (
                    <Toggler
                      style={{ marginTop: hp(0.5) }}
                      ref={(x) => (this.pickupToggle = x)}
                      initial={pickup}
                      handle={(val) =>
                        this.setState(
                          {
                            pickup: val,
                          },
                          this.callStoreMode,
                        )
                      }
                      size={hp(3)}
                    />
                  )}
                  <Spacer width={hp(4.5)} />
                  <HolidaysModal admin={admin} companyInfo={companyInfo} />
                </div>
              </div>
              <Spacer height={hp(3)} />

              {this.renderSchedules()}
              <Spacer height={hp(3)} />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {(this.state.delivery || admin) && <TextLato style={textStyle}>Delivery</TextLato>}
                <Spacer width={hp(2)} />
                {admin && (
                  <Toggler
                    style={{ marginTop: hp(0.5) }}
                    ref={(x) => (this.deliveryToggle = x)}
                    initial={delivery}
                    handle={(val) =>
                      this.setState(
                        {
                          delivery: val,
                        },
                        this.callStoreMode,
                      )
                    }
                    size={hp(3)}
                  />
                )}
              </div>
              <Spacer height={hp(3)} />
              {admin && (
                <div style={{ width: finalWidth }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextLato style={textStyle}>Categorias</TextLato>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <TextLato style={textStyle}>Developer</TextLato>
                      <Spacer width={hp(2)} />
                      <Toggler
                        style={{ marginTop: hp(0.5) }}
                        //ref={(x) => (this.pickupToggle = x)}
                        initial={developer}
                        handle={(val) =>
                          this.setState(
                            {
                              developer: val,
                            },
                            this.callStoreStatus,
                          )
                        }
                        size={hp(3)}
                      />
                      <Spacer width={hp(5)} />
                    </div>
                  </div>
                  <Spacer height={hp(2)} />
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
                      <InputBoxSelector
                        items={cateArray.map((x, index) => ({ ...x, index })) || []}
                        renderName={(k) => cateList[k].description}
                        style={{
                          paddingTop: hp(1.5),
                          paddingBottom: hp(1.5),
                        }}
                        showAll={false}
                        levelShadow={20}
                        onHandle={(val) => {
                          console.log(val);
                          this.setState({
                            cateArray: val,
                          });
                        }}
                      />
                      <Spacer height={hp(20)} />
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', flex: 0.5 }}></div>
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                        flex: 0.7,
                      }}
                    >
                      <div style={{ cursor: 'pointer' }} onClick={this.openModalCategory}>
                        <img src={RedAdd} style={{ width: hp(4.3), height: hp(4.3) }} />
                      </div>
                      <Spacer width={hp(1.5)} />
                      <TextLato style={{ ...textSmall, marginBottom: 5 }}>
                        Agregar categoría
                      </TextLato>
                    </div>
                  </div>
                </div>
              )}
              <Spacer height={hp(4)} />
            </div>
          </div>

          {this.renderAllCoveredError()}
          {this.renderModalCategory()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.companyID,
    companyInfo: state.companyInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
