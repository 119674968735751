import React from 'react';
import cropImage from '../../assets/images/crop.png';
import PIN from '../../assets/images/pin.png';
import Toggler from '../../components/Toggler';
import { getPositionByAddress } from '../../utils/maps';
import { hp, wp } from '../../utils/responsive';
import { getComercials } from '../../utils/services';
import BoxImage from '../BoxImage';
import BoxSearchBusiness from '../BoxSearchBusiness';
import Button from '../Button';
import CheckButton from '../CheckButton';
import CropImage from '../CropImage';
import Input from '../Input';
import Modal from '../Modal';
import Spacer from '../Spacer';
import TextLato from '../TextLato';

import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
const { compose, withProps, withHandlers } = require('recompose');
const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');
class FormBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.initialData != null ? props.initialData.name : '',
      email: props.initialData != null ? props.initialData.email : '',
      phone: props.initialData != null ? props.initialData.phone : '',
      address: props.initialData != null ? props.initialData.address : '',
      prefix: props.initialData != null ? props.initialData.prefix : '',
      initial: props.initialData != null ? props.initialData : null,

      image: props.initialData != null ? props.initialData.image : null,
      imageUrl: props.initialData != null ? props.initialData.image : null,
      imageFile: props.initialData != null ? props.initialData.image : null,
      banner: props.initialData != null ? props.initialData.banner : null,
      bannerUrl: props.initialData != null ? props.initialData.banner : null,
      bannerFile: props.initialData != null ? props.initialData.banner : null,
      imageEdit: false,
      imageUpdated: false,
      bannerEdit: false,
      bannerUpdated: false,
      size: null,
      crop: null,
      banneSize: null,
      cropBanner: null,
      mapOpen: false,
      storeLocation: null,
      finalLocation: null,
      emailNotificationEnabled:
        props.initialData != null ? props.initialData.emailNotificationEnabled : null,
      smsNotificationEnabled:
        props.initialData != null ? props.initialData.smsNotificationEnabled : null,
      emailNotification: props.initialData != null ? props.initialData.emailNotification : null,
      smsNotification: props.initialData != null ? props.initialData.smsNotification : null,
      comercialId: props.initialData != null ? props.initialData.comercialId : null,
      hasRestaurant: props.initialData != null ? props.initialData.hasRestaurant : true,
      comercialList: [],
    };
  }
  componentDidMount() {
    this.loadComercials();
    const { imageFile } = this.state;
    if (typeof imageFile == 'string') {
      this.toDataURL(imageFile).then((dataUrl) => {
        this.setState({ imageUrl: dataUrl, imageFile: dataUrl });
      });
    } else {
    }

    const { bannerFile } = this.state;

    if (typeof bannerFile == 'string') {
      this.toDataURL(bannerFile).then((dataUrl) => {
        //alert(dataUrl)
        this.setState({ bannerUrl: dataUrl, bannerFile: dataUrl });
      });
    } else {
    }
  }

  loadComercials = async () => {
    const { loading } = this.props;
    loading(true);
    const comercials = await getComercials();
    this.setState({ comercialList: comercials.items });
    loading(false);
  };

  onChangeText = (value, key) => {
    const { onChange } = this.props;

    this.setState(
      {
        [key]: value,
      },
      () => {
        if (onChange) {
          onChange({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            prefix: this.state.prefix,
            emailNotificationEnabled: this.state.emailNotificationEnabled,
            smsNotificationEnabled: this.state.smsNotificationEnabled,
            emailNotification: this.state.emailNotification,
            smsNotification: this.state.smsNotification,
            comercialId: this.state.comercialId,
            hasRestaurant: this.state.hasRestaurant,
            image: this.state.image,
          });
        }
      },
    );
  };
  renderShadow = () => {
    return (
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          flex: 1,

          position: 'absolute',
          top: hp(0.6),
          left: hp(0.6),
          right: hp(0.6),
          bottom: hp(0.6),
          zIndex: 2,
          alignItems: 'center',
          borderRadius: hp(1.2),
          boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
        }}
      ></div>
    );
  };
  initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current);
    new window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      let place = autocomplete.getPlace();
      setPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    });
  };
  renderInput = (title, name, map) => {
    const labelStyle = {
      fontSize: hp(1.7),
      opacity: 0.7,
    };
    const inputStyle = {
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    const fieldStyle = {
      fontSize: hp(1.8),
      fontFamily: 'Lato',
      fontWeight: '400',
    };
    const { admin, placeholders, loading, onPinpointChange } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <TextLato style={labelStyle}>{title}</TextLato>
        <Spacer height={hp(0.8)} />
        <Input
          disabled={!admin}
          value={this.state[name]}
          fieldStyle={fieldStyle}
          onChangeText={this.onChangeText}
          name={name}
          bottomSpc={hp(0.6)}
          style={inputStyle}
          iconRight={
            map
              ? () => {
                  return (
                    <div
                      style={{ cursor: 'pointer', position: 'absolute', right: 0, bottom: -3 }}
                      onClick={async () => {
                        loading(true);
                        var response = await getPositionByAddress(this.state[name]);
                        if (response != null) {
                          this.setState({
                            storeLocation: response,
                            finalLocation: response,
                            mapOpen: true,
                          });
                          onPinpointChange(response);
                        } else {
                          this.setState(
                            {
                              mapOpen: true,
                            },
                            () =>
                              alert(
                                'No se pudo encontrar el pinpoint, seleccione en el mapa el local',
                              ),
                          );
                        }
                        loading(false);
                      }}
                    >
                      {/* 
                      <GPlace /> */}
                      <img src={PIN} style={{ width: hp(3), height: hp(3) }} />
                    </div>
                  );
                }
              : null
          }
          placeholder={placeholders[name]}
        />
      </div>
    );
  };
  renderRow = (items) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {items.map((x) => {
          return x();
        })}
      </div>
    );
  };
  onSuccessImage = (file) => {
    const { onChange } = this.props;
    this.setState({ image: file, imageUrl: file, imageFile: file }, () => {
      if (onChange) {
        onChange({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          address: this.state.address,
          prefix: this.state.prefix,
          image: this.state.image,
        });
      }
    });
  };
  onSuccessBanner = (file) => {
    const { onChange } = this.props;
    this.setState({ banner: file, bannerUrl: file, bannerFile: file }, () => {
      if (onChange) {
        onChange({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          address: this.state.address,
          prefix: this.state.prefix,
          image: this.state.image,
          banner: this.state.banner,
        });
      }
    });
  };
  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      )
      .catch((e) => {});

  notificationConfiguration = () => {
    const labelStyle = {
      fontSize: hp(1.7),
      opacity: 0.7,
    };
    const fieldStyle = {
      fontSize: hp(1.8),
      fontFamily: 'Lato',
      fontWeight: '400',
    };
    const inputStyle = {
      paddingLeft: hp(2.2),
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <TextLato style={labelStyle}>Noficación</TextLato>
          <Spacer height={hp(1.5)} />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <TextLato
              style={{
                fontWeight: '300',
                fontSize: hp(1.9),
                color: '#000000',
                width: '15%',
                textAlign: 'left',
              }}
            >
              Correo
            </TextLato>
            <Toggler
              initial={this.state.emailNotificationEnabled}
              handle={() =>
                this.onChangeText(!this.state.emailNotificationEnabled, 'emailNotificationEnabled')
              }
              size={wp(1) + hp(1)}
            />
            <Input
              value={this.state['emailNotification']}
              disabled={!this.state.emailNotificationEnabled}
              fieldStyle={fieldStyle}
              bottomSpc={hp(0.6)}
              style={inputStyle}
              placeholder="Ingrese un correo"
              onChangeText={this.onChangeText}
              name="emailNotification"
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Spacer height={hp(1.5)} />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <TextLato
              style={{
                fontWeight: '300',
                fontSize: hp(1.9),
                color: '#000000',
                width: '15%',
                textAlign: 'left',
              }}
            >
              Celular
            </TextLato>
            <Toggler
              initial={this.state.smsNotificationEnabled}
              handle={() => () =>
                this.onChangeText(!this.state.smsNotificationEnabled, 'smsNotificationEnabled')}
              size={wp(1) + hp(1)}
            />
            <Input
              value={this.state['smsNotification']}
              disabled={!this.state.smsNotificationEnabled}
              fieldStyle={fieldStyle}
              bottomSpc={hp(0.6)}
              style={inputStyle}
              placeholder="Ingrese número de teléfono"
              onChangeText={this.onChangeText}
              name="smsNotification"
            />
          </div>
        </div>
      </div>
    );
  };

  isLocale = () => {
    const { hasRestaurant } = this.state;
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            alignItems: 'center',
            marginBottom: hp(2),
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <CheckButton
            initial={hasRestaurant}
            handle={(val) =>
              this.setState({ hasRestaurant: val }, () => {
                !val && this.setState({ comercialId: 0 });
              })
            }
            size={hp(3)}
          />
          <Spacer width={hp(2)} />
          <TextLato
            style={{
              fontWeight: '300',
              fontSize: hp(2),
              color: '#000000',
              width: '100%',
              textAlign: 'left',
            }}
          >
            Mi local es propio
          </TextLato>
        </div>
      </div>
    );
  };

  searchBusiness = () => {
    const { comercialList } = this.state;
    const { onChange } = this.props;
    return (
      <BoxSearchBusiness
        handleRow={(row) => {
          this.setState({ comercialId: row.id }, () => {
            if (onChange) {
              onChange({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                address: this.state.address,
                prefix: this.state.prefix,
                emailNotificationEnabled: this.state.emailNotificationEnabled,
                smsNotificationEnabled: this.state.smsNotificationEnabled,
                emailNotification: this.state.emailNotification,
                smsNotification: this.state.smsNotification,
                comercialId: row.id,
                hasRestaurant: this.state.hasRestaurant,
                image: this.state.image,
              });
            }
          });
        }}
        comerciales={comercialList}
      />
    );
  };

  renderImageBox = () => {
    const { imageFile, crop } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextLato
          style={{
            fontSize: hp(1.8),
            opacity: 0.7,
          }}
        >
          Imagen
        </TextLato>
        <Spacer height={hp(1.2)} />
        <div style={{ display: 'flex' }}>
          <BoxImage
            onDisplay={(x) => this.setState({ size: x })}
            large
            dimensions={crop != null ? { w: crop.width, h: crop.height } : null}
            onSuccess={this.onSuccessImage}
            upload={true}
            url={imageFile}
            size={wp(1) + hp(7)}
          />
          <div
            onClick={() => {
              this.setState({ imageEdit: true });
            }}
            style={{ marginLeft: hp(2), cursor: 'pointer' }}
          >
            <img src={cropImage} style={{ width: hp(3), height: hp(3) }} />
          </div>
        </div>
      </div>
    );
  };
  renderBannerBox = () => {
    const { bannerFile, crop } = this.state;
    return (
      <div style={{ display: 'flex', marginTop: wp(1), flexDirection: 'column' }}>
        <TextLato
          style={{
            fontSize: hp(1.8),
            opacity: 0.7,
          }}
        >
          Banner
        </TextLato>
        <Spacer height={hp(1.2)} />
        <div style={{ display: 'flex' }}>
          <BoxImage
            onDisplay={(x) => this.setState({ bannerSize: x })}
            large
            banner
            dimensions={crop != null ? { w: crop.width, h: crop.height } : null}
            onSuccess={this.onSuccessBanner}
            upload={true}
            url={bannerFile}
            size={wp(1) + hp(7)}
          />
          <div
            onClick={() => {
              this.setState({ bannerEdit: true });
            }}
            style={{ marginLeft: hp(2), cursor: 'pointer' }}
          >
            <img src={cropImage} style={{ width: hp(3), height: hp(3) }} />
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { style, onPinpointChange } = this.props;
    const {
      imageEdit,
      imageUrl,
      bannerUrl,
      bannerEdit,
      size,
      bannerSize,
      crop,
      cropBanner,
      mapOpen,
    } = this.state;

    const textStyle = {
      fontSize: wp(0.9) + hp(1),
      fontWeight: '300',
      textAlign: 'center',
    };
    return (
      <div>
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {this.renderShadow()}
          <div
            style={{
              display: 'flex',
              position: 'relative',
              zIndex: 3,
              width: '100%',
              flex: 1,
              borderRadius: hp(1.2),
              paddingTop: hp(3),
              paddingBottom: hp(3),
              ...style,
            }}
          >
            <Spacer width={hp(3)} />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              {this.renderRow([
                () => this.renderInput('Nombre del establecimiento', 'name'),
                () => <Spacer width={hp(2)} />,
                () => this.renderInput('E-mail de contacto', 'email'),
              ])}
              <Spacer height={hp(0.8)} />
              {this.renderRow([
                () => this.renderInput('Teléfono', 'phone'),
                () => <Spacer width={hp(2)} />,
                () => this.renderInput('Prefijo Boleta', 'prefix'),
              ])}
              <Spacer height={hp(0.8)} />
              {this.renderRow([() => this.renderInput('Dirección', 'address', true)])}
              <Spacer height={hp(1.8)} />
              {this.notificationConfiguration()}
              <Spacer height={hp(1.8)} />
              {this.isLocale()}
              {!this.state.hasRestaurant && <Spacer height={hp(1.8)} />}
              {!this.state.hasRestaurant && this.searchBusiness()}
              <Spacer height={hp(1.8)} />
              {this.renderImageBox()}
              {this.renderBannerBox()}
            </div>

            <Spacer width={hp(3)} />
          </div>
        </div>
        {imageEdit && (
          <CropImage
            onOk={(data) => {
              const { onChange } = this.props;
              this.setState(
                { image: data.image, imageFile: data.image, imageEdit: false, crop: data.crop },
                () => {
                  if (onChange) {
                    onChange({
                      name: this.state.name,
                      email: this.state.email,
                      phone: this.state.phone,
                      address: this.state.address,
                      prefix: this.state.prefix,
                      image: this.state.image,
                      banner: this.state.banner,
                    });
                  }
                },
              );
            }}
            crop={crop}
            onBack={() => this.setState({ imageEdit: false })}
            initialSize={size}
            src={typeof imageUrl == 'string' ? imageUrl : URL.createObjectURL(imageUrl)}
          />
        )}
        {bannerEdit && (
          <CropImage
            banner
            onOk={(data) => {
              const { onChange } = this.props;
              this.setState(
                {
                  banner: data.image,
                  bannerFile: data.image,
                  bannerEdit: false,
                  cropBanner: data.crop,
                },
                () => {
                  if (onChange) {
                    onChange({
                      name: this.state.name,
                      email: this.state.email,
                      phone: this.state.phone,
                      address: this.state.address,
                      prefix: this.state.prefix,
                      image: this.state.image,
                      banner: this.state.banner,
                    });
                  }
                },
              );
            }}
            crop={cropBanner}
            onBack={() => this.setState({ bannerEdit: false })}
            initialSize={bannerSize}
            src={typeof bannerUrl == 'string' ? bannerUrl : URL.createObjectURL(bannerUrl)}
          />
        )}
        {mapOpen && (
          <Modal>
            <div
              style={{
                backgroundColor: 'white',
                width: wp(90),
                maxWidth: 500,
                borderRadius: hp(1),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '70%',
                }}
              >
                <Spacer height={hp(5)} />
                <MapComponent
                  position={this.state.storeLocation}
                  changePosition={(data) => this.setState({ storeLocation: data })}
                  googleMapURL={
                    'https://maps.googleapis.com/maps/api/js?key=AIzaSyC4-3hI5y0bbO6io2GFUCquByrY7hVwNdI&v=3.exp&libraries=geometry,drawing,places'
                  }
                  loadingElement={<div style={{ height: 500, width: 400 }} />}
                  containerElement={<div style={{ height: 400, width: 400 }} />}
                  mapElement={<div style={{ height: 400, width: 400 }} />}
                />
                <Spacer height={hp(5)} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '40%',
                  }}
                >
                  <Button
                    onClick={() => {
                      this.setState({ finalLocation: this.state.storeLocation, mapOpen: false });
                      onPinpointChange(this.state.storeLocation);
                    }}
                    second={true}
                    textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      paddingTop: hp(1.6),
                      paddingBottom: hp(1.6),
                      paddingLeft: wp(2),
                      paddingRight: wp(2),
                      borderRadius: hp(1.2),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    backgroundColor={'#D87F7A'}
                  >
                    Aceptar
                  </Button>
                  <Spacer height={hp(2)} />

                  <Button
                    onClick={() => this.setState({ mapOpen: false })}
                    second={true}
                    textStyle={{ fontSize: wp(0.8) + hp(0.5), color: '#392E2E' }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      paddingTop: hp(1.6),
                      paddingBottom: hp(1.6),
                      paddingLeft: wp(2),
                      paddingRight: wp(2),
                      borderRadius: hp(1.2),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    backgroundColor={'#ffffff'}
                  >
                    Volver
                  </Button>
                </div>
              </div>
              <Spacer height={hp(5)} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const MapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      onClick={(data) => {
        var dat = data.latLng;
        var newPosition = { lat: dat.lat(), lng: dat.lng() };
        props.changePosition(newPosition);
      }}
      defaultZoom={14}
      defaultCenter={props.position || { lat: -12.092100922655309, lng: -77.04601871962937 }}
    >
      {props.position != null ? <Marker position={props.position} /> : null}
    </GoogleMap>
  )),
);

export default FormBusiness;
