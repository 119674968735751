import CKEditor from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react'
import { useTermsStorage } from 'src/hooks/useTermsStorage';
import { LoadingSection } from './LoadingSection';

const TermsUserSection = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [content, setContent] = useState(null)
  const { document, updateFile } = useTermsStorage('user')

  useEffect(() => {
    setContent(document)
  }, [document])

  const undoChanges = () => {
    setContent(null)
    setIsLoading(true)
    setTimeout(() => {
      setContent(document)
      setIsLoading(false)
    }, 1000);
  }

  const saveChanges = async () => {
    setIsLoading(true)
    updateFile(content, () => { setIsLoading(false) })
  }

  return (
    <div style={{ padding: '3rem', width: '100%' }}>
      <div className='flex-row justify-between mb-20'>
        <h3 style={{ fontSize: '3.25rem' }}>Terminos y Condiciones de Usuarios</h3>
        <div className='grid-center'>
          <div className='flex-row gap-10'>
            <button onClick={undoChanges} type="button" class="btn btn-default btn-lg">Deshacer</button>
            <button onClick={saveChanges} type="button" className="btn btn-primary btn-lg">Guardar</button>
          </div>
        </div>
      </div>
      <div style={{ overflowX: 'hidden' }}>
        {
          content != null ?
            <CKEditor
              editor={ClassicEditor}
              onInit={(editor) => {
                editor.setData(content);
                setIsLoading(false)
                console.log('Editor is ready to use!', editor);
              }}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'fontColor',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'indent',
                  'outdent',
                  '|',
                  'imageUpload',
                  'blockQuote',
                  'insertTable',
                  'mediaEmbed',
                  'undo',
                  'redo',
                  'underline',
                  'fontSize',
                  'alignment',
                ],
                language: 'es',
                image: {
                  toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
                },
                table: {
                  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                },
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    {
                      model: 'heading1',
                      view: 'h1',
                      title: 'Heading 1',
                      class: 'ck-heading_heading1',
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2',
                    },
                  ],
                },
              }}
              onChange={(_, editor) => {
                setContent(editor.getData())
              }}
            />
            :
            null
        }
        <LoadingSection show={isLoading} />
      </div>
    </div>
  )
}

export default TermsUserSection