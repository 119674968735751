import React from 'react';

import { hp, wp } from '../../utils/responsive';
import arrow from '../../assets/images/arrow.png';
import TextLato from '../TextLato';
import Spacer from '../Spacer';
class BoxSelection extends React.Component {
  constructor(props) {
    super(props);
    this.listBox = React.createRef();
    this.state = {
      open: false,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.listBox != null)
      if (this.listBox && !this.listBox.current.contains(event.target)) {
        this.setState({ open: false });
      }
  };
  render() {
    const { style, items, handleRow, wrapper = {}, selected } = this.props;
    const { open } = this.state;
    return (
      <div
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: hp(2),
          marginBottom: hp(2),
          display: 'flex',
          overflowY: 'visible',
          backgroundColor: 'white',
          //paddingBottom: hp(2),
          position: 'relative',
          borderRadius: hp(1.2),
          boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
          ...style,
        }}
      >
        <div
          onClick={() => this.setState({ open: true })}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: hp(2),
            ...wrapper,
          }}
        >
          <div style={{ width: hp(2) }} />

          <TextLato
            style={{
              fontSize: wp(0.7) + hp(1),
            }}
          >
            {selected || 'Elegir dia(s)'}
          </TextLato>
          <div style={{ flex: 1 }} />
          <img src={arrow} style={{ height: hp(1.8), opacity: 0.6 }} />
          <div style={{ width: hp(2) }} />
        </div>
        {open && (
          <div
            style={{
              opacity: 0.7,
              position: 'absolute',
              top: wp(0.7) + hp(4.3) - 1,
              height: 1,
              backgroundColor: '#C4C4C4',
              width: '100%',
            }}
          />
        )}
        <div
          ref={this.listBox}
          style={{
            boxShadow: '0px 3px 20px rgba(0,0,0,0.1)',
            backgroundColor: 'white',
            borderRadius: `0px 0px ${hp(1.2)}px ${hp(1.2)}px`,
            zIndex: 5,
            left: 0,
            right: 0,
            width: '100%',
            display: 'flex',
            top: wp(0.7) + hp(4.3),
            flexDirection: 'row',
            position: 'absolute',
          }}
        >
          <Spacer width={hp(2)} />
          <div
            className="scrollbar"
            style={{
              transition: '0.3s height',
              display: 'flex',
              flex: 1,
              marginTop: open ? hp(1) : 0,
              marginBottom: open ? hp(1) : 0,
              height: open ? hp(16) : 0,
              overflowY: 'auto',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {items.map((item) => {
              return (
                <div
                  onClick={() => {
                    handleRow(item);
                    this.setState({ open: false });
                  }}
                  style={{
                    cursor: 'pointer',
                    paddingTop: hp(1.4),
                    paddingBottom: hp(1.4),
                  }}
                >
                  <TextLato style={{ fontSize: wp(0.7) + hp(1) }}>{item.name}</TextLato>
                </div>
              );
            })}
          </div>
          <Spacer width={hp(2)} />
        </div>
      </div>
    );
  }
}
export default BoxSelection;
