import React from 'react';
import { hp, wp } from '../../utils/responsive';
import { notifyUsers } from '../../utils/services';
import './index.css';
import TextLato from '../../components/TextLato';
import Spacer from '../../components/Spacer';
import phoneIcon from '../../assets/images/phone-icon.png';
import mailIcon from '../../assets/images/mail-icon.png';
import whatsapp_contact from '../../assets/images/whatsapp_contact.png';
import { connect } from 'react-redux';
import List from '../../components/List';
import BoxButton from '../../components/BoxButton';
import FormSales from '../../components/FormSales';
import Card from '../../components/Card';
class Support extends React.Component {
  state = {
    products: [],
    sales: [],
    formVisible: false,
    selectVisible: false,
    currentProduct: null,
    notif: true,
    file: null,
    importVisible: false,
    dragging: false,
    dragged: false,
    importResult: null,
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }
  prevents = (e) => e.preventDefault();
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const { navigate, admin } = this.props;
    const { products, sales, formVisible, selectVisible, formDetails, importVisible } = this.state;
    return (
      <div
        className="scrollnone"
        style={{
          overflowY: 'auto',
          display: 'flex',
          width: '100%',
          minWidth: 800,
          height: this.props.admin ? hp(92.5) : hp(100),
          flexDirection: 'row',
          justifyContent: 'stretch',
        }}
      >
        <div
          style={{
            paddingLeft: hp(10),
            paddingTop: hp(5),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextLato style={textStyle}>Soporte</TextLato>
          <Spacer height={hp(3)} />

          <Spacer height={hp(3)} />
          <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.8 }}>
            Comunícate directamente
          </TextLato>
          <div
            style={{
              flexDirection: 'column',
              width: '40%',
            }}
          >
            <Spacer height={hp(1)} />
            <div style={{ backgroundColor: '#BCBBC1', height: 1 }} />
            <Spacer height={hp(3)} />
            <Card paddingHorizontal={wp(1)}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Spacer height={hp(1)} />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: hp(5),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={whatsapp_contact} style={{ width: hp(3) }} />
                    </div>
                    <Spacer width={hp(1)} />
                    <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.8 }}>
                      <a href="http://api.whatsapp.com/send?phone=51965729028">+51 965 729 028</a>
                    </TextLato>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: hp(5),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={mailIcon} style={{ width: hp(3) }} />
                  </div>
                  <Spacer width={hp(1)} />
                  <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.8 }}>
                    contacto@cirkulaapp.com
                  </TextLato>
                </div>
                <Spacer height={hp(1)} />
              </div>
            </Card>
          </div>
          <Spacer height={hp(2)} />
          <div
            style={{
              flexDirection: 'column',
              width: '70%',
            }}
          >
            <Card paddingHorizontal={wp(2)}>
              <TextLato
                style={{ ...textStyle, fontWeight: '500', fontSize: textStyle.fontSize * 0.7 }}
              >
                ¿Qué hago sin un usuario me pregunta como comunicarse con soporte?
              </TextLato>
              <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.7 }}>
                {
                  'En caso de que el usuario necesite ponerse en contacto con Cirkula le pueden indicar que puede hacerlo a través de la app en Perfil > Soporte. La información de soporte mencionada en la página es exclusiva para negocios.'
                }
              </TextLato>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.currentCompantID,
    companyInfo: state.companyInfo,
  };
};

export default connect(mapStateToProps, null)(Support);
