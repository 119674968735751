import React from "react";
import { hp, wp } from "../../utils/responsive";
import "./index.css";
import TextLato from "../../components/TextLato";
import { firebase, signOut } from "../../utils/firebase";
import { connect } from "react-redux";
import { timeConverter } from "../../utils/functions";
import BoxImage from "../../components/BoxImage";
import Spacer from "../../components/Spacer";
import Card from "../../components/Card";
import logout from "../../assets/images/logout.png";
import plate from "../../assets/images/plate.png";
import contact from "../../assets/images/contact.png";
import settings from "../../assets/images/settings.png";
import { setCompanyID } from "../../store/actions";
class Profile extends React.Component {
  state = {};
  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }
  resize = () => { if (wp(100) > 700) this.forceUpdate(); }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  renderOption = (title, icon, onClick) => {
    const textStyle = {
      fontSize: wp(1.3),
      fontWeight: "400",
    };
    return (
      <div
        onClick={onClick != null ? onClick : () => { }}
        style={{
          cursor: onClick != null ? "pointer" : "auto",
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {icon && <img src={icon} style={{ height: hp(3) }} />}
        {icon && <Spacer width={hp(3)} />}
        <TextLato style={textStyle}>{title}</TextLato>
      </div>
    );
  };
  renderSeparator = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: hp(7),
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            height: 1.5,
            width: "100%",
            opacity: 0.7,
            backgroundColor: "#C8C7CC",
          }}
        />
      </div>
    );
  };
  render() {
    const textStyle = {
      fontSize: wp(1.5),
      fontWeight: "500",
    };
    const { navigate, companyInfo } = this.props;
    return (
      <div
        className="scrollnone"
        style={{
          overflowY: "auto",
          width: "100%",
          height: hp(81.5),
          justifyContent: "stretch",
        }}
      >
        <div
          style={{
            flexDirection: "column",
            paddingLeft: hp(5),
            paddingTop: hp(3),
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <BoxImage url={companyInfo.image} size={hp(8)} />
            <Spacer width={hp(2.5)} />
            <TextLato style={textStyle}>{companyInfo.fullName}</TextLato>
          </div>
          <Spacer height={hp(4)} />
          <div style={{ width: "30%" }}>
            <Card paddingHorizontal={hp(5)}>
              <Spacer height={hp(2)} />
              {this.renderOption("Configuraciones", settings, () => {
                navigate(3, 1);
              })}
              {this.renderSeparator()}
              {this.renderOption("Productos", plate, () => {
                navigate(3, 2);
              })}
              {this.renderSeparator()}
              {this.renderOption("Contacto", contact)}
              {this.renderSeparator()}
              {this.renderOption("Cerrar Sesión", logout, () => {
                this.props.setCompanyID("");
                signOut();
              })}
              <Spacer height={hp(2)} />
            </Card>
          </div>
          {/* <TextLato style={textStyle}>{
                        JSON.stringify(companyInfo)
                    }</TextLato> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.companyID,
    companyInfo: state.companyInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCompanyID: (data) => {
      dispatch(setCompanyID(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
