import React, { PureComponent } from 'react';
import { wp, hp } from '../../utils/responsive';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Spacer from '../Spacer';
import Button from '../Button';
class CropImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      crop:
        props.crop != null
          ? props.crop
          : {
              unit: '%',
              x: 0,
              y: 0,
              height: 100,
              width: 100,
            },
    };
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
    this.imageRef.crossOrigin = '*';
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    return canvas.toDataURL('image/jpeg');
  }

  render() {
    const { crop, croppedImageUrl } = this.state;
    const { src, onBack, onOk, banner } = this.props;
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.3)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          zIndex: 10,
          top: 0,
          left: 0,
          width: wp(100),
          height: hp(100),
        }}
      >
        <div
          style={{
            position: 'relative',
            top: -hp(5),
            borderRadius: hp(1),
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: hp(3),
          }}
        >
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            imageStyle={{ height: hp(40) }}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
          {banner ? (
            <div style={{ display: 'flex', overflow: 'hidden', padding: hp(2) }}>
              {croppedImageUrl && (
                <div
                  style={{
                    width: hp(10) * 3.65,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: hp(10),
                    overflow: 'hidden',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                  }}
                >
                  <img
                    alt="Crop"
                    style={{
                      objectFit: 'cover',
                      ...(crop.width / crop.height > 3.65 ? { height: '100%' } : { width: '100%' }),
                    }}
                    src={croppedImageUrl}
                  />
                </div>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', overflow: 'hidden', padding: hp(2) }}>
              {croppedImageUrl && (
                <div
                  style={{
                    width: hp(10) * 1.6,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: hp(10),
                    overflow: 'hidden',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                  }}
                >
                  <img
                    alt="Crop"
                    style={{
                      objectFit: 'cover',
                      ...(crop.width / crop.height > 1.6 ? { height: '100%' } : { width: '100%' }),
                    }}
                    src={croppedImageUrl}
                  />
                </div>
              )}
              <Spacer width={wp(5)} />
              {croppedImageUrl && (
                <div
                  style={{
                    height: hp(10),
                    width: hp(10),
                    borderRadius: hp(5),
                    overflow: 'hidden',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                  }}
                >
                  <img
                    alt="Crop"
                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                    src={croppedImageUrl}
                  />
                </div>
              )}
            </div>
          )}
          <Spacer height={hp(2)} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '45%' }}>
              <Button
                backgroundColor="#ffffff"
                onClick={onBack}
                textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: hp(1),
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                }}
              >
                Volver
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <Button
                backgroundColor={'#D87F7A'}
                onClick={() => {
                  onOk({ image: croppedImageUrl, crop });
                }}
                textStyle={{ fontSize: hp(2.3) }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: hp(1),
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                }}
              >
                Aceptar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CropImage;
