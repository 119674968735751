import React from 'react';
import './index.css';
import { hp, wp } from '../../utils/responsive';
import searchIcon from '../../assets/images/search-icon.png';
import Spacer from '../Spacer';
class InputAreaBox extends React.Component {
  render() {
    const {
      onChangeText,
      pass,
      value,
      placeholder,
      search,
      levelShadow = 30,
      style = {},
      disabled,
    } = this.props;
    return (
      <div
        className="field"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          paddingTop: hp(2.5),
          paddingBottom: hp(2.5),
          borderRadius: hp(1.2),
          boxShadow: `2px 0px ${levelShadow}px rgba(0,0,0,0.2)`,
          ...style,
        }}
      >
        {search && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Spacer width={hp(2.4)} />
            <img src={searchIcon} style={{ height: (wp(0.3) + hp(1.5)) * 1.1 }} />
          </div>
        )}
        <div style={{ display: 'flex', flex: 1 }}>
          <textarea
            disabled={disabled}
            placeholder={placeholder || ''}
            value={value}
            style={{
              resize: 'none',
              fontSize: wp(0.3) + hp(1.5) < 13 ? 13 : wp(0.3) + hp(1.5),
              paddingLeft: search ? hp(2.4) : hp(3),
              paddingRight: hp(3),
              ...(disabled ? { color: '#C4C4C4' } : {}),
            }}
            type={pass ? 'password' : 'text'}
            onChange={(e) => {
              if (onChangeText != null) {
                onChangeText(e.target.value);
              }
            }}
          />
        </div>
      </div>
    );
  }
}
export default InputAreaBox;
