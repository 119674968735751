import React from 'react';
import { hp } from '../../utils/responsive';
import Spacer from '../Spacer';
class Card extends React.Component {
  render() {
    const { onClick, style, children, paddingHorizontal, wrapper, flex = 1 } = this.props;
    return (
      <div
        onClick={() => {
          if (onClick) onClick();
        }}
        style={{
          flexDirection: 'column',
          display: 'flex',
          flex: flex,
          alignItems: 'center',
          position: 'relative',
          // boxShadow: "2px 0px 20px rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,

            position: 'absolute',
            top: hp(0.6),
            left: hp(0.6),
            right: hp(0.6),
            bottom: hp(0.6),
            zIndex: 0,
            alignItems: 'center',
            boxShadow: '0px 0px 20px rgba(0,0,0,0.3)',
          }}
        ></div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            flex: 1,
            zIndex: 0,
            alignItems: 'center',
            paddingTop: hp(2),
            backgroundColor: 'white',
            paddingBottom: hp(2),
            borderRadius: hp(1.2),
            ...style,
          }}
        >
          <Spacer width={paddingHorizontal} />
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              ...(wrapper != null ? wrapper : {}),
            }}
          >
            {children}
          </div>
          <Spacer width={paddingHorizontal} />
        </div>
      </div>
    );
  }
}
export default Card;
