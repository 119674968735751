import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { SearchUsers } from 'src/widgets';
import XLSX from 'xlsx';
import arrowIcon from '../../assets/images/arrow.png';
import card from '../../assets/images/card.png';
import cardId from '../../assets/images/cardid.png';
import downloadIcon from '../../assets/images/download.png';
import forks from '../../assets/images/forks.png';
import percent from '../../assets/images/percent.png';
import trashIcon from '../../assets/images/trash.png';
import uploadIcon from '../../assets/images/upload-primary.png';
import BoxButton from '../../components/BoxButton';
import Button from '../../components/Button';
import Card from '../../components/Card';
import InputAreaBox from '../../components/InputAreaBox';
import InputBox from '../../components/InputBox';
import InputBoxSelector from '../../components/InputBoxSelector';
import InputDate from '../../components/InputDate';
import List from '../../components/List';
import Modal from '../../components/Modal';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { setCompanyInfo, setCurrentCompanyID } from '../../store/actions';
import { firebase } from '../../utils/firebase';
import { ReportActions, eventNames } from '../../utils/reports';
import { hp, isMobile, wp } from '../../utils/responsive';
import {
  createPromos,
  getAllGroupedStores,
  getAllStores,
  getPromos,
  getPushNotificationsById,
  getRatings,
  getUsersIds,
  getUsersQTY,
  searchUsers,
  updatePromos,
  updatePushNotifications,
} from '../../utils/services';
import ModalQuestion from '../ModalQuestion';
import OrdersUser from '../UserModule/OrdersUser';
import PaymentsUser from '../UserModule/PaymentsUser';
import ProfileUser from '../UserModule/ProfileUser';
import PromosUser from '../UserModule/PromosUser';
import PromoComponent from './components/promoComponent';
import './index.css';
const params = [
  {
    title: 'Name',
    key: 'fullName',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: '',
    width: '2%',
    render: () => {
      return <div style={{ width: '2%' }}></div>;
    },
  },
  {
    title: 'Correo',
    key: 'email',
    width: '24%',
    alignment: 'flex-start',
  },
  {
    title: 'Última sesión',
    key: 'lastLogin',
    width: '18%',
    alignment: 'flex-start',
  },
  {
    title: 'Creado',
    key: 'created',
    width: '18%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle, index) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onRedirect(data, index);
          }}
          style={{
            width: '18%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#2697FF', fontSize: textStyle.fontSize * 0.9 }}>
            {'Ver más >'}
          </TextLato>
        </div>
      );
    },
  },
];
const promParams = [
  {
    title: 'Código',
    key: 'title',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: '',
    width: '2%',
    render: () => {
      return <div style={{ width: '2%' }}></div>;
    },
  },
  {
    title: 'Usados',
    width: '18%',
    alignment: 'center',
    render: (data, style) => {
      return (
        <div
          style={{ width: '18%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <TextLato style={style}>{data.cantidad - data.stock}</TextLato>
        </div>
      );
    },
  },
  {
    title: 'Stock',
    key: 'cantidad',
    width: '18%',
    alignment: 'center',
  },
  {
    title: 'Fecha de vencimiento',
    key: 'expiration',
    width: '24%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onEdit(data);
          }}
          style={{
            width: '18%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#2697FF', fontSize: textStyle.fontSize * 0.9 }}>
            {'Editar >'}
          </TextLato>
        </div>
      );
    },
  },
];
const importParams = [
  {
    title: 'File',
    key: 'file',
    width: '40%',
    alignment: 'flex-start',
  },
  {
    title: 'Error en columna',
    key: 'error',
    width: '60%',
    alignment: 'flex-start',
  },
];
const pushParams = [
  {
    title: 'Campaña',
    key: 'name',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: 'Abiertos',
    key: 'open',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: 'Total',
    key: 'total',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: 'Fecha de envío',
    key: 'fecha',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onEdit(data);
          }}
          style={{
            width: '20%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#2697FF', fontSize: textStyle.fontSize * 0.9 }}>
            {'Ver >'}
          </TextLato>
        </div>
      );
    },
  },
];
const listNotificationsLocals = [
  {
    title: 'Título',
    key: 'title',
    width: '25%',
    alignment: 'flex-start',
  },
  {
    title: 'Fecha de Envío',
    key: 'date',
    width: '25%',
    alignment: 'flex-start',
  },
  {
    title: 'Estado',
    width: '25%',
    alignment: 'flex-start',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            !res.send && actions.resend(data);
          }}
          style={{
            cursor: res.send ? 'auto' : 'pointer',
            display: 'flex',
            width: '25%',
            flexDirection: 'column',
          }}
        >
          <TextLato
            style={{
              ...textStyle,
              color: res.send ? '#000' : '#2697ff',
              fontSize: textStyle.fontSize * 0.9,
            }}
          >
            {res.send ? 'Enviado' : 'Por enviar... Enviar'}
          </TextLato>
        </div>
      );
    },
  },
  {
    type: 'actions',
    title: '',
    width: '25%',
    alignment: 'flex-end',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onDetail(data);
          }}
          style={{
            width: '25%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#2697FF', fontSize: textStyle.fontSize * 0.9 }}>
            {'Ver >'}
          </TextLato>
        </div>
      );
    },
  },
];
const listLocalsToNotification = [
  {
    title: '',
    key: 'name',
    width: '25%',
    alignment: 'flex-start',
  },
];
class Consumers extends React.Component {
  constructor(props) {
    super(props);
    let events = {};
    Object.keys(eventNames).map((x) => {
      events[x] = false;
    });
    const preloadUser = props.preloadUser;
    let toggleReports = 1;
    this.state = {
      users: [],
      usersKeys: {},
      promos: [],
      mode: preloadUser ? 'info' : 'users',
      currentUser: preloadUser || null,
      search: '',
      toggleReports: preloadUser ? 0 : parseInt(toggleReports) == 1,
      userIndexContent: 0,
      promoForm: {
        name: '',
        stock: '',
        fecha: '',
        cantidad: '',
        description: '',
        amount: '',
        minAmount: '',
      },
      createModalVisible: false,
      edit: false,
      mixpanelForm: {
        fromDate: moment().format('DD/MM/YYYY'),
        toDate: moment().format('DD/MM/YYYY'),
        events,
      },
      eye: true,
      stores: [],
      templateStores: [],
      ratings: {},
      notifications: [],
      currentNotification: null,
      currentNotificationToLocal: null,
      importVisible: false,
      quantity: 0,
      showModalNotification: false,
      listLocals: [],
      listNotifications: [],
      notificationSelect: null,
      showDetailSelect: false,
    };
    this.searchUsersRef = React.createRef();
  }

  async componentDidMount() {
    window.addEventListener('resize', this.resize);

    this.loadUsersQuantiy();
    this.loadPromos();
    this.loadStores();
    /* this.loadRatings(); */
    this.getNotificationsAllLocal();
  }
  loadUsersQuantiy = async () => {
    let response = await getUsersQTY();
    this.setState({ quantity: response });
  };

  loadRatings = async () => {
    try {
      let ratings = await getRatings();
      if (ratings.items != null) {
        let rate = ratings.items.reduce((prev, x) => ({ ...prev, [x.orderNumber]: x.rating }), {});
        return rate;
      }
    } catch (error) {
      return {};
    }
  };
  loadPromos = async () => {
    const { toggleReports } = this.state;
    if (toggleReports) this.props.loading();

    const promos = await getPromos();
    this.setState({
      promos: promos.items.map((x) => ({
        ...x,
        expiration: moment(new Date(x.expirationDate)).format('DD/MM/YY  HH:mm'),
      })),
    });
  };
  loadStores = async () => {
    const stores = await getAllStores();
    console.log('CCx');
    const template = stores.items
      .filter((x) => x.status != 'dev')
      .map((x) => {
        return { id: x.id, name: x.name, value: true };
      });
    this.setState({
      promoForm: { ...this.state.promoForm, stores: template },
      templateStores: template,
      stores: stores.items
        .filter((x) => x.status != 'dev')
        .map((x) => ({
          ...x,
        })),
    });

    const { toggleReports } = this.state;
    if (toggleReports) {
      this.props.loading(false);
    }
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };
  updateUsers = (users) => {
    let usersKeys = {};
    for (let i = 0; i < users.items.length; i++) {
      usersKeys[`${users.items[i].id}`] = {
        deviceToken: users.items[i].deviceToken != null ? users.items[i].deviceToken : null,
        name: users.items[i].fullName != null ? users.items[i].fullName : 'N/A',
        email: users.items[i].email != null ? users.items[i].email : 'N/A',
      };
      users.items[i].fullName != null ? users.items[i].fullName : 'N/A';
    }
    this.setState({
      usersKeys,
      users: users.items
        .filter((x) => (x.lastLogin != null || x.createdAt != null) && x.phone != null)

        .map((x, index) => ({
          ...x,
          index,
          fullName: x.fullName != null ? x.fullName : 'N/A',
          lastLogin:
            x.lastLogin != null ? moment(new Date(x.lastLogin)).format('DD/MM/YY HH:mm') : 'N/A',
          created:
            x.createdAt != null ? moment(new Date(x.createdAt)).format('DD/MM/YY HH:mm') : 'N/A',
          dateBirth: x.birthdate != null ? moment(new Date(x.birthdate)).format('DD/MM/YY') : 'N/A',
        })),
    });
  };

  getNotificationsAllLocal = () => {
    firebase
      .firestore()
      .collection('notifications-admin')
      .onSnapshot((querySnapshot) => {
        this.setState({
          listNotifications: querySnapshot.docs.map((x) => ({
            ...x.data(),
            date: moment(x.data().date.toDate()).format('DD/MM/YY  HH:mm'),
          })),
        });
      });
  };
  saveNotification = async (status) => {
    const { currentNotificationToLocal, promoForm } = this.state;
    let a = 0;
    promoForm.stores.map((item) => {
      if (item.value) a++;
    });
    let b = 0;
    promoForm.stores.map((item) => {
      if (item.value == true) {
        const configProduct = firebase
          .firestore()
          .collection('notifications')
          .doc(`${item.id}`)
          .collection('list');

        let sendData = {};
        sendData.title = currentNotificationToLocal.title;
        sendData.viewed = false;
        sendData.body = currentNotificationToLocal.message;
        sendData.idStore = item.id.toString();
        sendData.send = status;
        sendData.date = new Date();
        configProduct
          .add(sendData)
          .then((docSales) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    });
    if (status) {
      alert('Guardado y enviado');
    } else {
      alert('Guardado');
    }
    await firebase
      .firestore()
      .collection('notifications-admin')
      .add({
        title: currentNotificationToLocal.title,
        body: currentNotificationToLocal.message,
        date: new Date(),
        send: status,
        storeList: promoForm.stores
          .filter(({ value }) => value)
          .map(({ id, name }) => ({ id, name })),
      });
    this.props.loading(false);
    this.setState({
      showModalNotification: false,
      currentNotificationToLocal: null,
    });
  };
  renderModalNotifications = () => {
    const maxWidth = 410;
    const padding = wp(9) > 100 ? 100 : hp(9);
    const valid = true;
    const { promoForm, currentNotificationToLocal } = this.state;
    return (
      <Modal>
        <div
          style={{
            backgroundColor: 'white',
            width: wp(90),
            maxWidth,
            paddingRight: padding,
            paddingLeft: padding,
            borderRadius: hp(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Spacer height={hp(5)} />
          <TextLato style={{ fontWeight: '400', fontSize: hp(2.5), color: '#392E2E' }}>
            Crear Notificación
          </TextLato>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Spacer height={hp(3)} />
            <InputBox
              style={{
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
              }}
              placeholder="Titulo"
              //value={currentNotification.name}
              levelShadow={20}
              onChangeText={(str) => {
                this.setState({
                  currentNotificationToLocal: { ...currentNotificationToLocal, title: str },
                });
              }}
            />
            <Spacer height={hp(3)} />
            <InputAreaBox
              style={{
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
              }}
              placeholder="Mensaje"
              //value={currentNotification.message}
              levelShadow={20}
              onChangeText={(str) => {
                this.setState({
                  currentNotificationToLocal: { ...currentNotificationToLocal, message: str },
                });
              }}
            />
            <Spacer height={hp(3)} />
            <InputBoxSelector
              items={promoForm.stores.map((x, index) => ({ ...x, index }))}
              style={{
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
              }}
              //placeholder="Descripción"
              //value={promoForm.locales}
              levelShadow={20}
              onHandle={(val) => {
                this.setState({
                  promoForm: { ...promoForm, stores: val, listLocals: val },
                });
              }}
            />
          </div>
          <Spacer height={hp(5)} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '30%' }}>
              <Button
                backgroundColor="#ffffff"
                onClick={() => {
                  this.setState({
                    showModalNotification: false,
                    currentNotificationToLocal: null,
                  });
                }}
                textStyle={{ fontSize: hp(2.3), color: '#392E2E' }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: hp(1),
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                }}
              >
                Volver
              </Button>
            </div>
            <div style={{ width: '30%' }}>
              <Button
                backgroundColor={valid ? '#D87F7A' : '#BDBDBD'}
                onClick={async () => {
                  this.saveNotification(1);
                }}
                textStyle={{ fontSize: hp(2.3) }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: hp(1),
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                }}
              >
                Guardar y Enviar
              </Button>
            </div>
          </div>
          <Spacer height={hp(5)} />
        </div>
      </Modal>
    );
  };

  renderModalDetailNotification = () => {
    const { notificationSelect } = this.state;
    const maxWidth = 610;
    const padding = wp(9) > 100 ? 100 : hp(9);
    const messageStyle = {
      fontSize: wp(0.9) + hp(0.7) < 15 ? 15 : wp(0.9) + hp(0.7),
      fontWeight: '300',
    };
    const textStyle = {
      fontSize: wp(0.9) + hp(0.7) < 15 ? 15 : wp(0.9) + hp(0.7),
      fontWeight: '500',
      width: '100%',
    };
    const textStyleTitle = {
      fontSize: wp(0.9) + hp(0.7) < 15 ? 15 : wp(0.9) + hp(0.7),
      fontWeight: '500',
      width: '100%',
    };
    return (
      <Modal>
        <div
          style={{
            backgroundColor: 'white',
            width: wp(90),
            maxWidth,
            paddingRight: padding,
            paddingLeft: padding,
            borderRadius: hp(1),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Spacer height={hp(5)} />
          <TextLato style={textStyle}>Detalle de Notificación</TextLato>
          <Spacer height={hp(2)} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '50%', margin: '0 1rem' }}>
              <TextLato style={textStyle}>Locales</TextLato>
              <List
                paddingVertical={
                  notificationSelect != null
                    ? notificationSelect.storeList.length > 5
                      ? hp(1)
                      : null
                    : null
                }
                maxHeight={
                  notificationSelect != null
                    ? notificationSelect.storeList.length > 5
                      ? hp(25)
                      : null
                    : null
                }
                labelEmpty={'No hay locales registrados'}
                topHeader
                header
                params={listLocalsToNotification}
                data={notificationSelect.storeList}
              />
            </div>
            <div style={{ width: '50%', margin: '0 1rem' }}>
              <TextLato style={textStyle}>Mensaje</TextLato>
              <Spacer height={hp(3)} />
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  flex: '1 1 0%',
                  inset: '4.38px',
                  zIndex: '1',
                  alignItems: 'center',
                  boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 20px',
                  padding: '1rem',
                }}
              >
                <TextLato style={textStyle}>{notificationSelect.title}</TextLato>
                <Spacer height={hp(2)} />
                <div style={{ display: 'flex' }}>
                  <TextLato style={messageStyle}>{notificationSelect.body}</TextLato>
                  <Spacer width={hp(4)} />
                </div>
              </div>
            </div>
          </div>
          <Spacer height={hp(5)} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '30%' }}>
              <Button
                backgroundColor={'#D87F7A'}
                onClick={() => {
                  this.setState({
                    showDetailSelect: false,
                    notificationSelect: null,
                  });
                }}
                textStyle={{ fontSize: hp(2.3) }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: hp(1),
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                }}
              >
                Cerrar
              </Button>
            </div>
          </div>
          <Spacer height={hp(5)} />
        </div>
      </Modal>
    );
  };

  renderListNotifications = () => {
    const { listNotifications } = this.state;
    return (
      <List
        paddingVertical={
          listNotifications != null ? (listNotifications.length > 5 ? hp(1) : null) : null
        }
        maxHeight={
          listNotifications != null ? (listNotifications.length > 5 ? hp(25) : null) : null
        }
        actions={{
          onEdit: async (data) => {
            loading();
            //HERE2
            let res = await getPushNotificationsById(data.id);
            this.setState({
              currentNotification: res,
            });
            loading(false);
          },
          onDetail: async (data) => {
            this.setState({
              notificationSelect: data,
              showDetailSelect: true,
            });
          },
        }}
        labelEmpty={'No hay notificaciones creadas'}
        topHeader
        header
        params={listNotificationsLocals}
        data={listNotifications}
      />
    );
  };

  renderHeader = () => {
    const { users, mode } = this.state;
    return mode == 'users' ? (
      <div
        style={{
          height: hp(7.5),
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div style={{ width: '3.5%' }} />
        <TextLato
          style={{
            fontWeight: '500',
            fontSize: hp(2.4),
            color: '#555155',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          CONSUMIDORES&nbsp;
          <TextLato style={{ fontSize: hp(2.4), color: '#9C9C9C' }}>
            ({this.state.quantity})
          </TextLato>
        </TextLato>
      </div>
    ) : (
      <div
        style={{
          height: hp(7.5),
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Spacer width={hp(7)} />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.setState({
              mode: 'users',
              currentUser: null,
            });
          }}
        >
          <TextLato
            style={{
              fontWeight: '500',
              fontSize: hp(2.4),
              color: '#00A7A9',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {'< Volver'}
          </TextLato>
        </div>
      </div>
    );
  };
  renderHeaderNotification = () => {
    return (
      <div
        style={{
          height: hp(7.5),
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Spacer width={hp(7)} />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.setState({
              currentNotification: null,
            });
          }}
        >
          <TextLato
            style={{
              fontWeight: '500',
              fontSize: hp(2.4),
              color: '#00A7A9',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {'< Volver'}
          </TextLato>
        </div>
      </div>
    );
  };
  renderOption = (title, icon, onClick) => {
    const textStyle = {
      fontSize: wp(1.3),
      fontWeight: '400',
      display: 'flex',
      flex: 1,
    };
    return (
      <div
        onClick={onClick != null ? onClick : () => {}}
        style={{
          cursor: onClick != null ? 'pointer' : 'auto',
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {icon && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: hp(4),
            }}
          >
            <img src={icon} style={{ width: hp(3) }} />
          </div>
        )}
        {icon && <Spacer width={hp(3)} />}
        <TextLato style={textStyle}>{title}</TextLato>
        <img src={arrowIcon} style={{ height: hp(2), opacity: 0.3 }} />
      </div>
    );
  };
  renderSeparator = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: hp(5.5),
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: 1.5,
            width: '100%',
            opacity: 0.7,
            backgroundColor: '#C8C7CC',
          }}
        />
      </div>
    );
  };
  renderUserContent = (index) => {
    const { currentUser } = this.state;
    switch (index) {
      case 0:
        return null;
      case 1:
        return (
          <ProfileUser
            loadUsers={() => {}}
            updateUserinList={(data) => {
              this.setState({ users: data });
            }}
            usersList={this.state.users}
            loading={this.props.loading}
            currentUser={currentUser}
            updateCurrentUser={(data) => this.setState({ currentUser: data })}
          />
        );
      case 2:
        return <OrdersUser currentUser={currentUser} />;
      case 3:
        return <PaymentsUser loading={this.props.loading} currentUser={currentUser} />;
      case 4:
        return <PromosUser loading={this.props.loading} currentUser={currentUser} />;
    }
  };
  renderContent = () => {
    const { mode, userIndexContent, search, toggleReports } = this.state;
    if (mode == 'users') {
      if (toggleReports) return this.renderReports();
      else return this.renderUsersScreen();
    } else {
      return (
        <div style={{ width: '93%', display: 'flex' }}>
          <div style={{ width: '30%' }}>
            <Spacer height={hp(5)} />
            <Card paddingHorizontal={hp(5)}>
              <Spacer height={hp(2)} />
              {this.renderOption('Informacion Personal', cardId, () => {
                this.setState({ userIndexContent: 1 });
              })}
              {this.renderSeparator()}
              {this.renderOption('Mis Pedidos', forks, () => {
                this.setState({ userIndexContent: 2 });
              })}
              {this.renderSeparator()}
              {this.renderOption('Metodos de pago', card, () => {
                this.setState({ userIndexContent: 3 });
              })}
              {this.renderSeparator()}
              {this.renderOption('Promociones', percent, () => {
                this.setState({ userIndexContent: 4 });
              })}
              <Spacer height={hp(2)} />
            </Card>
          </div>

          <div style={{ display: 'flex', flex: 1 }}>{this.renderUserContent(userIndexContent)}</div>
        </div>
      );
    }
  };
  renderToggleButton = () => {
    const { toggleReports } = this.state;
    return (
      <div
        style={{
          paddingTop: hp(2.5),
          paddingBottom: hp(2.5),
          backgroundColor: 'white',

          position: 'relative',
          width: 200,
          boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
          borderRadius: hp(1.2),
        }}
      >
        <TextLato style={{ opacity: 0, fontSize: wp(0.3) + hp(1.5) < 13 ? 13 : wp(0.3) + hp(1.5) }}>
          hol
        </TextLato>
        <div
          style={{
            top: 0,
            borderRadius: hp(1.2),
            position: 'absolute',
            height: '100%',
            width: '50%',
            left: toggleReports ? 100 : 0,
            transition: 'left 0.5s',
            backgroundColor: '#00A7A9',
          }}
        />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextLato
              style={{
                fontSize: wp(0.3) + hp(1),
                transition: 'color 0.5s',
                color: toggleReports ? '#1F2041' : 'white',
              }}
            >
              Consumidores
            </TextLato>
          </div>
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextLato
              style={{
                fontSize: wp(0.3) + hp(1),
                transition: 'color 0.5s',
                color: !toggleReports ? '#1F2041' : 'white',
              }}
            >
              Admin
            </TextLato>
          </div>
        </div>
        <div
          onClick={() => {
            this.setState({ toggleReports: !toggleReports });
          }}
          style={{
            top: 0,
            cursor: 'pointer',
            borderRadius: hp(1.2),
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        />
      </div>
    );
  };
  renderReportItem = ({ title, action }, _, list) => {
    const subtitleStyle = {
      fontSize: wp(0.8) + hp(0.7) < 15 ? 15 : wp(0.8) + hp(0.7),
      fontWeight: '500',
    };
    return (
      <div style={{ width: '18.5%', marginRight: list.length < 5 ? '1.875%' : 0 }}>
        <Card wrapper={{ alignItems: 'center' }} paddingHorizontal={hp(1)} flex={null}>
          <TextLato style={subtitleStyle}>{title}</TextLato>
          <Spacer height={hp(2)} />
          <Card
            onClick={action}
            flex={null}
            paddingHorizontal={hp(2.5)}
            style={{ cursor: 'pointer', flex: null, paddingTop: hp(1.5), paddingBottom: hp(1.5) }}
          >
            <div>
              <img
                src={downloadIcon}
                style={{
                  width: wp(1.2) + hp(0.8),
                  height: wp(1.2) + hp(0.8),
                }}
              />
            </div>
          </Card>
          <Spacer height={hp(1)} />
        </Card>
      </div>
    );
  };
  renderReports = () => {
    const { mode, search, toggleReports, usersKeys, users } = this.state;
    const { loading } = this.props;
    const titleStyle = {
      fontSize: wp(1) + hp(0.9) < 17 ? 17 : wp(1) + hp(0.9),
      fontWeight: '500',
    };
    let cancelLoad = () => this.props.loading(false);
    const reports = [
      [
        {
          title: 'Reporte de Ventas',
          action: async () => {
            this.props.loading();
            const ratings = await this.loadRatings();

            ReportActions.SALES(usersKeys, cancelLoad, ratings, this.props.nodevstores);
          },
        },
        {
          title: 'Reporte de Favoritos',
          action: () => {
            this.props.loading();
            ReportActions.FAVORITES(cancelLoad);
          },
        },
        {
          title: 'Reporte de Publicados',
          action: () => {
            this.props.loading();
            ReportActions.PUBLICADOS(cancelLoad);
          },
        },
        {
          title: 'Reporte de Referidos',
          action: () => {
            this.props.loading();
            ReportActions.REFERIDOS(cancelLoad);
          },
        },
        {
          title: 'Reporte de Suspendidos',
          action: () => {
            this.props.loading();
            ReportActions.SUSPENDED(cancelLoad);
          },
        },
      ],
      [
        {
          title: 'Reporte de Eliminados',
          action: () => {
            this.props.loading();
            ReportActions.DELETED(cancelLoad);
          },
        },
        {
          title: 'Reporte de IDs',
          action: () => {
            this.props.loading();
            ReportActions.STOREIDS(cancelLoad);
          },
        },
        {
          title: 'Reporte de Tabla',
          action: () => {
            this.props.loading();
            ReportActions.ONSALE(cancelLoad, this.props.nodevstores);
          },
        },
      ],
    ];
    return (
      <div style={{ width: '93%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: hp(3),
            marginBottom: hp(4),
          }}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ display: 'flex', width: 300 }}>
              <InputBox
                search
                placeholder={
                  mode == 'listed'
                    ? toggleReports
                      ? 'Buscar'
                      : 'Buscar local'
                    : 'Buscar consumidores'
                }
                value={search}
                onChangeText={(str) => {
                  this.setState({
                    search: str,
                  });
                }}
              />
            </div>
            <Spacer width={hp(4)} />
            {this.renderToggleButton()}
          </div>
          <Spacer width={wp(1) + hp(2)} />
        </div>
        <div
          style={{
            margin: '0px auto',
            display: 'flex',
            flexDirection: 'column',
            width: '96%',
          }}
        >
          <TextLato style={titleStyle}>Reportes</TextLato>

          {reports.map((reportList) => (
            <>
              <Spacer height={hp(2)} />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: reportList.length < 5 ? 'flex-start' : 'space-between',
                }}
              >
                {reportList.map(this.renderReportItem)}
              </div>
            </>
          ))}
          <Spacer height={hp(5)} />
          <div
            style={{
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <PromoComponent
              promos={this.state.promos}
              templateStores={this.state.templateStores}
              loading={loading}
              update={this.loadPromos}
            />
            <Spacer height={hp(5)} />
            <TextLato style={titleStyle}>Reporte Ventas Locales</TextLato>
            <Spacer height={hp(3)} />
            <div style={{ display: 'flex', position: 'relative', zIndex: 20 }}>
              {this.renderMixpanelForm()}
            </div>
            <Spacer height={hp(5)} />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <TextLato style={titleStyle}>Notificaciones a locales</TextLato>

              <Button
                onClick={() => {
                  this.setState({
                    showModalNotification: true,
                  });
                }}
                backgroundColor={'#D87F7A'}
                second={true}
                textStyle={{ fontSize: wp(1.2) + hp(0.9), fontWeight: '500' }}
                style={{
                  display: 'flex',
                  padding: hp(1),
                  paddingLeft: hp(2.2),
                  paddingRight: hp(2.2),
                  borderRadius: hp(1),
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                +
              </Button>
            </div>
            {this.renderListNotifications()}
            <Spacer height={hp(3)} />
          </div>
        </div>

        <Spacer height={hp(2)} />
      </div>
    );
  };
  changeMixpanelData = (val, key) => {
    const { mixpanelForm } = this.state;
    let newForm = mixpanelForm;
    newForm = { ...newForm, [key]: val };
    this.setState({
      mixpanelForm: newForm,
    });
  };

  downloadReportsSales = async () => {
    const groups = await getAllGroupedStores();
    const { ratings, mixpanelForm } = this.state;
    let initFromDate = moment(mixpanelForm.fromDate, 'DD/MM/YYYY');
    let initToDate = moment(mixpanelForm.toDate, 'DD/MM/YYYY')
      .add(1, 'days')
      .subtract(1, 'seconds');
    let from = initFromDate.format();
    let to = initToDate.format();
    let mapgroup = {};
    for (let i = 0; i < groups.items.length; i++) {
      let stores = groups.items[i];
      console.log(stores);
      mapgroup[stores.name] = stores.stores.map((x) => x.id);
    }

    let orders = await firebase
      .firestore()
      .collection('orders')
      .where('date', '>=', new Date(initFromDate))
      .where('date', '<=', new Date(initToDate))
      .get()
      .then((querySnapshot) => {
        let orderArray = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (
            doc.data().state == 'FAILED' ||
            doc.data().state == 'PENDING' ||
            doc.data().canceled == true
          )
            return;
          let final = parseFloat(data.total).toFixed(2);

          let backendOrderId = data.backendOrderId;
          if (data.date)
            orderArray.push({
              ...data,
              timestamp: moment(data.date.toDate()).unix(),
              date: moment(data.date.toDate()).format('DD/MM/YY'),
              dateDelivered:
                data.deliveredAt != null
                  ? data.status != null
                    ? data.status == 'not delivered'
                      ? 'No Entregado'
                      : moment(data.deliveredAt.toDate()).format('DD/MM/YY')
                    : moment(data.deliveredAt.toDate()).format('DD/MM/YY')
                  : '-',
              time: moment(data.date.toDate()).format('HH:mm'),
              timeDelivered:
                data.deliveredAt != null
                  ? data.status != null
                    ? data.status == 'not delivered'
                      ? 'No Entregado'
                      : moment(data.deliveredAt.toDate()).format('HH:mm')
                    : moment(data.deliveredAt.toDate()).format('HH:mm')
                  : '-',
              products: doc
                .data()
                .products.map((item) => item.name + '(' + item.count + ')')
                .toString(),
              final: final < 0 ? 0 : final,
              rating:
                ratings[backendOrderId] != null
                  ? ratings[backendOrderId] != 0
                    ? ratings[backendOrderId]
                    : '-'
                  : '-',
              delivery: data.delivery ? data.deliveryAmount : 0,
            });
        });
        return orderArray;
      });
    //return false

    const mapgrouporders = {};
    const mapgroupArray = Object.entries(mapgroup);
    for (let j = 0; j < mapgroupArray.length; j++) {
      const [group, storesIds] = mapgroupArray[j];
      const itemsOrders = orders.filter((x) => storesIds.includes(parseInt(x.companyId)));
      if (itemsOrders.length > 0) {
        mapgrouporders[group] = itemsOrders;
      }
      // mapgrouporders[group] = orders.filter((x) => storesIds.includes(parseInt(x.companyId)));
    }
    ReportActions.GROUPS(
      mapgrouporders,
      initFromDate.format('DD/MM/YYYY'),
      initToDate.format('DD/MM/YYYY'),
      () => {
        alert('Archivo Generado');
      },
    );
  };

  renderMixpanelForm = () => {
    const { mixpanelForm } = this.state;
    let maxDate = moment().subtract(1, 'days').toDate();
    const inputStyle = {
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    const fieldStyle = {
      fontSize: hp(2.2),
      fontFamily: 'Lato',
      fontWeight: '400',
    };
    const labelStyle = {
      fontSize: hp(1.8),
      opacity: 0.7,
    };
    return (
      <Card wrapper={{ alignItems: 'center' }} paddingHorizontal={hp(3)} flex={null}>
        <Spacer height={hp(0.8)} />
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: wp(50) }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TextLato style={labelStyle}>Desde</TextLato>
                <Spacer height={hp(0.8)} />
                <InputDate
                  value={mixpanelForm.fromDate}
                  onChangeText={this.changeMixpanelData}
                  name="fromDate"
                  bottomSpc={hp(0.2)}
                  fieldStyle={fieldStyle}
                  style={inputStyle}
                  placeholder="Desde"
                  maxDate={maxDate}
                />
              </div>
              <Spacer width={hp(0.8)} />
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TextLato style={labelStyle}>Hasta</TextLato>
                <Spacer height={hp(0.8)} />
                <InputDate
                  value={mixpanelForm.toDate}
                  onChangeText={this.changeMixpanelData}
                  name="toDate"
                  bottomSpc={hp(0.2)}
                  fieldStyle={fieldStyle}
                  style={inputStyle}
                  placeholder="Hasta"
                  maxDate={maxDate}
                />
              </div>
            </div>
          </div>
          <Spacer width={hp(6)} />
          <div>
            <Card
              onClick={this.downloadReportsSales}
              flex={null}
              paddingHorizontal={hp(2.5)}
              style={{ cursor: 'pointer', flex: null, paddingTop: hp(1.5), paddingBottom: hp(1.5) }}
            >
              <div>
                <img
                  src={downloadIcon}
                  style={{
                    width: wp(1.2) + hp(0.8),
                    height: wp(1.2) + hp(0.8),
                  }}
                />
              </div>
            </Card>
          </div>
        </div>
        <Spacer height={hp(0.8)} />
      </Card>
    );
  };

  searchConsumers = async (query) => {
    let search = 'a';
    if (query) search = query;
    else search = this.state.search;
    this.props.loading();
    const response = await searchUsers(search === '' ? 'a' : search);
    this.props.loading(false);
    if (response.errorCode == '200') {
      this.updateUsers(response);
    }
  };
  renderUsersScreen = () => {
    const { mode, search, toggleReports } = this.state;
    return (
      <div style={{ width: '93%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: hp(3),
            marginBottom: hp(4),
          }}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ display: 'flex', width: 300 }}>
              <InputBox
                search
                placeholder={
                  mode == 'listed'
                    ? toggleReports
                      ? 'Buscar'
                      : 'Buscar local'
                    : 'Buscar consumidores'
                }
                value={search}
                onChangeText={(str) => {
                  this.setState({
                    search: str,
                  });
                  if (mode != 'listed') {
                    if (this.debounceSearch) clearTimeout(this.debounceSearch);
                    this.debounceSearch = setTimeout(() => {
                      console.log('loggin');
                      console.log(this.searchUsersRef.current);
                      if (this.searchUsersRef.current) {
                        this.searchUsersRef.current.search?.(str.trim());
                      }
                    }, 1000);
                  }
                }}
              />
            </div>
            <Spacer width={hp(4)} />
            {this.renderToggleButton()}
          </div>
          <div>
            <div style={{ cursor: 'pointer' }} onClick={() => {}}>
              <Card
                onClick={() => {
                  ReportActions.USERSFIREBASE(this.state.users, () => {});
                }}
                paddingHorizontal={hp(2.5)}
                style={{
                  cursor: 'pointer',
                  flex: null,
                  paddingTop: hp(1.5),
                  paddingBottom: hp(1.5),
                }}
              >
                <div>
                  <img
                    src={downloadIcon}
                    style={{
                      width: wp(1.2) + hp(0.8),
                      height: wp(1.2) + hp(0.8),
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
          <Spacer width={wp(1) + hp(2)} />
        </div>
        <SearchUsers
          ref={this.searchUsersRef}
          handleLoading={this.props.loading}
          initialSearch={search}
          onRedirect={(pdata) => {
            this.setState({ currentUser: pdata, mode: 'info' });
          }}
        />
        <Spacer height={hp(5)} />
      </div>
    );
  };
  handleDrag = (e) => {
    e.preventDefault();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    this.setState({ dragging: true });
  };
  handleDragOut = (e) => {
    e.preventDefault();
    this.setState({ dragging: false });
  };
  handleDrop = (e) => {
    const $this = this;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false, dragged: true });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.processFileDropped(e.dataTransfer.files[0]);
    }
  };
  processBufferExcel = (result) => {
    let data = new Uint8Array(result);
    let arr = new Array();
    for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    let bstr = arr.join('');

    /* Call XLSX */
    let workbook = XLSX.read(bstr, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });

    /* DO SOMETHING WITH workbook HERE */
    let first_sheet_name = workbook.SheetNames[0];
    /* Get worksheet */
    let worksheet = workbook.Sheets[first_sheet_name];
    return XLSX.utils.sheet_to_json(worksheet, { raw: true, header: 1 });
  };
  processFileDropped(file) {
    const $this = this;
    if (file.name.indexOf('.xlsx') != -1) {
      if (file != null) {
        let reader = new FileReader();
        reader.onload = function () {
          let arrayBuffer = this.result;
          let result = $this.processBufferExcel(arrayBuffer);
          $this.onFinishParse(result);
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('NO ENCONTRO');
      }
    } else {
      alert('El archivo debe ser XLSX. Descargue la plantilla');
    }
  }
  onFinishParse = async (data) => {
    let dataArray = data.slice(1);
    let pass = 0;
    let fail = 0;
    let listFail = [];
    let listValid = [];
    let users = dataArray.map((x) => parseInt(x[0].toString().trim()));
    const usersKeys = await getUsersIds();
    for (let i = 0; i < dataArray.length; i++) {
      let element = dataArray[i];
      if (element.length >= 1) {
        if (element[0].toString().trim() != '') {
          let id = element[0].toString().trim();
          let obj = {};
          if (usersKeys[parseInt(id)] != null) {
            let currentUser = usersKeys[parseInt(id)];
            obj = {
              ...obj,
              id: parseInt(id),
              email: currentUser.email,
              deviceToken: currentUser.deviceToken,
              fullName: currentUser.name,
            };
            if (obj.deviceToken == null) {
              fail++;
              listFail.push({ file: i + 1, error: 'Dispositivo invalido' });
              continue;
            }
          } else {
            fail++;
            listFail.push({ file: i + 1, error: 'Usuario no encontrado' });
            continue;
          }
          pass++;
          listValid.push(obj);
        } else {
          fail++;

          listFail.push({ file: i + 1, error: 'Campos en blanco' });
        }
      } else {
        if (element.length > 0) {
          fail++;
          listFail.push({ file: i + 1, error: 'Campos incompletos' });
        }
      }
    }
    this.setState({
      importResult: {
        pass,
        fail,
        list: listFail,
        passed: listValid,
      },
    });
  };
  addListeners = () => {
    const $this = this;

    let div = this.importContainer;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
    let input = this.inputFil.addEventListener(
      'change',
      function () {
        $this.processFileDropped(this.files[0]);
      },
      false,
    );
  };
  removeListeners = () => {
    const $this = this;

    let div = this.importContainer;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
    let input = this.inputFil.removeEventListener(
      'change',
      function () {
        $this.processFileDropped(this.files[0]);
      },
      false,
    );
  };
  renderImportModal = () => {
    const { importVisible, importResult, dragging } = this.state;
    return (
      <Modal visible={importVisible}>
        <div
          style={{
            backgroundColor: 'white',
            width: wp(90),
            maxWidth: 500,
            height: hp(55),
            borderRadius: hp(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Spacer height={hp(4)} />
          <TextLato style={{ fontSize: wp(0.2) + hp(2), fontWeight: '400' }}>Importación</TextLato>
          <Spacer height={importResult != null ? hp(2) : hp(9)} />
          {importResult != null ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <Spacer width={wp(4)} />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  height: '100%',
                  alignItems: 'flex-start',
                }}
              >
                <TextLato
                  style={{
                    fontSize: wp(0.4) + hp(1),
                    fontWeight: '400',
                  }}
                >
                  Resultado
                </TextLato>
                <Spacer height={hp(2)} />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextLato
                    style={{
                      fontSize: wp(0.4) + hp(1),
                      fontWeight: '400',
                    }}
                  >
                    {importResult.pass} usuarios encontrados
                  </TextLato>
                  <Spacer width={wp(3)} />
                  <TextLato
                    style={{
                      fontSize: wp(0.4) + hp(1),
                      fontWeight: '400',
                    }}
                  >
                    {importResult.fail} usuarios no encontrados
                  </TextLato>
                </div>
                <Spacer height={hp(2)} />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                  }}
                >
                  {importResult.list.length > 0 && (
                    <List
                      maxHeight={hp(20)}
                      expand={true}
                      headerStyle={{
                        fontSize: wp(0.4) + hp(1),
                        fontWeight: '400',
                        fontStyle: 'italic',
                      }}
                      paddingVertical={hp(1)}
                      rowStyle={{
                        fontSize: wp(0.4) + hp(1),
                        fontWeight: '400',
                      }}
                      topHeader={true}
                      handleRow={async (data) => {}}
                      header={true}
                      params={importParams}
                      data={importResult.list}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ display: 'flex', flex: 1 }} />
                  <Button
                    onClick={() => {
                      this.setState({ importResult: null }, () => {
                        this.removeListeners();
                        this.addListeners();
                      });
                    }}
                    second={true}
                    textStyle={{ fontSize: wp(0.8) + hp(0.5), color: '#392E2E' }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      paddingTop: hp(1.4),
                      paddingBottom: hp(1.4),
                      paddingLeft: wp(2),
                      paddingRight: wp(2),
                      borderRadius: hp(0.8),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    backgroundColor={'#ffffff'}
                  >
                    Deshacer
                  </Button>
                  <Spacer width={wp(4)} />
                  <Button
                    onClick={() => {
                      let lista = importResult.passed;

                      const currentNotificationUsers = this.state.currentNotification
                        .userPushNotifications;
                      let listParsed = currentNotificationUsers.reduce(
                        (prev, x) => ({ ...prev, [x.userId]: x }),
                        {},
                      );
                      let listParsedToAdd = lista.reduce(
                        (prev, x) => ({ ...prev, [x.id]: { ...x, userId: x.id } }),
                        {},
                      );
                      let composedList = { ...listParsed, ...listParsedToAdd };
                      this.setState({
                        importResult: null,
                        importVisible: false,
                        currentNotification: {
                          ...this.state.currentNotification,
                          userPushNotifications: Object.entries(composedList).map(
                            ([key, value]) => value,
                          ),
                        },
                      });
                    }}
                    second={true}
                    textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      paddingLeft: wp(2),
                      paddingRight: wp(2),
                      paddingTop: hp(1.4),
                      paddingBottom: hp(1.4),
                      borderRadius: hp(0.8),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    backgroundColor={'#D87F7A'}
                  >
                    Guardar
                  </Button>
                  <div style={{ display: 'flex', flex: 1 }} />
                </div>
              </div>
              <Spacer width={wp(4)} />
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <Spacer width={wp(8)} />
              <div
                ref={(x) => (this.importContainer = x)}
                style={{
                  width: '100%',
                  display: 'flex',
                  flex: 1,
                  backgroundColor: dragging ? 'rgba(9,175,175,0.3)' : 'transparent',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #09AFAF',
                  borderRadius: hp(1),
                }}
              >
                <input
                  ref={(x) => (this.inputFil = x)}
                  type="file"
                  name="importFile"
                  id="importFile"
                  class="inputfile"
                  accept="text/xlsx"
                />
                {dragging ? (
                  <TextLato
                    style={{
                      fontSize: wp(0.1) + hp(1.4),
                      fontWeight: '400',
                      position: 'relative',
                      zIndex: 0,
                    }}
                  >
                    Suelta tu archivo
                  </TextLato>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextLato
                      style={{
                        fontSize: wp(0.1) + hp(1.4),
                        fontWeight: '400',
                        position: 'relative',
                        zIndex: 0,
                      }}
                    >
                      Arrastra o&nbsp;
                    </TextLato>
                    <label for="importFile">
                      <TextLato
                        style={{
                          fontSize: wp(0.1) + hp(1.4),
                          fontWeight: '500',
                          position: 'relative',
                          color: '#00A7A9',
                          zIndex: 0,
                          cursor: 'pointer',
                        }}
                      >
                        selecciona archivo
                      </TextLato>
                    </label>
                  </div>
                )}
              </div>
              <Spacer width={wp(8)} />
            </div>
          )}
          <Spacer height={importResult != null ? hp(2) : hp(9)} />
        </div>
      </Modal>
    );
  };
  renderModalQuestion = () => {
    const { deleteId, currentNotification } = this.state;

    const { userPushNotifications: users } = currentNotification;
    return (
      <ModalQuestion
        visible={deleteId != null}
        message={
          deleteId >= 0
            ? '¿Estas seguro que deseas eliminar usuario?'
            : '¿Estas seguro que deseas enviar campaña?'
        }
        okAction={() => {
          if (deleteId >= 0) {
            this.setState({
              deleteId: null,
              currentNotification: {
                ...currentNotification,
                userPushNotifications: users.filter((x, i) => deleteId != i),
              },
            });
          } else {
            this.sendPushNotification();
          }
        }}
        cancelAction={() => {
          this.setState({ deleteId: null });
        }}
        okText={deleteId >= 0 ? 'Eliminar' : 'Enviar'}
      />
    );
  };
  sendPushNotification = async () => {
    const { loading } = this.props;

    const { currentNotification } = this.state;
    const { userPushNotifications: users } = currentNotification;

    const request = {
      id: currentNotification.id,
      users: users.map((x) => ({
        id: x.userId,
        deviceToken: x.deviceToken,
      })),
    };
    loading(true);
    await updatePushNotifications(request);
    this.setState({ currentNotification: null, deleteId: null });
    loading(false);
  };
  renderNotificationContent = () => {
    const { currentNotification } = this.state;
    //HERE1
    const { userPushNotifications: users, status } = currentNotification;
    const titleStyle = {
      fontSize: wp(1) + hp(0.9) < 17 ? 17 : wp(1) + hp(0.9),
      fontWeight: '500',
    };
    const params =
      status == 'PROCESS'
        ? [
            {
              title: 'Usuario',
              key: 'name',
              width: '32%',
              alignment: 'flex-start',
            },
            {
              title: '',
              key: 'email',
              width: '45%',
              alignment: 'flex-start',
            },
            {
              title: '',
              width: '10%',
              render: () => {
                return <div style={{ width: '10%' }}></div>;
              },
            },
            {
              type: 'actions',
              title: '',
              render: (res, textStyle, index) => {
                const { data, actions } = res;
                return (
                  <div
                    onClick={() => {
                      actions.onDelete(data, index);
                    }}
                    style={{
                      width: '10%',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    <img
                      src={trashIcon}
                      style={{ width: wp(1) + hp(1), height: ((wp(1) + hp(1)) * 512) / 404 }}
                    />
                  </div>
                );
              },
            },
            {
              title: '',
              width: '3%',
              render: () => {
                return <div style={{ width: '3%' }}></div>;
              },
            },
          ]
        : [
            {
              title: 'Usuario',
              key: 'name',
              width: '40%',
              alignment: 'flex-start',
            },
            {
              title: '',
              key: 'email',
              width: '60%',
              alignment: 'flex-start',
            },
          ];
    const usersKeys = {};
    const list = users.map((x) => ({
      name: x.fullName ? x.fullName : usersKeys[x.userId]?.name || 'Cargando...',
      email: x.email ? x.email : usersKeys[x.userId]?.email || 'Cargando...',
    }));
    const actions = {
      onDelete: (data, index) => {
        this.setState({
          deleteId: index,
        });
      },
    };
    const textStyle = {
      fontSize: wp(0.9) + hp(0.7) < 15 ? 15 : wp(0.9) + hp(0.7),
      fontWeight: '500',
    };
    const messageStyle = {
      fontSize: wp(0.9) + hp(0.7) < 15 ? 15 : wp(0.9) + hp(0.7),
      fontWeight: '400',
    };
    let showList = list.length > 0;
    return (
      <div style={{ width: '100%' }}>
        <Spacer height={hp(3)} />
        <div
          style={{
            width: '93%',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 auto',
          }}
        >
          <TextLato style={titleStyle}>{currentNotification.name}</TextLato>
          <div style={{ flex: 1, display: 'flex' }} />
          {showList && status == 'PROCESS' && (
            <Button
              backgroundColor="#D87F7A"
              onClick={() => this.setState({ deleteId: -1 })}
              textStyle={{ fontSize: isMobile() ? hp(2) : hp(2.3), textAlign: 'center' }}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: hp(1),
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
                paddingLeft: isMobile() ? hp(1) : hp(1.5),
                paddingRight: isMobile() ? hp(1) : hp(1.5),
              }}
            >
              Enviar
            </Button>
          )}
        </div>

        <div
          style={{
            width: '93%',
            justifyContent: 'space-between',
            display: 'flex',
            margin: '0 auto',
          }}
        >
          <div style={{ width: '48%' }}>
            <Spacer height={hp(5)} />
            {status == 'PROCESS' && (
              <>
                <BoxButton
                  onClick={() => {
                    this.setState({
                      importVisible: true,
                    });
                    setTimeout(this.addListeners, 1000);
                  }}
                  iconSource={uploadIcon}
                  icon={true}
                >
                  Importar bbdd
                </BoxButton>
                {this.renderImportModal()}
                <Spacer height={hp(3)} />
              </>
            )}
            {showList && (
              <List
                actions={actions}
                labelEmpty={'No hay usuarios'}
                topHeader
                header
                params={params}
                data={list}
              />
            )}
          </div>
          {showList && (
            <div style={{ width: '48%' }}>
              <Spacer height={hp(5)} />
              <TextLato style={textStyle}>Mensaje</TextLato>
              <Spacer height={hp(3)} />
              <Card
                onClick={() => this.setState({ createPushModal: true })}
                paddingHorizontal={hp(5)}
              >
                <Spacer height={hp(2)} />
                <TextLato style={textStyle}>{currentNotification.title}</TextLato>
                <Spacer height={hp(2)} />
                <div style={{ display: 'flex' }}>
                  <TextLato style={messageStyle}>{currentNotification.message}</TextLato>
                  <Spacer width={hp(4)} />
                </div>
                <Spacer height={hp(2)} />
              </Card>
            </div>
          )}
        </div>
        {this.renderModalQuestion()}
      </div>
    );
  };
  render() {
    const { currentNotification, showModalNotification, showDetailSelect } = this.state;
    if (currentNotification?.id != null) {
      return (
        <div
          style={{
            width: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          {this.renderHeaderNotification()}
          <div
            className="scrollnone"
            style={{
              overflowY: 'auto',
              flexDirection: 'column',
              backgroundColor: '#F9F9F9',
              paddingBottom: hp(5),
              flex: 1,
              width: '100%',
              display: 'flex',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                top: 0,
                height: '100%',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              {this.renderNotificationContent()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {this.renderHeader()}
        <div
          className="scrollnone"
          style={{
            overflowY: 'auto',
            flexDirection: 'column',
            backgroundColor: '#F9F9F9',
            paddingBottom: hp(5),
            flex: 1,
            width: '100%',
            display: 'flex',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {this.renderContent()}
          </div>
        </div>
        {showModalNotification && this.renderModalNotifications()}
        {showDetailSelect && this.renderModalDetailNotification()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  companyID: state.companyID,
  companyInfo: state.companyInfo,
  stores: state.stores,
  nodevstores: state.nodevstores,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyID: (value) => {
    dispatch(setCurrentCompanyID(value));
  },
  setCompanyInfo: (value) => {
    dispatch(setCompanyInfo(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Consumers);
