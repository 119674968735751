import moment from 'moment';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import Button from '../../../../components/Button';
import InputBox from '../../../../components/InputBox';
import InputBoxDefault from '../../../../components/InputBoxRequest';
import InputBoxSelector from '../../../../components/InputBoxSelector';
import Modal from '../../../../components/Modal';
import Spacer from '../../../../components/Spacer';
import TextLato from '../../../../components/TextLato';
import { hp, wp } from '../../../../utils/responsive';
import { createPromos, updatePromos } from '../../../../utils/services';

const PROMOCATEGORIES = [
  { name: 'General', value: 0 },
  { name: 'Primera compra', value: 1 },
  { name: 'Max Discount 35', value: 2 },
  { name: 'Max Discount 50', value: 3 },
  { name: 'No Order +60 Dias', value: 4 },
];
const PromoModal = ({
  visible,
  edit,
  clean,
  promoForm,
  currentPromo,
  setPromoForm,
  loading,
  update,
}) => {
  const title = edit ? 'Editar código' : 'Crear código';
  const buttonText = edit ? 'Guardar' : 'Crear';
  const datepicker = useRef(null);
  const onChangeText = (key, value) => {
    setPromoForm({ ...promoForm, [key]: value });
  };
  const handleCreatePromo = async () => {
    const {
      name,
      description,
      stock,
      cantidad,
      minAmount,
      fecha,
      amount,
      stores,
      category,
    } = promoForm;
    var allStores = stores.filter((x) => x.value);
    if (allStores.length == 0) {
      alert('Selecciona al menos un restaurante');
      return;
    }
    console.log(promoForm);
    var request = {
      title: name,
      description: description,
      amount: parseFloat(parseFloat(amount).toFixed(2)),
      expirationDate: moment(fecha, 'DD/MM/YY').toDate(),
      cantidad: parseInt(cantidad),
      minAmount: parseFloat(parseFloat(minAmount).toFixed(2)),
      stores: allStores.length == stores.length ? 'all' : allStores.map((x) => x.id).join(','),
      category,
    };
    request.id = edit ? currentPromo.id : null;
    clean();
    loading();

    if (request.id) {
      await updatePromos(request);
    } else await createPromos(request);

    await update();
    loading(false);
  };
  const renderForm = () => {
    const {
      name,
      description,
      stock,
      cantidad,
      minAmount,
      fecha,
      amount,
      stores,
      category,
    } = promoForm;
    console.log('ST', stores);
    return (
      <div style={styles.formContainer}>
        <Spacer height={hp(3)} />
        <InputBox
          style={styles.inputStyle}
          placeholder="Nombre del código"
          value={name}
          levelShadow={20}
          onChangeText={(str) => onChangeText('name', str)}
        />
        <Spacer height={hp(2)} />
        <InputBox
          style={styles.inputStyle}
          placeholder="Descripción"
          value={description}
          levelShadow={20}
          onChangeText={(str) => onChangeText('description', str)}
        />
        <Spacer height={hp(2)} />

        {edit ? (
          <div style={styles.rowContainer}>
            <div style={styles.inputWrapper}>
              <InputBox
                disabled={true}
                style={styles.inputStyle}
                placeholder="Usados"
                value={stock}
                levelShadow={20}
                onChangeText={(str) => onChangeText('stock', str)}
              />
            </div>
            <div style={styles.inputWrapper}>
              <InputBox
                style={styles.inputStyle}
                placeholder="Stock"
                value={cantidad}
                levelShadow={20}
                onChangeText={(str) => onChangeText('cantidad', str)}
              />
            </div>
          </div>
        ) : (
          <InputBox
            style={styles.inputStyle}
            placeholder="Stock"
            value={cantidad}
            levelShadow={20}
            onChangeText={(str) => onChangeText('cantidad', str)}
          />
        )}
        <Spacer height={hp(2)} />
        <InputBox
          style={styles.inputStyle}
          placeholder="Monto mínimo"
          value={minAmount}
          levelShadow={20}
          onChangeText={(str) => onChangeText('minAmount', str)}
        />
        <Spacer height={hp(2)} />
        <div style={styles.rowContainer}>
          <div style={styles.wrapper}>
            <InputBox
              style={styles.inputStyle}
              placeholder="Fecha de Vencimiento"
              value={fecha}
              levelShadow={20}
              onChangeText={(str) => onChangeText('fecha', str)}
            />
            <div onClick={() => datepicker.current.setOpen(true)} style={styles.pickerButton}>
              <DatePicker
                onClickOutside={() => {}}
                customInput={<div></div>}
                ref={datepicker}
                selected={
                  promoForm != null
                    ? fecha != null && fecha != ''
                      ? moment(fecha, 'DD/MM/YY').toDate()
                      : new Date()
                    : new Date()
                }
                onChange={(x) => onChangeText('fecha', moment(x).format('DD/MM/YY'))}
              />
            </div>
          </div>
          <div style={styles.inputWrapper}>
            <InputBox
              style={styles.inputStyle}
              placeholder="Valor de descuento"
              value={amount}
              levelShadow={20}
              onChangeText={(str) => onChangeText('amount', str)}
            />
          </div>
        </div>
        <Spacer height={hp(2)} />
        <div style={styles.reverseContainer}>
          <InputBoxDefault
            list={PROMOCATEGORIES}
            style={styles.inputStyleRequest}
            levelShadow={20}
            value={PROMOCATEGORIES[category]}
            onHandle={(val) => {
              setPromoForm({ ...promoForm, category: val.value });
            }}
            loading={loading}
          />
          <Spacer height={hp(2)} />
          <InputBoxSelector
            items={stores.map((x, index) => ({ ...x, index }))}
            style={styles.inputStyle}
            levelShadow={20}
            onHandle={(val) => onChangeText('stores', val)}
          />
        </div>
        <Spacer height={hp(5)} />
      </div>
    );
  };
  return visible ? (
    <Modal>
      <div style={styles.container}>
        <Spacer height={hp(5)} />
        <TextLato style={styles.title}>{title}</TextLato>
        {renderForm()}
        <div style={styles.buttonContainer}>
          <div style={styles.buttonWrapper}>
            <Button
              backgroundColor="#ffffff"
              onClick={clean}
              textStyle={styles.buttonText}
              style={styles.button}
            >
              Volver
            </Button>
          </div>
          <div style={styles.buttonWrapper}>
            <Button
              backgroundColor={'#D87F7A'}
              onClick={handleCreatePromo}
              textStyle={styles.buttonPrimaryText}
              style={styles.button}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        <Spacer height={hp(5)} />
      </div>
    </Modal>
  ) : null;
};
const styles = {
  container: {
    backgroundColor: 'white',
    width: wp(90),
    maxWidth: 620,
    paddingRight: wp(9) > 100 ? 100 : hp(9),
    paddingLeft: wp(9) > 100 ? 100 : hp(9),
    borderRadius: hp(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: '400',
    fontSize: hp(2.5),
    color: '#392E2E',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    width: '45%',
  },
  inputWrapper: {
    width: '45%',
  },
  button: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: hp(1),
    paddingTop: hp(1.5),
    paddingBottom: hp(1.5),
  },
  buttonText: {
    fontSize: hp(2.3),
    color: '#392E2E',
  },
  buttonPrimaryText: {
    fontSize: hp(2.3),
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputStyle: {
    paddingTop: hp(1.5),
    paddingBottom: hp(1.5),
    zIndex: 4,
  },
  inputStyleRequest: {
    paddingTop: hp(1.5),
    paddingBottom: hp(1.5),
    zIndex: 3,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapper: {
    position: 'relative',
    width: '45%',
  },
  reverseContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  pickerButton: {
    position: 'absolute',
    top: 0,
    zIndex: 2,
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
  },
};
export default PromoModal;
