import { css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Sound from 'react-sound';
import FadeLoader from 'react-spinners/FadeLoader';
import { toast, ToastContainer } from 'react-toastify';
import contact_primary from '../../assets/images/contact-primary.png';
import contact_white from '../../assets/images/contact-white.png';
import forks_primary from '../../assets/images/forks-primary.png';
import forks_white from '../../assets/images/forks-white.png';
import history_primary from '../../assets/images/history-primary.png';
import history_white from '../../assets/images/history-white.png';
import logout_primary from '../../assets/images/logout-primary.png';
import logout_white from '../../assets/images/logout-white.png';
import report_primary from '../../assets/images/report-primary.png';
import report_white from '../../assets/images/report-white.png';
import sales_primary from '../../assets/images/sales-primary.png';
import sales_white from '../../assets/images/sales-white.png';
import setup_primary from '../../assets/images/setup-primary.png';
import setup_white from '../../assets/images/setup-white.png';
import sound_notification from '../../assets/sounds/notifications.mp3';
import Ring from '../../assets/sounds/ring.mp3';
import AdminTop from '../../components/AdminTop';
import Layout from '../../components/Layout';
import List from '../../components/List';
import Spacer from '../../components/Spacer';
import TabBar from '../../components/TabBar';
import TextLato from '../../components/TextLato';
import {
  setCompanyID,
  setCompanyInfo,
  setCurrentCompanyID,
  setFirebaseID,
  setLocals,
  setNoDevStores,
  setOwner,
  setStores,
  setUsers,
} from '../../store/actions';
import _emitter from '../../utils/events';
import { firebase } from '../../utils/firebase';
import { getAdminCredentials, notAdmin } from '../../utils/functions';
import { hp, wp } from '../../utils/responsive';
import { getAllStores, getCompany, getUserProfile, loginCompanyV3 } from '../../utils/services';
import { getUrlParam } from '../../utils/url';
import Admin from '../Admin';
import Consumers from '../Consumers';
import Dashboard from '../Dashboard';
import Editor from '../Editor';
import History from '../History';
import Logout from '../Logout';
import MainSales from '../MainSales';
import Orders from '../Orders';
import Profile from '../Profile';
import Receipt from '../Receipt';
import Restaurants from '../Restaurants';
import Sales from '../Sales';
import Settings from '../Settings';
import Stored from '../Stored';
import Support from '../Support';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const params = [
  {
    title: 'Local',
    render: (res, textStyle) => {
      return <div style={{ width: '1%' }}></div>;
    },
  },
  {
    title: ' ',
    key: 'fullName',
    width: '84%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;

      return (
        <div
          onClick={() => {
            actions.onClick(data);
          }}
          style={{
            width: '15%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#00A7A9', fontSize: textStyle.fontSize * 0.9 }}>
            {'Ingresar >'}
          </TextLato>
        </div>
      );
    },
  },
];
const headerNames = [
  {
    title: 'Ventas',
    subtitles: [
      'Agregar Productos',
      (textDecoration) => {
        return <VerPedidosComponent textDecoration={textDecoration} />;
      },
    ],
    sub: ['sales', 'orders'],
  },
  {
    title: 'Historial',
    sub: ['history', 'receipt'],
  },
  {
    title: 'Configuraciones',
    sub: ['settings'],
  },

  {
    title: 'Productos',
    sub: ['stored'],
  },
  {
    title: 'Reportes',
    sub: ['reports'],
  },
  {
    title: 'Contacto',
    sub: ['support'],
  },
  {
    title: 'Cerrar Sesión',
    sub: ['logout'],
  },
];

const headerNamesAdmin = [
  {
    title: 'RESTAURANTES',
    sub: ['restaurants', 'admin'],
  },
];
const styles = {
  fontFamily: 'Lato',
  textAlign: 'center',
  fontSize: '18px',
};
class Welcome extends React.Component {
  constructor() {
    super();
    var c = sessionStorage.getItem('index');
    var adminIndex = sessionStorage.getItem('adminTab');
    this.state = {
      headerIndex: c || 0,
      headerSubIndex: 0,
      prev: [],
      storedRestaurants: null,
      loading: false,
      adminIndex: adminIndex ? parseInt(adminIndex) : 0,
      preloadUser: null,
      adminMode: 0,
      percent: -1,
      toLogout: false,
      adminTabs: ['NEGOCIOS', 'CONSUMIDORES', 'EDITOR'],
      barVisible: true,
      soundPlayed: false,
      audioUnlocked: false,
      showNotif: false,
      notif_event: false,
      cant_show_notif: true,
      showMessageBox: false,
      listNotifications: [],
    };
    this.unlockAudio = this.unlockAudio.bind(this);
  }

  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };
  focusState = false;
  componentWillUnmount() {
    if (this.notifListener) {
      this.notifListener();
    }
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  }

  onBackButton = (e) => {
    e.preventDefault();
    var prevs = sessionStorage.getItem('prevIndex');

    var prevs = prevs != null ? JSON.parse(prevs) : [];

    if (prevs.length > 0) {
      var newIndex = prevs.pop();
      sessionStorage.setItem('prevIndex', JSON.stringify(prevs));
      this.setState({ headerIndex: parseInt(newIndex), headerSubIndex: 0 });

      sessionStorage.setItem('index', parseInt(newIndex));
      window.location.reload();
    } else {
      //alert("nah");
    }

    window.removeEventListener('popstate', this.onBackButton, false);
  };
  onFocus = () => {
    this.focusState = true;
    document.title = 'Cirkula';
  };
  onBlur = () => {
    this.focusState = false;
  };
  componentDidMount() {
    const { setStores, setNoDevStores } = this.props;
    console.log(this.props.companyID);
    window.addEventListener('focus', this.onFocus);
    document.body.addEventListener('click', this.unlockAudio);
    document.body.addEventListener('touchstart', this.unlockAudio);
    window.addEventListener('blur', this.onBlur);
    window.addEventListener('resize', this.resize);
    window.addEventListener('popstate', this.onBackButton, false);
    var credentials = localStorage.getItem('credentials');
    credentials = JSON.parse(credentials);
    window.addEventListener('resize', this.resize);

    if (credentials != null) {
      getAllStores().then((res) => {
        if (res.items != null) {
          var storeKeys = res.items.reduce((prev, x) => ({ ...prev, [x.id]: x.name }), {});
          console.log(res.items[0]);
          var nodevstoreKeys = res.items
            .filter((x) => x.status != 'dev')
            .reduce(
              (prev, x) => ({
                ...prev,
                [x.id]: { name: x.name, buySince: x.buySince, buyLimit: x.buyLimit },
              }),
              {},
            );
          setStores(storeKeys);

          setNoDevStores(nodevstoreKeys);
        }
      });
      if (this.props.companyID == '' || this.props.companyInfo?.admin) {
        this.completeLogin(
          credentials,
          getAdminCredentials(credentials.username, credentials.password),
        );
      } else {
        console.log(this.props.companyID);
        if (notAdmin(this.props.companyID)) {
          this.notifyMe(this.props.companyID);
          this.getNotifications(this.props.companyID);
        } else {
          var admin = this.props.companyID;
          this.setState({
            adminTabs:
              admin == 'admin'
                ? ['NEGOCIOS', 'CONSUMIDORES', 'EDITOR']
                : admin == 'marketing'
                ? ['CONSUMIDORES', 'EDITOR']
                : ['NEGOCIOS'],
          });
        }
      }
    } else {
      sessionStorage.removeItem('index');
      sessionStorage.removeItem('prevIndex');
      localStorage.removeItem('credentials');
      this.props.history.push('/login');
    }
  }
  playSound = () => {
    const { soundPlayed, audioUnlocked } = this.state;
    if (!soundPlayed && audioUnlocked) {
      this.setState({ soundPlayed: true });
      setTimeout(() => {
        this.setState({ soundPlayed: false });
      }, 2000);
      try {
        document.getElementById('audiotag1').play();
      } catch (error) {
        alert(error);
      }
    }
  };
  updateUnlock = () => {
    this.setState({ audioUnlocked: true });
  };
  unlockAudio() {
    document.getElementById('audiotag1').play();
    document.getElementById('audiotag1').pause();
    this.updateUnlock();
    document.body.removeEventListener('click', this.unlockAudio);
    document.body.removeEventListener('touchstart', this.unlockAudio);
  }
  notifListener = null;
  notifyMe = (companyId) => {
    const $this = this;
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      if (this.notifListener != null) this.notifListener();
      this.configureNotif(companyId);
      console.log('GRANTED');
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      console.log('DENIED');
      Promise.resolve(
        Notification.requestPermission((x) => {
          if (x == 'granted') {
            console.log('FIRSLY');
            if (this.notifListener != null) this.notifListener();
            this.configureNotif(companyId);
          }
        }),
      ).then((permission) => {
        if (permission == 'granted') {
          console.log('SECONDLY');
          if (this.notifListener != null) this.notifListener();
          this.configureNotif(companyId);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  };
  setIntervalX = (callback, delay, repetitions, onFinish) => {
    var x = 0;
    var intervalID = setInterval(() => {
      callback();

      if (++x === repetitions) {
        clearInterval(intervalID);
        onFinish();
      }
    }, delay);
  };

  isDeleting = false;
  configureNotifListener = () => {
    if (this.state.notif_event) return;
    this.setState({ notif_event: true });
    _emitter.on('listen_notif', (value) => {
      console.log('listener => ', value);
      this.setState({ cant_show_notif: value });
    });
  };
  configureNotif = (companyID) => {
    this.configureNotifListener();
    this.notifListener = firebase
      .firestore()
      .collection('webnotif')
      .where('storeId', '==', companyID.toString())
      .onSnapshot(async (snapshot) => {
        if (!this.state.cant_show_notif) return;
        console.log('welcome => ', snapshot.docs.length);
        if (!this.isDeleting) {
          var cant = snapshot.docs.length;
          if (cant > 0) {
            this.setIntervalX(
              () => this.setState({ playSound: Sound.status.PLAYING }),
              2000,
              3,
              () => setTimeout(() => this.setState({ playSound: Sound.status.STOPPED }), 2500),
            );
            toast.success(
              `${cant > 1 ? '¡Tienes varios pedidos por atender!' : '¡Tienes un nuevo pedido!'}`,
              {
                ...{},
                className: 'toast-success-container toast-success-container-after',
                progressClassName: css({
                  background: 'black',
                }),
              },
            );
            var notification = new Notification(
              `${cant > 1 ? '¡Tienes varios pedidos por atender!' : '¡Tienes un nuevo pedido!'}`,
            );
            document.title = `(${cant}) Cirkula`;
          }
        }
      });
  };
  completeLogin = async (user, admin) => {
    // login user in the backend
    try {
      if (admin == null) {
        const loginResponse = await loginCompanyV3(user);
        var loginResult;
        var isOwner = false;
        console.log(loginResponse);
        if (loginResponse.errorCode == '200') {
          const { works, restaurants } = loginResponse;
          var listRest = [];
          if (restaurants.length > 0) {
            isOwner = true;
            listRest = [
              ...listRest,
              ...restaurants.map((x) => {
                return {
                  ...x,
                  isOwner,
                };
              }),
            ];
            loginResult = restaurants[0];
          }
          if (works.length > 0) {
            isOwner = false;
            listRest = [
              ...listRest,
              ...works.map((x) => {
                return {
                  ...x,
                  isOwner,
                };
              }),
            ];
            loginResult = works[0];
          }
        } else {
          sessionStorage.removeItem('index');
          sessionStorage.removeItem('prevIndex');
          this.props.history.push('/login');
          localStorage.removeItem('credentials');
          return false;
        }
        console.log(listRest);
        if (listRest.length > 1) {
          console.log(this.props);
          this.props.setLocals(listRest);
          this.props.setCompanyID('locals');
        } else {
          this.props.setLocals(null);
          let companyId = loginResult.id.toString();

          this.notifyMe(companyId);

          this.getNotifications(companyId);
          // set the ids in local storage
          this.props.setOwner(isOwner);

          this.props.setCompanyInfo(loginResult);
          this.props.setCompanyID(companyId);
        }
      } else {
        const userQuery = getUrlParam('user');

        const currentUser = userQuery ? await getUserProfile(userQuery) : null;
        this.setState({
          adminTabs:
            admin == 'admin'
              ? ['NEGOCIOS', 'CONSUMIDORES', 'EDITOR']
              : admin == 'marketing'
              ? ['CONSUMIDORES', 'EDITOR']
              : ['NEGOCIOS'],
          adminIndex: userQuery ? (admin === 'admin' ? 1 : 0) : this.state.adminIndex,
          preloadUser: currentUser || null,
        });
        this.props.setCompanyID(admin);
        this.props.setCompanyInfo({ admin: true });

        if (userQuery) {
        } else if (admin != 'marketing') await this.loadPersistantAdmin();
      }
      return true;
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };
  loadPersistantAdmin = async () => {
    const { setCurrentCompanyID, setCompanyInfo } = this.props;
    const storeQuery = getUrlParam('store');
    if (storeQuery) {
      setCurrentCompanyID(`${storeQuery}`);

      const response = await getCompany(storeQuery);
      setCompanyInfo(response);
      this.setState({ adminMode: 1, headerIndex: 0, headerSubIndex: 0 });
    } else {
      var admin = sessionStorage.getItem('admin');

      var parsedAdmin = admin != null ? JSON.parse(admin) : null;
      if (parsedAdmin != null) {
        setCurrentCompanyID(`${parsedAdmin.local.id}`);

        const response = await getCompany(parsedAdmin.local.response.id);
        setCompanyInfo(response);
        this.setState({ adminMode: 1, storedRestaurants: parsedAdmin.storedRestaurants });
      }
    }
  };
  handleNavigate = (index, subIndex, back) => {
    const { headerIndex, headerSubIndex, prev } = this.state;
    var previo = this.state.prev;
    if (back) {
      var pop = previo.pop();
      console.log(pop);
      this.setState({
        prev: previo,
        headerIndex: pop['headerIndex'],
        headerSubIndex: pop['headerSubIndex'],
      });
    } else {
      this.setState({
        prev: [...prev, { headerIndex, headerSubIndex }],
        headerIndex: index != null ? index : headerIndex,
        headerSubIndex: subIndex != null ? subIndex : headerSubIndex,
      });
    }
  };
  handleLoading = (val = true, percent = -1) => {
    this.setState({ loading: val, percent });
  };
  renderScreen(admin = false) {
    const { headerIndex, headerSubIndex } = this.state;
    const { companyInfo } = this.props;
    var screen = headerNames[headerIndex].sub[headerSubIndex];
    switch (screen) {
      case 'main':
        return <MainSales navigate={this.handleNavigate} />;
      case 'sales':
        return companyInfo != null ? (
          <Sales admin={admin} loading={this.handleLoading} navigate={this.handleNavigate} />
        ) : null;
      case 'orders':
        return <Orders admin={admin} loading={this.handleLoading} navigate={this.handleNavigate} />;
      case 'support':
        return <Support admin={admin} navigate={this.handleNavigate} />;
      case 'history':
        return (
          <History
            admin={admin}
            unlockPending={() => {}}
            loading={this.handleLoading}
            navigate={this.handleNavigate}
          />
        );
      case 'receipt':
        return <Receipt navigate={this.handleNavigate} />;
      case 'profile':
        return <Profile navigate={this.handleNavigate} />;
      case 'settings':
        return companyInfo != null ? (
          <Settings loading={this.handleLoading} admin={admin} navigate={this.handleNavigate} />
        ) : null;
      case 'stored':
        return companyInfo != null ? (
          <Stored loading={this.handleLoading} admin={admin} navigate={this.handleNavigate} />
        ) : null;
      case 'reports':
        return <Dashboard navigate={this.handleNavigate} />;
      case 'logout':
        return this.renderToLogout();
      default:
        return null;
    }
  }
  renderToLogout = (admin) => {
    return (
      <Logout
        onBack={
          admin
            ? () => {
                this.setState({ toLogout: false });
              }
            : null
        }
        history={this.props.history}
        navigate={this.handleNavigate}
      />
    );
  };
  renderAdminScreens = () => {
    const { adminMode, adminIndex, storedRestaurants, adminTabs } = this.state;

    switch (adminMode) {
      case 0:
        switch (adminTabs[adminIndex]) {
          case 'NEGOCIOS':
            return (
              <Restaurants
                persistAdmin={(data) => {
                  sessionStorage.setItem(
                    'admin',
                    JSON.stringify({
                      local: data,
                      storedRestaurants,
                    }),
                  );
                }}
                persistRestaurants={(lst) => {
                  this.setState({ storedRestaurants: { ...storedRestaurants, grouped: lst } });
                }}
                persistSelection={(obj) => {
                  this.setState({ storedRestaurants: { ...storedRestaurants, selected: obj } });
                }}
                selectedRestaurants={storedRestaurants != null ? storedRestaurants.selected : null}
                loadedRestaurants={storedRestaurants != null ? storedRestaurants.grouped : null}
                loading={this.handleLoading}
                navigate={this.handleNavigate}
                changeAdminMode={(val) => this.setState({ adminMode: val })}
              />
            );
            break;
          case 'CONSUMIDORES':
            return (
              <Consumers
                preloadUser={this.state.preloadUser}
                loading={this.handleLoading}
                navigate={this.handleNavigate}
                changeAdminMode={(val) => this.setState({ adminMode: val })}
              />
            );
            break;
          case 'EDITOR':
            return (
              <Editor
                loading={this.handleLoading}
                navigate={this.handleNavigate}
                changeAdminMode={(val) => this.setState({ adminMode: val })}
              />
            );
            break;
          default:
            return (
              <Consumers
                preloadUser={this.state.preloadUser}
                loading={this.handleLoading}
                navigate={this.handleNavigate}
                changeAdminMode={(val) => this.setState({ adminMode: val })}
              />
            );
            break;
        }

      case 1:
        return this.renderManager(false, true);
    }
  };
  renderAdmin = () => {
    const { headerIndex, headerSubIndex } = this.state;
    var screen = headerNamesAdmin[headerIndex].sub[headerSubIndex];
    switch (screen) {
      case 'restaurants':
        return (
          <Restaurants
            persistAdmin={(data) => {}}
            loading={this.handleLoading}
            navigate={this.handleNavigate}
          />
        );
      case 'admin':
        return <Admin loading={this.handleLoading} navigate={this.handleNavigate} />;
      default:
        return null;
    }
  };

  getNotifications = (companyID) => {
    console.log(companyID);
    firebase
      .firestore()
      .collection('notifications')
      .doc(companyID.toString().trim())
      .collection('list')
      .orderBy('date', 'desc')
      .onSnapshot((querySnapshot) => {
        console.log(querySnapshot.docs);
        let notifications = [];
        querySnapshot.docs.forEach((doc) => {
          let data = doc.data();
          console.log(data);
          notifications.push({ ...data, id: doc.id });
        });
        this.setState({ listNotifications: notifications });
      });
  };

  updateStateNotification = (item) => {
    const { companyID } = this.props;
    firebase
      .firestore()
      .collection('notifications')
      .doc(companyID.toString().trim())
      .collection('list')
      .doc(item.id)
      .update({ viewed: true })
      .then((docSales) => {
        //notifyUsers(companyID);
      })
      .catch((error) => {});
  };
  renderManager = (loadparam = true, administrador = false) => {
    const { headerIndex, headerSubIndex, barVisible, showMessageBox } = this.state;
    const { isOwner } = this.props;

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: wp(18),
            alignItems: 'strech',
            minWidth: 200,
            height: '100%',
          }}
        >
          <TabBar
            onTapLogo={() => {
              if (this.props.locals) {
                this.props.setOwner(false);
                this.props.setCompanyInfo(null);
                this.props.setCompanyID('locals');
              }
            }}
            hidden={administrador ? null : isOwner ? null : 4}
            onClick={(index) => {
              console.log('index =>', headerNames[index].sub);
              if (headerNames[index].title == 'Cerrar Sesión') {
                this.handleNavigate(index, 0);
                return;
              }
              if (headerNames[index].sub != null) {
                this.setState({
                  headerIndex: index,
                  headerSubIndex: 0,
                });

                var prev = sessionStorage.getItem('prevIndex');
                var prevItem = sessionStorage.getItem('index') || 0;
                prev = prev != null ? JSON.parse(prev) : [];
                prev.push(prevItem);
                sessionStorage.setItem('prevIndex', JSON.stringify(prev));
                sessionStorage.setItem('index', index);
                //window.location.replace(new_url);
              }
            }}
            onClickSubtitle={(tab, index) => {
              this.setState({
                showNotif: index == 1,
                headerIndex: tab,
                headerSubIndex: index == 1 ? 0 : 1,
              });
              setTimeout(
                () =>
                  this.setState({
                    headerIndex: tab,
                    headerSubIndex: index,
                  }),
                50,
              );
            }}
            icons={[
              sales_primary,
              history_primary,
              setup_primary,
              forks_primary,
              report_primary,
              contact_primary,
              logout_primary,
            ]}
            iconsWhite={[
              sales_white,
              history_white,
              setup_white,
              forks_white,
              report_white,
              contact_white,
              logout_white,
            ]}
            currentSub={headerSubIndex}
            current={headerIndex}
            tabs={headerNames}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '100%',
          }}
        >
          {this.props.companyID != '' ? this.renderScreen(administrador) : null}
        </div>
      </div>
    );
  };
  renderAdminManager = () => {
    const { toLogout, adminMode, adminIndex } = this.state;
    const { companyID } = this.props;
    return (
      <Layout
        header={() => {
          return adminMode == 0 ? (
            <AdminTop
              adminRole={companyID}
              active={adminIndex}
              onClick={(index) => {
                this.setState({
                  adminIndex: index,
                });
              }}
              onClickLogout={() => {
                this.setState({
                  toLogout: true,
                });
              }}
            />
          ) : null;
        }}
      >
        {this.renderAdminScreens()}

        {toLogout && this.renderToLogout(true)}
        {adminMode == 1 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 5,
              width: '100%',
              paddingTop: hp(1.2),
              paddingBottom: hp(1.2),
              backgroundColor: '#D87F7A',
            }}
          >
            <Spacer width={hp(4)} />

            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({
                  adminMode: 0,
                });
                sessionStorage.removeItem('admin');
              }}
            >
              <TextLato
                style={{
                  fontWeight: '500',
                  fontSize: hp(2),
                  textDecoration: 'underline',
                  color: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {'< Negocios'}
              </TextLato>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextLato style={{ fontWeight: '500', fontSize: hp(2), color: '#FFFFFF' }}>
                Administrador
              </TextLato>
            </div>
            <div style={{ opacity: 0 }}>
              <TextLato
                style={{
                  fontWeight: '500',
                  fontSize: hp(2),
                  textDecoration: 'underline',
                  color: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {'< Negocios'}
              </TextLato>
            </div>
            <Spacer width={hp(4)} />
          </div>
        )}
        <audio
          ref={(x) => (this.audio = x)}
          autoPlay
          id="audiotag1"
          src={Ring}
          preload="auto"
        ></audio>
      </Layout>
    );
  };
  renderLocalManager = () => {
    const { toLogout } = this.state;
    const { locals } = this.props;
    console.log(locals);
    return (
      <Layout
        header={() => {
          return (
            <AdminTop
              backgroundColor="#02A9AB"
              onClickLogout={() => {
                this.setState({
                  toLogout: true,
                });
              }}
            />
          );
        }}
      >
        <div
          className="scrollnone"
          style={{
            overflowY: 'auto',
            flexDirection: 'column',
            backgroundColor: '#F9F9F9',
            height: hp(83),
            width: wp(100),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              paddingTop: hp(2),
              paddingBottom: hp(2),
              display: 'flex',
              justifyContent: 'center',
              width: wp(100),
            }}
          >
            <div style={{ width: wp(100) - hp(14) }}>
              <TextLato
                style={{
                  fontWeight: '500',
                  fontSize: hp(2.6),
                  color: '#392E2E',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {locals[0].groupName}
              </TextLato>
            </div>
          </div>
          <Spacer height={hp(3)} />
          <div style={{ width: wp(100) - hp(14) }}>
            <List
              actions={{
                onClick: (data) => {
                  let companyId = data.id.toString();

                  this.notifyMe(companyId);
                  // set the ids in local storage
                  this.props.setOwner(data.isOwner);
                  this.props.setCompanyInfo(data);
                  this.props.setCompanyID(companyId);
                },
              }}
              labelEmpty={'No hay locales asignados'}
              topHeader
              header
              params={params}
              data={locals}
            />
          </div>
        </div>
        {toLogout && this.renderToLogout(true)}
      </Layout>
    );
  };

  render() {
    const isActive = this.state.loading || this.props.companyID === '';
    return (
      <>
        {notAdmin(this.props.companyID) ? (
          <Layout
            header={() => {
              return null;
            }}
          >
            {this.props.companyID != 'locals' ? this.renderManager() : this.renderLocalManager()}
            <ToastContainer style={styles} />
            <Sound
              url={sound_notification}
              playStatus={this.state.playSound}
              playFromPosition={300 /* in milliseconds */}
              volume={100}
              loop={false}
            />
            <audio
              ref={(x) => (this.audio = x)}
              id="audiotag1"
              src={Ring}
              autoPlay={false}
              preload="auto"
            ></audio>
          </Layout>
        ) : (
          this.renderAdminManager()
        )}
        {isActive && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              zIndex: 5,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.4)',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <FadeLoader css={override} size={130} color={'#40E0D0'} loading={true} />
            {this.state.percent >= 0 && (
              <TextLato
                style={{ fontSize: hp(3), color: '#40E0D0', fontWeight: '400', marginTop: hp(2) }}
              >
                {this.state.percent}%
              </TextLato>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseID: state.firebaseID,
    companyID: state.companyID,
    isOwner: state.isOwner,
    companyInfo: state.companyInfo,
    locals: state.locals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFirebaseID: (id) => {
      dispatch(setFirebaseID(id));
    },
    setCompanyID: (id) => {
      dispatch(setCompanyID(id));
    },
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
    setCurrentCompanyID: (value) => {
      dispatch(setCurrentCompanyID(value));
    },
    setOwner: (data) => {
      dispatch(setOwner(data));
    },
    setLocals: (data) => {
      dispatch(setLocals(data));
    },
    setUsers: (data) => {
      dispatch(setUsers(data));
    },
    setStores: (data) => {
      dispatch(setStores(data));
    },
    setNoDevStores: (data) => {
      dispatch(setNoDevStores(data));
    },
  };
};
const VerPedidosComponent = ({ textDecoration }) => {
  const [pedidos, setPedidos] = useState(0);
  const [showNotif, setShowNotif] = useState(true);
  const subtitleStyle = {
    color: '#FFFFFF',
    fontSize: wp(0.5) + hp(1) < 14 ? 14 : wp(0.5) + hp(1),
    fontWeight: '300',
    paddingLeft: hp(6.6),
  };
  useEffect(() => {
    _emitter.on('pedidos', (value) => {
      setPedidos(value);
    });
    _emitter.on('showNotif', (value) => {
      setShowNotif(value);
    });
    return () => {
      _emitter.removeAllListeners('pedidos');
      _emitter.removeAllListeners('showNotif');
    };
  }, []);
  return (
    <TextLato
      style={{
        ...subtitleStyle,
        position: 'absolute',
        zIndex: 1,
        textDecoration: textDecoration,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      Ver Pedidos
      {pedidos > 0 && showNotif && (
        <span
          style={{
            marginLeft: '5px',
            color: '#02a9ab',
            borderRadius: '50%',
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '2em',
            height: '2em',
            background: '#ffffff',
            fontWeight: '500',
          }}
        >
          {pedidos}
        </span>
      )}
    </TextLato>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
