import moment from 'moment';
import React, { useState } from 'react';
import Button from '../../../../components/Button';
import EyeIcon from '../../../../components/EyeIcon';
import List from '../../../../components/List';
import Modal from '../../../../components/Modal';
import Spacer from '../../../../components/Spacer';
import TextLato from '../../../../components/TextLato';
import { hp, wp } from '../../../../utils/responsive';
import PromoModal from './modal';

const promParams = [
  {
    title: 'Código',
    key: 'title',
    width: '20%',
    alignment: 'flex-start',
  },
  {
    title: '',
    width: '2%',
    render: () => {
      return <div style={{ width: '2%' }}></div>;
    },
  },
  {
    title: 'Usados',
    width: '18%',
    alignment: 'center',
    render: (data, style) => {
      return (
        <div
          style={{ width: '18%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <TextLato style={style}>{data.cantidad - data.stock}</TextLato>
        </div>
      );
    },
  },
  {
    title: 'Stock',
    key: 'cantidad',
    width: '18%',
    alignment: 'center',
  },
  {
    title: 'Fecha de vencimiento',
    key: 'expiration',
    width: '24%',
    alignment: 'flex-start',
  },
  {
    type: 'actions',
    title: '',
    render: (res, textStyle) => {
      const { data, actions } = res;
      return (
        <div
          onClick={() => {
            actions.onEdit(data);
          }}
          style={{
            width: '18%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextLato style={{ ...textStyle, color: '#2697FF', fontSize: textStyle.fontSize * 0.9 }}>
            {'Editar >'}
          </TextLato>
        </div>
      );
    },
  },
];

const PromoComponent = ({ promos, templateStores, loading, update }) => {
  const [eye, setEye] = useState(true);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentPromo, setCurrentPromo] = useState(null);
  const [promoForm, setPromoForm] = useState({
    name: '',
    stock: '',
    fecha: '',
    cantidad: '',
    description: '',
    amount: '',
    minAmount: '',
    stores: templateStores,
    category: 0,
  });
  const toggleEye = () => setEye(!eye);

  const onAddPress = () => {
    setModal(true);
    setEdit(false);
    setCurrentPromo(null);
    setPromoForm({
      name: '',
      stock: '',
      fecha: '',
      cantidad: '',
      description: '',
      amount: '',
      minAmount: '',
      stores: templateStores,
      category: 0,
    });
  };

  const matchStores = (lst) => {
    if (lst[0] == 'all') return templateStores;
    var obj = {};
    lst.forEach((x) => {
      obj[x] = true;
    });
    var newTemplate = templateStores.map((x) => ({ ...x, value: obj[x.id] != null }));
    console.log(newTemplate);
    return newTemplate;
  };

  const onEdit = (data) => {
    const {
      title,
      cantidad,
      stock,
      expirationDate,
      description,
      amount,
      minAmount,
      stores,
      category,
    } = data;
    setModal(true);
    setEdit(true);
    setCurrentPromo(data);
    setPromoForm({
      name: title,
      stock: cantidad - stock,
      fecha: moment(new Date(expirationDate)).format('DD/MM/YY'),
      cantidad: cantidad,
      description: description,
      amount: amount,
      minAmount: minAmount,
      stores: matchStores(stores.split(',')),
      category,
    });
  };
  const clean = () => {
    setModal(false);
    setEdit(false);
    setCurrentPromo(null);
    setPromoForm({
      name: '',
      stock: '',
      fecha: '',
      cantidad: '',
      description: '',
      amount: '',
      minAmount: '',
      stores: templateStores,
      category: 0,
    });
  };
  const renderList = () => {
    const list = promos.filter((x) => {
      return eye || moment(x.expiration, 'DD/MM/YY  HH:mm').isAfter(moment());
    });
    return (
      <List
        paddingVertical={promos != null ? (promos.length > 5 ? hp(1) : null) : null}
        maxHeight={promos != null ? (promos.length > 5 ? hp(25) : null) : null}
        actions={{
          onEdit,
        }}
        labelEmpty={'No hay cupones creados'}
        topHeader
        header
        params={promParams}
        data={list}
      />
    );
  };
  return (
    <>
      <div style={styles.headerContainer}>
        <div style={styles.titleContainer}>
          <TextLato style={styles.title}>Códigos Promocionales</TextLato>
          <Spacer width={wp(3)} />
          <EyeIcon onClick={toggleEye} active={eye} style={styles.eyeStyle} />
        </div>
        <Button
          onClick={onAddPress}
          backgroundColor={'#D87F7A'}
          second={true}
          textStyle={styles.buttonText}
          style={styles.button}
        >
          +
        </Button>
      </div>
      {renderList()}
      <PromoModal
        visible={modal}
        edit={edit}
        clean={clean}
        promoForm={promoForm}
        setPromoForm={setPromoForm}
        currentPromo={currentPromo}
        loading={loading}
        update={update}
      />
    </>
  );
};
const styles = {
  headerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: wp(1) + hp(0.9) < 17 ? 17 : wp(1) + hp(0.9),
    fontWeight: '500',
  },
  eyeStyle: {
    marginTop: hp(1),
    width: hp(4),
    height: hp(4),
  },
  buttonText: {
    fontSize: wp(1.2) + hp(0.9),
    fontWeight: '500',
  },
  button: {
    display: 'flex',
    padding: hp(1),
    paddingLeft: hp(2.2),
    paddingRight: hp(2.2),
    borderRadius: hp(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export default PromoComponent;
