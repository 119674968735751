import React from 'react';
import { connect } from 'react-redux';
import errorIcon from '../../assets/images/error-icon.png';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { setCompanyID, setCompanyInfo, setFirebaseID, setOwner } from '../../store/actions';
import { hp, wp } from '../../utils/responsive';
class ModalQuestion extends React.Component {
  render() {
    const {
      navigate,
      visible,
      message,
      okAction,
      cancelAction,
      okText,
      messages,
      maxWidth = 320,
      cancelText,
      alertMode,
      sizeIcon = hp(10),
    } = this.props;
    const textStyle = {
      fontSize: wp(0.9) + hp(1),
      fontWeight: '300',
      textAlign: 'center',
    };
    if (!visible) return null;
    else
      return (
        <Modal>
          <div
            style={{
              backgroundColor: 'white',
              width: wp(90),
              maxWidth,
              borderRadius: hp(1),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '70%',
              }}
            >
              <Spacer height={hp(5)} />
              {alertMode && <img src={errorIcon} style={{ width: sizeIcon, height: sizeIcon }} />}
              {alertMode && <Spacer height={hp(3)} />}
              {messages != null ? (
                messages.map((x) => <TextLato style={textStyle}>{x}</TextLato>)
              ) : (
                <TextLato style={textStyle}>{message}</TextLato>
              )}
              <Spacer height={hp(5)} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '40%',
                }}
              >
                <Button
                  onClick={okAction}
                  second={true}
                  textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                  style={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    paddingTop: hp(1.6),
                    paddingBottom: hp(1.6),
                    paddingLeft: wp(2),
                    paddingRight: wp(2),
                    borderRadius: hp(1.2),
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  backgroundColor={'#D87F7A'}
                >
                  {okText || 'Aceptar'}
                </Button>
                {cancelAction && <Spacer height={hp(2)} />}
                {cancelAction && (
                  <Button
                    onClick={cancelAction}
                    second={true}
                    textStyle={{ fontSize: wp(0.8) + hp(0.5), color: '#392E2E' }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      paddingTop: hp(1.6),
                      paddingBottom: hp(1.6),
                      paddingLeft: wp(2),
                      paddingRight: wp(2),
                      borderRadius: hp(1.2),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    backgroundColor={'#ffffff'}
                  >
                    {cancelText || 'Volver'}
                  </Button>
                )}
              </div>
            </div>
            <Spacer height={hp(5)} />
          </div>
        </Modal>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseID: state.firebaseID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFirebaseID: (id) => {
      dispatch(setFirebaseID(id));
    },
    setCompanyID: (id) => {
      dispatch(setCompanyID(id));
    },
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
    setOwner: (data) => {
      dispatch(setOwner(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalQuestion);
