import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import SliderMulti from '../../../components/SliderMulti';
import Spacer from '../../../components/Spacer';
import TextLato from '../../../components/TextLato';
import Toggler from '../../../components/Toggler';
import { hp, wp } from '../../../utils/responsive';
import { getHolidays, setupHoliday } from '../../../utils/services';
import arrow from '../../../assets/images/arrow.png';
import moment from 'moment';
import { convertFromMinutesToString, convertFromStringToMinutes } from '../../../utils/functions';

const optionsKeys = {
  11: 'Todos los días',
  10: 'Lunes a Viernes',
  9: 'Lunes a Sábado',
  12: 'Martes a Domingo',
  8: 'Sábado y Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};
const schTypesDays = {
  11: ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'],
  10: ['lunes', 'martes', 'miercoles', 'jueves', 'viernes'],
  9: ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'],
  12: ['domingo', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'],
  8: ['domingo', 'sabado'],
  1: ['lunes'],
  2: ['martes'],
  3: ['miercoles'],
  4: ['jueves'],
  5: ['viernes'],
  6: ['sabado'],
  7: ['domingo'],
};
const diasNormalized = {
  domingo: 'domingo',
  lunes: 'lunes',
  martes: 'martes',
  miércoles: 'miercoles',
  jueves: 'jueves',
  viernes: 'viernes',
  sábado: 'sabado',
};
const dias = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];

const HolidaysModal = ({ admin, companyInfo }) => {
  const [open, setOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [holidaysMap, setHolidaysMap] = useState({});
  const [error, setError] = useState('');
  const [regularScheduleMap, setRegularScheduleMap] = useState({});

  const toggleModal = () => setOpen(!open);

  const loadHolidays = async () => {
    const response = await getHolidays();
    if (response.errorCode == '200') {
      /** SE LE QUITO EL  &&ADMIN para que se muestren para todos */
      setHolidays(response.items);
      const { storeHoliday, storeSchedules } = companyInfo;
      setHolidaysMap(storeHoliday.reduce((prev, x) => ({ ...prev, [x.holiDayId]: x }), {}));
      var regularSch = {};
      for (var i = 0; i < storeSchedules.length; i++) {
        const { type, buySince, buyLimit, pickupLimit } = storeSchedules[i];
        regularSch = {
          ...regularSch,
          ...schTypesDays[`${type}`].reduce(
            (prev, x) => ({ ...prev, [x]: { buySince, buyLimit, pickupLimit } }),
            {},
          ),
        };
      }
      setRegularScheduleMap(regularSch);
    }
  };
  const setupOpen = async (request) => {
    const { schedule, errorCode, errorMessage } = await setupHoliday({ ...request, admin });
    const { holiDayId, buySince, buyLimit, pickupLimit } = schedule;
    if (errorCode === '200') {
      setHolidaysMap({
        ...holidaysMap,
        [holiDayId]: {
          ...schedule,
          buySince: convertFromMinutesToString(buySince),
          buyLimit: convertFromMinutesToString(buyLimit),
          pickupLimit: convertFromMinutesToString(pickupLimit),
        },
      });
    }
    setError(errorCode === '400' ? errorMessage : '');
  };
  const renderScheHoliday = (holiday) => {
    const { id: companyId } = companyInfo;
    const { id, dateHoliday, description } = holiday;
    const schedule = holidaysMap[id];
    const isOpen = schedule ? schedule.isOpen : true;
    const dateIndex = new Date(holiday.dateHoliday).getDay();
    const dateName = dias[dateIndex];
    const dateFormatted = moment(dateHoliday).format('DD/MM/YYYY');
    const { buySince, buyLimit, pickupLimit } = schedule ||
      regularScheduleMap[diasNormalized[dateName]] || {
        buySince: '00:00',
        buyLimit: '00:00',
        pickupLimit: '00:00',
      };
    const mainObject = {
      holiDayId: id,
      storeId: companyId,
      isOpen: isOpen,
      buySince: convertFromStringToMinutes(buySince),
      buyLimit: convertFromStringToMinutes(buyLimit),
      pickupLimit: convertFromStringToMinutes(pickupLimit),
    };
    return (
      <div style={styles.schedulesContainer}>
        <Spacer height={hp(1.5)} />
        <div style={styles.fill}>
          <div style={styles.fillCenter}>
            <Toggler
              initial={isOpen}
              handle={() => {
                setupOpen({ ...mainObject, isOpen: !isOpen });
              }}
              size={hp(3)}
            />
          </div>
          <div style={styles.headerStyles.description}>
            <div style={styles.descriptionBox}>
              <div style={styles.descriptionSections}>
                <Spacer width={wp(1)} />
                <div style={styles.descriptionColumns}>
                  <TextLato style={styles.descriptionTextStyle}>
                    {dateName} {dateFormatted}
                  </TextLato>
                  <TextLato style={styles.descriptionItalicTextStyle}>{description}</TextLato>
                </div>
                <Spacer width={wp(1)} />
                <div style={styles.spacer} />
                <img src={arrow} style={styles.arrowIcon} />
                <Spacer width={wp(1)} />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: 5 }}>
            <Spacer width={wp(2)} />
            <Card
              flex={1}
              style={{
                flex: 1,
                borderRadius: hp(1.2),
                paddingBottom: hp(0.2),
                paddingTop: hp(0.2),
              }}
              wrapper={{
                flexDirection: 'row',
                display: 'flex',

                justifyContent: 'space-around',
              }}
            >
              <SliderMulti
                disabled={!isOpen}
                handle={(val) => {
                  setupOpen({ ...mainObject, buySince: convertFromStringToMinutes(val) });
                }}
                value={buySince}
              />
              <SliderMulti
                disabled={!isOpen}
                handle={(val) =>
                  setupOpen({ ...mainObject, buyLimit: convertFromStringToMinutes(val) })
                }
                value={buyLimit}
              />
              <SliderMulti
                disabled={!isOpen}
                handle={(val) =>
                  setupOpen({ ...mainObject, pickupLimit: convertFromStringToMinutes(val) })
                }
                value={pickupLimit}
              />
            </Card>
            <Spacer width={wp(2)} />
          </div>
        </div>
      </div>
    );
  };

  const modalHolidays = () => {
    return (
      <Modal visible={open}>
        <div style={styles.modalContainer}>
          <Spacer height={hp(4)} />
          <TextLato style={styles.titleModal}>
            Agregar horarios para feriados o fechas especiales
          </TextLato>
          <Spacer height={hp(4)} />
          <div style={styles.contentModal}>
            <div style={styles.holidayContainer}>
              <div style={styles.headerStyles.container}>
                <div style={styles.headerStyles.open}>
                  <TextLato style={styles.headerStyles.text}>Abierto</TextLato>
                </div>
                <div style={styles.headerStyles.description} />
                <div style={styles.headerStyles.options}>
                  <Spacer width={wp(2)} />
                  <TextLato style={styles.headerStyles.text}>Comprar desde</TextLato>
                  <TextLato style={styles.headerStyles.text}>Comprar hasta</TextLato>
                  <TextLato style={styles.headerStyles.text}>Recoger hasta</TextLato>
                  <Spacer width={wp(2)} />
                </div>
              </div>
              <Spacer height={wp(0.4)} />
              <div style={styles.holidayListCOntainer}>
                <Spacer height={wp(0.4)} />
                {holidays.map(renderScheHoliday)}
                <Spacer height={wp(0.4)} />
              </div>
              <Spacer height={wp(1.5)} />
              <div style={styles.buttonsContainer}>
                <TextLato style={styles.errorText}>&zwnj;{error}</TextLato>
              </div>
              <Spacer height={wp(1.5)} />
              <div style={styles.buttonsContainer}>
                <div style={styles.spacer} />
                <Button
                  onClick={toggleModal}
                  second={true}
                  textStyle={styles.secondaryText}
                  style={styles.buttonContainer}
                  backgroundColor={'#ffffff'}
                >
                  Cerrar
                </Button>
                <Spacer width={wp(2)} />
                <Button
                  onClick={toggleModal}
                  textStyle={styles.primaryText}
                  style={styles.buttonContainer}
                  backgroundColor={'#D87F7A'}
                >
                  Guardar
                </Button>
                <div style={styles.spacer} />
              </div>
              <Spacer height={hp(4)} />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    loadHolidays();
  }, []);

  return (
    <>
      <div style={styles.spacer} />
      <div style={styles.openContainer}>
        <TextLato style={styles.openText} onClick={toggleModal}>
          + Agregar feriados
        </TextLato>
        <Spacer width={wp(2)} />
      </div>
      {modalHolidays()}
    </>
  );
};

const styles = {
  holidayListCOntainer: {
    width: '100%',
    maxHeight: hp(60),
    overflow: 'auto',
  },
  spacer: {
    flex: 1,
    display: 'flex',
  },
  openContainer: { display: 'flex' },
  openText: {
    fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
    fontWeight: '400',
    color: '#d87f7a',
    cursor: 'pointer',
  },
  rowStyle: {
    fontSize: wp(0.5) + hp(0.6) < 13 ? 13 : wp(0.5) + hp(0.6),
    fontWeight: '400',
  },
  modalContainer: {
    backgroundColor: 'white',
    width: wp(70),
    borderRadius: hp(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleModal: {
    fontSize: wp(0.2) + hp(2),
    fontWeight: '400',
  },
  contentModal: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  holidayContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    paddingLeft: wp(4.5),
    paddingRight: wp(4.5),
    paddingTop: wp(0.7),
    paddingBottom: wp(0.7),
    borderRadius: hp(0.8),
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryText: {
    fontSize: wp(0.8) + hp(0.5),
  },
  secondaryText: {
    fontSize: wp(0.8) + hp(0.5),
    color: '#392E2E',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  fill: {
    width: '100%',
    display: 'flex',
  },
  fillCenter: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  togglerContainer: {},
  headerStyles: {
    container: {
      width: '100%',
      display: 'flex',
    },
    text: {
      fontSize: wp(0.5) + hp(0.6) < 13 ? 13 : wp(0.5) + hp(0.6),
      fontWeight: '400',
      flex: 1,
      textAlign: 'center',
    },
    options: {
      display: 'flex',
      flex: 5,
      justifyContent: 'space-around',
      boxSizing: 'border-box',
    },
    open: {
      display: 'flex',
      flex: 1,
    },
    description: {
      display: 'flex',
      flex: 2,
    },
  },
  schedulesContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  descriptionBox: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflowY: 'visible',
    paddingBottom: hp(1.7),
    paddingTop: hp(1.7),
    borderRadius: hp(1.2),
    boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
    cursor: 'pointer',
  },
  descriptionSections: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 0,
  },
  descriptionColumns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 0,
  },
  descriptionTextStyle: {
    fontSize: wp(0.3) + hp(1.2),
  },
  descriptionItalicTextStyle: {
    fontSize: wp(0.2) + hp(1.6),
    fontStyle: 'italic',
    textAlign: 'center',
  },
  arrowIcon: {
    height: wp(0.8),
    opacity: 0.6,
  },
  errorText: {
    fontSize: wp(0.2) + hp(1.6),
    fontWeight: '400',
    color: '#d87f7a',
    flex: 1,
    textAlign: 'center',
  },
};
export default HolidaysModal;
