import 'bootstrap/less/bootstrap.less';
import moment from 'moment';
import React from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import deliveryIcon from '../../assets/images/delivery.png';
import downloadIcon from '../../assets/images/download.png';
import emptyState from '../../assets/images/empty-state.png';
import errorLight from '../../assets/images/error-icon-light.png';
import Star from '../../assets/images/fill-star.png';
import StarActive from '../../assets/images/star.png';
import BoxButton from '../../components/BoxButton';
import Button from '../../components/Button';
import Card from '../../components/Card';
import InputBox from '../../components/InputBox';
import List from '../../components/List';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { setCurrentReceipt } from '../../store/actions';
import { firebase } from '../../utils/firebase';
import { timeConverter } from '../../utils/functions';
import { hp, wp } from '../../utils/responsive';
import {
  cancelOrderPickupDate,
  getRatings,
  sendNotification,
  updateOrderPickupDate,
} from '../../utils/services';
import ModalQuestion from '../ModalQuestion';
import Receipt from '../Receipt';
import './index.css';

const paramsPending = [
  {
    key: 'companyHref',
    alignment: 'flex-start',
  },
  {
    key: 'products',
    alignment: 'center',
    render: (data, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <Spacer width={wp(0.5) + hp(0.2)} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            {data.map((item) => {
              return (
                <TextLato style={textStyle}>
                  ({item.count}) {item.name}
                </TextLato>
              );
            })}
          </div>
          <Spacer width={wp(0.5) + hp(0.2)} />
        </div>
      );
    },
  },
  {
    type: 'actions',
    alignment: 'center',
    render: (res, textStyle) => {
      const { data, actions } = res;

      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            width: wp(15),
          }}
        >
          <Button
            backgroundColor={'#D87F7A'}
            onClick={() => actions.onOk(data)}
            textStyle={{ fontSize: wp(0.9) + hp(0.6) }}
            style={{
              display: 'flex',
              flex: 1,
              paddingTop: hp(1.4),
              paddingBottom: hp(1.4),
              borderRadius: hp(0.8),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            SI
          </Button>
          <Spacer width={wp(1) + hp(1.3)} />
          <Button
            backgroundColor={'#fff'}
            onClick={() => actions.onCancel(data)}
            textStyle={{ fontSize: wp(0.9) + hp(0.6), color: '#392E2E' }}
            style={{
              display: 'flex',
              flex: 1,
              paddingTop: hp(1.4),
              paddingBottom: hp(1.4),
              borderRadius: hp(0.8),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            NO
          </Button>
          <Spacer width={wp(1) + hp(1)} />
        </div>
      );
    },
  },
];

class History extends React.Component {
  state = {
    data: [],
    pending: [],
    receipt: false,
    search: '',
    pendingPrev: false,
    pendingLoaded: false,
    ratings: {},
    users: {},
    counter: 0,
    currentPage: 1, //pagina actual
    maxItemsForPage: 10, //items maximos por pagina
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.getData();
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  downloadCSV = async () => {
    console.log('downloading');
    this.props.loading();
    const { companyInfo } = this.props;
    const { ratings } = this.state;
    var companyID = companyInfo.id.toString();
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';
    var paramsCSV = {
      headers: [
        'Codigo',
        'Fecha',
        'Pedido',
        'Fecha Entregado',
        'Entregado',
        'Total',
        'Productos',
        'Rating',
        'Delivery',
      ],
      keys: [
        'companyHref',
        'date',
        'created',
        'dateDelivered',
        'delivered',
        'final',
        'products',
        'rating',
        'delivery',
      ],
      data: [],
    };
    var orders = [];

    firebase
      .firestore()
      .collection(dev ? 'orders' : 'orders')
      .where('companyId', '==', companyID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //ordenes.push({...doc.data(),id:doc.id})
          if (doc.data().state == 'FAILED' || doc.data().state == 'PENDING') return;
          var final = parseFloat(doc.data().total).toFixed(2);
          var data = doc.data();
          var backendOrderId = doc.data().backendOrderId;
          orders.push({
            ...doc.data(),
            date: timeConverter(doc.data().date.seconds, 'date'),
            timestamp: moment(timeConverter(doc.data().date.seconds), 'DD/MM/YY - HH:mm').unix(),
            dateDelivered:
              doc.data().deliveredAt != null
                ? doc.data().status != null
                  ? doc.data().status == 'not delivered'
                    ? 'No Entregado'
                    : moment.unix(doc.data().deliveredAt.seconds).format('DD/MM/YYYY')
                  : moment.unix(doc.data().deliveredAt.seconds).format('DD/MM/YYYY')
                : '-',
            created: timeConverter(doc.data().date.seconds, 'hours'),
            delivered:
              doc.data().deliveredAt != null
                ? doc.data().status != null
                  ? doc.data().status == 'not delivered'
                    ? 'No Entregado'
                    : timeConverter(doc.data().deliveredAt.seconds, 'hours')
                  : timeConverter(doc.data().deliveredAt.seconds, 'hours')
                : '-',
            products: doc
              .data()
              .products.map((item) => item.name + '(' + item.count + ')')
              .toString(),
            final: final < 0 ? 0 : final,
            rating:
              ratings[backendOrderId] != null
                ? ratings[backendOrderId] != 0
                  ? ratings[backendOrderId]
                  : '-'
                : '-',
            delivery: data.delivery ? data.deliveryAmount : 0,
          });
        });
        console.log(orders);
        // setTable(ordenes)
        paramsCSV.data = orders.sort((a, b) => b.timestamp - a.timestamp);
        this.constructCSV(paramsCSV);
      });
  };
  constructCSV(objeto) {
    var csv = 'sep=|\n';
    var headers = objeto.headers;
    var sep = '';
    for (var i = 0; i < headers.length; i++) {
      csv += sep + headers[i];
      sep = '|';
    }

    var data = objeto.data;
    csv += '\n';
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      sep = '';
      for (var j = 0; j < objeto.keys.length; j++) {
        csv += sep + row[objeto.keys[j]];
        sep = '|';
      }
      csv += '\n';
    }

    //$("#csv").text(csv);

    window.URL = window.URL || window.webkiURL;
    var blob = new Blob([csv]);
    var blobURL = window.URL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.href = blobURL;
    tempLink.setAttribute('download', 'historial.csv');
    this.props.loading(false);
    tempLink.click();
    //location.href = blobURL
  }

  getData = async () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';

    var $this = this;
    console.log(companyID);
    var counter = 0;
    if (companyID != null) {
      firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        .where('companyId', '==', companyID)
        .orderBy('date', 'desc')
        .onSnapshot(async (querySnapshot) => {
          const data = [];
          const pending = [];
          //const dataTemp = [];
          counter = 0;
          querySnapshot.docs.forEach((doc) => {
            if (doc.data().state == 'FAILED' || doc.data().state == 'PENDING') return;
            //let item = { ...doc.data(), id: doc.id }
            let temp = doc.data();
            //dataTemp.push(item)
            const originalDate = temp.date;

            const hours = timeConverter(temp.date.seconds, 'hours');
            const date = timeConverter(temp.date.seconds, 'date');
            const fulldate = `${date}\n${hours}`;
            temp.date = hours;
            temp.timeBought = hours;
            temp.fulldate = fulldate;
            temp.justDate = date;
            temp.time = hours;
            temp.delivered = null;
            //6:40:37 PM)
            if (temp.deliveredAt != null) {
              const hoursDelivered = timeConverter(temp.deliveredAt.seconds, 'hours');
              const dateDelivered = timeConverter(temp.deliveredAt.seconds, 'date');
              const fulldateDelivered = `${dateDelivered}\n${hoursDelivered}`;
              temp.date = hoursDelivered;
              temp.delivered = fulldateDelivered;
            }
            const unixDate = new Date(originalDate.seconds * 1000);

            const now = new Date(),
              nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
              orderDateOnly = new Date(
                unixDate.getFullYear(),
                unixDate.getMonth(),
                unixDate.getDate(),
                0,
                0,
                0,
              );

            if (nowDateOnly.getTime() === orderDateOnly.getTime()) {
              return;
            }
            if (companyID != null) {
              if (
                doc.data().companyId != null &&
                companyID.trim() === doc.data().companyId.trim()
              ) {
                if (!(temp.visible != null)) {
                  if (temp.deliveredAt == null) counter++;

                  data.push({
                    ...temp,
                    id: doc.id,
                    key: doc.id,
                    unixDelivered: moment(
                      temp.deliveredAt != null ? temp.delivered : temp.date,
                      'DD/MM/YYYY HH:mm',
                    ).unix(),
                  }); /* 
                  } else {
                    pending.push({
                      ...temp,
                      id: doc.id,
                      key: doc.id,
                      index: counter,
                    });
                    
                  } */
                } else {
                  if (temp.visible !== false) {
                    if (temp.deliveredAt != null) {
                      data.push({
                        ...temp,
                        id: doc.id,
                        key: doc.id,
                        unixDelivered: moment(temp.delivered, 'DD/MM/YYYY HH:mm').unix(),
                      });
                    } else {
                      pending.push({ ...temp, id: doc.id, key: doc.id });
                    }
                  }
                }
              }
            }
          });
          var users = {}; /* await searchUsersIds(data.map((x) => parseInt(x.userId))); */
          console.log('data history', data);
          //console.log("data history 2 ", dataTemp);
          $this.setState(
            {
              //pendingLoaded: true,
              users,
              dataTemp: data,
              /*data: data.sort((a, b) => {
              if (a.companyHref < b.companyHref) {
                return 1;
              }
              if (a.companyHref > b.companyHref) {
                return -1;
              }
              // a must be equal to b
              return 0;
            }),
            counter: counter, //,
            pending,
            pendingPrev: !this.state.pendingLoaded ? pending.length > 0 : this.state.pendingPrev,*/
            },
            () => this.setItemsForPage(),
          );
        });
      try {
        /*  var ratings = await getRatings();
        if (ratings.items != null) {
          var rate = ratings.items.reduce(
            (prev, x) => ({ ...prev, [x.orderNumber]: x.rating }),
            {},
          );
          $this.setState({
            ratings: rate,
          });
        } */
      } catch (error) {}
    }
  };

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ currentPage: pageNumber }, () => this.setItemsForPage());
  };

  setItemsForPage = () => {
    const { dataTemp, currentPage, maxItemsForPage } = this.state;
    let indexFinal = parseInt(currentPage) * parseInt(maxItemsForPage);
    let indexInit = indexFinal - parseInt(maxItemsForPage);
    if (indexFinal > dataTemp.length) {
      indexFinal = dataTemp.length;
    }
    let dataHistory = [];
    let counter = 0;
    for (let i = indexInit; i < indexFinal; i++) {
      if (!dataTemp[i]) {
        return;
      } else {
        if (!(dataTemp[i].visible != null)) {
          if (dataTemp[i].deliveredAt == null) counter++;
        }
        dataHistory.push(dataTemp[i]);
      }
    }
    this.setState({
      pendingLoaded: true,
      data: dataHistory.sort((a, b) => {
        if (a.companyHref < b.companyHref) {
          return 1;
        }
        if (a.companyHref > b.companyHref) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }),
      counter: counter,
    });
  };

  handleRow = (row) => {
    const { navigate, setCurrentReceipt } = this.props;
    /* if (row.deliveredAt == null) return; */
    setCurrentReceipt(row);
    this.setState({ receipt: true });
  };
  updateButtons = async (dataRes, success) => {
    this.setState({ pendingRow: null });
    const { companyInfo } = this.props;
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';

    const userId = dataRes != null ? dataRes.companyId : 0;
    const id = dataRes != null ? dataRes.id : 0;

    const now = new Date();

    // temp.type = false;
    const deliveredAt = firebase.firestore.Timestamp.fromDate(now);

    const orderRef = firebase.firestore().collection('orders').doc(id);
    const updateResult = await orderRef.update({
      deliveredAt,
      status: success ? 'delivered' : 'not delivered',
      employeId: userId,
    });
    const order = await orderRef.get();
    const backendOrderId = order.data().backendOrderId;
    if (success)
      if (backendOrderId != null) {
        if (backendOrderId > 0) {
          var updateBackendResult = await updateOrderPickupDate(backendOrderId);
        }
      }
  };
  openModalConfirm = (dataRes, type) => {
    this.setState({
      pendingRow: dataRes,
      modalType: type ? 'SI' : 'NO',
      modalVisible: true,
    });
  };
  renderStars = () => {
    const { currentReceipt } = this.props;
    const { ratings } = this.state;
    const val = ratings[currentReceipt.backendOrderId] || 0;
    var filled = [0, 1, 2, 3, 4];
    return (
      <>
        {ratings[currentReceipt.backendOrderId] != null && val > 0
          ? filled.map((x) => (
              <img src={x < val ? StarActive : Star} style={{ width: hp(3.7), height: hp(3.7) }} />
            ))
          : null}
      </>
    );
  };

  sendNotification = async () => {
    const { currentReceipt } = this.props;
    console.log(currentReceipt);
    var ids = [];
    ids.push(currentReceipt.userId);
    console.log(ids);
    var send = await sendNotification(
      ids,
      'Pedido cancelado',
      'Lo sentimos tu pedido ha sido cancelado revisar tu correo para mayor detalle.',
    );
    if (send == 200) {
      alert('Notificación enviada.');
    } else {
      alert('Ocurrió un problema al enviar la notificación.');
    }
  };

  uncanceled = async () => {
    try {
      const { currentReceipt } = this.props;
      const { companyInfo } = this.props;
      const { status } = companyInfo || { status: 'live' };
      var dev = status == 'dev';
      const id = currentReceipt.id;

      if (this.props.loading != null) {
        this.props.loading(true);
      }
      const orderRef = firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        .doc(id);
      await orderRef.update({
        canceled: false,
      });

      if (this.props.loading != null) {
        this.props.loading(false);
      }

      this.setState({
        receipt: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  canceled = async () => {
    try {
      const { currentReceipt, navigate } = this.props;
      const { companyInfo } = this.props;
      const companyID = companyInfo.id;
      const { status } = companyInfo || { status: 'live' };
      var dev = status == 'dev';
      const userId = companyID;
      const id = currentReceipt.id;

      const now = new Date();

      if (this.props.loading != null) {
        this.props.loading(true);
      }
      const deliveredAt = firebase.firestore.Timestamp.fromDate(now);
      const orderRef = firebase
        .firestore()
        .collection(dev ? 'orders' : 'orders')
        .doc(id);
      const updateResult = await orderRef.update({
        canceled: true,
        deliveredAt,
        status: 'not delivered',
        employeId: userId,
      });

      const order = await orderRef.get();
      const backendOrderId = order.data().backendOrderId;

      if (backendOrderId != null) {
        if (backendOrderId > 0) {
          var updateBackendResult = await cancelOrderPickupDate(backendOrderId);
        }
      }
      if (this.props.loading != null) {
        this.props.loading(false);
      }

      this.setState({
        receipt: false,
      });
    } catch (error) {
      alert(error);
    }
  };
  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const { navigate } = this.props;
    var { pending, search, users, counter, dataTemp, data } = this.state;
    let lenghtItems = dataTemp ? dataTemp.length : 0;
    let lenghtItemsData = data ? data.length : 0;
    var data =
      search != ''
        ? this.state.data.filter(
            (x) => x.companyHref.toUpperCase().indexOf(search.toUpperCase()) != -1,
          )
        : this.state.data;
    const params = [
      {
        key: 'companyHref',
        alignment: 'flex-start',
        width: '15%',
      },
      {
        alignment: 'center',
        render: (data, textStyle) => {
          const { products: info } = data;
          return (
            <div
              onClick={() => this.handleRow(data)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <Spacer width={wp(0.5) + hp(0.2)} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: hp(1),
                  flex: 1,
                }}
              >
                {info.map((item) => {
                  return (
                    <TextLato style={textStyle}>
                      ({item.count}) {item.name}
                    </TextLato>
                  );
                })}
              </div>
              <Spacer width={wp(0.5) + hp(0.2)} />
            </div>
          );
        },
      },
      {
        width: '20%',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div onClick={() => this.handleRow(data)} style={{ width: '20%' }}>
              <TextLato style={textStyle}>{data.canceled ? 'CANCELADO' : data.justDate}</TextLato>
            </div>
          );
        },
      },
      {
        type: 'actions',
        alignment: 'center',
        render: (res, textStyle) => {
          const { data, actions } = res;

          return (
            <div
              style={{
                display: 'flex',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'row',
                width: wp(15),
                zIndex: 2,
              }}
            >
              {data.deliveredAt != null ? null : (
                <>
                  <Button
                    backgroundColor={'#02A9AB'}
                    onClick={() => actions.onOk(data)}
                    textStyle={{ fontSize: wp(0.9) + hp(0.6) }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      paddingTop: hp(1.4),
                      paddingBottom: hp(1.4),
                      borderRadius: hp(0.8),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    SI
                  </Button>
                  <Spacer width={wp(1) + hp(1.3)} />
                  <Button
                    backgroundColor={'#fff'}
                    onClick={() => actions.onCancel(data)}
                    textStyle={{ fontSize: wp(0.9) + hp(0.6), color: '#392E2E' }}
                    style={{
                      display: 'flex',
                      flex: 1,
                      paddingTop: hp(1.4),
                      paddingBottom: hp(1.4),
                      borderRadius: hp(0.8),
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    NO
                  </Button>
                  <Spacer width={wp(1) + hp(1)} />
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'delivery',
        alignment: 'center',
        render: (data, textStyle) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: wp(5),
              }}
            >
              {data ? <img src={deliveryIcon} width={wp(0.8)} height={wp(1.8)} /> : null}
            </div>
          );
        },
      },
    ];
    return (
      <div
        className="scrollnone"
        style={{
          overflowY: 'auto',
          width: '100%',
          minWidth: 600,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {this.state.pendingPrev ? (
              pending.length > 0 ? (
                <div
                  style={{
                    flexDirection: 'column',
                    paddingLeft: hp(10),
                    paddingTop: hp(5),
                  }}
                >
                  <TextLato style={textStyle}>¿Los siguientes pedidos fueron entregados?</TextLato>
                  <Spacer height={hp(3)} />
                  <div style={{ width: '93%' }}>
                    {pending.length == 0 ? (
                      <BoxButton>No tienes pedidos antiguos</BoxButton>
                    ) : (
                      <List
                        actions={{
                          onOk: (pdata) => this.openModalConfirm(pdata, true),
                          onCancel: (pdata) => this.openModalConfirm(pdata, false),
                        }}
                        params={paramsPending}
                        data={pending}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div style={{ width: '93%', display: 'flex', flex: 1 }}>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spacer height={hp(10)} />
                    <img src={emptyState} height={hp(30)} />
                    <Spacer height={hp(2)} />
                    <TextLato
                      style={{
                        fontSize: textStyle.fontSize,
                        fontWeight: '300',
                        textAlign: 'center',
                      }}
                    >
                      {'No cuentas con pedidos pendientes por confirmar...'}
                    </TextLato>
                    <Spacer height={hp(5)} />
                    <Button
                      backgroundColor={'#D87F7A'}
                      onClick={() =>
                        this.setState({ pendingPrev: false }, () => {
                          if (this.props.unlockPending) {
                            this.props.unlockPending();
                          }
                        })
                      }
                      textStyle={{ fontSize: hp(2.5) }}
                      style={{
                        borderRadius: hp(1),
                        paddingTop: hp(1.8),
                        paddingBottom: hp(1.8),
                        paddingLeft: hp(8),
                        paddingRight: hp(8),
                      }}
                    >
                      Volver
                    </Button>
                    <Spacer height={hp(12)} />
                  </div>
                </div>
              )
            ) : this.state.receipt ? (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Spacer height={hp(5)} />
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: 350 + hp(10),
                    flexDirection: 'row',
                  }}
                >
                  <Spacer width={hp(10)} />
                  <TextLato
                    onClick={() => {
                      this.setState({
                        receipt: false,
                      });
                    }}
                    style={{
                      color: '#02A9AB',
                      fontSize: wp(0.6) + hp(1.2),
                      fontWeight: '400',
                      lineHeight: 1.6,
                      borderBottom: '1px solid #02A9AB',
                    }}
                  >
                    {'<Volver a historial'}{' '}
                  </TextLato>
                  <Spacer width={hp(9)} />
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {this.renderStars()}
                    </div>
                    {this.props.admin && (
                      <>
                        <Spacer height={hp(1.5)} />
                        <div>
                          <Button
                            backgroundColor={'#D87F7A'}
                            onClick={
                              this.props.currentReceipt.canceled ? this.uncanceled : this.canceled
                            }
                            textStyle={{ fontSize: hp(2.3) }}
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: hp(1),
                              paddingTop: hp(1.5),
                              paddingBottom: hp(1.5),
                            }}
                          >
                            {this.props.currentReceipt.canceled ? 'Descancelar' : 'Cancelar'} pedido
                          </Button>
                        </div>
                      </>
                    )}
                    {this.props.admin && this.props.currentReceipt.canceled && (
                      <>
                        <Spacer height={hp(1.5)} />
                        <div>
                          <Button
                            backgroundColor={'#D87F7A'}
                            onClick={() => this.sendNotification()}
                            textStyle={{ fontSize: hp(2.3) }}
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: hp(1),
                              paddingTop: hp(1.5),
                              paddingBottom: hp(1.5),
                            }}
                          >
                            Enviar Notificación
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <Spacer height={hp(2)} />
                <Receipt
                  admin={this.props.admin}
                  onRelease={() =>
                    this.setState({
                      receipt: false,
                    })
                  }
                  loading={this.props.loading}
                />
              </div>
            ) : (
              <div
                style={{
                  flexDirection: 'column',
                  paddingLeft: hp(10),
                  paddingTop: hp(5),
                }}
              >
                <TextLato style={textStyle}>Historial</TextLato>
                <Spacer height={hp(3)} />
                <div
                  style={{
                    width: '93%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', width: 300 }}>
                    <InputBox
                      search={true}
                      placeholder="Buscar pedido"
                      value={search}
                      onChangeText={(str) => {
                        this.setState({
                          search: str,
                        });
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flex: 1 }} />
                  <div style={{ cursor: 'pointer' }} onClick={() => this.downloadCSV()}>
                    <Card paddingHorizontal={hp(3)} style={{ flex: null }}>
                      <div>
                        <img
                          src={downloadIcon}
                          style={{
                            width: wp(1.4) + hp(1),
                            height: wp(1.4) + hp(1),
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
                <Spacer height={hp(3)} />
                <div style={{ width: '93%', paddingBottom: wp(0.8) }}>
                  {counter > 0 && (
                    <div
                      style={{
                        backgroundColor: '#D87F7A',
                        paddingTop: wp(0.8),
                        paddingBottom: wp(0.8),
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: wp(0.3),
                        marginBottom: wp(2),
                      }}
                    >
                      <Spacer width={wp(1.7)} />
                      <img src={errorLight} height={wp(1.8)} />
                      <Spacer width={wp(1.3)} />
                      <TextLato
                        style={{
                          fontSize: textStyle.fontSize * 0.7,
                          fontWeight: '400',
                          textAlign: 'left',
                          color: 'white',
                        }}
                      >
                        ¡Confirma el estado de tus pedidos pendientes!
                      </TextLato>
                    </div>
                  )}
                  {data.length == 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spacer height={hp(10)} />
                      <img src={emptyState} height={hp(30)} />
                      <Spacer height={hp(2)} />
                      <TextLato
                        style={{
                          fontSize: textStyle.fontSize,
                          fontWeight: '300',
                          textAlign: 'center',
                        }}
                      >
                        No cuentas con historial de pedidos aún...
                      </TextLato>
                      <Spacer height={hp(12)} />
                    </div>
                  ) : (
                    <List
                      maxHeight={hp(65)}
                      handleRow={this.handleRow}
                      actioninPartialRow
                      params={params}
                      data={data}
                      actions={{
                        onOk: (pdata) => this.openModalConfirm(pdata, true),
                        onCancel: (pdata) => this.openModalConfirm(pdata, false),
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: '93%',
                    paddingBottom: wp(0.8),
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Pagination
                    activePage={this.state.currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={lenghtItems}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
            <ModalQuestion
              visible={this.state.modalVisible}
              message={`¿Confirmas que “${this.state.modalType}” lo entregaste?`}
              okAction={() => {
                var response = this.state.modalType == 'SI';
                this.setState({ modalVisible: false, modalType: '' });
                this.updateButtons(this.state.pendingRow, response);
              }}
              cancelAction={() => {
                this.setState({ modalVisible: false, modalType: '', pendingRow: null });
              }}
              okText={'Confirmar'}
              cancelText={'Volver'}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.companyID,
    companyInfo: state.companyInfo,
    currentReceipt: state.currentReceipt,
    users: state.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentReceipt: (value) => {
      dispatch(setCurrentReceipt(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
