import { useEffect, useState } from "react"
import { storage } from "src/utils/firebase"

type DocumentType = 'user' | 'business'

export const useTermsStorage = (docType: DocumentType) => {
  const [document, setDocument] = useState<any | null>(null)

  // GET STORAGE TEXT FILE FROM BLOG
  const getHTMLContent = async () => {
    const res = await fetch(`https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/terms%2F${docType}.txt?alt=media&token=563ffb2c-c535-4f75-a6d3-c551d8278759`)
    const content = await res.text()
    // const info = content.split("figure").join("div")
    setDocument(content)
  }

  const updateFile = (content: string, onFinish: () => void) => {
    const file = new Blob([content], { type: 'text/plain' });
    const uploadTask = storage.ref(`terms/${docType}.txt`).put(file, {
      contentType: 'text/plain',
    });
    uploadTask.on(
      'state_changed',
      () => { },
      () => { },
      () => { onFinish() },
    );
  }

  useEffect(() => {
    getHTMLContent()
  }, [])

  return { document, updateFile }
}