import React from 'react';
import { connect } from 'react-redux';
import Card from '../../../components/Card';
import Spacer from '../../../components/Spacer';
import TextLato from '../../../components/TextLato';
import { setCurrentCompanyID } from '../../../store/actions';
import { firebase } from '../../../utils/firebase';
import { timeConverter } from '../../../utils/functions';
import { hp, wp } from '../../../utils/responsive';
import Receipt from '../../Receipt';
class OrdersUser extends React.Component {
  state = {
    dataDelivered: [],
    dataNext: [],
    currentOrder: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.getProximos();
    this.getEntregados();
    // this.groupRestaurants(storeList)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  transformOrder = (data) => {
    const hours = timeConverter(data.date.seconds, 'hours');
    const date = timeConverter(data.date.seconds, 'date');
    // store the original date
    data.originalDate = data.date;
    const fulldate = `${date} ${hours}`;
    data.date = hours;
    data.fulldate = fulldate;
    data.datetime = date;
    data.delivered = null;
    data.products = data.products.map((x) => {
      return {
        ...x,
        expireLabel: x.expireDate != null ? timeConverter(x.expireDate.seconds, 'date') : '',
      };
    });
    if (data.deliveredAt != null) {
      const hoursDelivered = timeConverter(data.deliveredAt.seconds, 'hours');
      const dateDelivered = timeConverter(data.deliveredAt.seconds, 'date');
      const fulldateDelivered = `${dateDelivered} ${hoursDelivered}`;
      data.date = hoursDelivered;
      data.delivered = fulldateDelivered;
    }

    return data;
  };
  getEntregados = () => {
    const { currentUser } = this.props;
    var dev = false; //status == 'dev';
    var $this = this;
    firebase
      .firestore()
      .collection(dev ? 'orders' : 'orders')
      .where('userId', '==', currentUser.id.toString())
      .orderBy('date', 'desc')
      .onSnapshot((querySnapshot) => {
        const dataDelivered = [];

        querySnapshot.docs.forEach((doc) => {
          if (doc.data().state === 'FAILED' || doc.data().state == 'PENDING') return;
          let temp = $this.transformOrder(doc.data());
          // don't add anything if the item doesn't have a date or if it's date is from a past day
          if (!temp.originalDate) {
            return;
          }

          const unixDate = new Date(temp.originalDate.seconds * 1000);

          const now = new Date(),
            nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
            orderDateOnly = new Date(
              unixDate.getFullYear(),
              unixDate.getMonth(),
              unixDate.getDate(),
              0,
              0,
              0,
            );

          /* if (nowDateOnly.getTime() !== orderDateOnly.getTime()) {
            return;
          } */

          if (!(temp.visible != null)) {
            if (temp.deliveredAt != null) {
              dataDelivered.push({ ...temp, id: doc.id, key: doc.id });
            }
          } else {
            if (temp.visible !== false) {
              if (temp.deliveredAt != null) {
                dataDelivered.push({ ...temp, id: doc.id, key: doc.id });
              }
            }
          }
        });
        console.log('data delivery => ', dataDelivered);
        //dataDelivered.sort((a, b) => b.datetime - a.datetime)
        //alert(JSON.stringify(dataDelivered[0]));
        $this.setState({ dataDelivered });
      });
  };

  getProximos = () => {
    const { currentUser } = this.props;
    var dev = false; // status == "dev";
    var $this = this;
    firebase
      .firestore()
      .collection(dev ? 'orders' : 'orders')
      .where('userId', '==', currentUser.id.toString())
      //.orderBy("date", "desc")
      //.where('companyId', '==', companyID)
      .onSnapshot((querySnapshot) => {
        const dataNext = [];

        querySnapshot.docs.forEach((doc) => {
          if (doc.data().state === 'FAILED' || doc.data().state == 'PENDING') return;
          let temp = $this.transformOrder(doc.data());

          // don't add anything if the item doesn't have a date or if it's date is from a past day
          if (!temp.originalDate) {
            return;
          }

          const unixDate = new Date(temp.originalDate.seconds * 1000);

          const now = new Date(),
            nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
            orderDateOnly = new Date(
              unixDate.getFullYear(),
              unixDate.getMonth(),
              unixDate.getDate(),
              0,
              0,
              0,
            );

          /*if (nowDateOnly.getTime() !== orderDateOnly.getTime()) {
            return;
          }*/

          if (!(temp.visible != null)) {
            if (!(temp.deliveredAt != null)) {
              dataNext.push({ ...temp, id: doc.id, key: doc.id });
            }
          } else {
            if (temp.visible !== false) {
              if (!(temp.deliveredAt != null)) {
                dataNext.push({ ...temp, id: doc.id, key: doc.id });
              }
            }
          }
        });
        $this.setState({ dataNext });
      });
  };
  renderTicket = (data, back) => {
    const {
      companyName,
      datetime,
      products,
      total,
      deliveryAmount,
      hasServiceFee = false,
      ponderatedDiscount,
    } = data;
    const { count, name } = products[0];
    const serviceValue = hasServiceFee ? 0.75 : 0;
    var subtotal = (total || 0) - (ponderatedDiscount || 0) + (deliveryAmount || 0) + serviceValue;
    var finaltotal = `S/ ${parseFloat(subtotal < 0 ? 0 : subtotal).toFixed(2)}`;
    const nameStyle = {
      fontSize: hp(2),
      color: back ? '#FFFFFF' : '#000000',
      fontWeight: '400',
    };
    const priceStyle = {
      fontSize: hp(4),
      color: back ? '#FFFFFF' : '#000000',
      fontWeight: '400',
    };
    return (
      <div style={{ paddingTop: hp(2), width: '95%', paddingBottom: hp(2) }}>
        <Card
          onClick={() => {
            this.setState({
              currentOrder: data,
            });
          }}
          style={{
            display: 'flex',
            cursor: 'pointer',
            width: '100%',
            paddingTop: hp(1.5),
            paddingBottom: hp(1.5),
            backgroundColor: back ? '#D87F7A' : '#FFFFFF',
          }}
          wrapper={{ flexDirection: 'row' }}
        >
          <div style={{ display: 'flex', flex: 1, paddingLeft: hp(2), flexDirection: 'column' }}>
            <TextLato style={nameStyle}>{companyName}</TextLato>
            <TextLato style={nameStyle}>{datetime}</TextLato>
            <TextLato style={nameStyle}>
              {count} {name}
            </TextLato>
          </div>
          <div style={{ display: 'flex', paddingRight: hp(3), alignItems: 'center' }}>
            <TextLato style={priceStyle}>{finaltotal}</TextLato>
          </div>
        </Card>
      </div>
    );
  };
  render() {
    const { currentUser } = this.props;
    const { dataNext, dataDelivered, currentOrder } = this.state;
    const titleStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '500',
    };
    const subtitleStyle = {
      fontSize: wp(0.6) + hp(0.7) < 15 ? 15 : wp(0.6) + hp(0.7),
      fontWeight: '400',
      textAlign: 'flex-start',
      marginTop: hp(1),
      marginBottom: hp(1),
    };
    return (
      <div
        style={{
          width: '100%',
          minWidth: 600,
          justifyContent: 'stretch',
          backgroundColor: 'transparent',
          display: 'flex',
        }}
      >
        {currentOrder != null ? (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Spacer height={hp(3)} />
            <div
              onClick={() => {
                this.setState({
                  currentOrder: null,
                });
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Spacer width={hp(10)} />
              <TextLato
                style={{
                  color: '#02A9AB',
                  fontSize: wp(0.6) + hp(1.2),
                  fontWeight: '400',
                  lineHeight: 1.6,
                }}
              >
                {'< Pedidos'}
              </TextLato>
              <div style={{ display: 'flex', flex: 1 }} />
            </div>
            <Spacer height={hp(2)} />
            <div
              className="scrollbar-transparent"
              style={{
                height: hp(80),
                overflowY: 'auto',
                scrollbarColor: 'transparent',
              }}
            >
              <Receipt
                full={true}
                localReceipt={currentOrder}
                onRelease={() => {}}
                loading={() => {}}
              />
            </div>
          </div>
        ) : (
          <div
            className="scrollnone"
            style={{
              overflowY: 'auto',
              width: '60%',
              paddingLeft: hp(6),
              paddingRight: hp(6),
              flexDirection: 'column',
              backgroundColor: 'transparent',
              height: hp(83),
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Spacer height={hp(5)} />
            <TextLato style={titleStyle}>Pedidos</TextLato>
            <Spacer height={hp(3)} />
            <div
              style={{
                height: hp(65),
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Card wrapper={{ alignItems: 'flex-start' }}>
                <div
                  className="scrollbar"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: hp(60),
                    paddingLeft: hp(3),
                    width: '92%',
                    overflowY: 'auto',
                  }}
                >
                  <TextLato style={titleStyle}>Próximos</TextLato>
                  {dataNext.length > 0 ? (
                    dataNext.map((item) => this.renderTicket(item, true))
                  ) : (
                    <TextLato style={subtitleStyle}>No hay pedidos próximos</TextLato>
                  )}
                  <TextLato style={titleStyle}>Anteriores</TextLato>
                  {dataDelivered.length > 0 ? (
                    dataDelivered.map((item) => this.renderTicket(item, false))
                  ) : (
                    <TextLato style={subtitleStyle}>No hay pedidos Anteriores</TextLato>
                  )}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyID: (value) => {
    dispatch(setCurrentCompanyID(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersUser);
