import {
  ADMIN_PASSWORD,
  ADMIN_USERNAME,
  COMERCIAL_PASSWORD,
  COMERCIAL_USERNAME,
  MARKETING_PASSWORD,
  MARKETING_USERNAME,
} from './constants';

export const timeConverter = (UNIX_timestamp, type = null, unix = true) => {
  var a = unix ? new Date(UNIX_timestamp * 1000) : UNIX_timestamp;
  // var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  month = parseInt(month) > 9 ? month : '0' + month;
  var date = a.getDate();
  date = parseInt(date) > 9 ? date : '0' + date;
  var hour = a.getHours();
  hour = parseInt(hour) > 9 ? hour : '0' + hour;
  var min = a.getMinutes();
  min = parseInt(min) > 9 ? min : '0' + min;
  var sec = a.getSeconds();
  sec = parseInt(sec) > 9 ? sec : '0' + sec;

  if (type === 'hours') {
    return hour + ':' + min;
  }

  if (type === 'date') {
    return date + '/' + month + '/' + year;
  }

  return date + '/' + month + '/' + year + ' - ' + hour + ':' + min;
};
export const number_format = (amount, decimals = 2) => {
  if (amount === undefined || amount === null) {
    return '';
  }

  const stringAmount = amount.toString();
  if (stringAmount.trim().length === 0 || stringAmount.trim() === '') {
    return '';
  }

  amount += ''; // por si pasan un numero en vez de un string
  amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto

  decimals = decimals || 0; // por si la variable no fue fue pasada

  // si no es un numero o es igual a cero retorno el mismo cero
  if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

  // si es mayor o menor que cero retorno el valor formateado como numero
  amount = '' + amount.toFixed(decimals);

  var amount_parts = amount.split('.'),
    regexp = /(\d+)(\d{3})/;

  while (regexp.test(amount_parts[0]))
    amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2');

  return amount_parts.join('.');
};

export function getAdminCredentials(username, password) {
  if (username === ADMIN_USERNAME && password === ADMIN_PASSWORD) {
    return 'admin';
  } else if (username === MARKETING_USERNAME && password === MARKETING_PASSWORD) {
    return 'marketing';
  } else if (username === COMERCIAL_USERNAME && password === COMERCIAL_PASSWORD) {
    return 'comercial';
  } else {
    return null;
  }
}

export function isTryingAdminCredentials(username) {
  if (username === ADMIN_USERNAME) {
    return true;
  } else if (username === MARKETING_USERNAME) {
    return true;
  } else if (username === COMERCIAL_USERNAME) {
    return true;
  } else {
    return false;
  }
}

export function notAdmin(text) {
  return text != 'admin' && text != 'marketing' && text != 'comercial';
}
export function imageExists(image_url) {
  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.send();
  console.log(http.status);
  return http.status != 404;
}

export function convertFromStringToMinutes(val) {
  const [hour, minutes] = val.split(':');
  return parseInt(hour) * 60 + parseInt(minutes);
}

export function convertFromMinutesToString(val) {
  const first = parseInt(val / 60);
  const second = parseInt(val % 60);
  return `${first < 10 ? '0' : ''}${first}:${second === 0 ? '0' : ''}${second}`;
}
