import React from 'react';
import { hp, wp } from '../../utils/responsive';
class Modal extends React.Component {
  render() {
    const { visible = true, children } = this.props;
    if (visible) {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 25,
            backgroundColor: 'rgba(0,0,0,0.3)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Modal;
