import React from 'react';
import { connect } from 'react-redux';
import checkCircle from '../../../assets/images/check-circle.png';
import closeIcon from '../../../assets/images/closeIcon.png';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import InputBox from '../../../components/InputBox';
import Spacer from '../../../components/Spacer';
import TextLato from '../../../components/TextLato';
import { setCurrentCompanyID } from '../../../store/actions';
import { hp, wp } from '../../../utils/responsive';
import { deleteCoupon, getCouponsForUser, validateCoupon } from '../../../utils/services';
class PromosUser extends React.Component {
  state = {
    code: '',
    coupons: [],
    currentOrder: null,
  };

  async componentDidMount() {
    window.addEventListener('resize', this.resize);
    const { loading } = this.props;
    loading();
    await this.getCoupons();
    loading(false);
    // this.groupRestaurants(storeList)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  getCoupons = async () => {
    const { currentUser } = this.props;
    var coupons = await getCouponsForUser(currentUser.id);

    this.setState({
      coupons:
        coupons.couponsByUser != null
          ? coupons.couponsByUser.map((x) => {
              return {
                id: x.couponId,
                title: x.title,
                description: x.description,
              };
            })
          : [],
    });
  };
  removeCoupon = async (id) => {
    const { currentUser, loading } = this.props;
    loading();
    await deleteCoupon(currentUser.id, id);
    await this.getCoupons();
    loading(false);
  };
  renderCoupon = (coupon) => {
    const titleStyle = {
      fontSize: wp(0.7) + hp(0.6) < 12 ? 12 : wp(0.7) + hp(0.6),
      fontWeight: '400',
      color: '#392E2E',
      flex: 1,
      display: 'flex',
    };
    const descriptionStyle = {
      fontSize: wp(0.6) + hp(0.5) < 12 ? 12 : wp(0.6) + hp(0.5),
      fontWeight: '400',
      color: '#8A8A8F',
      flex: 1,
      display: 'flex',
    };
    return (
      <div style={{ marginLeft: hp(6), width: '40%', marginBottom: hp(3) }}>
        <Card>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',

              paddingLeft: hp(3),
              paddingRight: hp(4),
            }}
          >
            <img src={checkCircle} style={{ height: hp(3), marginBottom: hp(1.5) }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',

                marginLeft: hp(2),
              }}
            >
              <TextLato style={titleStyle}>{coupon.title}</TextLato>
              <TextLato style={descriptionStyle}>{coupon.description}</TextLato>
            </div>
          </div>
          <img
            onClick={() => this.removeCoupon(coupon.id)}
            src={closeIcon}
            style={{
              position: 'absolute',
              right: hp(2),
              top: hp(2),
              height: hp(2),
              marginBottom: hp(2),
            }}
          />
        </Card>
      </div>
    );
  };
  addCoupon = async () => {
    const { code } = this.state;
    const { currentUser, loading } = this.props;
    loading(true);
    const validationResult = await validateCoupon({ userId: currentUser.id, cupon: code });

    await this.getCoupons();
    loading(false);
    alert(validationResult.errorMessage);
  };
  render() {
    const { currentUser } = this.props;
    const { code, coupons } = this.state;
    const titleStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '500',
      marginLeft: hp(6),
    };
    const subtitleStyle = {
      fontSize: wp(0.6) + hp(0.7) < 15 ? 15 : wp(0.6) + hp(0.7),
      fontWeight: '400',
      textAlign: 'center',
    };
    return (
      <div
        style={{
          width: '100%',
          minWidth: 600,
          justifyContent: 'stretch',
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Spacer height={hp(5)} />
        <TextLato style={titleStyle}>Código promocional</TextLato>
        <Spacer height={hp(3)} />
        <div
          style={{
            width: '35%',
            paddingLeft: hp(6),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <InputBox
            style={{
              paddingTop: hp(1.5),
              paddingBottom: hp(1.5),
            }}
            placeholder="Escribe el código acá"
            value={code}
            levelShadow={20}
            onChangeText={(str) => {
              this.setState({
                code: str,
              });
            }}
          />
          <Spacer height={hp(4)} />
          <Button
            backgroundColor={'#D87F7A'}
            second={true}
            onClick={this.addCoupon}
            textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
            style={{
              display: 'flex',
              padding: hp(1.4),
              paddingLeft: hp(6.5),
              paddingRight: hp(6.5),
              borderRadius: hp(0.8),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Enviar
          </Button>
          <Spacer height={hp(6)} />
        </div>
        {coupons.map(this.renderCoupon)}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyID: (value) => {
    dispatch(setCurrentCompanyID(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromosUser);
