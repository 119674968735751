import React from "react";
import "./index.css";
import { hp } from "../../utils/responsive";
import Spacer from "../Spacer";
import TextLato from "../TextLato";
import check from "../../assets/images/check-icon.png";
import checked from "../../assets/images/checked-icon.png";
class CheckButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.initial,
    };
  }

  handleActive = (val) => {
    this.setState({
      active: val,
    });
  };
  render() {
    const { handle, label, size, labelStyle } = this.props;
    const { active } = this.state;
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          onClick={() => {
            this.setState({ active: !active }, () => {
              if (handle) handle(this.state.active);
            });
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {active ? (
            <img src={checked} style={{ width: size, height: size }} />
          ) : (
            <img src={check} style={{ width: size, height: size }} />
          )}
        </div>
      </div>
    );
  }
}
export default CheckButton;
