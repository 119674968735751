import React from 'react';
import { hp, wp } from '../../utils/responsive';
import arrow from '../../assets/images/arrow.png';
import TextLato from '../TextLato';
import Spacer from '../Spacer';
import searchIcon from '../../assets/images/search-icon.png';
class BoxSearch extends React.Component {
  state = {
    query: '',
  };
  render() {
    const { style, onChangeText, products, handleRow, maxHeight, search } = this.props;
    const { query } = this.state;
    return (
      <div
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: hp(2),
          marginBottom: hp(2),
          backgroundColor: 'white',
          //paddingBottom: hp(2),
          borderRadius: hp(1.2),
          boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
          ...style,
        }}
      >
        <div
          className="input"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: hp(2),
          }}
        >
          {search && (<div style={{ display: 'flex', flexDirection: 'row' }}>
            <Spacer width={hp(2.4)} />
            <img src={searchIcon} style={{ height: (wp(0.3) + hp(1.5)) * 1.1 }} />
          </div>)}
          <div style={{ width: hp(2) }} />

          <input
            value={query}
            style={{
              fontSize: wp(0.7) + hp(1),
              fontFamily: 'Lato',
            }}
            placeholder={'Buscar Plato...'}
            onChange={(e) => {
              this.setState({
                query: e.target.value,
              });
            }}
          />
          <img src={arrow} style={{ height: hp(1.8), opacity: 0.6 }} />
          <div style={{ width: hp(2) }} />
        </div>
        <div style={{ height: 1.5, backgroundColor: 'rgba(100,100,100,0.2)' }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Spacer width={hp(2)} />
          <div className="scrollbar"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              height: maxHeight || null,
              ...(maxHeight != null ? { overflowY: 'auto' } : {}),
            }}>
            {products
              .filter((x) => x.name.toUpperCase().indexOf(query.toUpperCase()) != -1)
              .sort((item1, item2) => {
                if (item1.name < item2.name) return -1;
                if (item1.name > item2.name) return 1;
                return 0;
              })
              .map((item) => {
                return (
                  <div
                    onClick={() => {
                      handleRow(item);
                    }}
                    style={{
                      cursor: 'pointer',
                      paddingTop: hp(2),

                      backgroundColor: 'white',
                      paddingBottom: hp(2),
                    }}
                  >
                    <TextLato style={{ fontSize: wp(0.7) + hp(1) }}>{item.name}</TextLato>
                  </div>
                );
              })}
          </div>
          <Spacer width={hp(2)} />
        </div>
      </div>
    );
  }
}
export default BoxSearch;
