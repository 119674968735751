import CKEditor from '@ckeditor/ckeditor5-react';
import { css } from '@emotion/core';
import React from 'react';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import FadeLoader from 'react-spinners/FadeLoader';
import CAMERA from '../../assets/images/camera.png';
import TRASH from '../../assets/images/trash.png';
import '../../components/Editor/build/ckeditor';
import InputBox from '../../components/InputBox';
import Spacer from '../../components/Spacer';
import TextAvenir from '../../components/TextAvenir';
import TextLato from '../../components/TextLato';
import Compress from '../../utils/compress';
import { firebase, storage } from '../../utils/firebase';
import { hp, wp } from '../../utils/responsive';
import ModalQuestion from '../ModalQuestion';
import './index.css';
import TermsBusinessSection from 'src/components/Section/TermsBusinessSection';
import TermsUserSection from 'src/components/Section/TermsUserSection';
import FAQSection from 'src/components/Section/FAQSection';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const OptionEditorView = {
  Blog: 'blog',
  TermsUser: 'terms_user',
  TermsBusiness: 'terms_business',
  Faq: 'faq',
}

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      preview: false,
      blogs: [],
      currentBlog: null,
      deleteId: null,
      deleteVersion: null,
      showEditor: OptionEditorView.Blog
    };
  }

  changeViewEditor = (type) => {
    this.setState({ showEditor: type })
  }

  async componentDidMount() {
    this.loadBlogs();
  }
  loading = (val = true) => this.setState({ loading: val });
  loadBlogs = async () => {
    this.loading();
    firebase
      .firestore()
      .collection('blogs')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        var list = [];

        snapshot.forEach((x) => {
          var data = x.data();
          var description = Compress.decompressFromBase64(data.description) || '';

          var d = description.split('figure').join('div');
          var bl = { ...data, id: x.id, description: d, hasImage: data.noImage != true };

          list.push(bl);
        });
        this.loading(false);
        console.log(list);
        this.setState({ blogs: list });
      });
  };
  onUploadImage = (file, blog) => {
    var filename = blog.id + '.jpg';
    const $this = this;
    $this.loading();
    Resizer.imageFileResizer(
      file,
      800,
      800,
      'JPEG',
      100,
      0,
      (uri) => {
        const uploadTask = storage.ref(`blogs/${filename}`).put(uri, {
          contentType: 'image/jpeg',
        });
        uploadTask.on(
          'state_changed',
          () => { },
          () => { },
          () => {
            firebase
              .firestore()
              .collection('blogs')
              .doc(blog.id)
              .update('noImage', false)
              .then(() => {
                $this.loading(false);
                alert('ACTUALIZADO');
              })
              .catch((e) => alert(e));
          },
        );
      },
      'blob',
    );
  };
  renderBlog = (blog, index) => {
    return (
      <div
        style={{
          position: 'relative',
          width: wp(25),
          height: wp(25),
          margin: '0px auto',
          marginBottom: hp(2),
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/blogs%2F${blog.hasImage ? blog.id : '0'
            }.jpg?alt=media&token=04acf678-1c28-4e1b-80a0-4cb1145670d1`}
          style={{
            objectFit: 'cover',
            position: 'absolute',
            width: wp(25),
            height: wp(25),
            top: 0,
            left: 0,
          }}
        />
        <div
          onClick={() => this.onHandleBlog(blog)}
          style={{
            backgroundColor: 'rgba(0,0,0,0.3)',
            position: 'absolute',
            top: 0,
            left: 0,
            width: wp(25),
            height: wp(25),
          }}
        />
        <div style={{ paddingLeft: wp(1), paddingBottom: wp(1), position: 'relative', zIndex: 2 }}>
          <TextLato
            style={{ lineHeight: 1.3, fontWeight: '300', color: 'white', fontSize: wp(1.8) }}
          >
            {blog.title}
          </TextLato>
        </div>
        {blog.id == 0 || (
          <div
            style={{
              backgroundColor: 'transparent',
              zIndex: 3,
              position: 'absolute',
              right: wp(1),
              top: wp(1),
            }}
          >
            <input
              type="file"
              name={'file' + blog.id}
              id={'file' + blog.id}
              style={{ display: 'none' }}
              class="inputfile"
              accept="image/png, image/jpeg"
              onChange={(e) => this.onUploadImage(e.target.files.item(0), blog)}
            />
            <label for={'file' + blog.id} style={{ cursor: 'pointer' }}>
              <div>
                <img src={CAMERA} style={{ width: wp(3), height: wp(3), fill: 'white' }} />
              </div>
            </label>
          </div>
        )}
        <div
          onClick={() => this.setState({ deleteId: blog.id, deleteVersion: blog.version })}
          style={{
            backgroundColor: 'transparent',
            zIndex: 3,
            position: 'absolute',
            left: wp(1),
            top: wp(1),
          }}
        >
          <img src={TRASH} style={{ width: wp(2.7), height: wp(3), fill: 'white' }} />
        </div>
      </div>
    );
  };
  deleteBlog = (id, version) => {
    const { blogs, currentBlog } = this.state;
    if (id == 0) {
      var newList = blogs;
      newList.shift();
      if (currentBlog.id == 0) {
        this.setState({ blogs: newList, currentBlog: null, deleteId: null, deleteVersion: null });
      } else {
        this.setState({ blogs: newList, deleteId: null, deleteVersion: null });
      }
      return;
    } else {
      firebase.firestore().collection('blogs').doc(id).delete();
      storage
        .ref(`blogs/${id}.jpg`)
        .delete()
        .then(() => { })
        .catch(() => { });
      this.deleteFileStorage(id, version);
      if (currentBlog != null) {
        if (currentBlog.id == id) {
          this.setState({ deleteId: null, deleteVersion: null, currentBlog: null });
        } else this.setState({ deleteId: null, deleteVersion: null });
      } else {
        this.setState({ deleteId: null, deleteVersion: null });
      }
    }
  };
  getBlogText = async (id, version) => {
    var res = await fetch(
      `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/blogs%2F${id}%2F${version}.txt?alt=media&token=563ffb2c-c535-4f75-a6d3-c551d8278759`,
    );
    var text = await res.text();
    return text;
  };
  onHandleBlog = async (blog) => {
    this.changeViewEditor(OptionEditorView.Blog)
    this.loading();
    this.setState({ currentBlog: null });
    var description =
      blog.id == 0 ? blog.description : await this.getBlogText(blog.id, blog.version);
    this.setState({ currentBlog: { ...blog, description } });
    this.loading(false);
  };
  renderBlogs = () => {
    const { blogs } = this.state;
    return (
      <div
        style={{
          width: '100%',
          paddingTop: hp(2),
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '0 8%', gap: 20, marginBottom: 20 }}>
          {/* EDITAR TERMINOS Y CONDICIONES */}
          <button style={{ textWrap: 'balance' }} onClick={() => { this.changeViewEditor(OptionEditorView.TermsUser) }} class="btn btn-default btn-lg" type="button"><strong>Terminos y Condiciones de Usuarios</strong></button>
          <button style={{ textWrap: 'balance' }} onClick={() => { this.changeViewEditor(OptionEditorView.TermsBusiness) }} class="btn btn-default btn-lg" type="button"><strong>Terminos y Condiciones de Negocios</strong></button>
          {/* EDITAR PREGUNTAS FRECUENTES */}
          <button onClick={() => { this.changeViewEditor(OptionEditorView.Faq) }} class="btn btn-default btn-lg" type="button"><strong>Preguntas Frecuentes</strong></button>
        </div>
        <div
          onClick={this.newBlog}
          style={{
            cursor: 'pointer',
            borderRadius: wp(1),
            position: 'relative',
            width: wp(25),
            margin: '0px auto',
            marginBottom: hp(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <div style={{ position: 'relative', zIndex: 2 }}>
            <TextLato
              style={{
                lineHeight: 1.5,
                textAlign: 'center',
                fontWeight: '600',
                color: 'white',
                fontSize: wp(3),
              }}
            >
              +
            </TextLato>
          </div>
        </div>
        {blogs.map(this.renderBlog)}
      </div>
    );
  };
  uploadFileStorage = (txt, id, version, callback) => {
    const file = new Blob([txt], { type: 'text/plain' });
    const uploadTask = storage.ref(`blogs/${id}/${version}.txt`).put(file, {
      contentType: 'text/plain',
    });
    uploadTask.on(
      'state_changed',
      () => { },
      () => { },
      () => {
        callback();
      },
    );
  };
  deleteFileStorage = (id, version) => {
    storage
      .ref(version != null ? `blogs/${id}/${version}.txt` : `blogs/${id}`)
      .delete()
      .then(() => { })
      .catch(() => { });
  };
  newBlog = () => {
    this.changeViewEditor(OptionEditorView.Blog)
    const { blogs } = this.state;
    var newBlog = {
      id: 0,
      title: 'Nuevo Titulo',

      description: '<p>Escriba el contenido aqui</p>',
    };

    var newList = blogs;
    newList.unshift(newBlog);
    this.setState({ blogs: newList }, () => {
      this.onHandleBlog(newBlog);
    });
  };
  storeBlog = () => {
    const { currentBlog } = this.state;

    const $this = this;

    if (currentBlog.id != 0 && currentBlog.id != null) {
      var newVersion = currentBlog.version + 1;
      var blogUpdated = {
        title: currentBlog.title,
        version: newVersion,
        description: currentBlog.description.substr(0, currentBlog.description.indexOf('</p>') + 4),
        timestamp: currentBlog.timestamp,
      };
      $this.loading();
      this.uploadFileStorage(currentBlog.description, currentBlog.id, newVersion, () => {
        firebase
          .firestore()
          .collection('blogs')
          .doc(currentBlog.id)
          .update(blogUpdated)
          .then(() => {
            $this.loading(false);
            this.deleteFileStorage(currentBlog.id, newVersion - 1);
            alert('ACTUALIZADO');
          })
          .catch((e) => alert(e));
      });
    } else {
      var newBlog = {
        title: currentBlog.title,
        version: 1,
        //version:currentBlog.version!=null?currentBlog
        description: currentBlog.description.substr(0, currentBlog.description.indexOf('</p>') + 4),
        timestamp: currentBlog.timestamp || firebase.firestore.FieldValue.serverTimestamp(),
      };
      $this.loading();
      firebase
        .firestore()
        .collection('blogs')
        .add({ ...newBlog, noImage: true })
        .then((docRef) => {
          this.uploadFileStorage(currentBlog.description, docRef.id, 1, () => { });
          this.setState({ currentBlog: { ...currentBlog, id: docRef.id, version: 1 } });
          $this.loading(false);
          alert('CREADO');
        })
        .catch((e) => alert(e));
    }
  };
  downloadTxt = (txt) => {
    const element = document.createElement('a');
    const file = new Blob([txt], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'myFile.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  renderEditor = () => {
    const { blogs, currentBlog, preview } = this.state;
    return currentBlog != null ? (
      preview ? (
        <div style={{ width: '83%' }}>
          <Spacer height={hp(3)} />
          <TextLato
            onClick={() => this.setState({ preview: false })}
            style={{
              fontWeight: '500',
              fontSize: hp(2.4),
              color: '#00A7A9',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {' '}
            {'< Salir de vista previa'}
          </TextLato>
          <TextAvenir
            style={{
              fontSize: hp(3.6),
              fontWeight: '400',
              color: '#2F2E2E',
              textAlign: 'left',
              lineHeight: 1.7,
              marginTop: hp(4),
            }}
          >
            {currentBlog.title}
          </TextAvenir>
          <TextAvenir
            style={{
              fontSize: hp(2.4),

              fontWeight: '400',
              color: '#2F2E2E',
              textAlign: 'left',
              lineHeight: 1.8,
              marginTop: hp(4),
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: currentBlog.description }}></div>
          </TextAvenir>
        </div>
      ) : (
        <div>
          <Spacer height={hp(3)} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <InputBox
              style={{
                width: wp(40),
                paddingTop: hp(2),
                paddingBottom: hp(2),
              }}
              placeholder="Titulo"
              value={currentBlog.title}
              levelShadow={10}
              onChangeText={(str) => {
                this.setState({
                  currentBlog: { ...currentBlog, title: str },
                });
              }}
            />
            <div style={{ display: 'flex' }}>
              <TextLato
                onClick={this.storeBlog}
                style={{ fontSize: hp(3), fontWeight: '400', color: 'rgba(0,100,200,0.7)' }}
              >
                Guardar
              </TextLato>
              <Spacer width={hp(2)} />
              <TextLato
                onClick={() => this.setState({ preview: true })}
                style={{ fontSize: hp(3), fontWeight: '400', color: 'rgba(0,100,200,0.7)' }}
              >
                Vista Previa
              </TextLato>
            </div>
          </div>
          <Spacer height={hp(5)} />
          <div style={{ width: '100%', maxWidth: wp(65), overflowX: 'hidden' }}>
            <CKEditor
              editor={ClassicEditor}
              onInit={(editor) => {
                editor.setData(currentBlog.description);
                console.log('Editor is ready to use!', editor);
              }}
              config={{
                width: wp(65),
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'fontColor',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'indent',
                  'outdent',
                  '|',
                  'imageUpload',
                  'blockQuote',
                  'insertTable',
                  'mediaEmbed',
                  'undo',
                  'redo',
                  'underline',
                  'fontSize',
                  'alignment',
                ],
                language: 'es',
                image: {
                  toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
                },
                table: {
                  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                },
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    {
                      model: 'heading1',
                      view: 'h1',
                      title: 'Heading 1',
                      class: 'ck-heading_heading1',
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2',
                    },
                  ],
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.setState({ currentBlog: { ...currentBlog, description: data } });
              }}
            />{' '}
          </div>
          <Spacer height={hp(2)} />
        </div>
      )
    ) : (
      <div>
        <Spacer height={hp(3)} />
        Selecciona un blog
      </div>
    );
  };
  renderContent = () => {
    const { currentBlog } = this.state;
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'stretch', width: '100%' }}>
        <div
          style={{
            borderRight: '2px solid rgba(100,100,100,0.3)',
            overflowY: 'auto',
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            width: '30%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {this.renderBlogs()}
          </div>
        </div>
        <div
          style={{
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
            width: '70%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {/* MUESTRA EL CONTENIDO DE LA DERECHA */}
          {
            this.state.showEditor == OptionEditorView.Blog ?
              this.renderEditor()
              : this.state.showEditor == OptionEditorView.Faq ?
                <FAQSection />
                : this.state.showEditor == OptionEditorView.TermsBusiness ?
                  <TermsBusinessSection />
                  : <TermsUserSection />
          }
        </div>
      </div>
    );
  };
  renderQuestion = () => {
    const { deleteId, deleteVersion } = this.state;
    return (
      <ModalQuestion
        visible={deleteId != null}
        message={`¿Estas seguro que deseas eliminar la entrada?`}
        okAction={() => {
          this.deleteBlog(deleteId, deleteVersion);
        }}
        cancelAction={() => {
          this.setState({ deleteId: null, deleteVersion: null });
        }}
        okText="Eliminar"
      />
    );
  };
  render() {
    const { loading } = this.state;
    return (
      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <div
          className="scrollnone"
          style={{
            overflowY: 'auto',
            flexDirection: 'column',
            backgroundColor: '#F9F9F9',
            flex: 1,
            width: '100%',
            display: 'flex',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {this.renderContent()}
          </div>
        </div>
        {this.renderQuestion()}
        {loading && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              zIndex: 5,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.4)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FadeLoader css={override} size={130} color={'#40E0D0'} loading={true} />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
