import { saveAs } from 'file-saver';
import React from 'react';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import XLSX from 'xlsx';
import downloadIcon from '../../assets/images/download-black.png';
import emptyIcon from '../../assets/images/empty-products.png';
import uploadIcon from '../../assets/images/upload-black.png';
import BoxButton from '../../components/BoxButton';
import BoxImage from '../../components/BoxImage';
import Button from '../../components/Button';
import FormSales from '../../components/FormSales';
import InputBox from '../../components/InputBox';
import List from '../../components/List';
import Modal from '../../components/Modal';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { firebase, storage } from '../../utils/firebase';
import { number_format } from '../../utils/functions';
import { ReportActions } from '../../utils/reports';
import { hp, wp } from '../../utils/responsive';
import ModalQuestion from '../ModalQuestion';
import './index.css';
const tempImage =
  'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';

const params = [
  {
    title: 'Img',
    key: 'image',
    width: '15%',
    render: (row, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '15%',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
          }}
        >
          <BoxImage url={row} size={wp(3.5) < 40 ? 40 : wp(3.5)} />
        </div>
      );
    },
  },
  {
    title: '',
    key: 'name',
    width: '3%',
    render: (row, textStyle) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '3%',
            justifyContent: 'flex-start',
          }}
        ></div>
      );
    },
  },
  {
    title: 'Plato',
    key: 'name',
    width: '78%',
    alignment: 'flex-start',
  },
];

const importParams = [
  {
    title: 'File',
    key: 'file',
    width: '40%',
    alignment: 'flex-start',
  },
  {
    title: 'Error en columna',
    key: 'error',
    width: '60%',
    alignment: 'flex-start',
  },
];
class Stored extends React.Component {
  state = {
    products: [],
    sales: [],
    formVisible: false,
    selectVisible: false,
    currentProduct: null,
    notif: true,
    file: null,
    importVisible: false,
    dragging: false,
    dragged: false,
    importResult: null,
    deleteVisible: false,
    search: '',
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize);
    window.addEventListener('dragover', this.prevents);
    window.addEventListener('drop', this.prevents);

    this.getSales();
  }
  prevents = (e) => e.preventDefault();
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('dragover', this.prevents);
    window.removeEventListener('drop', this.prevents);
  }

  getSales = () => {
    const $this = this;
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    if (companyID != null) {
      firebase
        .firestore()
        .collection('products')
        .where('companyId', '==', companyID.trim())
        .onSnapshot((querySnapshot) => {
          const data = [];

          querySnapshot.docs.forEach((doc) => {
            let temp = doc.data();
            console.log(temp.name);

            if (!(temp.image != null)) {
              temp.image = tempImage;
            } else {
              if (temp.image.length === 0 || temp.image === null || temp.image === '') {
                temp.image = tempImage;
              }
            }

            data.push({ ...temp, id: doc.id, key: doc.id });
          });

          $this.setState({
            sales: data.sort((a, b) => {
              if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
                return 1;
              }
              if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
                return -1;
              }
              // a must be equal to b
              return 0;
            }),
          });
        });
    }
  };

  deleteCompanyProducts = () => {
    const { sales } = this.state;
    for (var i = 0; i < sales.length; i++) {
      const element = sales[i];
      firebase.firestore().collection('products').doc(element.id).delete();
    }
  };
  saveImportItems = (data) => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id;
    const configProduct = firebase.firestore().collection('products');
    const $this = this;

    var sendData = {};

    // delete sendData['image'];
    sendData.date = new Date();
    sendData.uid = companyID;
    sendData.userId = companyID;
    sendData.companyId = companyID.toString();
    sendData.count = 0;
    sendData.name = data.name;
    sendData.description = data.description;
    sendData.keepPublished = false;
    sendData.price = data.price;
    sendData.discounted = data.discounted;
    sendData.peso = parseFloat(data.peso || 0).toFixed(2);
    sendData.total = '0';
    sendData.image =
      data.image != null && data.image != ''
        ? data.image
        : 'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';
    sendData.radio = 1;
    sendData.expireDate = null;

    configProduct
      .add(sendData)
      .then((docSales) => {})
      .catch((error) => {});
  };
  onSave = async (data) => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id;
    const configProduct = firebase.firestore().collection('products');
    const $this = this;
    if (data != null) {
      var sendData = {};
      var currentPrice = parseFloat(data.original);
      var currentDiscount = parseFloat(data.discount);
      if (parseFloat(currentDiscount.toFixed(2)) > parseFloat((currentPrice * 0.6).toFixed(2))) {
        this.setState({
          visible: false,
          error: '¡El precio descontado debe tener 40% de dscto o más!',
        });
        console.log(
          'error 1' +
            parseFloat(currentDiscount.toFixed(2)) +
            parseFloat((currentPrice * 0.6).toFixed(2)),
        );
        return;
      }

      var duplicate = this.state.sales.find((x) => x.name == data.name);
      if (duplicate != null) {
        alert('Este producto ya ha sido publicado');
        return;
      }
      // delete sendData['image'];
      sendData.date = new Date();
      sendData.uid = companyID;
      sendData.userId = companyID;
      sendData.companyId = companyID.toString();
      sendData.count = 0;
      sendData.name = data.name;
      sendData.description = data.description;
      sendData.keepPublished = false;
      sendData.price = data.original == null ? '0.00' : number_format(data.original.toString());
      sendData.discounted =
        data.discount == null ? '0.00' : number_format(data.discount.toString());
      sendData.peso = data.peso == null ? '0.00' : number_format(data.peso.toString());
      sendData.total = (data.count || 0).toString();
      sendData.image =
        data.image != null
          ? data.image
          : 'https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/icono.png?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab';
      sendData.radio = data.radio;
      sendData.expireDate = data.expireDate;
      this.props.loading();
      if (this.state.file != null) {
        var type = this.state.file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        var filename = uuidv1() + '.' + type;
        Resizer.imageFileResizer(
          this.state.file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            const uploadTask = storage.ref(`stores/${companyID}/stored/${filename}`).put(uri, {
              contentType: 'image/jpeg',
            });
            uploadTask.on(
              'state_changed',
              (task) => {
                console.log(parseInt((100 * task.bytesTransferred) / task.totalBytes));
                this.props.loading(true, parseInt((100 * task.bytesTransferred) / task.totalBytes));
              },
              (e) => {
                this.props.loading(false);
                alert(e.message);
              },
              () => {
                sendData.image = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fstored%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
                configProduct
                  .add(sendData)
                  .then((docSales) => {
                    this.props.loading(false);
                    $this.setState({
                      formVisible: false,
                      selectVisible: false,
                      file: null,
                    });
                  })
                  .catch((error) => {
                    this.props.loading(false);
                    alert('Error adding document: ' + error);
                  });
              },
            );
          },
          'blob',
        );
      } else {
        configProduct
          .add(sendData)
          .then((docSales) => {
            this.props.loading(false);
            this.setState({
              formVisible: false,
              selectVisible: false,
              file: null,
            });
            console.log(docSales);
          })
          .catch((error) => {
            this.props.loading(false);
            alert('Error adding document: ' + error);
          });
      }
    }
  };
  onUpdate = async (data) => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id;
    const configProduct = firebase.firestore().collection('products');

    if (data != null) {
      var sendData = this.state.currentProduct;
      sendData.name = data.name;
      sendData.description = data.description;
      sendData.price = data.original == null ? '0.00' : number_format(data.original.toString());
      sendData.discounted =
        data.discount == null ? '0.00' : number_format(data.discount.toString());
      sendData.peso = data.peso == null ? '0.00' : number_format(data.peso.toString());
      sendData.radio = data.radio;
      sendData.total = '0';
      sendData.expireDate = data.expireDate;
      this.props.loading();
      if (this.state.file != null) {
        var type = this.state.file.name.indexOf('png') != -1 ? 'png' : 'jpg';
        var filename = uuidv1() + '.' + type;
        Resizer.imageFileResizer(
          this.state.file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            const uploadTask = storage.ref(`stores/${companyID}/stored/${filename}`).put(uri, {
              contentType: 'image/jpeg',
            });
            uploadTask.on(
              'state_changed',
              (task) => {
                console.log(parseInt((100 * task.bytesTransferred) / task.totalBytes));
                this.props.loading(true, parseInt((100 * task.bytesTransferred) / task.totalBytes));
              },
              (e) => {
                this.props.loading(false);
                alert(e.message);
              },
              () => {
                sendData.image = `https://firebasestorage.googleapis.com/v0/b/maagal-86c5c.appspot.com/o/stores%2F${companyID}%2Fstored%2F${filename}?alt=media&token=fb3babf6-5a67-4eb6-9ec2-f5d23f6769ab`;
                configProduct
                  .doc(sendData.id)
                  .update(sendData)
                  .then((docSales) => {
                    this.props.loading(false);
                    this.setState({
                      formVisible: false,
                      selectVisible: false,
                      formDetails: false,
                      file: null,
                    });

                    //notifyUsers(companyID);
                  })
                  .catch((error) => {
                    this.props.loading(false);
                    alert('Error adding document: ' + error);
                  });
              },
            );
          },
          'blob',
        );
      } else {
        configProduct
          .doc(sendData.id)
          .update(sendData)
          .then((docSales) => {
            this.props.loading(false);
            this.setState({
              formVisible: false,
              selectVisible: false,
              formDetails: false,
              file: null,
            });

            //notifyUsers(companyID);
          })
          .catch((error) => {
            this.props.loading(false);
            alert('Error adding document: ' + error);
          });
      }
    }
  };
  onDelete = async () => {
    const configProduct = firebase.firestore().collection('products');

    var sendData = this.state.currentProduct;
    configProduct
      .doc(sendData.id)
      .delete()
      .then(() => {
        this.setState({
          formVisible: false,
          selectVisible: false,
          formDetails: false,
          file: null,
        });
        //notifyUsers(companyID);
      })
      .catch((error) => {
        alert('Error delete document: ' + error);
      });
  };
  onChangeImage = (file) => {
    this.setState({ file });
  };
  handleDrag = (e) => {
    e.preventDefault();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    this.setState({ dragging: true });
  };
  handleDragOut = (e) => {
    e.preventDefault();
    this.setState({ dragging: false });
  };
  handleDrop = (e) => {
    const $this = this;
    e.preventDefault();
    e.stopPropagation();
    console.log('drop');
    this.setState({ dragging: false, dragged: true });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.processFileDropped(e.dataTransfer.files[0]);
      /* e.dataTransfer.clearData(); */
    }
  };
  processBufferExcel = (result) => {
    var data = new Uint8Array(result);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join('');

    /* Call XLSX */
    var workbook = XLSX.read(bstr, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });

    /* DO SOMETHING WITH workbook HERE */
    var first_sheet_name = workbook.SheetNames[0];
    /* Get worksheet */
    var worksheet = workbook.Sheets[first_sheet_name];
    return XLSX.utils.sheet_to_json(worksheet, { raw: true, header: 1 });
  };
  onFinishParse = (data) => {
    var dataArray = data.slice(1);
    var pass = 0;
    var fail = 0;
    var listFail = [];
    var listValid = [];

    for (var i = 0; i < dataArray.length; i++) {
      var element = dataArray[i];
      if (element.length >= 5) {
        if (
          element[0] != null &&
          element[1] != null &&
          element[2] != null &&
          element[3] != null &&
          element[4] != null
        ) {
          if (
            element[0].toString().trim() != '' &&
            element[1].toString().trim() != '' &&
            element[2].toString().trim() != '' &&
            element[3].toString().trim() != '' &&
            element[4].toString().trim() != ''
          ) {
            var obj = {
              name: element[0].toString().trim(),
              description: element[1].toString().trim(),
              price: '',
              discounted: 0,
              peso: element[4].toString().trim(),
              date: null,
              radio: 1,
              peso: element[4] != null && element[4] != '' ? element[4].toString().trim() : null,
              image: element[5] != null && element[5] != '' ? element[5].toString().trim() : null,
            };
            var currentPrice = parseFloat(element[2].toString().trim());
            if (currentPrice != null) {
              obj.price = number_format(currentPrice.toFixed(2).toString());
              currentPrice = parseFloat(currentPrice.toFixed(2));
            } else {
              fail++;
              listFail.push({ file: i + 1, error: 'Precio original' });
              continue;
            }
            var currentDiscount = parseFloat(element[3].toString().trim());
            if (currentDiscount != null) {
              var validateDiscount = parseFloat(currentDiscount.toFixed(2));
              if (validateDiscount <= parseFloat(parseFloat(currentPrice * 0.6).toFixed(2))) {
                obj.discounted = number_format(currentDiscount.toFixed(2).toString());
                currentDiscount = parseFloat(currentDiscount.toFixed(2));
              } else {
                fail++;
                listFail.push({ file: i + 1, error: 'Precio descontado' });
                continue;
              }
            } else {
              fail++;
              listFail.push({ file: i + 1, error: 'Precio descontado' });
              continue;
            }
            pass++;
            listValid.push(obj);
          } else {
            fail++;

            listFail.push({ file: i + 1, error: 'Campos en blanco' });
          }
        }
      } else {
        console.log(element);
        if (element.length > 0) {
          fail++;
          listFail.push({ file: i + 1, error: 'Campos incompletos' });
        }
      }
    }
    console.log(listValid);
    this.setState({
      importResult: {
        pass,
        fail,
        list: listFail,
        passed: listValid,
      },
    });
  };
  processFileDropped(file) {
    const $this = this;
    if (file.name.indexOf('.xlsx') != -1) {
      if (file != null) {
        var reader = new FileReader();
        reader.onload = function () {
          var arrayBuffer = this.result;
          var result = $this.processBufferExcel(arrayBuffer);
          console.log(result);
          $this.onFinishParse(result);
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('NO ENCONTRO');
      }
    } else {
      alert('El archivo debe ser XLSX. Descargue la plantilla');
    }
  }
  addListeners = () => {
    const $this = this;
    var div = this.importContainer;

    if (div) div.addEventListener('dragenter', this.handleDragIn);
    if (div) div.addEventListener('dragleave', this.handleDragOut);
    if (div) div.addEventListener('dragover', this.handleDrag);
    if (div) div.addEventListener('drop', this.handleDrop);
    if (this.inputFil)
      this.inputFil.addEventListener(
        'change',
        function () {
          $this.processFileDropped(this.files[0]);
        },
        false,
      );
  };
  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const { navigate, admin, companyInfo } = this.props;
    const {
      products,
      sales,
      formVisible,
      selectVisible,
      formDetails,
      importVisible,
      deleteVisible,
      search,
    } = this.state;
    var filteredList =
      search.trim().length > 0
        ? sales.filter((x) => x.name.toUpperCase().indexOf(search.trim().toUpperCase()) != -1)
        : sales;
    return (
      <div
        className="scrollbar"
        style={{
          overflowY: 'auto',
          display: 'flex',
          width: '100%',
          minWidth: 800,
          height: '100%',
          flexDirection: 'row',
          justifyContent: 'stretch',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                flex: 1,
                paddingLeft: hp(10),
                paddingTop: hp(5),
              }}
            >
              <TextLato style={textStyle}>Mis Productos</TextLato>
              <Spacer height={hp(3)} />

              {sales.length > 0 ? (
                <>
                  <div style={{ display: 'flex', width: 300 }}>
                    <InputBox
                      search={true}
                      placeholder="Buscar producto"
                      value={search}
                      onChangeText={(str) => {
                        this.setState({
                          search: str,
                        });
                      }}
                    />
                  </div>
                  <Spacer height={hp(2)} />

                  <Spacer height={hp(2)} />
                  <List
                    maxHeight={filteredList.length > (wp(100) < 1000 ? 6 : 5) ? hp(57) : null}
                    handleRow={(data) => {
                      this.setState(
                        {
                          formDetails: false,
                          formVisible: false,
                          selectVisible: false,
                        },
                        () =>
                          this.setState({
                            currentProduct: data,
                            formDetails: true,
                            formVisible: false,
                            selectVisible: false,
                          }),
                      );
                    }}
                    header={true}
                    params={params}
                    data={filteredList}
                  />
                </>
              ) : (
                <div
                  style={{
                    width: hp(25),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Spacer height={hp(2)} />
                  <img src={emptyIcon} height={hp(20)} />
                  <Spacer height={hp(2)} />
                  <TextLato
                    style={{
                      fontSize: textStyle.fontSize * 0.7,
                      fontWeight: '300',
                      textAlign: 'center',
                    }}
                  >
                    {'No cuentas con productos creados'}
                  </TextLato>
                  <TextLato
                    style={{
                      fontSize: textStyle.fontSize * 0.7,
                      fontWeight: '300',
                      textAlign: 'center',
                    }}
                  >
                    {'¡Crea tu primer producto!'}
                  </TextLato>
                  <Spacer height={hp(4)} />
                </div>
              )}
              <Spacer height={hp(3)} />
              <TextLato style={{ ...textStyle, fontSize: textStyle.fontSize * 0.85 }}>
                Agregar
              </TextLato>
              <Spacer height={hp(1)} />
              <div style={{ backgroundColor: '#BCBBC1', height: 1 }} />
              <Spacer height={hp(3)} />
              <div style={{ width: '100%', position: 'relative', zIndex: 2 }}>
                {!formVisible && !selectVisible && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <BoxButton
                      onClick={() => {
                        this.setState(
                          {
                            currentProduct: null,
                            formVisible: true,
                            formDetails: false,
                          },
                          () => {
                            this.bottomDiv.scrollIntoView({ behavior: 'smooth' });
                          },
                        );
                      }}
                      icon={true}
                    >
                      Agregar Nuevo
                    </BoxButton>
                    <Spacer height={hp(2)} />
                  </div>
                )}
                {selectVisible && formVisible && <Spacer height={hp(3)} />}
                {formVisible && (
                  <div style={{ display: 'flex', position: 'relative', width: '190%' }}>
                    <FormSales
                      admin={admin}
                      okText={'Agregar'}
                      reduced={true}
                      file={this.state.file}
                      administrador={true}
                      noStock={true}
                      initialData={this.state.currentProduct}
                      onSave={this.onSave}
                      onSuccessImage={this.onChangeImage}
                      onRelease={() => {
                        this.setState({
                          formVisible: false,
                          currentProduct: null,
                          file: null,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <div ref={(x) => (this.bottomDiv = x)}></div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                flex: 1,
                paddingLeft: hp(5),
                paddingTop: hp(5),
              }}
            >
              <TextLato style={{ ...textStyle, color: 'transparent' }}>Mis Productos</TextLato>
              <Spacer height={hp(3)} />
              <div style={{ width: '80%' }}>
                {formDetails && (
                  <FormSales
                    showDelete
                    okText={'Actualizar'}
                    admin={admin}
                    file={this.state.file}
                    onSuccessImage={this.onChangeImage}
                    administrador={true}
                    initialData={this.state.currentProduct}
                    onSave={this.onUpdate}
                    onDelete={() => {
                      this.setState({ deleteVisible: true });
                    }}
                    onRelease={() => {
                      this.setState({
                        formDetails: false,
                        currentProduct: null,
                        file: null,
                      });
                    }}
                  />
                )}
                {admin && (
                  <BoxButton
                    onClick={() => {
                      this.setState({
                        importVisible: true,
                      });
                      setTimeout(this.addListeners, 1000);
                    }}
                    iconSource={uploadIcon}
                    icon={true}
                  >
                    Importar
                  </BoxButton>
                )}
                <Spacer height={hp(2)} />
                {admin && (
                  <BoxButton
                    onClick={() => {
                      ReportActions.PRODUCTSBYSTORE(companyInfo.id.toString(), () => {});
                    }}
                    iconSource={downloadIcon}
                    icon={true}
                  >
                    Exportar
                  </BoxButton>
                )}
                <Spacer height={hp(2)} />
                {admin && (
                  <BoxButton
                    onClick={() => {
                      var wb = XLSX.utils.book_new();
                      wb.Props = {
                        Title: 'SheetJS Tutorial',
                        Subject: 'Test',
                        Author: 'Red Stapler',
                        CreatedDate: new Date(2017, 12, 19),
                      };

                      wb.SheetNames.push('Test Sheet');
                      var ws_data = [
                        [
                          'Nombre del Producto',
                          'Descripción del Producto',
                          'Precio original',
                          'Precio descontado',
                          'Peso',
                        ],
                        [
                          'input texto largo',
                          'input texto largo',
                          'debe ser input valor',
                          'debe ser input valor y validar que sea 40%',
                          'debe ser input valor, sino 0',
                        ],
                      ];
                      var ws = XLSX.utils.aoa_to_sheet(ws_data);
                      wb.Sheets['Test Sheet'] = ws;
                      var wbout = XLSX.write(wb, {
                        bookType: 'xlsx',
                        type: 'binary',
                      });
                      function s2ab(s) {
                        var buf = new ArrayBuffer(s.length);
                        var view = new Uint8Array(buf);
                        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
                        return buf;
                      }
                      saveAs(
                        new Blob([s2ab(wbout)], {
                          type: 'application/octet-stream',
                        }),
                        'plantilla.xlsx',
                      );
                    }}
                    iconSource={downloadIcon}
                    icon={true}
                  >
                    Descargar plantilla
                  </BoxButton>
                )}
              </div>
            </div>
            <Modal visible={importVisible}>
              <div
                style={{
                  backgroundColor: 'white',
                  width: wp(90),
                  maxWidth: 500,
                  height: hp(55),
                  borderRadius: hp(1),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Spacer height={hp(4)} />
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <TextLato
                    style={{
                      fontSize: wp(0.2) + hp(2),
                      fontWeight: '400',
                      width: '58%',
                      textAlign: 'right',
                    }}
                  >
                    Importación
                  </TextLato>
                  <TextLato
                    onClick={() => this.setState({ importVisible: false })}
                    style={{
                      fontSize: wp(0.2) + hp(2),
                      fontWeight: '400',
                      width: '35%',
                      textAlign: 'right',
                    }}
                  >
                    X
                  </TextLato>
                </div>
                <Spacer height={this.state.importResult != null ? hp(2) : hp(9)} />
                {this.state.importResult != null ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                    }}
                  >
                    <Spacer width={wp(4)} />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        height: '100%',
                        alignItems: 'flex-start',
                      }}
                    >
                      <TextLato
                        style={{
                          fontSize: wp(0.4) + hp(1),
                          fontWeight: '400',
                        }}
                      >
                        Resultado
                      </TextLato>
                      <Spacer height={hp(2)} />
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextLato
                          style={{
                            fontSize: wp(0.4) + hp(1),
                            fontWeight: '400',
                          }}
                        >
                          {this.state.importResult.pass} productos exitosamente
                        </TextLato>
                        <Spacer width={wp(3)} />
                        <TextLato
                          style={{
                            fontSize: wp(0.4) + hp(1),
                            fontWeight: '400',
                          }}
                        >
                          {this.state.importResult.fail} productos fallidos
                        </TextLato>
                      </div>
                      <Spacer height={hp(2)} />
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                      >
                        {this.state.importResult.list.length > 0 && (
                          <List
                            maxHeight={hp(20)}
                            expand={true}
                            headerStyle={{
                              fontSize: wp(0.4) + hp(1),
                              fontWeight: '400',
                              fontStyle: 'italic',
                            }}
                            paddingVertical={hp(1)}
                            rowStyle={{
                              fontSize: wp(0.4) + hp(1),
                              fontWeight: '400',
                            }}
                            topHeader={true}
                            handleRow={async (data) => {}}
                            header={true}
                            params={importParams}
                            data={this.state.importResult.list}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{ display: 'flex', flex: 1 }} />
                        <Button
                          onClick={() => {
                            this.setState({ importResult: null });
                          }}
                          second={true}
                          textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                          style={{
                            display: 'flex',
                            flex: 1,
                            paddingTop: hp(1.4),
                            paddingBottom: hp(1.4),
                            paddingLeft: wp(2),
                            paddingRight: wp(2),
                            borderRadius: hp(0.8),
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Deshacer
                        </Button>
                        <Spacer width={wp(4)} />
                        <Button
                          onClick={() => {
                            var lista = this.state.importResult.passed;
                            for (var i = 0; i < lista.length; i++) {
                              var elemento = lista[i];
                              this.saveImportItems(elemento);
                            }
                            this.setState({
                              importResult: null,
                              importVisible: false,
                            });
                          }}
                          second={true}
                          textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                          style={{
                            display: 'flex',
                            flex: 1,
                            paddingLeft: wp(2),
                            paddingRight: wp(2),
                            paddingTop: hp(1.4),
                            paddingBottom: hp(1.4),
                            borderRadius: hp(0.8),
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Guardar
                        </Button>
                        <div style={{ display: 'flex', flex: 1 }} />
                      </div>
                    </div>
                    <Spacer width={wp(4)} />
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                    }}
                  >
                    <Spacer width={wp(8)} />
                    <div
                      ref={(x) => (this.importContainer = x)}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        backgroundColor: this.state.dragging
                          ? 'rgba(9,175,175,0.3)'
                          : 'transparent',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #09AFAF',
                        borderRadius: hp(1),
                      }}
                    >
                      <input
                        ref={(x) => (this.inputFil = x)}
                        type="file"
                        name="importFile"
                        id="importFile"
                        class="inputfile"
                        accept="text/xlsx"
                      />
                      {this.state.dragging ? (
                        <TextLato
                          style={{
                            fontSize: wp(0.1) + hp(1.4),
                            fontWeight: '400',
                            position: 'relative',
                            zIndex: 0,
                          }}
                        >
                          Suelta tu archivo
                        </TextLato>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <TextLato
                            style={{
                              fontSize: wp(0.1) + hp(1.4),
                              fontWeight: '400',
                              position: 'relative',
                              zIndex: 0,
                            }}
                          >
                            Arrastra o&nbsp;
                          </TextLato>
                          <label for="importFile">
                            <TextLato
                              style={{
                                fontSize: wp(0.1) + hp(1.4),
                                fontWeight: '500',
                                position: 'relative',
                                color: '#00A7A9',
                                zIndex: 0,
                                cursor: 'pointer',
                              }}
                            >
                              selecciona archivo
                            </TextLato>
                          </label>
                        </div>
                      )}
                    </div>
                    <Spacer width={wp(8)} />
                  </div>
                )}
                <Spacer height={this.state.importResult != null ? hp(2) : hp(9)} />
              </div>
            </Modal>
            <ModalQuestion
              visible={deleteVisible}
              message={'¿Estas seguro que deseas eliminar el producto?'}
              okAction={() => {
                this.onDelete();
                this.setState({ deleteVisible: false });
              }}
              cancelAction={() => {
                this.setState({ deleteVisible: false });
              }}
              okText={'Eliminar'}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.currentCompantID,
    companyInfo: state.companyInfo,
  };
};

export default connect(mapStateToProps, null)(Stored);
