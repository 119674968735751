import React from "react";
import "./index.css";
import { hp } from "../../utils/responsive";
import Spacer from "../Spacer";
import TextLato from "../TextLato";
class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.initialValue,
    };
  }

  handleActive = (val) => {
    this.setState({
      active: val,
    });
  };
  render() {
    const { onChangeValue, label, size, labelStyle, group } = this.props;
    const { active } = this.state;
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          onClick={() => {
            this.setState({ active: group ? true : !active }, () => {
              if (onChangeValue) onChangeValue(this.state.active);
            });
          }}
          style={{
            cursor: "pointer",
            width: size,
            height: size,
            borderRadius: size * 0.5,
            border: active
              ? "1px solid #9AD6CD"
              : "1px solid rgba(31, 32, 65, 0.25)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {active && (
            <div
              style={{
                width: size * 0.7,
                height: size * 0.7,
                borderRadius: size * 0.35,
                backgroundColor: "#9AD6CD",
                //background: "background: linear-gradient(0.35deg, #00A7A9 0.31%, #40E0D0 99.7%)",
                display: "flex",
              }}
            />
          )}
        </div>

        {label && <Spacer width={hp(1)} />}
        {label && <TextLato style={labelStyle}>{label}</TextLato>}
      </div>
    );
  }
}
export default Checkbox;
