import React from 'react';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import Spacer from '../Spacer';
import './style.css';
class List extends React.Component {
  renderRow = (row, index) => {
    const {
      params,
      handleRow,
      topHeader,
      maxHeight,
      rowStyle = {},
      actions,
      labelEmpty,
      actioninPartialRow,
    } = this.props;
    //console.log(this.props);
    const textStyle = {
      fontSize: wp(0.9) + hp(0.6) < 14 ? 14 : wp(0.9) + hp(0.6), // wp(0.9) + hp(0.6),
      ...rowStyle,
    };
    return (
      <div
        onClick={() => {
          if (handleRow != null && !actioninPartialRow) {
            handleRow(row);
          }
        }}
        style={{
          cursor: handleRow != null ? 'pointer' : 'auto',
          width: '100%',
        }}
      >
        {index == 0 ? null : (
          <div
            style={{
              backgroundColor: '#BCBBC1',
              height: 1,
              width: maxHeight ? '95%' : '100%',
            }}
          />
        )}
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            paddingTop: hp(2.4),
            paddingBottom: hp(2),
            width: '100%',
          }}
        >
          {params.map((item) => {
            if (item.render != null) {
              return item.render(
                item.key != null
                  ? row[item.key]
                  : item.type == 'actions'
                    ? { data: row, actions }
                    : row,
                textStyle,
                index,
              );
            } else {
              return (
                <div
                  onClick={() => {
                    if (handleRow != null && actioninPartialRow) {
                      handleRow(row);
                    }
                  }}
                  style={{
                    display: 'flex',

                    width: item.width != null ? item.width : null,
                    justifyContent: item.alignment != null ? item.alignment : 'center',
                  }}
                >
                  <TextLato style={textStyle}>
                    {item.prefix != null ? item.prefix : ''}
                    {row[item.key]}
                  </TextLato>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };
  renderEmpty = () => {
    const {
      params,
      handleRow,
      topHeader,
      maxHeight,
      rowStyle = {},
      actions,
      labelEmpty,
    } = this.props;
    const textStyle = {
      fontSize: wp(0.9) + hp(0.6) < 14 ? 14 : wp(0.9) + hp(0.6), // wp(0.9) + hp(0.6),
      ...rowStyle,
    };
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            paddingTop: hp(2.4),
            paddingBottom: hp(2),

            width: '100%',
          }}
        >
          <TextLato style={textStyle}>{labelEmpty}</TextLato>
        </div>
      </div>
    );
  };
  renderHeader = () => {
    const { params, topHeader, headerStyle = {} } = this.props;
    const textStyle = {
      fontSize: wp(0.9) + hp(0.7) < 15 ? 15 : wp(0.9) + hp(0.7),
      fontWeight: '500',
      ...headerStyle,
    };
    return (
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          paddingTop: hp(1),
          paddingBottom: hp(1),
          width: '100%',
        }}
      >
        {params.map((item) => {
          return (
            <div
              style={{
                display: 'flex',
                width: item.width != null ? item.width : null,
                justifyContent: item.alignment != null ? item.alignment : 'flex-start',
              }}
            >
              <TextLato style={textStyle}>{item.title}</TextLato>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    const { data, header, topHeader, maxHeight, paddingVertical } = this.props;
    return (
      <div>
        {topHeader && (
          <div
            style={{
              width: '100%',
              display: 'flex',

              flexDirection: 'row',
              paddingBottom: hp(1.2),
            }}
          >
            <Spacer width={hp(3.5)} />
            <div style={{ display: 'flex', width: '100%' }}>
              {this.renderHeader()}
              <Spacer width={wp(1) + hp(2)} />
            </div>
          </div>
        )}
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            position: 'relative',
            // boxShadow: "2px 0px 20px rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              flex: 1,
              position: 'absolute',
              top: hp(0.6),
              left: hp(0.6),
              right: hp(0.6),
              bottom: hp(0.6),
              zIndex: 1,
              alignItems: 'center',
              boxShadow: '0px 0px 20px rgba(0,0,0,0.3)',
            }}
          ></div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              position: 'relative',
              zIndex: 2,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              backgroundColor: 'white',
              /* paddingTop: hp(2.1),
            paddingBottom: hp(2.1), */
              borderRadius: hp(1.2),
              //boxShadow: "2px 0px 30px rgba(0,0,0,0.2)",
            }}
          >
            <Spacer width={wp(1) + hp(2)} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                padding: maxHeight ? '1rem 0' : null
              }}
            >
              {header && !topHeader && <Spacer height={hp(2)} />}
              {header && !topHeader && this.renderHeader()}
              {header && !topHeader && <Spacer height={hp(1)} />}
              {paddingVertical && <Spacer height={paddingVertical} />}
              <div
                className="scrollbar"
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  height: maxHeight || null,
                  ...(maxHeight != null ? { overflowY: 'auto' } : {}),
                }}
              >
                {data.length > 0 ? data.map(this.renderRow) : this.renderEmpty()}
              </div>
              {paddingVertical && <Spacer height={paddingVertical} />}
              {header && !topHeader && <Spacer height={hp(2)} />}
            </div>
            <Spacer width={wp(1) + hp(2)} />
          </div>
        </div>
      </div>
    );
  }
}
export default List;
