import React from 'react';
import show from "../../assets/images/eye.png"
import hide from "../../assets/images/hide.png"
const EyeIcon = ({ onClick = () => { }, active = true, style = {} }) => {
    return <div style={{ cursor: "pointer", position: "relative" }} onClick={onClick}>
        <div style={{ transition: "0.4s opacity", opacity: active ? 1 : 0 }}>
            <img src={show} style={{ width: 20, height: 20, ...style }} />
        </div>
        <div style={{ position: "absolute", top: 0, transition: "0.4s opacity", opacity: active ? 0 : 1 }}>
            <img src={hide} style={{ width: 20, height: 20, ...style }} />
        </div>
    </div>
}

export default EyeIcon;