import React from "react";
import "./style.css";
import { hp, wp } from "../../utils/responsive";
class LineChart extends React.Component {
  getMinX = () => {
    const { data } = this.props;
    const only_x = data.map((obj) => obj.x);
    const min_x = Math.min.apply(null, only_x);
    return min_x;
  };
  getMinY = () => {
    const { data } = this.props;
    const only_y = data.map((obj) => obj.y);
    const min_y = Math.min.apply(null, only_y);
    return min_y;
  };
  getMaxX = () => {
    const { data } = this.props;
    const only_x = data.map((obj) => obj.x);
    const max_x = Math.max.apply(null, only_x);
    return max_x;
  };
  getMaxY = () => {
    const { data } = this.props;
    const only_y = data.map((obj) => obj.y);
    const max_y = Math.max.apply(null, only_y);

    return max_y == 0 ? 1 : max_y;
  };
  getSvgX = (x) => {
    const { svgWidth } = this.props;
    return (x / this.getMaxX()) * svgWidth;
  };
  getSvgY = (y) => {
    const { svgHeight } = this.props;
    return svgHeight - (y / this.getMaxY()) * svgHeight;
  };
  makePath = () => {
    const { data, color, svgHeight, svgWidth } = this.props;
    let pathD = ` M  ${svgWidth * 0.025 + this.getSvgX(data[0].x) * 0.95} ${
      svgHeight * 0.15 + this.getSvgY(data[0].y) * 0.5
    } `;

    data.map((point, i) => {
      pathD += `L ${svgWidth * 0.025 + this.getSvgX(point.x) * 0.95} ${
        svgHeight * 0.15 + this.getSvgY(point.y) * 0.5
      } `;
    });
    return <path d={pathD} fill="none" stroke={color} />;
  };
  makePoints() {
    const { data, color, svgHeight, svgWidth } = this.props;
    return (
      <g className="linechart_axis">
        {data.map((x, index) => {
          if (index % 2 == 1) {
            return null;
          }
          return (
            <circle
              cx={svgWidth * 0.025 + this.getSvgX(x.x) * 0.95}
              cy={svgHeight * 0.15 + this.getSvgY(x.y) * 0.5}
              r={4}
              strokeWidth={0}
              fill={color}
            />
          );
        })}
      </g>
    );
  }
  makeAxis() {
    const { data, color, svgWidth } = this.props;
    const minX = this.getMinX();
    const maxX = this.getMaxX();
    const minY = this.getMinY();
    const maxY = this.getMaxY();
    return (
      <g className="linechart_axis">
        {data.map((x, index) => {
          if (index % 2 == 1) {
            return null;
          }
          return (
            <line
              x1={svgWidth * 0.025 + this.getSvgX(x.x) * 0.95}
              y1={this.getSvgY(minY)}
              x2={svgWidth * 0.025 + this.getSvgX(x.x) * 0.95}
              y2={this.getSvgY(maxY)}
              strokeWidth={1.5}
              stroke={"rgba(0,0,0,0.2)"}
            />
          );
        })}
      </g>
    );
  }
  render() {
    const { svgHeight, svgWidth, data } = this.props;

    return (
      <svg width={svgWidth} height={svgHeight}>
        {data != null && this.makeAxis()}
        {data != null && this.makePath()}
        {data != null && this.makePoints()}
      </svg>
    );
  }
}
LineChart.defaultProps = {
  data: [],
  color: "#00A7A9",
  svgHeight: hp(20),
  svgWidth: wp(70),
};
export default LineChart;
