import React from 'react';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import Input from '../Input';
import Spacer from '../Spacer';
import Checkbox from '../Checkbox';
import Button from '../Button';
import BoxImage from '../BoxImage';
import DatePicker from 'react-datepicker';
import moment from 'moment';
class FormUser extends React.Component {
  constructor(props) {
    super(props);
    this.listBox = React.createRef();
    this.state = {
      listBox: false,
      name: props.initialData != null ? props.initialData.name : '',
      email: props.initialData != null ? props.initialData.email : '',
      phone: props.initialData != null ? props.initialData.phone : '',
      date: props.initialData != null ? props.initialData.date : '',
      genre: props.initialData != null ? props.initialData.genre : '',
      initial: props.initialData != null ? props.initialData : null,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.listBox != null)
      if (this.listBox && !this.listBox.current.contains(event.target)) {
        this.setState({ listBox: false });
      }
  };
  onChangeText = (value, key) => {
    const { onChange } = this.props;

    this.setState(
      {
        [key]: value,
        listBox: false,
      },
      () => {
        if (onChange) {
          onChange({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            date: this.state.date,
            genre: this.state.genre,
          });
        }
      },
    );
  };
  renderListBox = (genre) => {
    const { listBox } = this.state;
    const optionStyle = {
      fontSize: hp(1.8),
      marginLeft: hp(1),
      fontWeight: '400',
    };
    var blurred = -1;
    if (genre != null) {
      if (genre.length > 0) {
        blurred = genre[0] == 'M' ? 0 : genre[0] == 'F' ? 1 : 2;
      }
    }
    return (
      <div
        ref={this.listBox}
        style={{
          position: 'absolute',
          width: '100%',
          top: hp(4),

          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          transition: '0.3s height',
          boxShadow: '2px 0px 5px rgba(0,0,0,0.2)',
          height: listBox ? hp(10) : 0,
        }}
      >
        <div
          onClick={() => {
            this.onChangeText('Masculino', 'genre');
          }}
          style={{
            backgroundColor: blurred == 0 ? 'rgb(230,230,230)' : 'rgb(255,255,255)',
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flex: 1,
          }}
        >
          <TextLato style={optionStyle}>Masculino</TextLato>
        </div>
        <div
          onClick={() => {
            this.onChangeText('Femenino', 'genre');
          }}
          style={{
            backgroundColor: blurred == 1 ? 'rgb(230,230,230)' : 'rgb(255,255,255)',
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flex: 1,
          }}
        >
          <TextLato style={optionStyle}>Femenino</TextLato>
        </div>
        <div
          onClick={() => {
            this.onChangeText('Otro', 'genre');
          }}
          style={{
            backgroundColor: blurred == 2 ? 'rgb(230,230,230)' : 'rgb(255,255,255)',
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flex: 1,
          }}
        >
          <TextLato style={optionStyle}>Otro</TextLato>
        </div>
      </div>
    );
  };
  render() {
    const {
      style,
      containerStyle,
      admin,
      reduced,
      placeholders = {
        name: ' ',
        email: ' ',
        phone: ' ',
        date: ' ',
        genre: ' ',
      },
    } = this.props;
    const { name, email, phone, date, genre } = this.state;
    const labelStyle = {
      fontSize: hp(1.8),
      opacity: 0.7,
    };
    const inputStyle = {
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    const fieldStyle = {
      fontSize: hp(2.2),
      fontFamily: 'Lato',
      fontWeight: '400',
    };
    return (
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          ...containerStyle,
          // boxShadow: "2px 0px 20px rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,

            position: 'absolute',
            top: hp(0.6),
            left: hp(0.6),
            right: hp(0.6),
            bottom: hp(0.6),
            zIndex: 2,
            alignItems: 'center',
            boxShadow: '2px 0px 30px rgba(0,0,0,0.2)',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            zIndex: 3,
            width: '100%',
            flex: 1,
            backgroundColor: 'white',
            borderRadius: hp(1.2),
            paddingTop: hp(3),
            paddingBottom: hp(3),
            ...style,
          }}
        >
          <Spacer width={hp(3)} />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <TextLato style={labelStyle}>Nombre</TextLato>
            <Spacer height={hp(0.8)} />
            <Input
              disabled={!admin}
              value={name}
              fieldStyle={fieldStyle}
              onChangeText={this.onChangeText}
              name="name"
              bottomSpc={hp(0.6)}
              style={inputStyle}
              placeholder={placeholders.name}
            />
            <Spacer height={hp(0.8)} />
            <TextLato style={labelStyle}>E-mail</TextLato>
            <Spacer height={hp(0.8)} />
            <Input
              disabled={!admin}
              value={email}
              fieldStyle={fieldStyle}
              onChangeText={this.onChangeText}
              name="email"
              bottomSpc={hp(0.6)}
              style={inputStyle}
              placeholder={placeholders.email}
            />

            <Spacer height={hp(0.8)} />

            <TextLato style={labelStyle}>Teléfono</TextLato>
            <Spacer height={hp(0.8)} />
            <Input
              disabled={!admin}
              value={phone}
              fieldStyle={fieldStyle}
              onChangeText={this.onChangeText}
              name="phone"
              bottomSpc={hp(0.6)}
              style={inputStyle}
              placeholder={placeholders.phone}
            />
            <Spacer height={hp(0.8)} />
            <TextLato style={labelStyle}>Fecha de nacimiento</TextLato>
            <Spacer height={hp(0.8)} />
            <div style={{ position: 'relative' }}>
              <Input
                disabled={!admin}
                value={date}
                fieldStyle={fieldStyle}
                onChangeText={this.onChangeText}
                name="date"
                bottomSpc={hp(0.6)}
                style={inputStyle}
                placeholder={placeholders.date}
              />
              <div
                onClick={() => {
                  this.datepicker.setOpen(true);
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: 20,
                  backgroundColor: 'transparent',
                }}
              >
                <DatePicker
                  onClickOutside={() => {}}
                  customInput={<div></div>}
                  minDate={moment('01/01/1920', 'DD/MM/YYYY').toDate()}
                  ref={(x) => (this.datepicker = x)}
                  selected={
                    date != null && date != '' ? moment(date, 'DD/MM/YYYY').toDate() : new Date()
                  }
                  onChange={(x) => this.onChangeText(moment(x).format('DD/MM/YYYY'), 'date')}
                />
              </div>
            </div>
            <TextLato style={labelStyle}>Género</TextLato>
            <Spacer height={hp(0.8)} />
            <div style={{ position: 'relative' }}>
              <Input
                disabled={!admin}
                value={genre}
                fieldStyle={fieldStyle}
                onChangeText={this.onChangeText}
                name="genre"
                bottomSpc={hp(0.6)}
                style={inputStyle}
                placeholder={placeholders.genre}
              />
              <div
                onClick={() => {
                  if (!this.state.listBox) this.setState({ listBox: true });
                }}
                style={{
                  overflowY: 'visible',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: hp(4),
                  backgroundColor: 'transparent',
                }}
              >
                {this.renderListBox(genre)}
              </div>
            </div>
          </div>

          <Spacer width={hp(3)} />
        </div>
      </div>
    );
  }
}
export default FormUser;
