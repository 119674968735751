import React from 'react';
import './index.css';
import { hp, wp, isMobile } from '../../utils/responsive';
import TextLato from '../TextLato';
import logo from '../../assets/images/logo.png';
import Spacer from '../Spacer';
const AdminTop = React.memo(function HeaderAdmin({
  adminRole,
  backgroundColor,
  active,
  onClick,
  onClickLogout,
}) {
  const tabs =
    adminRole == 'admin'
      ? ['NEGOCIOS', 'CONSUMIDORES', 'EDITOR']
      : adminRole == 'marketing'
        ? ['CONSUMIDORES', 'EDITOR']
        : ['NEGOCIOS'];
  return (
    <div
      style={{
        paddingTop: hp(1.3),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: backgroundColor || '#ADE3E3',
        justifyContent: 'flex-start',
        paddingBottom: hp(1.3),
      }}
    >
      <Spacer width={wp(2)} />
      <div style={{ cursor: 'pointer' }} onClick={onClickLogout}>
        <img src={logo} style={{ width: hp(6.8), height: hp(6.8) }} />
      </div>
      <Spacer width={wp(5)} />
      {onClick != null && (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          {tabs.map((x, index) => {
            return (
              <div
                onClick={() => {
                  sessionStorage.setItem('adminTab', `${index}`);
                  onClick(index);
                }}
                style={{
                  marginLeft: index > 0 ? wp(3) : 0,
                  cursor: 'pointer',
                  display: 'flex',
                  borderRadius: hp(1),
                  paddingTop: hp(1.8),
                  paddingBottom: hp(2.2),
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: `rgba(6,172,172,${active == index ? 1 : 0.5})`,
                  width: isMobile() ? wp(30) : 140,
                }}
              >
                <TextLato style={{ fontSize: hp(1.5), color: '#FFFFFF', fontWeight: '600' }}>
                  {x}
                </TextLato>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default AdminTop;
