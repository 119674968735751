import * as firebase from 'firebase';
import { FIREBASE_KEY } from './constants';

const firebaseConfig = {
  apiKey: FIREBASE_KEY,
  authDomain: 'maagal-86c5c.firebaseapp.com',
  databaseURL: 'https://maagal-86c5c.firebaseio.com',
  projectId: 'maagal-86c5c',
  storageBucket: 'maagal-86c5c.appspot.com',
  messagingSenderId: '299403467499',
  appId: '1:299403467499:web:1415a077462795ad823cae',
};

firebase.initializeApp(firebaseConfig);

async function signOut() {
  sessionStorage.removeItem('index');
  sessionStorage.removeItem('prevIndex');
  localStorage.removeItem('credentials');
}

const storage = firebase.storage();

export { storage, signOut, firebase };
