import React, { CSSProperties, ReactNode } from 'react';

interface Props {
  children: ReactNode | string;
  style?: CSSProperties;
  onClick?: () => void;
}

const TextLato = React.memo(({ style, children, onClick }: Props) => {
  return (
    <p
      onClick={onClick ? onClick : () => {}}
      style={{
        ...(onClick ? { cursor: 'pointer' } : {}),
        fontFamily: 'Lato',
        fontSize: 30,
        ...style,
      }}
    >
      {children}
    </p>
  );
});

export default TextLato;
