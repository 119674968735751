import React from 'react';
import { connect } from 'react-redux';
import { setCompanyID, setCompanyInfo, setFirebaseID, setOwner } from '../../store/actions';
import { getAdminCredentials, isTryingAdminCredentials } from '../../utils/functions';
import { loginCompanyV3 } from '../../utils/services';

class SplashScreen extends React.Component {
  componentDidMount() {
    var credentials = localStorage.getItem('credentials');
    credentials = JSON.parse(credentials);

    let isAdminCredentials = credentials
      ? getAdminCredentials(credentials.username, credentials.password)
      : false;

    if (credentials != null) {
      this.completeLogin(credentials, isAdminCredentials);
    } else {
      this.props.history.push('/login');
    }
  }
  completeLogin = async (user, admin) => {
    // login user in the backend
    try {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const c = url.searchParams.get('index');
      const userQuery = url.searchParams.get('user');
      const storeQuery = url.searchParams.get('store');
      const userStr = userQuery ? `&user=${userQuery}` : '';
      const storeStr = storeQuery ? `&store=${storeQuery}` : '';
      console.log('userStr', userStr);
      this.props.history.push(`/inicio?index=${c || 0}${userStr}${storeStr}`);
      if (admin == null) {
        const loginResponse = await loginCompanyV3(user);
        let loginResult;
        console.log(loginResponse);
        let isOwner = false;
        if (loginResponse.errorCode == '200') {
          const { works, restaurants } = loginResponse;
          if (restaurants.length > 0) {
            isOwner = true;
            loginResult = restaurants[0];
          } else if (works.length > 0) {
            isOwner = false;
            loginResult = works[0];
          }
        } else {
          sessionStorage.removeItem('index');
          sessionStorage.removeItem('prevIndex');
          this.props.history.push('/login');
          localStorage.removeItem('credentials');
          return false;
        }
        let companyId = loginResult.id.toString();

        // set the ids in local storage
        this.props.setOwner(isOwner);
        this.props.setCompanyID(companyId);
        this.props.setCompanyInfo(loginResult);
      } else {
        console.log('AQUI RAPHAEL');
        this.props.setCompanyID(admin);
        this.props.setCompanyInfo({ admin: true });
      }

      return true;
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseID: state.firebaseID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFirebaseID: (id) => {
      dispatch(setFirebaseID(id));
    },
    setCompanyID: (id) => {
      dispatch(setCompanyID(id));
    },
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
    setOwner: (data) => {
      dispatch(setOwner(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
