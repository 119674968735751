import React from "react";
import { hp, wp } from "../../utils/responsive";
import TextLato from "../TextLato";
import addIcon from "../../assets/images/add-icon.png";
class BoxButton extends React.Component {
  render() {
    const {
      children,
      style,
      textStyle,
      onClick,
      icon,
      iconSource = addIcon,
    } = this.props;
    const labelStyle = {
      color: "black",
      fontWeight: "400",
      fontSize: wp(0.7) + hp(1) < 14.5 ? 14.5 : wp(0.7) + hp(1),
      ...textStyle,
    };
    return (
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        style={{
          cursor: onClick != null ? "pointer" : "auto",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: hp(2),
          paddingBottom: hp(2),
          borderRadius: hp(1.2),
          boxShadow: "2px 0px 30px rgba(0,0,0,0.2)",
          ...style,
        }}
      >
        <div style={{ width: hp(2) }} />
        {icon && <img src={iconSource} style={{ height: hp(2.8) }} />}
        {icon && <div style={{ width: hp(2) }} />}
        <TextLato style={labelStyle}>{children}</TextLato>
        <div style={{ width: hp(2) }} />
      </div>
    );
  }
}
export default BoxButton;
