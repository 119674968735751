import React from 'react';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import Input from '../Input';
import trashIcon from '../../assets/images/trash.png';
import historyIcon from '../../assets/images/historial-icon-light.png';
import Spacer from '../Spacer';
import Checkbox from '../Checkbox';
import Button from '../Button';
import BoxImage from '../BoxImage';
import DatePicker from 'react-datepicker';
import fillCheckIcon from '../../assets/images/fill-check.png';
import 'react-datepicker/dist/react-datepicker.css';
import { timeConverter } from '../../utils/functions';
import moment from 'moment';
import firebase from 'firebase';
import { firestore } from 'firebase-admin';
class FormSales extends React.Component {
  constructor(props) {
    super(props);
    if (props.initialData != null) {
      this.state = {
        companyId: props.initialData.companyId,
        id: props.initialData.id,
        name: props.initialData.name,
        description: props.initialData.description,
        original: props.initialData.price,
        discount: props.initialData.discounted,
        peso: props.initialData.peso,
        stock: props.noStock != null ? null : props.initialData.count,
        count: props.initialData.total,
        image: props.initialData.image,
        day: props.initialData.radio - 1,
        publish: (props.initialData.publish || 1) - 1,
        publishedTil:
          props.initialData.publishedTil != null
            ? props.initialData.publish == 1
              ? null
              : props.initialData.publishedTil.seconds != null
              ? new Date(props.initialData.publishedTil.seconds * 1000)
              : props.initialData.publishedTil
            : null,
        valid: props.admin ? true : false,
        expireDate:
          props.initialData.radio == 1
            ? null
            : props.initialData.expireDate != null
            ? props.initialData.expireDate.seconds != null
              ? new Date(props.initialData.expireDate.seconds * 1000)
              : props.initialData.expireDate
            : null,
        productId: props.initialData.productId,
        message: '',
        brandId: props.initialData.brandId || '',
        brandQuestionId: props.initialData.brandQuestionId || '',
        brandAnswerId: props.initialData.brandAnswerId || '',
      };
    } else {
      this.state = {
        id: null,
        companyId: null,
        name: '',
        description: '',
        original: '',
        discount: '',
        image: null,
        count: '',
        productId: null,
        message: '',
        day: 0,
        expireDate: null,
        publish: 0,
        publishedTil: null,
        valid: false,
        toStore: false,
      };
    }
    this.sameDay = React.createRef();
    this.nextDay = React.createRef();
    this.samePublishDay = React.createRef();
    this.nextPublishDay = React.createRef();
  }
  onChangeText = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.setState({ valid: this.validateForm() });
      },
    );
  };

  validateForm = () => {
    const { name, description, original, discount, count } = this.state;
    var valid = true;
    var message = '';
    if (!(name != '' && name != null)) {
      valid = false;
    }
    if (!(description != '' && description != null)) {
      valid = false;
    }
    if (!(original != '' && original != null && !isNaN(parseFloat(original)))) {
      valid = false;
    }
    if (!(discount != '' && discount != null && !isNaN(parseFloat(discount)))) {
      valid = false;
    }
    if (!this.props.administrador && !(count != '' && count != null && !isNaN(parseInt(count)))) {
      valid = false;
      message = '¡El valor ingresado debe ser un número superior a 0!';
    }
    var originalparsed = parseFloat(parseFloat(original * 0.6).toFixed(2));
    var discountparsed = parseFloat(parseFloat(discount).toFixed(2));
    if (discountparsed > originalparsed) {
      message = '¡Debe haber un descuento de 40% como mínimo!';

      valid = false;
    } else {
      console.log(discountparsed + ' -' + originalparsed);
    }
    this.setState({
      message,
    });
    return valid;
  };
  render() {
    const {
      style,
      onRelease,
      onSave,
      noStock,
      admin,
      onDelete,
      showDelete = false,
      administrador,
      onSuccessImage,
      reduced,
      okText,
      initialData,
      sales,
      showPersistance,
      loading,
    } = this.props;
    const {
      id,
      day,
      publish,
      valid,
      name,
      description,
      original,
      discount,
      peso,
      count,
      stock,
      image,
      message,
      toStore,
      productId,
      expireDate,
      companyId,
      brandId,
      brandQuestionId,
      brandAnswerId,
    } = this.state;
    const labelStyle = {
      fontSize: wp(0.7) + hp(0.6),
      opacity: 0.7,
    };
    const toStoreStyle = {
      fontSize: wp(0.6) + hp(0.6),
      opacity: 0.5,
      marginLeft: hp(1),
    };
    const fieldStyle = {
      fontSize: wp(0.8) + hp(0.6),
    };
    const inputStyle = {
      paddingTop: hp(0.5),
      paddingBottom: hp(0.5),
    };
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            borderRadius: hp(1.2),
            MozBorderRadius: hp(1.2),
            WebkitBorderRadius: hp(1.2),
            paddingTop: hp(3),
            width: '100%',
            paddingBottom: hp(3),
            WebkitBoxShadow: '2px 0px 20px rgba(0,0,0,0.2)',
            MozBoxShadow: '2px 0px 20px rgba(0,0,0,0.2)',
            boxShadow: '2px 0px 20px rgba(0,0,0,0.2)',
            ...style,
          }}
        >
          <Spacer width={wp(1.2) + hp(1)} />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {sales != null && initialData == null ? (
              <div
                onClick={() => {
                  this.setState({ toStore: !toStore });
                }}
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <img
                  src={fillCheckIcon}
                  style={{
                    opacity: toStore ? 1 : 0,
                    transition: 'opacity 0.2s',
                    width: hp(3),
                    height: hp(3),
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    opacity: toStore ? 0 : 1,
                    transition: 'opacity 0.2s',
                    display: 'flex',
                    borderRadius: hp(0.7),
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: hp(3),
                    height: hp(3),
                    backgroundColor: 'rgb(166,212,205)',
                  }}
                >
                  <div
                    style={{
                      width: hp(2.6),
                      height: hp(2.6),
                      borderRadius: hp(0.5),
                      backgroundColor: 'white',
                    }}
                  ></div>
                </div>
                <TextLato style={toStoreStyle}>Guardar en mis productos</TextLato>
              </div>
            ) : !productId && showPersistance ? (
              <div style={{ display: 'flex' }}>
                <div
                  onClick={async () => {
                    try {
                      const newObj = {
                        companyId,
                        count: 0,
                        date: new Date(),
                        description,
                        dicountedValue: Number(discount),
                        discounted: discount,
                        expireDate: null,
                        id: null,
                        image,
                        key: null,
                        name,
                        peso,
                        price: original,
                        radio: 1,
                        total: '0',
                        uid: companyId,
                        userId: companyId,
                      };
                      loading(true);
                      const newProductId = await firebase
                        .firestore()
                        .collection('products')
                        .add(newObj);
                      await firebase.firestore().collection('sales').doc(id).update({
                        productId: newProductId.id,
                      });
                      console.log(newProductId.id);
                      this.setState({
                        productId: newProductId.id,
                      });
                      loading(false);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  style={{
                    position: 'relative',
                    top: -10,
                    display: 'flex',
                  }}
                >
                  <img src={historyIcon} style={{ width: 32, height: 32 }} />
                </div>
              </div>
            ) : null}
            {((sales != null && initialData == null) || (!productId && showPersistance)) && (
              <Spacer height={hp(1)} />
            )}
            {reduced ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                  <Input
                    disabled={!administrador && !noStock && !admin}
                    value={name}
                    onChangeText={this.onChangeText}
                    name="name"
                    bottomSpc={hp(0.2)}
                    fieldStyle={fieldStyle}
                    style={inputStyle}
                    placeholder="Nombre del plato"
                  />
                </div>
                <Spacer width={hp(2)} />
                <div style={{ display: 'flex', flex: 1 }}>
                  <Input
                    disabled={!administrador && !noStock && !admin}
                    value={description}
                    onChangeText={this.onChangeText}
                    name="description"
                    bottomSpc={hp(0.2)}
                    fieldStyle={fieldStyle}
                    style={inputStyle}
                    placeholder="Descripción del plato"
                  />
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  disabled={!administrador && !noStock && !admin}
                  value={name}
                  onChangeText={this.onChangeText}
                  name="name"
                  bottomSpc={hp(0.2)}
                  fieldStyle={fieldStyle}
                  style={inputStyle}
                  placeholder="Nombre del plato"
                />
                <Input
                  disabled={!administrador && !noStock && !admin}
                  value={description}
                  onChangeText={this.onChangeText}
                  name="description"
                  bottomSpc={hp(0.2)}
                  fieldStyle={fieldStyle}
                  style={inputStyle}
                  placeholder="Descripción del plato"
                />
              </div>
            )}
            {reduced ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <Input
                      regex={/^\d*\.?\d*$/}
                      disabled={!administrador && !noStock && !admin}
                      value={original}
                      onChangeText={this.onChangeText}
                      name="original"
                      bottomSpc={hp(0.2)}
                      fieldStyle={fieldStyle}
                      style={inputStyle}
                      placeholder="Precio original"
                    />
                    <Spacer width={hp(4)} />
                    <Input
                      regex={/^\d*\.?\d*$/}
                      disabled={!administrador && !noStock && !admin}
                      value={discount}
                      onChangeText={this.onChangeText}
                      name="discount"
                      bottomSpc={hp(0.2)}
                      fieldStyle={fieldStyle}
                      style={inputStyle}
                      placeholder="Precio descontado"
                    />
                    <Spacer width={hp(4)} />
                    {admin && (
                      <Input
                        regex={/^\d*\.?\d*$/}
                        disabled={!administrador && !noStock && !admin}
                        value={peso}
                        onChangeText={this.onChangeText}
                        name="peso"
                        bottomSpc={hp(0.2)}
                        fieldStyle={fieldStyle}
                        style={inputStyle}
                        placeholder="Peso aprox (en kg)"
                      />
                    )}
                  </div>
                </div>
                {!administrador && <Spacer width={hp(2)} />}
                {!administrador && (
                  <div style={{ display: 'flex', flex: 1 }}>
                    {!administrador && (
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        {stock != null ? (
                          <Input
                            disabled={!noStock && !admin}
                            value={stock}
                            onChangeText={this.onChangeText}
                            name="stock"
                            bottomSpc={hp(0.2)}
                            fieldStyle={fieldStyle}
                            style={inputStyle}
                            placeholder="Stock"
                          />
                        ) : null}
                        {stock != null ? <Spacer width={hp(2)} /> : null}
                        <Input
                          value={count}
                          onChangeText={this.onChangeText}
                          name="count"
                          bottomSpc={hp(0.2)}
                          fieldStyle={fieldStyle}
                          style={inputStyle}
                          placeholder="Cantidad"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                  <Input
                    regex={/^\d*\.?\d*$/}
                    disabled={!administrador && !noStock && !admin}
                    value={original}
                    onChangeText={this.onChangeText}
                    name="original"
                    bottomSpc={hp(0.2)}
                    fieldStyle={fieldStyle}
                    style={inputStyle}
                    placeholder="Precio original"
                  />
                  <Spacer width={hp(2)} />
                  <Input
                    regex={/^\d*\.?\d*$/}
                    disabled={!administrador && !noStock && !admin}
                    value={discount}
                    onChangeText={this.onChangeText}
                    name="discount"
                    bottomSpc={hp(0.2)}
                    fieldStyle={fieldStyle}
                    style={inputStyle}
                    placeholder="Precio descontado"
                  />
                  <Spacer width={hp(2)} />
                  {admin && (
                    <Input
                      regex={/^\d*\.?\d*$/}
                      disabled={!administrador && !noStock && !admin}
                      value={peso}
                      onChangeText={this.onChangeText}
                      name="peso"
                      bottomSpc={hp(0.2)}
                      fieldStyle={fieldStyle}
                      style={inputStyle}
                      placeholder="Peso aprox (en kg)"
                    />
                  )}
                </div>
                {!administrador && (
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    {stock != null ? (
                      <Input
                        disabled={!noStock && !admin}
                        value={stock}
                        onChangeText={this.onChangeText}
                        name="stock"
                        bottomSpc={hp(0.2)}
                        fieldStyle={fieldStyle}
                        style={inputStyle}
                        placeholder="Stock"
                      />
                    ) : null}
                    {stock != null ? <Spacer width={hp(2)} /> : null}
                    <Input
                      value={count}
                      onChangeText={this.onChangeText}
                      name="count"
                      bottomSpc={hp(0.2)}
                      fieldStyle={fieldStyle}
                      style={inputStyle}
                      placeholder="Cantidad"
                    />
                  </div>
                )}
              </div>
            )}

            <Spacer height={hp(0.5)} />
            {administrador ? (
              <div></div>
            ) : reduced ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                  <TextLato style={labelStyle}>Tiempo máximo recomendando consumo</TextLato>
                  <Spacer height={hp(1)} />
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      initialValue={day == 0}
                      group={true}
                      ref={this.sameDay}
                      onChangeValue={(val) => {
                        /* if (!administrador) {
                          if (moment(this.state.publishedTil).isAfter(moment(new Date()))) {
                            this.setState({
                              publish: 0,
                            });
                            this.samePublishDay.current.handleActive(true);
                            this.nextPublishDay.current.handleActive(false);
                          }
                        } */
                        this.setState({
                          day: 0,
                        });
                        this.nextDay.current.handleActive(false);
                      }}
                      label={'Mismo día'}
                      labelStyle={labelStyle}
                      size={wp(1) + hp(1)}
                    />
                    <Spacer width={wp(3)} />
                    <DatePicker
                      onClickOutside={() => {
                        if (this.state.expireDate == null) {
                          if (moment(new Date()).isBefore(moment(this.state.publishedTil))) {
                            this.setState({ publishedTil: new Date() });
                          }
                          this.setState({ expireDate: new Date() });
                        } else {
                          if (
                            moment(this.state.expireDate).isBefore(moment(this.state.publishedTil))
                          ) {
                            this.setState({ publishedTil: this.state.expireDate });
                          }
                        }
                      }}
                      customInput={<div></div>}
                      minDate={new Date()}
                      ref={(x) => (this.datepicker = x)}
                      selected={this.state.expireDate || new Date()}
                      onChange={(x) => {
                        if (moment(x).isBefore(moment(this.state.publishedTil))) {
                          this.setState({ publishedTil: x });
                        }
                        this.setState({ expireDate: x });
                      }}
                    />
                    <Checkbox
                      initialValue={day == 1}
                      ref={this.nextDay}
                      group={true}
                      onChangeValue={(val) => {
                        this.datepicker.setOpen(true);
                        this.setState({
                          day: 1,
                        });

                        this.sameDay.current.handleActive(false);
                      }}
                      label={
                        this.state.expireDate != null
                          ? timeConverter(this.state.expireDate, 'date', false)
                          : 'Elegir fecha'
                      }
                      labelStyle={labelStyle}
                      size={wp(1) + hp(1)}
                    />
                  </div>
                </div>
                {!administrador && <Spacer width={hp(2)} />}
                {!administrador && (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                    }}
                  >
                    {!administrador && <TextLato style={labelStyle}>Publicar hasta</TextLato>}
                    <Spacer height={hp(1)} />
                    {!administrador && (
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Checkbox
                          initialValue={publish == 0}
                          group={true}
                          ref={this.samePublishDay}
                          onChangeValue={(val) => {
                            this.setState({
                              publish: 0,
                            });
                            this.nextPublishDay.current.handleActive(false);
                          }}
                          label={'Mismo día'}
                          labelStyle={labelStyle}
                          size={wp(1) + hp(1)}
                        />
                        <Spacer width={wp(3)} />
                        <DatePicker
                          onClickOutside={() => {
                            if (this.state.publishedTil == null) {
                              this.setState({ publishedTil: new Date() });
                            }
                          }}
                          maxDate={day == 1 ? this.state.expireDate || new Date() : null}
                          customInput={<div></div>}
                          minDate={new Date()}
                          ref={(x) => (this.datepublishpicker = x)}
                          selected={this.state.publishedTil || new Date()}
                          onChange={(x) => this.setState({ publishedTil: x })}
                        />
                        <Checkbox
                          initialValue={publish == 1}
                          ref={this.nextPublishDay}
                          group={true}
                          onChangeValue={(val) => {
                            this.datepublishpicker.setOpen(true);
                            this.setState({
                              publish: 1,
                            });

                            this.samePublishDay.current.handleActive(false);
                          }}
                          label={
                            this.state.publishedTil != null
                              ? timeConverter(this.state.publishedTil, 'date', false)
                              : 'Elegir fecha'
                          }
                          labelStyle={labelStyle}
                          size={wp(1) + hp(1)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextLato style={labelStyle}>Tiempo máximo recomendando consumo</TextLato>
                <Spacer height={hp(1)} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                  <Checkbox
                    initialValue={day == 0}
                    group={true}
                    ref={this.sameDay}
                    onChangeValue={(val) => {
                      this.setState({
                        day: 0,
                      });
                      this.nextDay.current.handleActive(false);
                    }}
                    label={'Mismo día'}
                    labelStyle={labelStyle}
                    size={wp(1) + hp(1)}
                  />
                  <Spacer width={wp(3)} />
                  <DatePicker
                    onClickOutside={() => {
                      if (this.state.expireDate == null) {
                        if (moment(new Date()).isBefore(moment(this.state.publishedTil))) {
                          this.setState({ publishedTil: new Date() });
                        }
                        this.setState({ expireDate: new Date() });
                      } else {
                        if (
                          moment(this.state.expireDate).isBefore(moment(this.state.publishedTil))
                        ) {
                          this.setState({ publishedTil: this.state.expireDate });
                        }
                      }
                    }}
                    customInput={<div></div>}
                    minDate={new Date()}
                    ref={(x) => (this.datepicker = x)}
                    selected={this.state.expireDate || new Date()}
                    onChange={(x) => {
                      if (moment(x).isBefore(moment(this.state.publishedTil))) {
                        this.setState({ publishedTil: x });
                      }
                      this.setState({ expireDate: x });
                    }}
                  />
                  <Checkbox
                    initialValue={day == 1}
                    ref={this.nextDay}
                    group={true}
                    onChangeValue={(val) => {
                      this.datepicker.setOpen(true);
                      this.setState({
                        day: 1,
                      });

                      this.sameDay.current.handleActive(false);
                    }}
                    label={
                      this.state.expireDate != null
                        ? timeConverter(this.state.expireDate, 'date', false)
                        : 'Elegir fecha'
                    }
                    labelStyle={labelStyle}
                    size={wp(1) + hp(1)}
                  />
                </div>
                {!administrador && <TextLato style={labelStyle}>Publicar hasta</TextLato>}
                <Spacer height={hp(1)} />
                {!administrador && (
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      initialValue={publish == 0}
                      group={true}
                      ref={this.samePublishDay}
                      onChangeValue={(val) => {
                        this.setState({
                          publish: 0,
                        });
                        this.nextPublishDay.current.handleActive(false);
                      }}
                      label={'Mismo día'}
                      labelStyle={labelStyle}
                      size={wp(1) + hp(1)}
                    />
                    <Spacer width={wp(3)} />
                    <DatePicker
                      onClickOutside={() => {
                        if (this.state.publishedTil == null) {
                          this.setState({ publishedTil: new Date() });
                        }
                      }}
                      maxDate={day == 1 ? this.state.expireDate || new Date() : null}
                      customInput={<div></div>}
                      minDate={new Date()}
                      ref={(x) => (this.datepublishpicker = x)}
                      selected={this.state.publishedTil || new Date()}
                      onChange={(x) => this.setState({ publishedTil: x })}
                    />
                    <Checkbox
                      initialValue={publish == 1}
                      ref={this.nextPublishDay}
                      group={true}
                      onChangeValue={(val) => {
                        this.datepublishpicker.setOpen(true);
                        this.setState({
                          publish: 1,
                        });

                        this.samePublishDay.current.handleActive(false);
                      }}
                      label={
                        this.state.publishedTil != null
                          ? timeConverter(this.state.publishedTil, 'date', false)
                          : 'Elegir fecha'
                      }
                      labelStyle={labelStyle}
                      size={wp(1) + hp(1)}
                    />
                  </div>
                )}
              </div>
            )}

            <Spacer height={hp(2)} />

            <TextLato style={labelStyle}>Imagen</TextLato>
            <Spacer height={hp(1)} />
            <div style={{ display: 'flex' }}>
              <BoxImage
                onSuccess={onSuccessImage}
                upload={true}
                url={this.props.file != null ? URL.createObjectURL(this.props.file) : image}
                size={wp(3) + hp(2)}
              />
            </div>
          </div>
          {admin || showDelete ? (
            <div onClick={onDelete} style={{ cursor: 'pointer' }}>
              <img
                src={trashIcon}
                style={{
                  width: wp(1) + hp(1),
                  height: ((wp(1) + hp(1)) * 512) / 404,
                  position: 'relative',
                  top: -(wp(0.8) + hp(0.7)),
                  left: -(wp(0.8) + hp(0.7)),
                }}
              />
            </div>
          ) : (
            <Spacer width={wp(1.2) + hp(1)} />
          )}
        </div>
        <Spacer height={hp(1.5)} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextLato style={{ color: '#D87F7A', fontSize: wp(0.7) + hp(0.6) }}>{message}</TextLato>
        </div>
        <Spacer height={hp(1.5)} />

        <div
          style={{
            flex: 1,
            width: reduced ? '50%' : '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Spacer width={hp(4)} />
          <Button
            backgroundColor={'#ffff'}
            onClick={() => {
              if (onRelease) onRelease();
            }}
            second={true}
            textStyle={{ fontSize: wp(0.8) + hp(0.5), color: '#392E2E' }}
            style={{
              display: 'flex',
              flex: 1,
              paddingTop: hp(1.4),
              paddingBottom: hp(1.4),
              borderRadius: hp(0.8),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Deshacer
          </Button>
          <Spacer width={hp(4)} />
          <Button
            backgroundColor={'#D87F7A'}
            onClick={() => {
              if (onSave) {
                var sendData = {
                  id: null,
                  name,
                  description,
                  image: image != null ? image : null,
                  original,
                  discount,
                  peso,
                  stock,
                  count,
                  radio: day + 1,
                  expireDate,
                  publish: publish + 1,
                  publishedTil: this.state.publishedTil || new Date(),
                  toStore,
                  productId,
                  brandId,
                  brandQuestionId,
                  brandAnswerId,
                };
                onSave(sendData);
              }
            }}
            disabled={!valid}
            second={true}
            textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
            style={{
              display: 'flex',
              flex: 1,
              paddingTop: hp(1.4),
              paddingBottom: hp(1.4),
              borderRadius: hp(0.8),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {okText || 'Publicar'}
          </Button>
          <Spacer width={hp(4)} />
        </div>
        <Spacer height={hp(4)} />
      </div>
    );
  }
}

export default FormSales;
