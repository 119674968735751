import React from 'react';
import './index.css';
import { hp } from '../../utils/responsive';
import Spacer from '../Spacer';
import TextLato from '../TextLato';
class Input extends React.Component {
  state = {
    debounce: false,
  };
  onDebounce = () => {};
  render() {
    var {
      onChangeText,
      placeholder,
      value,
      style,
      bottomSpc,
      name,
      disabled,
      fieldStyle,
      onBlur,
      prefix,
      iconRight,
      onClick,
      regex,
    } = this.props;
    if (fieldStyle == null) fieldStyle = {};
    return (
      <div
        className="input"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          borderRadius: hp(1.2),
          ...style,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            position: 'relative',
          }}
        >
          {prefix && (
            <TextLato
              style={{
                color: disabled ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,1)',
                ...fieldStyle,
              }}
            >
              {prefix}
            </TextLato>
          )}
          <input
            onBlur={onBlur != null ? onBlur : () => {}}
            disabled={disabled}
            value={value}
            style={{
              fontSize: hp(2.2),
              display: 'flex',
              flex: 1,
              color: disabled ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,1)',
              ...fieldStyle,
            }}
            name={name ? name : ''}
            placeholder={placeholder || 'Ingrese texto...'}
            onChange={(e) => {
              if (onChangeText != null) {
                if (regex) {
                  if (!regex.test(e.target.value)) return;
                }
                onChangeText(e.target.value, name);
                this.onDebounce();
              }
            }}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
          />
          {iconRight != null ? iconRight() : null}
        </div>
        <Spacer height={bottomSpc} />
        <div
          style={{
            height: 1,
            backgroundColor: disabled ? 'rgba(100,100,100,0.2)' : 'rgba(100,100,100,0.4)',
          }}
        />
      </div>
    );
  }
}
export default Input;
