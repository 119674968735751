import React from 'react';
import './index.css';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import Spacer from '../Spacer';
import moment from 'moment';
const SliderMulti = React.memo(function SliderMulti({ handle, textStyle, value, disabled, style }) {
  const labelStyle = {
    color: 'white',
    fontWeight: '400',
    fontSize: hp(1.9),
    ...textStyle,
  };
  const iconStyle = {
    color: 'white',
    fontWeight: '500',
    fontSize: hp(2.8),
  };
  var mcurrent = moment(value, 'HH:mm');
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: hp(0.7),

        boxShadow: '0px 0px 1px rgba(0,0,0,0.2)',
        ...style,
        backgroundColor: disabled ? '#BCBBC1' : '#02A9AB',
      }}
    >
      <div
        onClick={() => {
          if (!disabled) handle(mcurrent.subtract(30, 'minutes').format('HH:mm'));
        }}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          padding: `${hp(0.3)}px ${hp(2)}px`,
        }}
      >
        <TextLato style={iconStyle}>—</TextLato>
      </div>
      <TextLato style={labelStyle}>{value}</TextLato>

      <div
        onClick={() => {
          if (!disabled) handle(mcurrent.add(30, 'minutes').format('HH:mm'));
        }}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          padding: `${hp(0.3)}px ${hp(2)}px`,
        }}
      >
        <TextLato style={iconStyle}>+</TextLato>
      </div>
    </div>
  );
});
export default SliderMulti;
