import React from 'react';
import './index.css';
import { hp, wp } from '../../utils/responsive';
import TextLato from '../TextLato';
import logo from '../../assets/images/logo.png';
import Spacer from '../Spacer';
const TabBar = React.memo(function Footer({
  tabs,
  icons,
  hiddenTab,
  iconsWhite,
  current,
  onClick,
  onClickSubtitle,
  disabled,
  currentSub,
  hidden,
  onTapLogo,
}) {
  const titleStyle = {
    color: '#FFFFFF',
    fontSize: wp(0.5) + hp(1) < 14 ? 14 : wp(0.5) + hp(1),
    fontWeight: '500',
    paddingLeft: hp(1.3),
  };
  const subtitleStyle = {
    color: '#FFFFFF',
    fontSize: wp(0.5) + hp(1) < 14 ? 14 : wp(0.5) + hp(1),
    fontWeight: '300',
    paddingLeft: hp(6.6),
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: '#02A9AB',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Spacer height={hp(3)} />
      <div
        style={{
          cursor: 'pointer',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          if (onTapLogo != null) {
            onTapLogo();
          }
        }}
      >
        <img src={logo} style={{ width: '27%' }} />
      </div>
      <Spacer height={hp(6)} />
      <div
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          flexDirection: 'column',
        }}
      >
        {tabs.map((item, index) => {
          if (index == hidden) return null;
          return (
            <div style={{ width: '100%' }}>
              <div
                onClick={() => {
                  if (!disabled && current != index) onClick(index);
                }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: hp(6),
                  marginTop: hp(1),
                  marginBottom: hp(1),
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  borderTopLeftRadius: hp(3),
                  borderBottomLeftRadius: hp(3),
                  marginLeft: hp(1.3),
                  backgroundColor: current == index ? '#FFFFFF' : null,
                }}
              >
                <div
                  style={{
                    width: hp(4),
                    marginLeft: hp(1.3),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={current == index ? icons[index] : iconsWhite[index]}
                    style={{ height: hp(2.5) }}
                  />
                </div>
                <TextLato
                  style={{
                    ...titleStyle,
                    position: 'relative',
                    zIndex: 1,
                    color: current == index ? '#02A9AB' : '#FFFFFF',
                  }}
                >
                  {item.title}
                </TextLato>
                {current == index && (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      top: -hp(4),
                      right: hp(1.3),
                      height: hp(4),
                      width: hp(4),
                      backgroundColor: 'white',
                    }}
                  ></div>
                )}
                {current == index && (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      bottom: -hp(4),
                      right: hp(1.3),
                      height: hp(4),
                      width: hp(4),
                      backgroundColor: 'white',
                    }}
                  ></div>
                )}
                {current == index && (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      top: -hp(8),
                      right: hp(1.3),
                      borderRadius: hp(4),
                      height: hp(8),
                      width: hp(8),
                      backgroundColor: '#02A9AB',
                    }}
                  ></div>
                )}
                {current == index && (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      bottom: -hp(8),
                      right: hp(1.3),
                      borderRadius: hp(4),
                      height: hp(8),
                      width: hp(8),
                      backgroundColor: '#02A9AB',
                    }}
                  ></div>
                )}
              </div>
              {item.subtitles != null &&
                item.subtitles.map((x, j) => {
                  return (
                    <div
                      onClick={() => {
                        if (!disabled) onClickSubtitle(index, j);
                      }}
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: hp(7.5),
                        marginLeft: hp(1.3),
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Spacer width={hp(1.3)} />
                      {typeof x == 'string' ? (
                        <TextLato
                          style={{
                            ...subtitleStyle,
                            position: 'absolute',
                            zIndex: 1,
                            textDecoration:
                              currentSub == j && current == index ? 'underline' : 'none',
                          }}
                        >
                          {x}
                        </TextLato>
                      ) : (
                        x(currentSub == j && current == index ? 'underline' : 'none')
                      )}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default TabBar;
