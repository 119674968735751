import { useEffect, useState } from 'react'
// import { doc, getDoc } from 'firebase/firestore';
import { firebase } from '../utils/firebase'

// faq => frequently asked questions
const FAQ_NAME_COLLECTION = 'faq'

export const useFAQFirestore = () => {

  const [faq, setFaq] = useState<any[]>([])

  const getQuestions = async () => {
    await firebase
      .firestore()
      .collection(FAQ_NAME_COLLECTION)
      .doc(FAQ_NAME_COLLECTION)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          let counter = 0
          const questions = doc.data().questions.map((item: any) => {
            counter = counter + 1
            return ({ ...item, id: new Date().getTime() + counter})
          })
          setFaq(questions)
        } else {
          setFaq([])
        }
      }).catch((err) => {
        // HUBO UN ERROR
      })
  }

  useEffect(() => {
    getQuestions()
  }, [])

  const updateQuestions = async (questions: any[]) => {
    return await firebase
      .firestore()
      .collection(FAQ_NAME_COLLECTION)
      .doc(FAQ_NAME_COLLECTION)
      .update({
        questions: questions
      }).then(() => {
        // ACTUALIZO CORRECTAMENTE
        return true
      }).catch((err) => {
        // OCURRIO UN ERROR
        return false
      })
  }

  useEffect(() => {
    getQuestions()
  }, [])

  return { faq, updateQuestions }
}