import actionNames from '../action_names';

const initialState = {
  firebaseID: '',
  companyID: '',
  currentReceipt: null,
  companyInfo: null,
  currentCompantID: '',
  isOwner: false,
  locals: [],
  users: {},
  stores: {},
  nodevstores: {},
};
function authApp(state = initialState, action) {
  switch (action.type) {
    case actionNames.SET_FIREBASEID:
      return { ...state, firebaseID: action.payload };
    case actionNames.SET_COMPANYID:
      return { ...state, companyID: action.payload };
    case actionNames.SET_CURRENTRECEIPT:
      return { ...state, currentReceipt: action.payload };
    case actionNames.SET_COMPANYINFO:
      return { ...state, companyInfo: action.payload };
    case actionNames.SET_CURRENTCOMPANYID:
      return { ...state, currentCompantID: action.payload };
    case actionNames.SET_OWNER:
      return { ...state, isOwner: action.payload };
    case actionNames.SET_LOCALS:
      return { ...state, locals: action.payload };
    case actionNames.SET_USERS:
      return { ...state, users: action.payload };
    case actionNames.SET_STORES:
      return { ...state, stores: action.payload };
    case actionNames.SET_NODEV_STORES:
      console.log(action.payload);
      return { ...state, nodevstores: action.payload };
    default:
      return state;
  }
}

export default authApp;
