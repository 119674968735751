import React from 'react';
import { connect } from 'react-redux';
import loginIcon from '../../assets/images/login-icon.png';
import logo from '../../assets/images/logo-text.png';
import logoWithoutText from '../../assets/images/logo.png';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import {
  setCompanyID,
  setCompanyInfo,
  setFirebaseID,
  setLocals,
  setOwner,
} from '../../store/actions';
import { hp, wp } from '../../utils/responsive';
import { loginCompanyV3 } from '../../utils/services';
import './index.css';

import { css } from '@emotion/core';
import FadeLoader from 'react-spinners/FadeLoader';
import { getAdminCredentials } from '../../utils/functions';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Login extends React.Component {
  state = {
    username: '',
    password: '',
    loading: false,
  };
  prueba = async () => { };
  prue = () => { };

  async componentDidMount() {
    var credentials = localStorage.getItem('credentials');
    if (credentials != null) {
      this.props.history.push('/inicio');
    } else {
      console.log(this.props);
      window.addEventListener('resize', this.resize);
      /*if (!document.getElementsByClassName('crisp-client')[0]) {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = 'fad06750-6c61-40a3-b82f-2def788e3ddb';

        (async function () {
          var d = document;
          var s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';
          s.async = 1;
          s.id = 'asd';
          await d.getElementsByTagName('head')[0].appendChild(s);
        })();
      } else {
        document.getElementsByClassName('crisp-client')[0].style.display = null;
      }*/
    }
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  handleLogin = async () => {
    var username = this.state.username.toLowerCase();
    var password = this.state.password;
    try {
      this.setState({ loading: true });
      var res = await this.completeLogin(
        { username, password },
        getAdminCredentials(username, password),
      );
      if (!res) {
        this.setState({ loading: false }, () => alert('El usuario y contraseña son incorrectos'));
      }
    } catch (e) {
      var error = e;

      this.setState({ loading: false }, () => alert(error.message));
    }
  };
  completeLogin = async (user, admin) => {
    // login user in the backend
    const $this = this;
    console.log(admin);
    try {
      if (admin == null) {
        const loginResponse = await loginCompanyV3(user);
        var loginResult = null;
        console.log(loginResponse);
        var isOwner = false;
        if (loginResponse.errorCode == '200') {
          localStorage.setItem('credentials', JSON.stringify(user));
          this.setState({ loading: false }, () => {
            setTimeout(() => $this.props.history.push('/inicio'), 10);
          });

          const { works, restaurants } = loginResponse;
          var listRest = [];
          if (restaurants.length > 0) {
            isOwner = true;
            listRest = [
              ...listRest,
              ...restaurants.map((x) => {
                return {
                  ...x,
                  isOwner,
                };
              }),
            ];
            loginResult = restaurants[0];
          }
          if (works.length > 0) {
            isOwner = false;
            listRest = [
              ...listRest,
              ...works.map((x) => {
                return {
                  ...x,
                  isOwner,
                };
              }),
            ];
            loginResult = works[0];
          }
        } else {
          return false;
        }

        if (listRest.length > 1) {
          this.props.setLocals(listRest);
          this.props.setCompanyID('locals');
        } else {
          this.props.setLocals(null);
          let companyId = loginResult.id.toString();

          // set the ids in local storage
          this.props.setOwner(isOwner);
          this.props.setCompanyID(companyId);
          this.props.setCompanyInfo(loginResult);
        }
      } else {
        localStorage.setItem('credentials', JSON.stringify(user));

        this.setState({ loading: false }, () => {
          setTimeout(() => $this.props.history.push('/inicio'), 10);
        });
        this.props.setCompanyID(admin);
        this.props.setCompanyInfo({ admin: true });
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  render() {
    const { username, password, loading } = this.state;
    const titleStyle = {
      color: '#392E2E',
      fontWeight: '400',
      fontSize: wp(0.7) + hp(2.1),
    };
    return (
      <div className="content" style={{ height: hp(100) }}>
        <div style={{ zIndex: 1, position: 'absolute', height: '100vh', width: '100vw', alignContent: 'center', display: 'grid', justifyContent: 'center', backgroundColor: 'rgb(0 0 0 / 0.5)' }}>
          <div style={{
            padding: 30, borderRadius: 10,
            position: 'relative', height: '85vh', width: '80vw', backgroundColor: '#01A8AB', display: 'grid', placeItems: 'center'
          }}>
            <div style={{ display: 'grid', gap: 10, placeItems: 'center' }}>
              <img src={logoWithoutText} style={{ height: 150, width: 'auto' }} />
              <p style={{ fontSize: '56px', fontWeight: 'bold', color: 'white' }}>Cikrula</p>
              <p style={{ fontSize: '24px', color: 'white', textAlign: 'center', maxWidth: 420 }}> Hemos actualizado la web de negocios. Haz clic abajo para ir a la nueva web</p>
              <a href='https://admin.cirkulaapp.com/' style={{ fontSize: '18px', textDecoration: 'none', backgroundColor: 'white', borderRadius: 30, padding: '10px 20px', color: '#026666', marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                <p >Ir a la nueva web</p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '80%',
                maxWidth: 450,
                height: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextLato style={titleStyle}>Iniciar Sesión</TextLato>
              <Spacer height={hp(3)} />
              <img src={loginIcon} style={{ height: hp(18), maxHeight: wp(20) }} />
              <Spacer height={hp(4.5)} />
              <InputBox
                placeholder="Usuario"
                value={username}
                onChangeText={(str) => {
                  this.setState({
                    username: str,
                  });
                }}
              />

              <Spacer height={hp(4.5)} />
              <InputBox
                placeholder="Contraseña"
                value={password}
                pass={true}
                onChangeText={(str) => {
                  this.setState({
                    password: str,
                  });
                }}
              />
              <Spacer height={hp(7)} />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Button
                  backgroundColor={'#D87F7A'}
                  onClick={this.handleLogin}
                  style={{
                    padding: `${hp(2)}px ${wp(4)}px`,
                    borderRadius: hp(1),
                  }}
                >
                  Ingresar
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              backgroundColor: '#02A9AB',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={logo} style={{ height: wp(30) }} />
          </div>
        </div>
        {loading && (
          <div
            style={{
              position: 'absolute',
              width: wp(100),
              height: hp(100),
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.4)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FadeLoader css={override} size={130} color={'#40E0D0'} loading={true} />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFirebaseID: (id) => {
      dispatch(setFirebaseID(id));
    },
    setCompanyID: (id) => {
      dispatch(setCompanyID(id));
    },
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
    setOwner: (data) => {
      dispatch(setOwner(data));
    },
    setLocals: (data) => {
      dispatch(setLocals(data));
    },
  };
};
export default connect(null, mapDispatchToProps)(Login);
