import axios from 'axios';
import { ADMIN_PASSWORD } from './constants';
const BASE_URL = 'https://api.maagalapp.com';

export async function deleteCoupon(userId, couponId) {
  const response = await axios.delete(`${BASE_URL}/promos/remove/${userId}/${couponId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function loginCompanyV2(request) {
  const response = await axios.post(`${BASE_URL}/auth/v2/storelogin`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function reportMixpanel(request) {
  console.log(request);
  const response = await axios.post(`${BASE_URL}/reports/mixpanel`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function loginCompanyV3(request) {
  const response = await axios.post(`${BASE_URL}/auth/v3/storelogin`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getCompany(id) {
  const response = await axios.get(`${BASE_URL}/stores/v2/${id}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getHolidays(id) {
  const response = await axios.get(`${BASE_URL}/holidays/holidays`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getCouponsForUser(userId) {
  const response = await axios.get(`${BASE_URL}/promos/${userId}/coupons`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function validateCoupon(request) {
  const response = await axios.post(`${BASE_URL}/promos/validatecupon`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateNewStoreSchedule(request) {
  const response = await axios.post(`${BASE_URL}/stores/schedule`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function setupHoliday(request) {
  const response = await axios.post(`${BASE_URL}/stores/holiday`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function deleteNewStoreSchedule(scheduleId) {
  const response = await axios.delete(`${BASE_URL}/stores/schedule/${scheduleId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function deleteCards(cardId) {
  const response = await axios.delete(`${BASE_URL}/sales/kushki/unregister/${cardId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getCards(userId) {
  const response = await axios.get(`${BASE_URL}/users/cardtoken/${userId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getUsers() {
  const response = await axios.get(`${BASE_URL}/users`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      pass: ADMIN_PASSWORD,
    },
  });
  return response.data;
}
export async function updateStoreStock(storeId) {
  const response = await axios.get(`${BASE_URL}/sales/update-sales-stock/${storeId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getUsersFirebase() {
  const response = await axios.get(`${BASE_URL}/users/firebase`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function suspendUser(userId) {
  const response = await axios.put(`${BASE_URL}/users/suspend/${userId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getUserProfile(userId) {
  const response = await axios.get(`${BASE_URL}/users/${userId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateUserProfile(request) {
  const response = await axios.post(`${BASE_URL}/users/updateprofile`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getPromos() {
  const response = await axios.get(`${BASE_URL}/promos`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getCategories() {
  const response = await axios.get(`${BASE_URL}/stores/categories`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function createPromos(request) {
  const response = await axios.post(`${BASE_URL}/promos/create`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updatePromos(request) {
  const response = await axios.put(`${BASE_URL}/promos`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateCategories(request) {
  const response = await axios.post(`${BASE_URL}/stores/categories`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updatePriceDelivery(request) {
  const response = await axios.post(`${BASE_URL}/stores/updatePriceDelivery`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateCategoriesv2(request) {
  const response = await axios.post(`${BASE_URL}/stores/categoriesv2`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function createCategories(request) {
  const response = await axios.post(`${BASE_URL}/stores/categories-create`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getRatings() {
  const response = await axios.get(`${BASE_URL}/sales/ratings`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function notifyUsers(storeId) {
  const response = await axios.put(`${BASE_URL}/stores/${storeId}/notify-new-plate`, null, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function changeStoreType(request) {
  const response = await axios.put(`${BASE_URL}/stores/type`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function changeStoreStatus(request) {
  const response = await axios.put(`${BASE_URL}/stores/status`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function changeStoreDelivery(request) {
  const response = await axios.put(`${BASE_URL}/stores/delivery`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getAllStores() {
  const response = await axios.get(`${BASE_URL}/stores/discover-dev`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getAllGroupedStores() {
  const response = await axios.get(`${BASE_URL}/stores/groups`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getAllCentrosComerciales() {
  const response = await axios.get(`${BASE_URL}/comerciales/comercials`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getStoreProductionCount() {
  const response = await axios.get(`${BASE_URL}/stores/quantity`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getAllGroupedUsers(groupId) {
  const response = await axios.get(`${BASE_URL}/stores/groups/${groupId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function createGroupedStores(request) {
  const response = await axios.post(`${BASE_URL}/stores/groups`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function deleteGroup(groupId) {
  const response = await axios.delete(`${BASE_URL}/stores/groups/${groupId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function deleteStore(storeId) {
  const response = await axios.delete(`${BASE_URL}/stores/${storeId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function deleteComercial(comercialId) {
  const response = await axios.delete(`${BASE_URL}/comerciales/${comercialId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function createEmployee(request) {
  const response = await axios.post(`${BASE_URL}/stores/employee`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function createStores(request) {
  const response = await axios.post(`${BASE_URL}/stores/create`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function createComerciales(request) {
  const response = await axios.post(`${BASE_URL}/comerciales/create`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getComercials() {
  const response = await axios.get(`${BASE_URL}/comerciales/comercials`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateOrderPickupDate(orderId) {
  const response = await axios.patch(`${BASE_URL}/stores/${orderId}/set-pickup`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function cancelOrderPickupDate(orderId) {
  const response = await axios.patch(`${BASE_URL}/stores/${orderId}/cancel-pickup`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateStoreInfo(request) {
  const response = await axios.put(`${BASE_URL}/stores/update`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updateComercialInfo(request) {
  const response = await axios.put(`${BASE_URL}/comerciales/update`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getUserFullName(userId) {
  try {
    const response = await axios.get(`${BASE_URL}/users/${userId}`, null, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (response.data.fullName != null)
      return { fullName: response.data.fullName, phone: response.data.phone };
    else return { fullName: '-', phone: '-' };
  } catch (error) {
    return { fullName: '-', phone: '-' };
  }
}
export async function searchUsers(search) {
  const response = await axios.get(`${BASE_URL}/users/search`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      query: search,
    },
  });
  return response.data;
}

export async function queryUsers(query, page) {
  const response = await axios.get(`${BASE_URL}/users/search`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      query,
      page,
    },
  });
  return response.data;
}

export async function sendNotification(ids, title, message) {
  const response = await axios.post(
    `${BASE_URL}/users/send-notification`,
    {
      ids: ids,
      title: title,
      message: message,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
  var res = response.data;
  return parseInt(res.errorCode);
}

export async function searchUsersIds(ids) {
  const response = await axios.post(
    `${BASE_URL}/users/search`,
    { ids },
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
  var users = response.data;
  var usersKeys = {};
  for (var i = 0; i < users.items.length; i++) {
    usersKeys[`${users.items[i].id}`] = {
      deviceToken: users.items[i].deviceToken != null ? users.items[i].deviceToken : null,
      name: users.items[i].fullName != null ? users.items[i].fullName : 'N/A',
      email: users.items[i].email != null ? users.items[i].email : 'N/A',
    };
  }
  return usersKeys;
}
export async function getUsersIds() {
  const response = await axios.get(`${BASE_URL}/users`, {
    headers: { 'Content-Type': 'application/json' },
  });
  var users = response.data;
  var usersKeys = {};
  for (var i = 0; i < users.items.length; i++) {
    usersKeys[`${users.items[i].id}`] = {
      deviceToken: users.items[i].deviceToken != null ? users.items[i].deviceToken : null,
      name: users.items[i].fullName != null ? users.items[i].fullName : 'N/A',
      email: users.items[i].email != null ? users.items[i].email : 'N/A',
    };
  }
  return usersKeys;
}
export async function updateStoreSchedule(request) {
  const response = await axios.put(`${BASE_URL}/stores/schedules`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getSalesReport(storeId) {
  const response = await axios.get(`${BASE_URL}/reports/${storeId}/salesreport`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getPushNotifications() {
  const response = await axios.get(`${BASE_URL}/users/push-notifications`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getUsersQTY() {
  const response = await axios.post(
    `${BASE_URL}/users/quantity`,
    {},
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
  var res = response.data;
  return parseInt(res.errorMessage);
}
export async function createPushNotifications(request) {
  const response = await axios.post(`${BASE_URL}/users/push-notifications`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function editPushNotifications(request) {
  const response = await axios.put(`${BASE_URL}/users/push-notifications-values`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function updatePushNotifications(request) {
  const response = await axios.put(`${BASE_URL}/users/push-notifications`, request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getPushNotificationsById(id) {
  const response = await axios.get(`${BASE_URL}/users/push-notifications/${id}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getClientsReport(storeId) {
  const response = await axios.get(`${BASE_URL}/reports/${storeId}/clientsreport`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}

export async function getMostSelledReport(storeId) {
  const response = await axios.get(`${BASE_URL}/reports/${storeId}/topdishesreport`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}

export async function getPopularDaysReport(storeId) {
  const response = await axios.get(`${BASE_URL}/reports/${storeId}/populardaysreport`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}

export async function getPopularTimesReport(storeId) {
  const response = await axios.get(`${BASE_URL}/reports/${storeId}/popularschedulesreport`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
export async function getRedeemReports() {
  const response = await axios.get(`${BASE_URL}/promos/report-redeem`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
}
