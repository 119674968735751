import React from 'react';
import './index.css';
import { hp, wp } from '../../utils/responsive';
import searchIcon from '../../assets/images/search-icon.png';
import Spacer from '../Spacer';
import CheckButton from '../CheckButton';
import TextLato from '../TextLato';
import CheckFillButton from '../CheckFillButton';
class InputBoxSelector extends React.Component {
  constructor(props) {
    super(props);
    this.listBox = React.createRef();
    this.input = React.createRef();
    this.state = {
      open: false,
      search: '',
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.state.open)
      if (!this.listBox.current.contains(event.target)) {
        this.setState({ open: false });
      }
  };
  render() {
    const { open, search } = this.state;
    const {
      renderName,
      levelShadow = 30,
      style = {},
      items,
      onHandle,
      showAll = true,
    } = this.props;
    const all = items.filter((x) => !x.value).length === 0;
    const reduced = items
      .filter((x) => x.value)
      .map((x) => {
        if (renderName) return renderName(x.name);

        return x.name;
      })
      .join(', ');
    return (
      <div
        ref={this.listBox}
        className="field"
        style={{
          width: '100%',
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          paddingTop: hp(2.5),

          paddingBottom: hp(2.5),
          borderRadius: hp(1.2),
          boxShadow: `2px 0px ${levelShadow}px rgba(0,0,0,0.2)`,
          ...style,
          zIndex: '1',
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          <div
            //value={value}
            style={{
              fontSize: wp(0.3) + hp(1.5) < 13 ? 13 : wp(0.3) + hp(1.5),
              paddingLeft: hp(3),
              paddingRight: hp(3),
            }}
            /*onChange={(e) => {
            if (onChangeText != null) {
              onChangeText(e.target.value);
            }
          }} */
          >
            {open ? 'a' : all && showAll ? 'Todos los locales' : reduced || 'Ninguno'}
          </div>
        </div>
        {open ? (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}
          >
            <input
              placeholder={'Buscar...'}
              value={search}
              style={{
                width: '100%',
                fontSize: wp(0.3) + hp(1.5) < 13 ? 13 : wp(0.3) + hp(1.5),
                paddingLeft: hp(3),
                paddingRight: hp(3),
              }}
              onChange={(e) => {
                this.setState({ search: e.target.value });
              }}
            />
          </div>
        ) : (
          <div
            onClick={() => this.setState({ open: !this.state.open })}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,

              height: '100%',
              width: '100%',
            }}
          ></div>
        )}
        <div
          style={{
            position: 'absolute',
            bottom: open ? -hp(19.3) : 0,
            transition: 'height 0.2s,box-shadow 0.2s, bottom 0.2s',
            left: 0,
            height: open ? hp(20) : 0,
            width: '100%',
            backgroundColor: 'white',
            borderBottomLeftRadius: hp(1.2),
            borderBottomRightRadius: hp(1.2),
            boxShadow: open ? `2px 14px ${levelShadow}px rgba(0,0,0,0.2)` : 'none',
          }}
        >
          <div
            className="scrollbar"
            style={{
              transition: 'height 0.2s,box-shadow 0.2s, bottom 0.2s',
              marginRight: hp(2.2),
              height: open ? hp(19) : 0,
              overflowY: 'auto',
            }}
          >
            {search == '' && showAll && (
              <div
                style={{
                  display: 'flex',
                  padding: hp(1),
                  paddingLeft: hp(2.2),
                  alignItems: 'center',
                  paddingRight: hp(2.2),
                }}
              >
                <CheckFillButton
                  val={items.filter((x) => !x.value).length === 0}
                  handle={(val) => {
                    const newItems = items.map((x) => ({ ...x, value: val }));
                    onHandle(newItems);
                  }}
                  size={hp(2.5)}
                />
                <TextLato
                  style={{
                    paddingLeft: hp(1.8),
                    fontSize: wp(0.3) + hp(1.5) < 12 ? 12 : wp(0.3) + hp(1.4),
                  }}
                >
                  Todos los locales
                </TextLato>
              </div>
            )}
            {items
              .filter(
                (x) => search == '' || x.name.toUpperCase().indexOf(search.toUpperCase()) != -1,
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((x, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      padding: hp(1),
                      paddingLeft: hp(2.2),
                      alignItems: 'center',
                      paddingRight: hp(2.2),
                    }}
                  >
                    <CheckFillButton
                      //ref={(x) => (this.pickupToggle = x)}
                      val={x.value}
                      handle={(val) => {
                        const newItems = items;
                        newItems[x.index].value = val;
                        onHandle(newItems);
                        /* this.setState({
              categories: { ...categories, [`${name}`]: val },
            });
            this.changeCategory(index); */
                      }}
                      size={hp(2.5)}
                    />
                    <TextLato
                      style={{
                        paddingLeft: hp(1.8),
                        fontSize: wp(0.3) + hp(1.5) < 12 ? 12 : wp(0.3) + hp(1.4),
                      }}
                    >
                      {renderName ? renderName(x.name) : x.name}
                    </TextLato>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
export default InputBoxSelector;
