import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import calendar from '../../assets/images/calendar.png';
import emptystate from '../../assets/images/empty-state.png';
import happy from '../../assets/images/happy.png';
import Card from '../../components/Card';
import LineChart from '../../components/LineChart';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { hp, wp } from '../../utils/responsive';
import {
  getClientsReport,
  getMostSelledReport,
  getPopularDaysReport,
  getPopularTimesReport,
  getSalesReport,
} from '../../utils/services';
import './index.css';

class Dashboard extends React.Component {
  state = {
    products: [],
    sales: [],
    formVisible: false,
    selectVisible: false,
    currentProduct: null,
    notif: true,
    file: null,
    information: [null, null, null, null, null],
  };
  componentDidMount() {
    moment.locale('es');
    window.addEventListener('resize', this.resize);
    this.getReports();
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  getReports = async () => {
    const { companyInfo } = this.props;
    var companyID = companyInfo.id.toString();
    if (companyID != null) {
      //Promise.all([
      getSalesReport(companyID).then((x) => {
        var newInfo = this.state.information;
        newInfo[0] = x;
        //
        this.setState({ information: newInfo });
      });
      getClientsReport(companyID).then((x) => {
        var newInfo = this.state.information;
        newInfo[1] = x;

        this.setState({ information: newInfo });
      });
      getMostSelledReport(companyID).then((x) => {
        var newInfo = this.state.information;
        var top = ['N/A', 'N/A', 'N/A'];
        if (x.topDishes != null) {
          for (var i = 0; i < x.topDishes.length; i++) {
            var text = x.topDishes[i];
            top[i] = text.substr(0, text.indexOf('('));
          }
          newInfo[2] = top;

          this.setState({ information: newInfo });
        }
      });
      getPopularDaysReport(companyID).then((x) => {
        var newInfo = this.state.information;
        var days = ['Lunes', 'Martes', 'Mierc.', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
        var keys = {
          monday: { name: 'Lunes', sort: 1 },
          thursday: { name: 'Jueves', sort: 4 },
          wednesday: { name: 'Mierc.', sort: 3 },
          tuesday: { name: 'Martes', sort: 2 },
          friday: { name: 'Viernes', sort: 5 },
          saturday: { name: 'Sábado', sort: 6 },
          sunday: { name: 'Domingo', sort: 7 },
        };
        //popularDays
        if (x.popularDays != null) {
          var values = Object.entries(x.popularDays);
          var max = 0;
          for (var i = 0; i < values.length; i++) {
            var item = values[i][1];
            if (max < item) {
              max = item;
            }
          }
          var popularDays = values
            .map((d, index) => {
              return {
                sort: keys[d[0]].sort,
                name: keys[d[0]].name,
                value: (100 * d[1]) / (max * 1.2),
              };
            })
            .sort((a, b) => a.sort - b.sort);
          newInfo[3] = popularDays;

          this.setState({ information: newInfo });
        }
      });
      getPopularTimesReport(companyID).then((x) => {
        var newInfo = this.state.information;

        if (x.popularHours != null) {
          var values = Object.values(x.popularHours);
          newInfo[4] = values.map((val, index) => {
            return {
              x: index,
              y: val,
            };
          });
          this.setState({ information: newInfo });
        }
      });
    } else {
      alert(false);
    }
  };

  render() {
    const textStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '400',
    };
    const { navigate, admin } = this.props;
    const { information } = this.state;
    const actualSales = information[0] != null ? information[0].actualMonthSales : 0;
    const lastSales = information[0] != null ? information[0].lastMonthSales : 0;
    const actualCustomers = information[1] != null ? information[1].actualMonthClients : 0;
    const lastCustomers = information[1] != null ? information[1].lastMonthClients : 0;
    const top = information[2] != null ? information[2] : ['N/A', 'N/A', 'N/A'];

    const hours =
      information[4] != null
        ? information[4]
        : [
            { x: 0, y: 0 },
            { x: 1, y: 0 },
            { x: 2, y: 0 },
            { x: 3, y: 0 },
            { x: 4, y: 0 },
            { x: 5, y: 0 },
            { x: 6, y: 0 },
            { x: 7, y: 0 },
            { x: 8, y: 0 },
            { x: 9, y: 0 },
            { x: 10, y: 0 },
            { x: 11, y: 0 },
            { x: 12, y: 0 },
            { x: 13, y: 0 },
            { x: 14, y: 0 },
            { x: 15, y: 0 },
            { x: 16, y: 0 },
          ];

    const popular =
      information[3] != null
        ? information[3]
        : [
            { name: 'Lunes', value: 0 },
            { name: 'Martes', value: 0 },
            { name: 'Mierc.', value: 0 },
            { name: 'Jueves', value: 0 },
            { name: 'Viernes', value: 0 },
            { name: 'Sábado', value: 0 },
            { name: 'Domingo', value: 0 },
          ];
    var emptyPopular = true;

    for (let i = 0; i < popular.length; i++) {
      var popItem = popular[i];
      if (popItem.value > 0) {
        emptyPopular = false;
        break;
      }
    }

    var emptyHorarios = true;
    for (let i = 0; i < hours.length; i++) {
      var hourItem = hours[i];
      if (hourItem.y != 0) {
        emptyHorarios = false;
        break;
      }
    }

    return (
      <div
        className="scrollnone"
        style={{
          overflowY: 'auto',
          minWidth: 700,
          width: '100%',
          height: '100%',
          flexDirection: 'row',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              top: 0,
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: hp(100),
                paddingLeft: wp(5),
                paddingRight: wp(5),
              }}
            >
              <Spacer height={hp(5)} />
              <TextLato style={textStyle}>Reportes - {moment().format('MMMM')}</TextLato>
              <Spacer height={hp(3)} />
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 10,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flex: 8,
                        width: '100%',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          width: '100%',
                          flexDirection: 'column',
                        }}
                      >
                        <Card>
                          <div
                            style={{
                              alignItems: 'center',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <img src={happy} width={wp(2)} height={wp(2)} />
                            <Spacer height={hp(0.2)} />
                            <TextLato
                              style={{
                                color: '#00A7A9',
                                fontSize: wp(1) + hp(2),
                                fontWeight: '400',
                              }}
                            >
                              {actualCustomers == 0 ? 'N/A' : actualCustomers}
                            </TextLato>
                            <Spacer height={hp(1.3)} />
                            <TextLato
                              style={{
                                color: '#1F2041',
                                fontSize: wp(0.65) + hp(0.5),
                                fontWeight: '400',
                              }}
                            >
                              Clientes
                            </TextLato>
                            <Spacer height={hp(0.9)} />
                            <div
                              style={{
                                width: '80%',
                                height: 1,
                                backgroundColor: 'rgba(31, 32, 65, 0.1)',
                              }}
                            ></div>
                            <Spacer height={hp(1.2)} />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyItems: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img src={calendar} width={wp(1)} height={wp(1)} />
                              <Spacer width={wp(0.3)} />
                              <TextLato
                                style={{
                                  color: '#1F2041',
                                  fontSize: wp(0.6) + hp(0.5),
                                  fontWeight: '400',
                                }}
                              >
                                {lastCustomers == 0 ? 'N/A' : lastCustomers}{' '}
                                {moment().subtract(1, 'month').format('MMMM')}
                              </TextLato>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <Spacer width={wp(1)} />
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          width: '100%',
                          flexDirection: 'column',
                        }}
                      >
                        <Card>
                          <div
                            style={{
                              alignItems: 'center',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div style={{ height: hp(0.1) + wp(2) }}>
                              <TextLato
                                style={{
                                  color: '#00A7A9',
                                  fontSize: wp(1) + hp(2),
                                  fontWeight: '400',
                                }}
                              >
                                S/
                              </TextLato>
                            </div>
                            <Spacer height={hp(0.1)} />
                            <TextLato
                              style={{
                                color: '#00A7A9',
                                fontSize: wp(1) + hp(2),
                                fontWeight: '400',
                              }}
                            >
                              {actualSales == 0 ? 'N/A' : actualSales}
                            </TextLato>
                            <Spacer height={hp(1.3)} />
                            <TextLato
                              style={{
                                color: '#1F2041',
                                fontSize: wp(0.65) + hp(0.5),
                                fontWeight: '400',
                              }}
                            >
                              en ventas
                            </TextLato>
                            <Spacer height={hp(0.9)} />
                            <div
                              style={{
                                width: '80%',
                                height: 1,
                                backgroundColor: 'rgba(31, 32, 65, 0.1)',
                              }}
                            ></div>
                            <Spacer height={hp(1.2)} />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyItems: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img src={calendar} width={wp(1)} height={wp(1)} />
                              <Spacer width={wp(0.3)} />
                              <TextLato
                                style={{
                                  color: '#1F2041',
                                  fontSize: wp(0.6) + hp(0.5),
                                  fontWeight: '400',
                                }}
                              >
                                S/ {lastSales == 0 ? 'N/A' : lastSales}{' '}
                                {moment().subtract(1, 'month').format('MMMM')}
                              </TextLato>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                    <Spacer height={hp(2)} />
                    <div
                      style={{
                        display: 'flex',
                        flex: 10,
                        borderRadius: hp(1.2),
                        width: '100%',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Card
                        style={{ paddingBottom: hp(1) }}
                        paddingHorizontal={wp(2)}
                        wrapper={{ height: '100%' }}
                      >
                        <div
                          style={{
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',

                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <TextLato
                            style={{
                              color: 'black',
                              fontSize: wp(0.7) + hp(0.8),
                              fontWeight: '400',
                              width: '100%',
                              textAlign: 'left',
                            }}
                          >
                            Días populares
                          </TextLato>
                          <Spacer height={hp(2)} />
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              width: '100%',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {emptyPopular ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                  height: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <img src={emptystate} style={{ height: hp(10) }} />
                                <Spacer height={hp(0.5)} />
                                <TextLato
                                  style={{
                                    color: 'black',
                                    fontSize: wp(0.7) + hp(0.7),
                                    fontWeight: '300',
                                    width: '100%',
                                    textAlign: 'center',
                                  }}
                                >
                                  No hay suficiente información dispomible
                                </TextLato>
                              </div>
                            ) : (
                              popular.map((x) => {
                                return (
                                  <div
                                    style={{
                                      width: '12%',
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '90%',
                                        flex: 1,
                                        display: 'flex',
                                        borderRadius: wp(0.4),
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        backgroundColor: 'rgba(240,249,249)',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '100%',
                                          borderRadius: wp(0.4),
                                          height: x.value + '%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          backgroundColor: 'rgba(73,165,168)',
                                        }}
                                      ></div>
                                    </div>
                                    <Spacer height={hp(0.7)} />
                                    <TextLato
                                      style={{
                                        color: 'black',
                                        fontSize: wp(0.6) + hp(0.6),
                                        fontWeight: '400',
                                        width: '100%',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {x.name}
                                    </TextLato>
                                  </div>
                                );
                              })
                            )}
                          </div>
                          <Spacer height={hp(1)} />
                        </div>
                      </Card>
                    </div>
                  </div>
                  <Spacer width={hp(2)} />
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                    }}
                  >
                    <Card paddingHorizontal={wp(2)} wrapper={{ height: '100%' }}>
                      <div
                        style={{
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Spacer height={hp(1)} />
                        <TextLato
                          style={{
                            ...textStyle,
                            textAlign: 'left',
                            width: '100%',
                            fontWeight: '400',
                            fontSize: wp(1) + hp(1) < 17 ? 17 : wp(1) + hp(1),
                          }}
                        >
                          Productos mas vendidos
                        </TextLato>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          {top.map((x, index) => {
                            return (
                              <div
                                style={{
                                  width: '100%',
                                  flexDirection: 'column',
                                  display: 'flex',
                                }}
                              >
                                {index > 0 ? (
                                  <div
                                    style={{
                                      height: hp(7),
                                      width: '100%',
                                      justifyContent: 'center',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 1.5,
                                        backgroundColor: 'rgba(0,0,0,0.3)',
                                        width: '100%',
                                      }}
                                    />
                                  </div>
                                ) : null}
                                <TextLato
                                  style={{
                                    ...textStyle,
                                    textAlign: 'left',
                                    width: '100%',
                                    fontWeight: '300',

                                    fontSize: wp(0.9) + hp(0.9),
                                  }}
                                >
                                  {index + 1}. {x}
                                </TextLato>
                              </div>
                            );
                          })}
                        </div>
                        <Spacer height={hp(1)} />
                      </div>
                    </Card>
                  </div>
                </div>
                <Spacer height={hp(2)} />
                <div
                  style={{
                    display: 'flex',
                    flex: 7,
                    width: '100%',
                  }}
                >
                  <Card paddingHorizontal={wp(2)} wrapper={{ height: '100%' }}>
                    <div
                      style={{
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <TextLato
                        style={{
                          color: 'black',
                          fontSize: wp(0.7) + hp(0.8),
                          fontWeight: '400',
                          width: '100%',
                          textAlign: 'left',
                        }}
                      >
                        Horarios populares
                      </TextLato>
                      <Spacer height={hp(emptyHorarios ? 0 : 4)} />
                      {emptyHorarios ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img src={emptystate} style={{ height: hp(15) }} />
                          <Spacer height={hp(0.5)} />
                          <TextLato
                            style={{
                              color: 'black',
                              fontSize: wp(0.7) + hp(0.7),
                              fontWeight: '300',
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            No hay suficiente información dispomible
                          </TextLato>
                        </div>
                      ) : (
                        <LineChart svgHeight={hp(15)} svgWidth={wp(60)} data={hours} />
                      )}
                      {emptyHorarios || <Spacer height={hp(1)} />}
                      {emptyHorarios || (
                        <div
                          style={{
                            width: wp(60) * 0.95 + wp(6),
                            height: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {[
                            '08',
                            '09',
                            '10',
                            '11',
                            '12',
                            '13',
                            '14',
                            '15',
                            '16',
                            '17',
                            '18',
                            '19',
                            '20',
                            '21',
                            '22',
                            '23',
                            '24',
                          ].map((x, index) => {
                            if (index % 2 == 1) {
                              return null;
                            }
                            return (
                              <div
                                style={{
                                  width: wp(6),
                                  height: 20,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <TextLato
                                  style={{
                                    color: '#00A7A9',
                                    fontSize: wp(0.8) + hp(0.8),
                                    fontWeight: '500',
                                    width: '100%',
                                    textAlign: 'center',
                                  }}
                                >
                                  {x}
                                </TextLato>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
              <Spacer height={hp(8)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyID: state.companyID,
    companyInfo: state.companyInfo,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
