type NamedStyles<T> = { [P in keyof T]: React.CSSProperties };
/* 
interface IStylesSheet {
  static create<T extends NamedStyles<T> | NamedStyles<any>>(styles: T | NamedStyles<T>): T;
} */

export class StyleSheet {
  static create<T extends NamedStyles<T> | NamedStyles<any>>(styles: T | NamedStyles<T>): T {
    return styles as T;
  }
}
