import React from 'react';
import { hp, wp } from '../../../utils/responsive';
import TextLato from '../../../components/TextLato';
import { firebase } from '../../../utils/firebase';
import { connect } from 'react-redux';
import Spacer from '../../../components/Spacer';
import { setCurrentCompanyID } from '../../../store/actions';
import FormUser from '../../../components/FormUser';
import Button from '../../../components/Button';
import moment from 'moment';
import trashIcon from '../../../assets/images/trash.png';
import Card from '../../../components/Card';
import { timeConverter } from '../../../utils/functions';
import Receipt from '../../Receipt';
import { getCards, deleteCards } from '../../../utils/services';
import visaIcon from '../../../assets/images/visa-icon.png';
import masterIcon from '../../../assets/images/mastercard-icon.png';
import ModalQuestion from '../../ModalQuestion';
class PaymentsUser extends React.Component {
  state = {
    payments: [],
    dataNext: [],
    currentOrder: null,
    deleteVisible: false,
    deleteCard: null,
  };

  async componentDidMount() {
    const { loading } = this.props;
    window.addEventListener('resize', this.resize);
    loading();
    await this.getCards();
    loading(false);
    // this.groupRestaurants(storeList)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  deleteCard = async (id) => {
    const { loading } = this.props;
    loading();
    var res = await deleteCards(id);
    await this.getCards();
    loading(false);
  };
  getCards = async () => {
    const { currentUser } = this.props;
    var cards = await getCards(currentUser.id);
    this.setState({
      payments:
        cards.items != null
          ? cards.items
              .map((x) => {
                return {
                  id: x.id,
                  number: x.cardNumber,
                  type: x.cardType == 'V' ? 1 : 0,
                  active: x.status,
                };
              })
              .sort((a, b) => b.active - a.active)
          : [],
    });
  };
  renderCard = (card) => {
    const titleStyle = {
      fontSize: wp(0.7) + hp(0.6) < 12 ? 12 : wp(0.7) + hp(0.6),
      fontWeight: '400',
      color: '#392E2E',
      flex: 1,
      marginLeft: hp(2),
      display: 'flex',
    };
    return (
      <div style={{ marginLeft: hp(6), width: '50%', marginBottom: hp(3) }}>
        <Card>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: hp(1.5),
              paddingLeft: hp(3),
              paddingRight: hp(3),
            }}
          >
            <img src={card.type == 1 ? visaIcon : masterIcon} style={{ height: hp(2.6) }} />
            <TextLato style={titleStyle}>La tarjeta termina en {card.number}</TextLato>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.setState({
                  deleteVisible: true,
                  deleteCard: { id: card.id, label: card.number },
                })
              }
            >
              <img src={trashIcon} style={{ height: hp(3) }} />
            </div>
          </div>
        </Card>
      </div>
    );
  };
  render() {
    const { currentUser } = this.props;
    const { payments, deleteVisible, deleteCard } = this.state;
    const titleStyle = {
      fontSize: wp(1) + hp(1) < 18 ? 18 : wp(1) + hp(1),
      fontWeight: '500',
      marginLeft: hp(6),
    };
    const subtitleStyle = {
      fontSize: wp(0.6) + hp(0.7) < 15 ? 15 : wp(0.6) + hp(0.7),
      fontWeight: '400',
      textAlign: 'center',
    };
    return (
      <div
        style={{
          width: '100%',
          minWidth: 600,
          justifyContent: 'stretch',
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Spacer height={hp(5)} />
        <TextLato style={titleStyle}>Métodos de pago</TextLato>
        <Spacer height={hp(3)} />
        {payments.map(this.renderCard)}
        <ModalQuestion
          visible={deleteVisible}
          messages={[
            `¿Estas seguro que deseas eliminar tarjeta`,
            `**** **** **** **** ${deleteCard != null ? deleteCard.label : ''}?`,
          ]}
          okAction={async () => {
            await this.deleteCard(deleteCard.id);
            this.setState({
              deleteCard: null,
              deleteVisible: false,
            });
          }}
          cancelAction={() => {
            this.setState({ deleteVisible: false });
          }}
          okText="Eliminar"
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyID: (value) => {
    dispatch(setCurrentCompanyID(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsUser);
