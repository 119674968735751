import React from 'react';
import { hp, wp } from "../../utils/responsive"
import "./index.css"
const TextAvenir = ({ children, style = {}, ...props }) => {
    return (
        <div style={{ ...styles.text, ...style }} {...props}>
            {children}
        </div>
    );
}

const styles = {
    text: {
        fontSize: hp(2),
        fontFamily: "Avenir"
    }
}
export default TextAvenir;
