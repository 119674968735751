import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Spacer from '../../components/Spacer';
import TextLato from '../../components/TextLato';
import { setCompanyID, setCompanyInfo, setFirebaseID, setOwner } from '../../store/actions';
import { signOut } from '../../utils/firebase';
import { hp, wp } from '../../utils/responsive';
class Logout extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  render() {
    const { navigate, onBack } = this.props;
    const textStyle = {
      fontSize: wp(0.9) + hp(1),
      fontWeight: '300',
      textAlign: 'center',
    };
    return (
      <Modal>
        <div
          style={{
            backgroundColor: 'white',
            width: wp(90),
            maxWidth: 360,
            borderRadius: hp(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '70%',
            }}
          >
            <Spacer height={hp(5)} />
            <TextLato style={textStyle}>¿Estas seguro que deseas cerrar sesión?</TextLato>
            <Spacer height={hp(5)} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '42%',
              }}
            >
              <Button
                onClick={() => {
                  this.props.setCompanyID('');
                  sessionStorage.removeItem('adminTab');
                  signOut();
                  this.props.history.push('/login');
                }}
                second={true}
                textStyle={{ fontSize: wp(0.8) + hp(0.5) }}
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  paddingTop: hp(1.6),
                  paddingBottom: hp(1.6),
                  paddingLeft: wp(2),
                  paddingRight: wp(2),
                  borderRadius: hp(1.2),
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                backgroundColor={'#D87F7A'}
              >
                Cerrar sesión
              </Button>
              <Spacer height={hp(2)} />
              <Button
                onClick={() => {
                  if (onBack != null) onBack();
                  else navigate(null, null, true);
                }}
                second={true}
                textStyle={{ fontSize: wp(0.8) + hp(0.5), color: '#392E2E' }}
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  paddingTop: hp(1.6),
                  paddingBottom: hp(1.6),
                  paddingLeft: wp(2),
                  paddingRight: wp(2),
                  borderRadius: hp(1.2),
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                backgroundColor={'#ffffff'}
              >
                Volver
              </Button>
            </div>
          </div>
          <Spacer height={hp(5)} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseID: state.firebaseID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFirebaseID: (id) => {
      dispatch(setFirebaseID(id));
    },
    setCompanyID: (id) => {
      dispatch(setCompanyID(id));
    },
    setCompanyInfo: (data) => {
      dispatch(setCompanyInfo(data));
    },
    setOwner: (data) => {
      dispatch(setOwner(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
