import React, { useEffect, useState } from 'react';
import './index.css';
import { ReportActions } from 'src/utils/reports';
import firebase, { firestore } from 'firebase';

const db = firebase.firestore();
interface Product {
  id?: string;
  name: string;
  brandId: string;
  brandQuestionId: string;
  brandAnswerId: string;
}

const listKeys: (keyof Product)[] = ['id', 'name', 'brandId', 'brandQuestionId', 'brandAnswerId'];

const formatByKeys = (obj: any) =>
  listKeys.reduce((prev, currentKey) => ({ ...prev, [currentKey]: obj[currentKey] || '' }), {});

export const DebugContainer = () => {
  const [list, setList] = useState<Product[]>([]);

  useEffect(() => {
    const getProducts = async () => {
      const response = await db.collection('products').where('companyId', '==', '694').get();
      const returnedList: Product[] = [];

      response.forEach((x) => {
        returnedList.push({ ...formatByKeys(x.data()), id: x.id } as Product);
      });
      console.log(response.size);
      setList(returnedList);
    };
    getProducts();
  }, []);

  const onClick = async () => {
    var batch = db.batch();
    list.forEach((update) => {
      var docRef = db.collection('products').doc(update.id);
      delete update.id;
      batch.update(docRef, update);
    });

    batch.commit();
  };

  const changeValue = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    const newList = [...list];
    newList[index][name as keyof Product] = value;

    setList(newList);
  };

  return (
    <div className="container">
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>BrandId</th>
            <th>BrandQuestionId</th>
            <th>BrandAnswerId</th>
          </tr>
        </thead>
        <tbody>
          {list.map((product, index) => (
            <tr key={index}>
              {listKeys.map((key) => (
                <td>
                  <input
                    type="text"
                    name={key}
                    value={product[key]}
                    onChange={changeValue(index)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={onClick}>Save</button>
    </div>
  );
};
