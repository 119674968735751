import React from 'react';
import { hp, wp } from '../../utils/responsive';
import { connect } from 'react-redux';
import Triangle from '../../components/Triangle';
import TextLato from '../../components/TextLato';
import logo from '../../assets/images/logo-primary.png';
import moment from 'moment';
import alertIcon from '../../assets/images/alert-icon.png';
import Button from '../../components/Button';
import { firebase } from '../../utils/firebase';
import { timeConverter } from '../../utils/functions';
import { getUserFullName, updateOrderPickupDate, getCompany } from '../../utils/services';
import Spacer from '../../components/Spacer';
class Receipt extends React.Component {
  state = {
    fullName: '-',
    company: null,
    userId: null,
  };
  async componentDidMount() {
    window.addEventListener('resize', this.resize);
    const { localReceipt, full } = this.props;

    var currentReceipt;
    if (localReceipt != null) {
      currentReceipt = localReceipt;
    } else {
      currentReceipt = this.props.currentReceipt;
    }
    if (full) {
      const { companyId, userId } = currentReceipt;
      var company = await getCompany(companyId);
      this.setState({
        company,
        userId,
      });
    } else {
      const { userId } = currentReceipt;
      var user = await getUserFullName(userId);
      this.setState({
        fullName: user.fullName,
        phone: user.phone,
        userId,
      });
    }
  }
  resize = () => {
    if (wp(100) > 700) this.forceUpdate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  renderRow = (
    title,
    value,
    cf = this.props.full ? wp(0.2) + hp(1.3) : wp(0.2) + hp(1.6),
    onPress,
  ) => {
    const { full } = this.props;
    const titleStyle = {
      fontSize: cf,
      fontWeight: '400',
      flex: 1,
    };
    const valueStyle = {
      fontSize: full ? wp(0.2) + hp(1.3) : wp(0.2) + hp(1.6),
      fontWeight: '300',
    };
    return (
      <div
        onClick={onPress}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          cursor: onPress ? 'pointer' : 'auto',
          justifyContent: 'space-between',
        }}
      >
        <TextLato style={titleStyle}>{title}</TextLato>
        <Spacer width={hp(2)} />
        <TextLato style={valueStyle}>{value}</TextLato>
      </div>
    );
  };
  renderProduct = (
    count,
    title,
    value,
    cf = this.props.full ? wp(0.2) + hp(1.3) : wp(0.2) + hp(1.6),
    alert,
  ) => {
    const { full } = this.props;
    const titleStyle = {
      fontSize: cf,
      fontWeight: '400',
    };
    const valueStyle = {
      fontSize: full ? wp(0.2) + hp(1.3) : wp(0.2) + hp(1.6),
      fontWeight: '300',
    };
    const alertStyle = {
      fontSize: wp(0.2) + hp(0.9),
      fontWeight: '400',
      color: '#D87F7A',
    };
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',

          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <TextLato style={titleStyle}>{count}&nbsp;</TextLato>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <TextLato style={titleStyle}>{title}</TextLato>
            {full && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={alertIcon} style={{ height: hp(1.3) }} />
                <TextLato style={alertStyle}>&nbsp;{alert}</TextLato>
              </div>
            )}
          </div>
        </div>
        <Spacer width={hp(2)} />
        <TextLato style={valueStyle}>{value}</TextLato>
      </div>
    );
  };
  update = async (deliv) => {
    const { navigate, localReceipt } = this.props;
    var currentReceipt;
    if (localReceipt != null) {
      currentReceipt = localReceipt;
    } else {
      currentReceipt = this.props.currentReceipt;
    }
    const { companyInfo } = this.props;
    const companyID = companyInfo.id;
    const { status } = companyInfo || { status: 'live' };
    var dev = status == 'dev';
    const userId = companyID;
    const id = currentReceipt.id;
    let temp = { ...currentReceipt };

    const now = new Date();
    const hoursDelivered = timeConverter(now, 'hours', false);
    const dateDelivered = timeConverter(now, 'date', false);
    const fulldateDelivered = `${dateDelivered} ${hoursDelivered}`;
    temp.date = hoursDelivered;
    temp.delivered = fulldateDelivered;
    temp.employeId = userId;

    if (this.props.loading != null) {
      this.props.loading(true);
    }
    const deliveredAt = firebase.firestore.Timestamp.fromDate(now);
    const orderRef = firebase
      .firestore()
      .collection(dev ? 'orders' : 'orders')
      .doc(id);
    if (deliv == null) {
      const updateResult = await orderRef.update({
        deliveredAt,
        status: 'delivered',
        employeId: userId,
      });
    } else {
      const updateResult = await orderRef.update({
        deliveredAt: null,
        status: 'not delivered',
        employeId: userId,
      });
    }

    const order = await orderRef.get();
    const backendOrderId = order.data().backendOrderId;

    if (backendOrderId != null) {
      if (backendOrderId > 0) {
        if (deliv == null) {
          var updateBackendResult = await updateOrderPickupDate(backendOrderId);
        }
      }
    }
    if (this.props.loading != null) {
      this.props.loading(false);
    }
    if (this.props.onRelease != null) this.props.onRelease();
  };

  uncanceledOrder = async () => {
    const { localReceipt } = this.props;
    var currentReceipt;
    if (localReceipt != null) {
      currentReceipt = localReceipt;
    } else {
      currentReceipt = this.props.currentReceipt;
    }
    const id = currentReceipt.id;
    const orderRef = firebase.firestore().collection('orders').doc(id);
    const orderDoc = await orderRef.get();
    const { canceledAt, ...newData } = orderDoc.data();
    orderRef.set(newData);
    if (this.props.onRelease != null) this.props.onRelease();
  };
  markDeliveredOrder = async () => {
    const { localReceipt } = this.props;
    var currentReceipt;
    if (localReceipt != null) {
      currentReceipt = localReceipt;
    } else {
      currentReceipt = this.props.currentReceipt;
    }
    const id = currentReceipt.id;
    const orderRef = firebase.firestore().collection('orders').doc(id);
    await orderRef.update({
      deliveryStatus: 'delivered',
    });
    if (this.props.onRelease != null) this.props.onRelease();
  };
  render() {
    const { full, admin, style } = this.props;
    const { company, userId } = this.state;
    const headerStyle = {
      fontSize: full ? wp(0.2) + hp(1.7) : wp(0.2) + hp(2),
      fontWeight: '500',
      display: 'flex',
      flexDirection: 'row',
    };
    const infoStyle = {
      fontSize: wp(0.2) + hp(1.3),
      fontWeight: '300',
      color: '#392E2E',
      textAlign: 'center',
    };
    const descriptionStyle = {
      fontSize: full ? wp(0.2) + hp(1.3) : wp(0.3) + hp(1.5),
      fontWeight: '400',
    };
    const { localReceipt } = this.props;
    var currentReceipt;
    if (localReceipt != null) {
      currentReceipt = localReceipt;
    } else {
      currentReceipt = this.props.currentReceipt;
    }
    const finalWidth = 350;
    const date = currentReceipt.fulldate.split(
      currentReceipt.fulldate.indexOf('\n') != -1 ? '\n' : ' ',
    );
    const delivered =
      currentReceipt.delivered != null
        ? currentReceipt.delivered.split(currentReceipt.delivered.indexOf('\n') != -1 ? '\n' : ' ')
        : null;
    const boughtTime = currentReceipt.timeBought != null ? currentReceipt.timeBought : null;
    const discountFirebase = parseFloat(currentReceipt.ponderatedDiscount) || 0;
    const deliveryAmount = parseFloat(currentReceipt.deliveryAmount) || 0;
    const dicountFinal = parseFloat(discountFirebase);
    const canShowDiscount = (full || admin) && dicountFinal > 0;
    const hasServiceFee = currentReceipt.hasServiceFee && (admin || full);
    const serviceFeeValue = hasServiceFee ? 0.75 : 0;
    const prevFinal = currentReceipt.total - discountFirebase + deliveryAmount + serviceFeeValue;
    const prevNoDiscFinal = currentReceipt.total + deliveryAmount;

    const totalFinal = parseFloat(
      full != null || admin
        ? prevFinal < 0
          ? 0
          : prevFinal
        : prevNoDiscFinal < 0
        ? 0
        : prevNoDiscFinal,
    );
    const dfinal = prevFinal < 0 ? dicountFinal + prevFinal : dicountFinal;
    return (
      <div
        //className="scrollnone"
        style={{
          // overflowY: "auto",
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            flexDirection: 'column',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            position: 'relative',
            top: -hp(2.6),
          }}
        >
          {(currentReceipt.delivery ||
            (currentReceipt.specifications != '' && currentReceipt.specifications != null)) ? (
            <div
              style={{
                left: finalWidth + hp(4),
                top: hp(3.2),
                position: 'absolute',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: hp(10),
                  backgroundColor: '#9FCACB',
                  borderRadius: hp(1.3),
                  ...style,
                }}
              >
                <Spacer width={finalWidth * 0.1} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Spacer height={hp(1)} />
                  {currentReceipt.delivery && (
                    <>
                      <TextLato style={headerStyle}>
                        {currentReceipt.pedidosya ? 'Delivery Cirkula' : 'Delivery'}
                      </TextLato>
                      <Spacer height={hp(0.5)} />
                      <TextLato style={descriptionStyle}>{this.state.fullName}</TextLato>
                      <Spacer height={hp(0.5)} />
                      <TextLato style={descriptionStyle}>{this.state.phone}</TextLato>
                      {(currentReceipt.pedidosya && !admin) || (
                        <>
                          <Spacer height={hp(0.5)} />
                          <TextLato
                            onClick={() => {
                              window.open(
                                'https://www.google.com/maps/place/' +
                                  currentReceipt.deliveryAddress.split(' ').join('+'),
                              );
                            }}
                            style={{
                              ...descriptionStyle,
                              ...{ cursor: 'pointer', textDecoration: 'underline' },
                            }}
                          >
                            {currentReceipt.deliveryAddress != ''
                              ? currentReceipt.deliveryAddress
                              : '-'}
                          </TextLato>
                        </>
                      )}
                      <Spacer height={hp(1)} />
                    </>
                  )}
                  <TextLato style={headerStyle}>Especificaciones</TextLato>
                  <Spacer height={hp(0.5)} />
                  <TextLato style={descriptionStyle}>
                    {currentReceipt.specifications != '' && currentReceipt.specifications != null
                      ? currentReceipt.specifications
                      : '-'}
                  </TextLato>

                  <Spacer height={hp(0.5)} />
                  <Spacer height={hp(1)} />
                </div>
                <Spacer width={finalWidth * 0.1} />
              </div>
              {currentReceipt.canceledAt && admin && (
                <div style={{ marginLeft: hp(10) }}>
                  <Spacer height={hp(1)} />
                  <Button
                    backgroundColor={'#D87F7A'}
                    onClick={this.uncanceledOrder}
                    textStyle={{ fontSize: hp(2.3) }}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: hp(1),
                      paddingTop: hp(1.5),
                      paddingBottom: hp(1.5),
                    }}
                  >
                    Descancelar Delivery
                  </Button>
                </div>
              )}
              {/* INFORMACION DE DNI Y NOMBRE COMPLETO  */}
              {
                (currentReceipt.fullName && currentReceipt.dni) ? 
                <>
                  <Spacer height={hp(1)} />
                  <div style={{marginLeft: hp(10), paddingLeft: finalWidth * 0.1, paddingRight: finalWidth * 0.1, paddingTop:'10px', paddingBottom: '10px', backgroundColor: '#d3d3d3', borderRadius: 10}}>
                    {/* <div class="flex flex-col space-y-1.5 p-6"> */}
                      <TextLato style={headerStyle}>Nombre Completo</TextLato>
                      <Spacer height={hp(0.5)} />
                      <TextLato style={descriptionStyle}>
                        {currentReceipt.fullName}
                      </TextLato>
                      <Spacer height={hp(1)} />
                      <TextLato style={headerStyle}>DNI</TextLato>
                      <Spacer height={hp(0.5)} />
                      <TextLato style={descriptionStyle}>
                        {currentReceipt.dni}
                      </TextLato>
                    {/* </div> */}
                  </div>
                </>
                : null
              }
              {currentReceipt.deliveryStatus !== 'delivered' && admin && (
                <div style={{ marginLeft: hp(10) }}>
                  <Spacer height={hp(1)} />
                  <Button
                    backgroundColor={'#D87F7A'}
                    onClick={this.markDeliveredOrder}
                    textStyle={{ fontSize: hp(2.3) }}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: hp(1),
                      paddingTop: hp(1.5),
                      paddingBottom: hp(1.5),
                    }}
                  >
                    Delivery Entregado
                  </Button>
                </div>
              )}
            </div>
          ):  
          (currentReceipt.fullName && currentReceipt.dni) ? 
          <div
            style={{
              left: finalWidth + hp(4),
              top: hp(3.2),
              position: 'absolute',
            }}
          >
          <Spacer height={hp(1)} />
              <Spacer height={hp(1)} />
              <div style={{marginLeft: hp(10), paddingLeft: finalWidth * 0.1, paddingRight: finalWidth * 0.1, paddingTop:'10px', paddingBottom: '10px', backgroundColor: '#d3d3d3', borderRadius: 10}}>
                {/* <div class="flex flex-col space-y-1.5 p-6"> */}
                  <TextLato style={headerStyle}>Nombre Completo</TextLato>
                  <Spacer height={hp(0.5)} />
                  <TextLato style={descriptionStyle}>
                    {currentReceipt.fullName}
                  </TextLato>
                  <Spacer height={hp(1)} />
                  <TextLato style={headerStyle}>DNI</TextLato>
                  <Spacer height={hp(0.5)} />
                  <TextLato style={descriptionStyle}>
                    {currentReceipt.dni}
                  </TextLato>
                {/* </div> */}
              </div>
            </div>
          : null
          }
          {currentReceipt.canceled && (
            <div
              style={{
                left: 0,
                top: hp(3.2),
                display: 'flex',
                flexDirection: 'row',
                marginLeft: hp(10),
                position: 'absolute',
                zIndex: 2,
                width: finalWidth,
                paddingTop: hp(1),
                paddingBottom: hp(1),
                justifyContent: 'center',
                backgroundColor: '#D87F7A',
                ...style,
              }}
            >
              <TextLato
                style={{
                  ...headerStyle,
                  color: 'white',
                  fontSize: headerStyle.fontSize * 0.9,
                  fontWeight: '400',
                }}
              >
                Este pedido fue cancelado
              </TextLato>
            </div>
          )}
          <Spacer height={hp(6)} />
          {currentReceipt != null ? (
            <div
              style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                paddingBottom: hp(3),
                marginLeft: hp(10),
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                width: finalWidth,
                boxShadow: '2px 7px 30px rgba(0,0,0,0.2)',
                ...style,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  display: 'flex',
                  top: -hp(3) + 2,
                  height: hp(5.5),
                  width: finalWidth,
                }}
              >
                <Triangle h={hp(3)} w={finalWidth / 22} direction="top_right" color="white" />

                {'1234567890'.split('').map((item) => {
                  return <Triangle h={hp(3)} w={finalWidth / 11} direction="top" color="white" />;
                })}
                <Triangle h={hp(3)} w={wp(15) / 11} direction="top_left" color="white" />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '80%',
                }}
              >
                <img src={logo} style={{ height: full ? hp(6) : hp(10) }} />
                <div style={{ height: hp(2) }} />
                <TextLato style={headerStyle}>
                  {currentReceipt.companyName}&nbsp;
                  <TextLato style={{ ...headerStyle, fontWeight: '400' }}>
                    {currentReceipt.companyHref}
                  </TextLato>
                </TextLato>
                {full && company != null && (
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={{ height: hp(1) }} />
                    <TextLato style={infoStyle}>Recoger en {company.address}</TextLato>
                    <TextLato style={infoStyle}>Recoger hasta las {company.pickupLimit}</TextLato>
                    <TextLato style={infoStyle}>Contacto {company.phone}</TextLato>
                  </div>
                )}
                <div style={{ height: hp(3) }} />
                <div
                  style={{
                    height: 1.5,
                    backgroundColor: 'rgba(100,100,100,0.2)',
                    width: '100%',
                  }}
                />
                <div style={{ height: hp(3) }} />
                {full
                  ? this.renderRow(
                      'Comprado:',
                      moment(date[0], 'DD/MM/YYYY').format('DD/MM/YY') + ' - ' + date[1],
                    )
                  : this.renderRow('Usuario:', this.state.fullName, undefined, () => {
                      console.log(`https://negocios.cirkulaapp.com/?user=${userId}`);
                      window
                        .open(`https://negocios.cirkulaapp.com/?user=${userId}`, '_blank')
                        .focus();
                    })}
                <div style={{ height: hp(0.5) }} />

                {full
                  ? this.renderRow('Restaurante:', currentReceipt.companyName, undefined, () => {
                      console.log(
                        `https://negocios.cirkulaapp.com/?store=${currentReceipt.companyId}`,
                      );
                      window
                        .open(
                          `https://negocios.cirkulaapp.com/?store=${currentReceipt.companyId}`,
                          '_blank',
                        )
                        .focus();
                    })
                  : this.renderRow(
                      'Comprado:',
                      moment(date[0], 'DD/MM/YYYY').format('DD/MM/YY') + ' - ' + date[1],
                    )}
                <div style={{ height: hp(0.5) }} />

                {this.renderRow(
                  'Entregado:',
                  currentReceipt.status == 'not delivered'
                    ? 'No Entregado'
                    : delivered != null
                    ? moment(delivered[0], 'DD/MM/YYYY').format('DD/MM/YY') + ' - ' + delivered[1]
                    : '-',
                )}
                <div style={{ height: hp(3) }} />
                <div
                  style={{
                    height: 1.5,
                    backgroundColor: 'rgba(100,100,100,0.2)',
                    width: '100%',
                  }}
                />
                <div style={{ height: hp(3) }} />
                {currentReceipt.products.map((item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      {this.renderProduct(
                        `(${item.count}) `,
                        `${item.name}`,
                        `S/ ${parseFloat(parseInt(item.count) * parseFloat(item.price)).toFixed(
                          2,
                        )}`,
                        full ? wp(0.2) + hp(1.3) : wp(0.2) + hp(1.6),
                        item.radio == 1
                          ? 'Consumir mismo día'
                          : 'Consumir hasta ' +
                              (item.expireLabel != '' ? item.expireLabel : 'día siguiente'),
                      )}
                      <div style={{ height: hp(0.5) }} />
                    </div>
                  );
                })}
                <div style={{ height: hp(3) }} />
                <div
                  style={{
                    height: 1.5,
                    backgroundColor: 'rgba(100,100,100,0.2)',
                    width: '100%',
                  }}
                />
                {(currentReceipt.deliveryAmount != 0 && currentReceipt.deliveryAmount != null) ||
                canShowDiscount ? (
                  <div style={{ height: hp(1) }} />
                ) : (
                  <div style={{ height: hp(3) }} />
                )}
                {(deliveryAmount > 0 || canShowDiscount || hasServiceFee) &&
                  this.renderRow(
                    '',
                    `Subtotal: S/ ${parseFloat(currentReceipt.total).toFixed(2)}`,
                    hp(3),
                  )}
                {currentReceipt.deliveryAmount != 0 && currentReceipt.deliveryAmount != null && (
                  <div style={{ height: hp(0.5) }} />
                )}
                {currentReceipt.deliveryAmount != 0 &&
                  currentReceipt.deliveryAmount != null &&
                  this.renderRow(
                    '',
                    `Delivery: S/ ${parseFloat(currentReceipt.deliveryAmount).toFixed(2)}`,
                    hp(3),
                  )}
                {canShowDiscount && <div style={{ height: hp(0.5) }} />}
                {canShowDiscount &&
                  this.renderRow('', `Descuento: S/ ${parseFloat(-dfinal).toFixed(2)}`, hp(3))}
                {hasServiceFee && <div style={{ height: hp(0.5) }} />}
                {hasServiceFee &&
                  this.renderRow(
                    '',
                    `Tarifa de servicio: S/ ${parseFloat(serviceFeeValue).toFixed(2)}`,
                    hp(3),
                  )}
                {((currentReceipt.deliveryAmount != 0 && currentReceipt.deliveryAmount != null) ||
                  canShowDiscount ||
                  hasServiceFee) && <div style={{ height: hp(0.5) }} />}
                {(currentReceipt.deliveryAmount != 0 && currentReceipt.deliveryAmount != null) ||
                canShowDiscount ||
                hasServiceFee
                  ? this.renderRow('', `Total: S/ ${totalFinal.toFixed(2)}`, hp(3))
                  : this.renderRow(
                      '',
                      `Total: S/ ${parseFloat(currentReceipt.total).toFixed(2)}`,
                      hp(3),
                    )}
              </div>
              <div
                style={{
                  position: 'relative',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  display: 'flex',
                  top: hp(6) - 2,
                  height: hp(3),
                  width: finalWidth,
                }}
              >
                <Triangle h={hp(3)} w={finalWidth / 22} direction="bottom_right" color="white" />

                {'1234567890'.split('').map((item) => {
                  return (
                    <Triangle h={hp(3)} w={finalWidth / 11} direction="bottom" color="white" />
                  );
                })}
                <Triangle h={hp(3)} w={finalWidth / 22} direction="bottom_left" color="white" />
              </div>
            </div>
          ) : null}
        </div>

        {(admin || delivered == null) && localReceipt == null && (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: finalWidth,
              marginLeft: hp(10),
              flexDirection: 'column',
              ...style,
            }}
          >
            <div style={{ height: hp(5) }} />
            <Button
              backgroundColor={'#D87F7A'}
              onClick={() => this.update(delivered)}
              textStyle={{ fontSize: hp(2.3) }}
              style={{
                borderRadius: hp(1),
                paddingTop: hp(1.5),
                paddingBottom: hp(1.5),
                paddingLeft: hp(5),
                paddingRight: hp(5),
              }}
            >
              {delivered == null ? 'Entregado' : 'No Entregado'}
            </Button>
          </div>
        )}
        <Spacer height={hp(5)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentReceipt: state.currentReceipt,
    companyID: state.companyID,
    companyInfo: state.companyInfo,
  };
};

export default connect(mapStateToProps, null)(Receipt);
